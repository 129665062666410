export const camel2title = (camelCase: string) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

export const toUnderscoreCase = (camelCase: string) =>
  camel2title(camelCase).toLowerCase().replace(" ", "_");

export function toTitleCase(str: string) {
  return str.replace("_", " ").replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function isNull(value?: number | string | null | undefined) {
  return value === null || value === undefined;
}

export function hasValue(errors: { [K: string]: string }) {
  return Object.values(errors).some((value) => !!value);
}

export function arraySorter<T>(a: T, b: T, key: keyof T, array: string[]) {
  return array.indexOf(a[key] as string) - array.indexOf(b[key] as string);
}

export function sanitizePath(path: string) {
  return path.endsWith("/") ? path : `${path}/`;
}
