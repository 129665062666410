import { gql } from "@urql/core";

const DOCUMENT_FRAGMENT: NidhiDocumentFragmentDoc = gql`
  fragment NidhiDocument on Document {
    id
    key
    documentType
    customerId
    filename
    url
    uploadedAt
  }
`;

export const DOCUMENT_CONNECTION_FRAGMENT: DocumentConnectionFragmentDoc = gql`
  fragment DocumentConnection on DocumentConnection {
    edges {
      node {
        ...NidhiDocument
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;
