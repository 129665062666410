import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const Svg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="currentcolor"
    x="0px"
    y="0px"
    viewBox="0 0 98.85 122.88"
    xmlSpace="preserve"
  >
    <g>
      <path d="M0,48.23l8.38-18.88h31.61c-0.4-1.34-1.09-2.66-2.09-3.95c-1-1.29-2.23-2.4-3.69-3.35c-1.46-0.94-3.09-1.72-4.89-2.29 c-1.8-0.6-3.72-0.89-5.72-0.89H0L8.38,0h90.47l-8.38,18.88H68.62c0.86,0.66,1.66,1.43,2.4,2.32c0.74,0.86,1.4,1.75,1.97,2.72 c0.54,0.94,1.03,1.89,1.43,2.83c0.37,0.94,0.63,1.83,0.74,2.6h23.68l-8.38,18.88H73.34c-1.26,3.2-3.12,6.24-5.61,9.1 c-2.49,2.89-5.38,5.43-8.67,7.72c-3.32,2.29-6.89,4.18-10.76,5.75c-3.86,1.54-7.78,2.57-11.76,3.15l47.54,48.94H46.51L4.12,76.37 V59.04h18.76c1.8,0,3.58-0.29,5.32-0.89c1.77-0.6,3.4-1.4,4.86-2.4c1.49-0.97,2.77-2.15,3.86-3.43c1.09-1.32,1.86-2.66,2.32-4.09H0 L0,48.23z" />
    </g>
  </svg>
);

export const CurrencyIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Svg} {...props} />
);
