import { Divider } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { FormikHelpers } from "formik";
import { gql, useMutation } from "urql";
import * as Yup from "yup";

import { refetchTransactions } from "../../../../hooks/api/accounts/useAccountTransactionsSearch";
import useError from "../../../../hooks/useError";
import { trackEvent } from "../../../../utils/analytics";
import { getAccountDataForAnalytics } from "../../../../utils/analytics/data";
import { getPlaceholderDate, toServerDateNonNull } from "../../../../utils/date";
import Form from "../../../form/Form";
import { FormButtons } from "../../../form/FormButtons";
import FormDateField from "../../../form/FormDateField";
import FormMoneyField from "../../../form/FormMoneyField";
import FormTextArea from "../../../form/FormTextArea";

const DISBURSE_LOAN_MUTATION: DisburseLoanMutationDoc = gql`
  mutation DisburseLoan(
    $borrowerAccountId: Int!
    $amount: Decimal!
    $date: Date!
    $description: String
  ) {
    disburseUnsecuredLoan(
      borrowerAccountId: $borrowerAccountId
      amount: $amount
      date: $date
      description: $description
    ) {
      success
      failureMessage
      transactions {
        id
        legs {
          id
          account {
            id
            currentBalance
          }
        }
      }
    }
  }
`;

type FormData = {
  amount: number | null;
  date: string | Dayjs;
  description?: string;
};

type FormProps = {
  account: AccountDetailsFragment;
  onClose: (done?: boolean) => void;
};

const FORM_VALIDATIONS: {
  [K in keyof FormData]: Yup.AnySchema;
} = {
  amount: Yup.number()
    .nullable()
    .required("Amount is required.")
    .min(1, "Amount should be greater than 1."),
  date: Yup.date().nullable().required("Date of disbursal is required"),
  description: Yup.string().nullable().optional(),
};

export default function DisburseLoan({ account, onClose }: FormProps) {
  const [, disburse] = useMutation(DISBURSE_LOAN_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    if (!values.amount) {
      formikActions.setSubmitting(false);
      return;
    }

    return disburse({
      borrowerAccountId: account.id,
      amount: values.amount,
      date: toServerDateNonNull(values.date),
      description: values.description,
    })
      .then(async (response) => {
        if (
          response.data?.disburseUnsecuredLoan?.success &&
          response.data.disburseUnsecuredLoan.transactions
        ) {
          trackEvent({ action: "Disburse Loan" }, getAccountDataForAnalytics(account));
          refetchTransactions(account.id);
          onClose(true);
        } else {
          setError({
            customError: response.data?.disburseUnsecuredLoan?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to disburse loan, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{
          amount: null,
          date: dayjs().startOf("day"),
        }}
        validationSchema={FORM_VALIDATIONS}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormDateField
                label="Disbursal Date"
                column
                name="date"
                placeholder={getPlaceholderDate()}
                required
                disabledDate={(date) => date < dayjs().subtract(1, "month") || date > dayjs()}
              />

              <FormMoneyField
                column
                label="Loan Amount"
                name="amount"
                placeholder="10,000"
                required
              />

              <FormTextArea
                column
                label="Description"
                name="description"
                placeholder="Optional description"
              />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
