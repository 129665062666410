import { Statistic } from "antd";
import clsx from "clsx";

export default function Stats({
  loading,
  title,
  value,
  className,
  containerClass,
  valueRender,
}: {
  loading?: boolean;
  title: string | React.ReactNode;
  value: string;
  className?: string;
  containerClass?: string;
  valueRender?: () => React.ReactNode;
}) {
  return (
    <div className={clsx(containerClass ? containerClass : "p-2 w-full sm:w-1/2 md:w-1/3")}>
      <Statistic
        className={clsx(className, "rounded-xl w-full border px-4 py-3")}
        title={title}
        loading={loading}
        value={value}
        valueRender={valueRender}
      />
    </div>
  );
}
