import { useState } from "react";
import { Link } from "react-router-dom";
import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps, message, Typography } from "antd";
import clsx from "clsx";

import usePermissions from "../../hooks/api/usePermissions";
import { getApiUrl } from "../../utils/api";
import AnchorLink from "../common/AnchorLink";

export default function Actions({ user }: { user: CurrentUserFragment }) {
  const [open, setOpen] = useState<boolean>(false);
  const { hasPerm } = usePermissions();

  const items: MenuProps["items"] = [];
  if (hasPerm("EDIT_LOAN")) {
    items.push({
      key: "loan",
      label: (
        <Link to="/loans/add" href="/loans/add" onClick={() => setOpen(false)}>
          <AnchorLink>Add Loan</AnchorLink>
        </Link>
      ),
    });
  }
  if (hasPerm("EDIT_CUSTOMER")) {
    items.push({
      key: "customer",
      label: (
        <Link to="/customers/add" href="/customers/add" onClick={() => setOpen(false)}>
          <AnchorLink>Add Customer</AnchorLink>
        </Link>
      ),
    });
  }
  if (hasPerm("RECORD_EXPENSE")) {
    items.push({
      key: "expense",
      label: (
        <Link
          to="/accounts/add-expense"
          href="/accounts/add-expense"
          onClick={() => setOpen(false)}
        >
          <AnchorLink>Add Expense</AnchorLink>
        </Link>
      ),
    });
  }
  if (hasPerm("IS_SUPERUSER")) {
    items.push({
      key: "staffs",
      label: (
        <Link to="/staffs" href="/staffs" onClick={() => setOpen(false)}>
          <AnchorLink>Staffs</AnchorLink>
        </Link>
      ),
    });
  }
  items.push({ key: "divider", type: "divider" });
  items.push({
    key: "phone",
    label: (
      <Typography.Paragraph ellipsis={true} className="text-gray-500 font-medium text-center">
        <div>
          {user.firstName} {user.lastName}
        </div>
        <div>{user.phone}</div>
      </Typography.Paragraph>
    ),
    disabled: true,
  });
  items.push({
    key: "logout",
    danger: true,
    label: (
      <Typography.Link
        strong
        className="text-center"
        onClick={() => {
          fetch(getApiUrl("/admin/logout/"), {
            method: "GET",
            mode: "cors",
            credentials: "include",
            redirect: "manual",
          })
            .then(() => {
              message.success("Logged out successfully.");
              window.location.href = "/accounts/login";
            })
            .catch(() => {
              console.error("Failed to logout successfully.");
            });
        }}
      >
        <div className="text-base">Log Out</div>
      </Typography.Link>
    ),
  });

  return (
    <div
      className={clsx(
        "bg-white border border-green-400 rounded-lg px-2 py-3 justify-center hover:bg-green-200",
        open && "bg-green-200"
      )}
      onClick={() => setOpen(true)}
    >
      <div className="flex items-end h-full text-green-800">
        <Dropdown
          open={open}
          onOpenChange={setOpen}
          menu={{ items }}
          trigger={["click", "hover", "contextMenu"]}
        >
          <MoreOutlined className="text-green-800" color="current" />
        </Dropdown>
      </div>
    </div>
  );
}
