import { useCallback, useMemo, useState } from "react";
import { gql, RequestPolicy, useQuery } from "urql";

import { AccountSearchParams } from "../../../utils/account";
import { getQueryVariables, getURLParams, queryStringUpdate } from "../../../utils/query-params";
import useDebounce from "../../useDebounce";
import { ACCOUNT_DETAILS_FRAGMENT } from "./useAccount";

const ACCOUNTS_SEARCH_QUERY: AccountsSearchQueryDoc = gql`
  query AccountsSearch(
    $searchText: String
    $types: [BooksAccountTypeChoices!]
    $subtypes: [BooksAccountSubtypeChoices]
  ) {
    accounts(
      status: ACTIVE
      search: $searchText
      type_In: $types
      subtype_In: $subtypes
      order: CODE_ASC
    ) {
      edges {
        node {
          ...AccountDetails
        }
      }
    }
  }
  ${ACCOUNT_DETAILS_FRAGMENT}
`;

export type Account = AccountDetailsFragment;

type AccountsSearch = {
  fetching: boolean;
  accounts: Account[];
  searchText: string;
  updateSearchText: (searchText: string) => void;
};

export type AccountsSearchVariblesProps = Omit<AccountsSearchQueryVariables, "searchText">;

type Props = {
  requestPolicy?: RequestPolicy;
  inlineMode?: boolean;
} & AccountsSearchVariblesProps;

export const ACCOUNTS_SEARCH_PARAMS = {
  SEARCH_TEXT: "searchText",
};

export default function useAccountsSearch({
  requestPolicy,
  inlineMode,
  ...accountSearchVariables
}: Props): AccountsSearch {
  function getSearchText() {
    return getQueryParam("searchText") ?? "";
  }

  const getQueryParam = useCallback(
    (param: keyof AccountSearchParams) => {
      if (inlineMode) {
        return;
      }

      const _variables = getQueryVariables(
        inlineMode ? "" : window.location.search,
        {} as AccountSearchParams
      );

      return _variables[param];
    },
    [inlineMode]
  );

  const [searchText, setSearchText] = useState<string>(getSearchText);
  const debouncedSearchText = useDebounce<string>(searchText.trim(), 100);
  const variables = useMemo(() => {
    return {
      searchText: debouncedSearchText ?? undefined,
      ...accountSearchVariables,
    };
  }, [debouncedSearchText, accountSearchVariables]);

  const [{ data, fetching }] = useQuery({ query: ACCOUNTS_SEARCH_QUERY, variables, requestPolicy });

  const accounts = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return (data?.accounts?.edges || []).map((edge) => edge!.node!);
  }, [data]);

  const updateSearchText = (text: string) => {
    setSearchText(text);
    if (!inlineMode) {
      const searchParams = getQueryVariables(window.location.search, {});
      queryStringUpdate(
        getURLParams({ ...searchParams, [ACCOUNTS_SEARCH_PARAMS.SEARCH_TEXT]: text })
      );
    }
  };

  return { fetching, accounts, searchText, updateSearchText };
}
