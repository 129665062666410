import { Divider } from "antd";
import dayjs from "dayjs";
import { FormikHelpers } from "formik";
import { gql, useClient, useMutation } from "urql";

import { GET_LOAN_QUERY, PAYMENT_FRAGMENT } from "../../../hooks/api/loans/useLoan";
import useError from "../../../hooks/useError";
import { trackEvent } from "../../../utils/analytics";
import { getPaymentDataForAnalytics } from "../../../utils/analytics/data";
import { toServerDateNonNull } from "../../../utils/date";
import Form from "../../form/Form";
import { FormButtons } from "../../form/FormButtons";
import { getPaymentFormValidations, PaymentForm, PaymentFormData } from "./PaymentForm";

const ADD_PAYMENT_MUTATION: AddPaymentMutationDoc = gql`
  mutation AddPayment(
    $loanContractId: Int!
    $paymentDate: Date!
    $paymentAmount: Decimal!
    $notes: String
  ) {
    addPayment(
      loanId: $loanContractId
      paymentAmount: $paymentAmount
      paymentDate: $paymentDate
      notes: $notes
    ) {
      success
      failureMessage
      payment {
        ...Payment
      }
    }
  }
  ${PAYMENT_FRAGMENT}
`;

type FormData = PaymentFormData;
type FormProps = {
  loan: CustomerLoanFragment;
  onClose: (done?: boolean) => void;
};
export default function AddPayment({ loan, onClose }: FormProps) {
  const [, addPayment] = useMutation(ADD_PAYMENT_MUTATION);
  const client = useClient();

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return addPayment({
      loanContractId: loan.id,
      paymentDate: values.paymentDate,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      paymentAmount: values.paymentAmount!,
      notes: values.notes,
    })
      .then(async (response) => {
        if (response.data?.addPayment?.success && response.data.addPayment.payment) {
          const payment = response.data.addPayment.payment;
          trackEvent({ action: "Add Payment" }, getPaymentDataForAnalytics(payment));
          await client
            .query(GET_LOAN_QUERY, { id: loan.id }, { requestPolicy: "network-only" })
            .toPromise();
          onClose();
        } else {
          setError({
            customError: response.data?.addPayment?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to add payment, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{
          paymentAmount: null,
          paymentDate: toServerDateNonNull(dayjs()),
          notes: "",
        }}
        validationSchema={getPaymentFormValidations(loan)}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <PaymentForm loan={loan} />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
