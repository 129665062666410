import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import { getIn } from "formik";

import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

type Props = TextAreaProps & { prefix?: string };

const FormTextArea = (props: Props & WithFormFieldWrapperProps) => {
  const { formik, ...rest } = props;
  const { name } = rest;
  const fieldValue = getIn(formik.values, name);
  return (
    <Input.TextArea
      {...rest}
      autoSize
      value={fieldValue}
      onChange={(event) => {
        const {
          name,
          formik: { setFieldValue },
        } = props;
        const newValue = event.target.value;
        setFieldValue(name, newValue);
      }}
    />
  );
};

export default withFormFieldWrapper<Props>(FormTextArea);
