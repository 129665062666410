import { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { CameraFilled, CheckOutlined, CloseOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Image, Skeleton } from "antd";

import { FALLBACK_IMAGE, PHOTO_FORMAT } from "../../../utils/image";
import ActionIconButton from "../ActionIconButton";

type Props = {
  onClose: () => void;
  onSelect: (img: string) => void;
};

export default function WebcamCapture({ onClose, onSelect }: Props) {
  const webcamRef = useRef<Webcam>(null);
  const [webcamReady, setWebcamReady] = useState(false);
  const [cameraMode, setCameraMode] = useState(true);

  const [imgSrc, setImgSrc] = useState<string | undefined>();

  const capture = useCallback(() => {
    const webcam = webcamRef.current;
    if (!webcam) {
      return;
    }
    const imageSrc = webcam.getScreenshot();
    if (imageSrc) {
      setImgSrc(imageSrc);
      setCameraMode(false);
    }
  }, [webcamRef]);

  return (
    <div className="w-full flex flex-col space-y-2">
      <div className="border bg-gray-50 w-full rounded-xl">
        <div className="relative w-full" style={{ minHeight: 250 }}>
          <Webcam
            audio={false}
            style={{ minWidth: "100%" }}
            forceScreenshotSourceSize
            className="rounded-t-xl"
            ref={webcamRef}
            screenshotFormat={PHOTO_FORMAT}
            screenshotQuality={1}
            videoConstraints={{ facingMode: "environment" }}
            onUserMedia={() => setWebcamReady(true)}
          />
          {!webcamReady && (
            <div className="absolute top-0 p-2 w-full justify-center">
              <Skeleton round active title={false} paragraph={{ rows: 6 }} />
            </div>
          )}
          {!cameraMode && imgSrc && (
            <div className="absolute top-0 z-10 bg-gray-50">
              <Image className="rounded-xl" src={imgSrc} fallback={FALLBACK_IMAGE} />
            </div>
          )}
        </div>

        <div className="flex justify-center space-x-2 p-2 w-full items-center">
          <div className="flex w-2/3 justify-center space-x-2 flex-grow">
            {cameraMode ? (
              <Button
                type="primary"
                shape="round"
                size="large"
                className="w-full"
                onClick={capture}
                disabled={!webcamReady}
                icon={<CameraFilled />}
              >
                Take Photo
              </Button>
            ) : (
              <>
                <Button
                  type="default"
                  shape="round"
                  size="large"
                  onClick={() => setCameraMode(true)}
                  icon={<ReloadOutlined />}
                >
                  Retake
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  className="w-full"
                  onClick={() => {
                    imgSrc && onSelect(imgSrc);
                    onClose();
                  }}
                  icon={<CheckOutlined />}
                >
                  Looks Good
                </Button>
              </>
            )}
          </div>
          <div className="flex justify-center flex-grow-0">
            <ActionIconButton
              type="default"
              icon={<CloseOutlined />}
              onClick={onClose}
              ghost={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
