import { useCallback, useMemo } from "react";

import type { PermissionType } from "../../types/enum";
import useUser from "../useUser";

export default function usePermissions() {
  const { user, loadingUser } = useUser();
  const permissions = useMemo(() => {
    return new Set(user ? user.permissions : []);
  }, [user]);

  const hasPerm = useCallback(
    (perm: PermissionType) => permissions.has("IS_SUPERUSER") || permissions.has(perm),
    [permissions]
  );

  return { hasPerm, permissions, loading: loadingUser };
}
