import { Divider } from "antd";
import { FormikHelpers } from "formik";
import { gql, useMutation } from "urql";

import { PAYMENT_FRAGMENT } from "../../../hooks/api/loans/useLoan";
import useError from "../../../hooks/useError";
import { trackEvent } from "../../../utils/analytics";
import { getPaymentDataForAnalytics } from "../../../utils/analytics/data";
import Form from "../../form/Form";
import { FormButtons } from "../../form/FormButtons";
import { getPaymentFormValidations, PaymentForm, PaymentFormData } from "./PaymentForm";

const EDIT_PAYMENT_MUTATION: EditPaymentMutationDoc = gql`
  mutation EditPayment(
    $paymentId: Int!
    $paymentDate: Date!
    $paymentAmount: Decimal!
    $notes: String
  ) {
    editPayment(
      paymentId: $paymentId
      paymentAmount: $paymentAmount
      paymentDate: $paymentDate
      notes: $notes
    ) {
      success
      failureMessage
      payment {
        ...Payment
      }
    }
  }
  ${PAYMENT_FRAGMENT}
`;

type FormData = PaymentFormData;

type FormProps = {
  loan: CustomerLoanFragment;
  payment: PaymentFragment;
  onClose: (done?: boolean) => void;
};

export default function EditPayment({ payment, loan, onClose }: FormProps) {
  const [, editPayment] = useMutation(EDIT_PAYMENT_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return editPayment({
      paymentId: payment.id,
      paymentDate: values.paymentDate,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      paymentAmount: values.paymentAmount!,
      notes: values.notes,
    })
      .then((response) => {
        if (response.data?.editPayment?.success && response.data.editPayment.payment) {
          trackEvent(
            { action: "Edit Payment" },
            getPaymentDataForAnalytics(response.data.editPayment.payment)
          );
          onClose(true);
        } else {
          setError({
            customError: response.data?.editPayment?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to edit payment, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{
          paymentAmount: payment.paymentAmount,
          paymentDate: payment.paymentDate,
          notes: payment.notes,
        }}
        validationSchema={getPaymentFormValidations(loan, payment)}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <PaymentForm loan={loan} payment={payment} />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
