import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { InputNumber, InputNumberProps } from "antd";
import { getIn } from "formik";

import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

type Props = InputNumberProps<number> & {
  inputStyle?: React.CSSProperties;
  maxFieldName: string;
};

const FormMoneyRangeField = (props: Props & WithFormFieldWrapperProps) => {
  const handleChange = (value: number | null, field: string) => {
    const {
      formik: { setFieldValue },
    } = props;
    setFieldValue(field, value);
  };

  const { type, maxFieldName, inputStyle, style, formik, ...rest } = props;
  return (
    <div className="flex space-x-2 items-center">
      <div className="flex flex-col space-y-1">
        <InputNumber
          {...rest}
          controls={false}
          precision={0}
          step={0.0000000000000000000000000001}
          type="number"
          addonBefore="₹"
          placeholder="1000"
          value={getIn(formik.values, props.name)}
          onChange={(value) => handleChange(value, props.name)}
          style={{ width: "100%", ...inputStyle }}
        />
        <div className="text-xs text-gray-500">Min.</div>
      </div>
      <div className="mb-6 text-gray-400">
        <ArrowRightOutlined />
      </div>
      <div className="flex flex-col space-y-1">
        <InputNumber
          {...rest}
          type="number"
          addonBefore="₹"
          controls={false}
          precision={0}
          step={0.0000000000000000000000000001}
          placeholder="5000"
          value={getIn(formik.values, props.maxFieldName)}
          onChange={(value) => handleChange(value, props.maxFieldName)}
          style={{ width: "100%", ...inputStyle }}
        />
        <div className="text-xs text-gray-500">Max.</div>
      </div>
    </div>
  );
};

export default withFormFieldWrapper<Props>(FormMoneyRangeField);
