import { gql } from "urql";

export const PERMISSION_FRAGMENT: PermissionFragmentDoc = gql`
  fragment Permission on Permission {
    id
    permissionEnum
    description
  }
`;

export const BRANCH_FRAGMENT: BranchFragmentDoc = gql`
  fragment Branch on Branch {
    id
    name
    code
    address
    city
  }
`;

export const STAFF_PERMISSIONS_QUERY: StaffPermissionsQueryDoc = gql`
  query StaffPermissions {
    permissions {
      ...Permission
    }
    groups {
      id
      name
    }
    branches {
      edges {
        node {
          ...Branch
        }
      }
    }
  }
  ${PERMISSION_FRAGMENT}
  ${BRANCH_FRAGMENT}
`;

export const STAFF_FRAGMENT: StaffFragmentDoc = gql`
  fragment Staff on User {
    id
    name
    dateJoined
    isActive
    isSuperuser
    phone
    groups {
      id
      name
      permissions {
        ...Permission
      }
    }
    userPermissions {
      ...Permission
    }
    branches {
      ...Branch
    }
  }
  ${BRANCH_FRAGMENT}
  ${PERMISSION_FRAGMENT}
`;
