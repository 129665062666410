import { useState } from "react";
import { useHistory } from "react-router";

import { Customer } from "../components/customers/CustomerCard";

export type Loan = Pick<
  LoanFragment,
  | "id"
  | "pledgeSheetNumber"
  | "interestRate"
  | "loanAmount"
  | "pledgeItemType"
  | "contractStartDate"
  | "loanStatus"
  | "firstMonthInterest"
  | "loanNotes"
  | "partPaymentAmount"
> & {
  customer: Customer;
};

export type Action = "close" | "delete" | "reopen";

export default function useLoanAction() {
  const [selection, setSelection] = useState<{ loan: Loan; action: Action }>();
  const [showLoanForm, setLoanForm] = useState<boolean>();
  const history = useHistory();
  const loan = selection?.loan;
  const action = selection?.action;

  function openLoanForm(loan: Loan, action: Action) {
    setSelection({ loan, action });
    setLoanForm(true);
  }

  function closeLoanForm(success?: boolean) {
    if (success && action && loan) {
      if (action === "delete") {
        history.push(`/customers/${loan.customer.id}`);
      } else {
        history.push(`/loans/${loan.id}`);
      }
    }

    setLoanForm(false);
    setSelection(undefined);
  }

  return {
    openLoanForm,
    closeLoanForm,
    showLoanForm,
    loan,
    action,
  };
}
