/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql } from "@urql/core";
import { Divider } from "antd";
import { FormikHelpers } from "formik";
import { useMutation } from "urql";
import * as Yup from "yup";

import { LOAN_TOP_UP_FRAGMENT } from "../../../hooks/api/loans/useLoan";
import useError from "../../../hooks/useError";
import { trackEvent } from "../../../utils/analytics";
import { getLoanTopUpDataForAnalytics } from "../../../utils/analytics/data";
import { formatCurrency } from "../../../utils/currency";
import { formatDate } from "../../../utils/date";
import LabelValue from "../../common/LabelValue";
import Form from "../../form/Form";
import { FormButtons } from "../../form/FormButtons";
import FormNumberField from "../../form/FormNumberField";
import FormTextArea from "../../form/FormTextArea";

const DELETE_LOAN_TOP_UP_MUTATION: DeleteLoanTopUpMutationDoc = gql`
  mutation DeleteLoanTopUp($loanTopUpId: Int!, $notes: String!) {
    deleteLoanTopUp(loanTopUpId: $loanTopUpId, notes: $notes) {
      success
      failureMessage
      loanTopUp {
        ...LoanTopUp
      }
    }
  }
  ${LOAN_TOP_UP_FRAGMENT}
`;

type FormProps = {
  loan: CustomerLoanFragment;
  loanTopUp: LoanTopUpFragment;
  onClose: (done?: boolean) => void;
};

type FormData = {
  notes: string;
  psn: number | null;
};

export default function DeleteLoanTopUp({ loan, loanTopUp, onClose }: FormProps) {
  const [, deleteLoanTopUp] = useMutation(DELETE_LOAN_TOP_UP_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return deleteLoanTopUp({ loanTopUpId: loanTopUp.id, notes: values.notes })
      .then((response) => {
        if (response.data?.deleteLoanTopUp?.success && response.data.deleteLoanTopUp.loanTopUp) {
          trackEvent(
            { action: "Delete Loan Top Up" },
            getLoanTopUpDataForAnalytics(response.data.deleteLoanTopUp.loanTopUp)
          );
          onClose();
        } else {
          setError({
            customError: response.data?.deleteLoanTopUp?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to delete payment, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <div className="flex space-x-1 items-center justify-between space-y-1">
        <LabelValue label="Top Up Date" value={formatDate(loanTopUp.topUpDate)} />
        <LabelValue label="Top Up Amount" value={formatCurrency(loanTopUp.loanAmount)} />
      </div>

      <Divider className="my-3" />

      <Form<FormData>
        initialValues={{ psn: null, notes: loanTopUp.notes || "" }}
        validationSchema={{
          psn: Yup.number()
            .required("Enter the PSN to delete the payment.")
            .nullable(true)
            .equals([loan.pledgeSheetNumber], "Enter the PSN correctly to delete the payment."),
          notes: Yup.string().required("Enter the reason for deleting the top-up.").nullable(),
        }}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormNumberField
                label="Pledge Sheet Number"
                helper={
                  <span className="text-gray-600 text-sm font-medium">
                    Enter the &quot;PLEDGE SHEET NUMBER&quot; of the loan below to proceed with the
                    payment deletion
                    <span className="text-red-500 px-0.5">*</span>
                  </span>
                }
                name="psn"
                precision={0}
                placeholder={`${loan.pledgeSheetNumber}`}
                column
              />

              <FormTextArea
                helper="Reason for deleting this loan top-up"
                label="Reason"
                name="notes"
                placeholder="Provide a reason for deleting this loan top-up"
                column
                required
              />

              <Divider />

              <FormButtons onCancel={onClose} cta="Delete" isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
