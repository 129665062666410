/* eslint-disable react/forbid-elements */
import React, { useMemo, useState } from "react";
import { Router, useHistory } from "react-router-dom";
import PrinterFilled from "@ant-design/icons/PrinterFilled";
import { Button, Divider, Modal, Table, TableProps } from "antd";
import { GetRowKey } from "antd/es/table/interface";
import type { ColumnProps } from "antd/lib/table";
import clsx from "clsx";
import dayjs from "dayjs";
import { FormikHelpers } from "formik";
import { setTimeout } from "timers";
import * as Yup from "yup";

import { LANDSCAPE_PRINT_SETTINGS, PORTRAIT_PRINT_SETTINGS } from "../../constants/common";
import { usePrint } from "../../hooks/usePrint";
import useUserBranch from "../../hooks/useUserBranch";
import { toTitleCase } from "../../utils/common";
import { formatDateTime } from "../../utils/date";
import Form, { isFunction } from "../form/Form";
import FormCheckboxGroupField from "../form/FormCheckboxGroupField";
import FormRadioField from "../form/FormRadioField";
import FormSwitchField from "../form/FormSwitchField";
import ActionIconButton from "./ActionIconButton";
import CloseIcon from "./CloseIcon";

export type RecordType = { [K: string]: unknown };

export type ColumnType<T> = Omit<ColumnProps<T>, "key"> & {
  key: string;
  dataIndex?: keyof T;
  defaultPrintable?: boolean;
};

type PrintSettings = {
  title: string;
  tableTitle?: React.ReactNode;
  hideExpandInPrint?: boolean;
  orientation?: Orientation;
};

type Orientation = "portrait" | "landscape";
type Props<T> = Omit<TableProps<T>, "columns" | "rowKey" | "summary"> & {
  columns: ColumnType<T>[];
  rowKey: keyof T | GetRowKey<T>;
  printSettings: PrintSettings;
};

type PrintConfigFormData = {
  columns: string[];
  expandRows: boolean;
  orientation: Orientation;
  fileMargin: boolean;
};

const ORIENTATION_OPTIONS = ["landscape", "portrait"].map((orientation) => ({
  label: toTitleCase(orientation),
  value: orientation,
}));

type PrintConfigFormProps<T> = Pick<Props<T>, "columns" | "printSettings" | "expandable"> & {
  onClose: () => void;
  onSubmit: (config: PrintConfigFormData) => void;
};

const PrintConfigForm = <T extends RecordType>({
  printSettings,
  ...props
}: PrintConfigFormProps<T>) => {
  const { columnOptions, allColumns, defaultColumns } = useMemo(() => {
    const columnOptions = props.columns.map((column) => ({
      label: column.title,
      value: column.key,
    }));

    return {
      columnOptions,
      allColumns: columnOptions.map((option) => option.value),
      defaultColumns: props.columns
        .filter((column) => column.defaultPrintable !== false)
        .map((column) => column.key),
    };
  }, [props.columns]);

  function onSubmit(
    values: PrintConfigFormData,
    formikActions: FormikHelpers<PrintConfigFormData>
  ) {
    props.onSubmit(values);

    setTimeout(() => {
      formikActions.setSubmitting(false);
      props.onClose();
    }, 1000);
  }

  return (
    <div className="w-full py-2 px-2">
      <Form<PrintConfigFormData>
        initialValues={{
          columns: defaultColumns,
          expandRows: false,
          orientation: printSettings.orientation || "portrait",
          fileMargin: true,
        }}
        validationSchema={{
          columns: Yup.array().min(1, "Select at-least one column."),
          expandRows: Yup.boolean().required(),
          orientation: Yup.string().required(),
        }}
        validateOnChange
        validateOnBlur
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormCheckboxGroupField
                label="Columns"
                name="columns"
                options={columnOptions}
                allOptionValues={allColumns}
              />

              {!!props.expandable && !printSettings.hideExpandInPrint && (
                <FormSwitchField name="expandRows" label="Expand Rows" />
              )}

              <FormRadioField
                name="orientation"
                label="Orientation"
                optionType="button"
                buttonStyle="solid"
                options={ORIENTATION_OPTIONS}
              />

              <FormSwitchField name="fileMargin" label="File Margin" />

              <Divider />

              <div className="flex w-full justify-between px-2">
                <Button size="large" loading={isSubmitting} onClick={props.onClose}>
                  Cancel
                </Button>
                <Button
                  className="w-48"
                  size="large"
                  key="submit"
                  loading={isSubmitting}
                  type="primary"
                  htmlType="submit"
                >
                  Print
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};

export const TablePrinter = <T extends RecordType>(props: Props<T>) => {
  const MARGIN_COLUMN: ColumnType<T> = {
    key: "margin",
    dataIndex: "margin",
    title: "",
    render: () => <div className="px-4" />,
  };

  const history = useHistory();
  const { branch } = useUserBranch();

  const [showPrintConfigModal, setShowPrintConfigModal] = useState(false);
  const closeModal = () => setShowPrintConfigModal(false);

  const { print, printing } = usePrint({
    title: props.printSettings.title.toUpperCase().replaceAll(",", "_").replaceAll(" ", "_"),
  });

  function getTableToPrint(config: PrintConfigFormData) {
    function getColumns() {
      const _columns = props.columns.filter((column) => config.columns.includes(column.key));
      if (config.fileMargin) {
        return [MARGIN_COLUMN, ..._columns];
      }
      return _columns;
    }
    return (
      <Router history={history}>
        <style type="text/css" media="print">
          {config.orientation === "landscape" ? LANDSCAPE_PRINT_SETTINGS : PORTRAIT_PRINT_SETTINGS}
        </style>

        <Table<T>
          {...props}
          size="small"
          columns={getColumns()}
          bordered
          scroll={undefined}
          expandable={
            config.expandRows
              ? {
                  ...props.expandable,
                  defaultExpandAllRows: true,
                  expandedRowKeys: (props.dataSource || []).map((record) =>
                    isFunction(props.rowKey)
                      ? props.rowKey(record)
                      : (record[props.rowKey] as string)
                  ),
                }
              : undefined
          }
          className="printable-table"
          title={() => (
            <div className="flex flex-col w-full items-center px-2 py-4 space-y-2 bg-green-50">
              {props.printSettings.tableTitle ? (
                props.printSettings.tableTitle
              ) : (
                <div className="text-xl font-medium">{props.printSettings.title.toUpperCase()}</div>
              )}
              {branch && (
                <div className="text-base text-gray-900 font-medium capitalize">
                  {branch.name.toUpperCase()}, {branch.address.toUpperCase()}
                </div>
              )}
            </div>
          )}
          footer={(data) => {
            return (
              <>
                {props.footer && <div className="pb-2">{props.footer(data)}</div>}
                <div className={clsx("text-xs text-gray-600 p-2 font-medium text-center")}>
                  PRINTED @ {formatDateTime(dayjs()).toUpperCase()}
                </div>
              </>
            );
          }}
          summary={undefined}
          pagination={false}
        />
      </Router>
    );
  }

  return (
    <div
      className={clsx(
        "absolute z-50",
        !!props.pagination || !!props.footer
          ? `${props.size === "small" ? "bottom-2" : "bottom-4"} left-2`
          : "left-1/2 bottom-3"
      )}
    >
      <ActionIconButton
        size={props.size}
        disabled={!!props.loading || !props.dataSource?.length}
        icon={<PrinterFilled />}
        loading={printing || showPrintConfigModal}
        onClick={() => setShowPrintConfigModal(true)}
      />
      <Modal
        title={
          <>
            <div className="text-center text-xl font-medium pb-2">Configure Print</div>
            <Divider className="my-2" />
          </>
        }
        centered
        closable
        maskClosable
        open={showPrintConfigModal}
        className="max-w-2xl"
        closeIcon={<CloseIcon />}
        footer={false}
        width="100%"
        onCancel={closeModal}
      >
        <PrintConfigForm
          {...props}
          onClose={closeModal}
          onSubmit={(config) => print(getTableToPrint(config))}
        />
      </Modal>
    </div>
  );
};

export type PrintableTableProps<T> = Omit<Props<T>, "printSettings"> & {
  printSettings: PrintSettings | false;
  // optional printable table
  printableTableProps?: Props<T>;
};

export default function PrintableTable<T extends RecordType>({
  printSettings,
  printableTableProps,
  ...props
}: PrintableTableProps<T>) {
  return (
    <div className="w-full relative">
      <Table<T>
        {...props}
        footer={
          printSettings && !props.footer && !props.pagination
            ? () => <div className="flex justify-center w-full text-gray-500 font-bold h-5" />
            : props.footer
        }
      />

      {printSettings && (
        <>
          <Divider className="my-0" />
          <TablePrinter printSettings={printSettings} {...props} {...printableTableProps} />
        </>
      )}
    </div>
  );
}
