import { useHistory } from "react-router-dom";
import { Divider } from "antd";

import RecordExpense from "./forms/RecordExpense";

export default function AddExpense() {
  const history = useHistory();
  return (
    <div className="w-full flex flex-col justify-center items-center py-4 sm:pb-12 pb-24">
      <div className="text-center text-3xl font-semibold">ADD AN EXPENSE</div>
      <Divider />
      <RecordExpense
        onClose={(done, account) =>
          account ? history.push(`/accounts/${account.id}/`) : history.push("/accounts/")
        }
      />
    </div>
  );
}
