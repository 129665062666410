import { useMemo } from "react";
import { DeleteFilled, EditFilled, PlusCircleFilled } from "@ant-design/icons";
import { Typography } from "antd";
import dayjs from "dayjs";

import { Loan } from "../../hooks/api/loans/useLoan";
import useLoanTopUpAction from "../../hooks/useLoanTopUpAction";
import { renderCurrencyZeroPaise } from "../../utils/currency";
import { formatDate } from "../../utils/date";
import ActionButton from "../common/ActionButton";
import ActionIconButton from "../common/ActionIconButton";
import ResponsiveTable, { ResponsiveColumnType } from "../common/ResponsiveTable";
import LoanTopUpFormModal from "./forms/LoanTopUpFormModal";

type T = [keyof LoanTopUpFragment, string];

const LOAN_TOP_UP_AMOUNT_FIELDS: [keyof LoanTopUpFragment, string][] = [
  ["loanAmount", "Loan Amount"],
  ["firstMonthInterest", "First Month Interest"],
  ["processingFees", "Processing Fees"],
  ["initialInterestAmount", "Investor Interest"],
  ["initialCommissionsAmount", "Commissions"],
];

export default function LoanTopUps({ loan }: { loan: Loan }) {
  const loanTopUpActions = useLoanTopUpAction(loan);
  const { openLoanTopUpForm } = loanTopUpActions;

  const columns = useMemo(() => {
    const columns: ResponsiveColumnType<LoanTopUpFragment>[] = [
      {
        key: "date",
        title: "Date",
        dataIndex: "topUpDate",
        width: 150,
        fixed: "left",
        align: "left",
        render: (value) => formatDate(value),
        sorter: (a, b) => dayjs(a.topUpDate).diff(dayjs(b.topUpDate)),
        cardable: { position: "top", containerClassName: "w-1/3" },
      },
    ];

    LOAN_TOP_UP_AMOUNT_FIELDS.forEach((field) => {
      columns.push({
        title: field[1],
        dataIndex: field[0],
        key: field[0],
        width: 150,
        fixed: field[0] === "loanAmount",
        render: renderCurrencyZeroPaise,
        cardable:
          field[0] === "loanAmount"
            ? { position: "top", containerClassName: "w-1/3", title: "Amount" }
            : { position: "body" },
      });
    });

    columns.push({
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      width: 125,
      render: (value) => (
        <Typography.Paragraph ellipsis={{ tooltip: value }}>{value || "---"}</Typography.Paragraph>
      ),
      ellipsis: true,
      cardable: { position: "bottom" },
    });
    if (loan.loanStatus === "ACTIVE") {
      columns.push({
        title: "Actions",
        key: "actions",
        dataIndex: "id",
        width: 150,
        render: (value, loanTopUp) => (
          <div className="flex space-x-2">
            <ActionIconButton
              perm="EDIT_LOAN"
              icon={<EditFilled />}
              disabled={loanTopUp.initial}
              onClick={() => openLoanTopUpForm({ loanTopUp, action: "edit" })}
            />
            <ActionIconButton
              perm="DELETE_LOAN"
              icon={<DeleteFilled />}
              disabled={loanTopUp.initial}
              onClick={() => openLoanTopUpForm({ loanTopUp, action: "delete" })}
            />
          </div>
        ),
        cardable: { position: "top", containerClassName: "w-1/3 items-end" },
      });
    }
    return columns;
  }, [loan, openLoanTopUpForm]);

  return (
    <>
      <ResponsiveTable<LoanTopUpFragment>
        printSettings={false}
        rowKey="id"
        scroll={{ x: 500 }}
        bordered
        title={() => (
          <div className="flex items-center justify-between">
            <div className="text-center text-xl font-bold">
              {loan.topUps.length ? loan.topUps.length : "NO"} TOP UPS
            </div>
            {loan.loanStatus === "ACTIVE" && (
              <ActionButton
                cta="ADD TOP UP"
                perm="EDIT_LOAN"
                onClick={() => openLoanTopUpForm({ action: "add" })}
                icon={<PlusCircleFilled />}
              />
            )}
          </div>
        )}
        columns={columns}
        dataSource={loan.topUps}
        pagination={{ hideOnSinglePage: true }}
      />

      <LoanTopUpFormModal {...loanTopUpActions} />
    </>
  );
}
