import { AutoComplete, Empty, Input, Skeleton } from "antd";

import useSearch from "../../hooks/api/useSearch";
import useMobile from "../../hooks/useMobile";
import useTablet from "../../hooks/useTablet";
import useWindowSize from "../../hooks/useWindowSize";

export default function SearchBar({ loading }: { loading?: boolean }) {
  const { loading: inputLoading, searchTerm, setSearchTerm, options, onSelect } = useSearch({});
  const { height } = useWindowSize();
  const { isMobile } = useMobile();

  return (
    <div className="w-full flex items-center max-w-xl">
      {loading ? (
        <Skeleton.Input size="large" block active className="w-full mt-2" />
      ) : (
        <AutoComplete
          value={searchTerm}
          popupMatchSelectWidth={!isMobile}
          popupClassName={isMobile ? "max-w-xs" : ""}
          options={options}
          className="w-full mt-0.5"
          listHeight={height ? height - 100 : 500}
          onSelect={onSelect}
          onSearch={setSearchTerm}
          notFoundContent={<Empty />}
        >
          <Input.Search
            size="large"
            loading={inputLoading}
            placeholder="Search for customers or loan"
            enterButton
          />
        </AutoComplete>
      )}
    </div>
  );
}
