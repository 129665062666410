import { Empty, Skeleton } from "antd";

import useLoan from "../../../hooks/api/loans/useLoan";
import { getLoanInvoiceTitle } from "../../../utils/loan";
import Centered from "../../common/Centered";
import LoanInvoice from "./LoanInvoiceA4";
import PrintInvoice from "./PrintInvoice";

export default function LoanInvoicePage() {
  const { loan, fetching } = useLoan();

  return fetching ? (
    <Centered>
      <Skeleton active paragraph={{ rows: 10 }} className="max-w-screen-sm" />
    </Centered>
  ) : loan ? (
    <PrintInvoice title={getLoanInvoiceTitle(loan)}>
      <LoanInvoice loan={loan} />
    </PrintInvoice>
  ) : (
    <Centered>
      <Empty
        description={
          <div className="text-gray-600 text-lg font-medium">
            Unable to fetch the loan details, the loan might have been deleted, can&apos;t generate
            invoice.
          </div>
        }
      />
    </Centered>
  );
}
