import { useMemo } from "react";
import { gql, useQuery } from "urql";

import { BRANCH_FRAGMENT } from "../components/staffs/gql";

const GET_BRANCH_QUERY: GetBranchQueryDoc = gql`
  query GetBranch($branchId: Int!) {
    branches(id: $branchId) {
      edges {
        node {
          ...Branch
        }
      }
    }
  }
  ${BRANCH_FRAGMENT}
`;

export type Branch = { branch?: BranchFragment; loadingBranch: boolean };

export default function useBranch(branchId?: number): Branch {
  const variables = useMemo(() => ({ branchId: branchId ?? 0 }), [branchId]);
  const [{ data, fetching }] = useQuery({
    query: GET_BRANCH_QUERY,
    variables,
    pause: !branchId,
  });

  const branch = useMemo(() => data?.branches?.edges?.[0]?.node, [data]);

  return { branch, loadingBranch: fetching };
}
