import React from "react";
import { InputNumber, InputNumberProps } from "antd";
import { getIn } from "formik";

import { isNull } from "../../utils/common";
import { inrToWords } from "../../utils/inr";
import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

type Props = InputNumberProps<number> & {
  inputStyle?: React.CSSProperties;
};

export const moneyFormatter = (value?: number) =>
  `${value}`.replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
export const moneyParser = (value?: string) => parseFloat((value || "").replace(/\$\s?|(,*)/g, ""));

const FormMoneyField = (props: Props & WithFormFieldWrapperProps) => {
  const handleChange = (value: number | null) => {
    const {
      name,
      formik: { setFieldValue },
    } = props;
    setFieldValue(name, value);
  };

  const { type, inputStyle, style, formik, ...rest } = props;
  const { name } = rest;
  const fieldValue = getIn(formik.values, name);
  return (
    <>
      <InputNumber
        addonBefore="₹"
        value={fieldValue}
        onChange={handleChange}
        precision={0}
        controls={false}
        step={0.0000000000000000000000000001}
        style={{ width: "100%", ...inputStyle }}
        {...rest}
        type="number"
      />
      {!isNull(fieldValue) && (
        <span className="text-gray-500 mt-1 ml-1 font-normal text-base">
          {inrToWords(fieldValue)}
        </span>
      )}
    </>
  );
};

export default withFormFieldWrapper<Props>(FormMoneyField);
