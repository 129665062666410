import React from "react";

import { Loan } from "../../hooks/useLoanAction";
import { formatCurrency, formatRate } from "../../utils/currency";
import { formatDate } from "../../utils/date";
import LabelValue from "../common/LabelValue";
import CustomerCard from "../customers/CustomerCard";

export default function LoanCard({ loan }: { loan: Loan }) {
  return (
    <div className="flex justify-between flex-wrap items-start">
      <div className="w-1/2 font-medium">
        <CustomerCard customer={loan.customer} className="p-0 text-sm" />
      </div>

      <div className="flex w-1/2 flex-col justify-start items-end text-base font-normal">
        <LabelValue row label="PSN #" value={`${loan.pledgeSheetNumber}`} />
        <LabelValue row label="Open Date" value={formatDate(loan.contractStartDate)} />
        <LabelValue row label="Rate" value={formatRate(loan.interestRate)} />
        <LabelValue row label="Loan Value" value={formatCurrency(loan.loanAmount)} />
        {loan.partPaymentAmount && (
          <LabelValue row label="Part Payments" value={formatCurrency(loan.partPaymentAmount)} />
        )}
      </div>
    </div>
  );
}
