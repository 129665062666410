/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql } from "@urql/core";
import { Button, Divider } from "antd";
import dayjs from "dayjs";
import { FormikHelpers } from "formik";
import { useMutation } from "urql";

import useError from "../../../hooks/useError";
import { Loan } from "../../../hooks/useLoanAction";
import { trackEvent } from "../../../utils/analytics";
import { getLoanDataForAnalytics } from "../../../utils/analytics/data";
import { toServerDateNonNull } from "../../../utils/date";
import Form from "../../form/Form";
import { LOAN_CONTRACT_FRAGMENT } from "../fragments";
import { CLOSE_FORM_VALIDATIONS, CloseLoanForm, CloseLoanFormData } from "./LoanForm";

const CLOSE_LOAN_MUTATION: CloseLoanMutationDoc = gql`
  mutation CloseLoan(
    $loanId: Int!
    $closingDate: String!
    $finalInterestAmount: Decimal!
    $closingPayment: Decimal!
  ) {
    closeLoan(
      loanId: $loanId
      closingDate: $closingDate
      finalInterestAmount: $finalInterestAmount
      closingPayment: $closingPayment
    ) {
      success
      failureMessage
      loan {
        ...Loan
      }
    }
  }
  ${LOAN_CONTRACT_FRAGMENT}
`;

type FormProps = {
  loan: Loan;
  onSuccess: () => void;
};

export default function LoanForm({ loan, onSuccess }: FormProps) {
  const [, closeLoan] = useMutation(CLOSE_LOAN_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: CloseLoanFormData, formikActions: FormikHelpers<CloseLoanFormData>) {
    // Reset the error.
    resetError();

    return closeLoan({
      loanId: loan.id,
      closingDate: toServerDateNonNull(dayjs()),
      finalInterestAmount: values.finalInterestAmount!,
      closingPayment: values.closingPayment!,
    })
      .then((response) => {
        if (response.data?.closeLoan?.success && response.data?.closeLoan?.loan) {
          onSuccess();
          const loan = response.data.closeLoan.loan;
          trackEvent({ action: "Close Loan" }, getLoanDataForAnalytics(loan));
        } else {
          setError({
            customError: response.data?.closeLoan?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to close loan, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<CloseLoanFormData>
        initialValues={{
          finalInterestAmount: null,
          closingDate: dayjs().startOf("day"),
          closingPayment: null,
        }}
        validationSchema={CLOSE_FORM_VALIDATIONS}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting, values }) => {
          return (
            <>
              <CloseLoanForm loan={loan} column values={values} />
              <Divider />
              <Button
                shape="round"
                size="large"
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </>
          );
        }}
      </Form>
    </div>
  );
}
