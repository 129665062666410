import dayjs from "dayjs";
import * as Yup from "yup";

import { formatCurrency } from "../../../utils/currency";
import { sum, toNumber } from "../../../utils/number";
import FormDateField from "../../form/FormDateField";
import FormMoneyField from "../../form/FormMoneyField";
import FormTextArea from "../../form/FormTextArea";

export type PaymentFormData = {
  paymentDate: string;
  paymentAmount: number | null;
  notes?: string;
};

function getMaxPaymentAmount(loan: CustomerLoanFragment, payment?: PaymentFragment) {
  const partPaymentAmount = sum(
    toNumber(loan.partPaymentAmount),
    payment ? -toNumber(payment.paymentAmount) : undefined
  );
  return sum(loan.loanAmount, loan.accuredInterest, -partPaymentAmount);
}

export function getPaymentFormValidations(
  loan: CustomerLoanFragment,
  payment?: PaymentFragment
): {
  [K in keyof PaymentFormData]: Yup.AnySchema;
} {
  const partPaymentAmount =
    toNumber(loan.partPaymentAmount) - (payment ? toNumber(payment.paymentAmount) : 0);
  const maxPaymentAmount = getMaxPaymentAmount(loan, payment);
  return {
    paymentDate: Yup.date().nullable().required("Payment date is required"),
    paymentAmount: Yup.number()
      .nullable()
      .required("Payment amount is required")
      .min(10, "Payment amonut should be greater than 10 rupees.")
      .max(
        maxPaymentAmount,
        `Payment amount can't be more than ${formatCurrency(
          maxPaymentAmount,
          0
        )} (Loan Amount: ${formatCurrency(loan.loanAmount, 0)} + Accured Interest: ${formatCurrency(
          loan.accuredInterest,
          0
        )} - Part Payments: ${formatCurrency(partPaymentAmount, 0)}).`
      ),
    notes: Yup.string().nullable().optional(),
  };
}

export function PaymentForm({
  loan,
  payment,
}: {
  loan: CustomerLoanFragment;
  payment?: PaymentFragment;
}) {
  const contractStartDate = dayjs(loan.contractStartDate);
  return (
    <>
      <FormDateField
        label="Payment Date"
        name="paymentDate"
        placeholder="Payment Date"
        required
        column
        disabledDate={(date) => date < contractStartDate || date > dayjs()}
      />

      <FormMoneyField
        column
        required
        label="Payment Amount"
        name="paymentAmount"
        placeholder="500"
        helper={`Maximum payment amount can be ${formatCurrency(
          getMaxPaymentAmount(loan, payment),
          0
        )}`}
      />

      <FormTextArea
        column
        helper="Enter any notes about this payment."
        label="Notes"
        name="notes"
        placeholder="Notes about this payment..."
      />
    </>
  );
}
