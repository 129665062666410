import { LoadingOutlined, LockFilled } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import clsx from "clsx";

import usePermissions from "../../hooks/api/usePermissions";
import useMobile from "../../hooks/useMobile";
import type { PermissionType } from "../../types/enum";
import ActionIconButton from "./ActionIconButton";

type Props = {
  cta: string | React.ReactNode;
  icon: React.ReactNode;
  perm?: PermissionType;
  onClick: () => void;
  loading?: boolean;
  iconOnlyForMobile?: boolean;
} & ButtonProps;

export default function ActionButton({
  cta,
  icon,
  perm,
  loading,
  disabled,
  iconOnlyForMobile,
  onClick,
  ...rest
}: Props) {
  const { hasPerm } = usePermissions();
  const { isMobile } = useMobile();

  return isMobile && iconOnlyForMobile ? (
    <ActionIconButton
      icon={icon}
      onClick={onClick}
      perm={perm}
      loading={loading}
      disabled={disabled}
    />
  ) : (
    <Button
      type="primary"
      shape="round"
      size="large"
      ghost
      disabled={disabled || loading || (perm && !hasPerm(perm))}
      onClick={onClick}
      {...rest}
    >
      <div className={clsx("flex space-x-1 sm:space-x-2 justify-center items-center")}>
        {loading ? <LoadingOutlined /> : !perm || hasPerm(perm) ? icon : <LockFilled />}
        <div className="font-medium">{cta}</div>
      </div>
    </Button>
  );
}
