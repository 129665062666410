/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql } from "@urql/core";
import { Divider } from "antd";
import { FormikHelpers } from "formik";
import { useMutation } from "urql";
import * as Yup from "yup";

import { PAYMENT_FRAGMENT } from "../../../hooks/api/loans/useLoan";
import useError from "../../../hooks/useError";
import { trackEvent } from "../../../utils/analytics";
import { getPaymentDataForAnalytics } from "../../../utils/analytics/data";
import { formatCurrency } from "../../../utils/currency";
import { formatDate } from "../../../utils/date";
import LabelValue from "../../common/LabelValue";
import Form from "../../form/Form";
import { FormButtons } from "../../form/FormButtons";
import FormNumberField from "../../form/FormNumberField";
import FormTextArea from "../../form/FormTextArea";

const DELETE_PAYMENT_MUTATION: DeletePaymentMutationDoc = gql`
  mutation DeletePayment($paymentId: Int!, $notes: String!) {
    deletePayment(paymentId: $paymentId, notes: $notes) {
      success
      failureMessage
      payment {
        ...Payment
      }
    }
  }
  ${PAYMENT_FRAGMENT}
`;

type FormProps = {
  loan: CustomerLoanFragment;
  payment: PaymentFragment;
  onClose: (done?: boolean) => void;
};

type FormData = {
  notes: string;
  psn: number | null;
};

export default function DeletePayment({ loan, payment, onClose }: FormProps) {
  const [, deletePayment] = useMutation(DELETE_PAYMENT_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return deletePayment({ paymentId: payment.id, notes: values.notes })
      .then((response) => {
        if (response.data?.deletePayment?.success && response.data.deletePayment.payment) {
          trackEvent(
            { action: "Delete Payment" },
            getPaymentDataForAnalytics(response.data.deletePayment.payment)
          );
          onClose();
        } else {
          setError({
            customError: response.data?.deletePayment?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to delete payment, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <div className="flex space-x-1 items-center justify-between space-y-1">
        <LabelValue label="Payment Date" value={formatDate(payment.paymentDate)} />
        <LabelValue label="Payment Amount" value={formatCurrency(payment.paymentAmount)} />
      </div>

      <Divider className="my-3" />

      <Form<FormData>
        initialValues={{ psn: null, notes: payment.notes || "" }}
        validationSchema={{
          psn: Yup.number()
            .required("Enter the PSN to delete the payment.")
            .nullable(true)
            .equals([loan.pledgeSheetNumber], "Enter the PSN correctly to delete the payment."),
          notes: Yup.string().required("Enter the reason for deleting the payment.").nullable(),
        }}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormNumberField
                label="Pledge Sheet Number"
                helper={
                  <span className="text-gray-600 text-sm font-medium">
                    Enter the &quot;PLEDGE SHEET NUMBER&quot; of the loan below to proceed with the
                    payment deletion
                    <span className="text-red-500 px-0.5">*</span>
                  </span>
                }
                name="psn"
                precision={0}
                placeholder={`${loan.pledgeSheetNumber}`}
                column
              />

              <FormTextArea
                required
                helper="Enter the reason for deleting the payment."
                label="Reason"
                name="notes"
                placeholder="Reason for deleting the payment"
                column
              />

              <Divider />

              <FormButtons onCancel={onClose} cta="Delete" isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
