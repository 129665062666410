import { Link } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";
import { Button, Divider, List, Result } from "antd";

import { toTitleCase } from "../../utils/common";

const REASONS = [
  "Unauthorized access",
  "Recently switched to a new branch",
  "Invalid / Deleted ID",
];

type Props = {
  back: string;
  title: string;
  backCta?: string;
};

export default function EmptyPage({ back, backCta, title }: Props) {
  return (
    <div className="w-full py-3 mx-auto flex items-center justify-center">
      <Result
        status="500"
        title={toTitleCase(title)}
        subTitle={
          <>
            <Divider orientation="center">Possible Reasons:</Divider>
            <List
              size="large"
              itemLayout="horizontal"
              dataSource={REASONS}
              renderItem={(item) => (
                <List.Item className="text-left text-base">
                  <WarningOutlined className="text-red-500 px-2" /> {item}
                </List.Item>
              )}
            />
            <Divider className="my-2" />
          </>
        }
        extra={
          <div className="w-full">
            <Link to={back} href={back}>
              <Button size="large" block type="primary" href={back} shape="round">
                {backCta}
              </Button>
            </Link>
          </div>
        }
      />
    </div>
  );
}
