import dayjs from "dayjs";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";

import { SERVER_DATE_FORMAT } from "../constants/common";

export const getQueryObject = () => {
  if (typeof window === "undefined") {
    return new URLSearchParams();
  }
  return new URLSearchParams(window.location.search);
};

export function getQueryVariables<T>(query: string, defaults: T): T {
  if (!query) {
    return defaults;
  }

  return query
    .substring(query.startsWith("?") ? 1 : 0) // Remove '?'
    .split("&")
    .reduce((values: T, param: string) => {
      if (!param) {
        return values;
      }
      const pair = decodeURIComponent(`${param}`).split("=");
      (values as { [K: string]: unknown })[pair[0]] = pair[1];
      return values;
    }, {} as T);
}

// Undefined in record means to delete this value from queryString
export const queryStringUpdate = (urlParams: string) => {
  const baseUrl = window.location.pathname;
  const newSearch = `?${urlParams.toString()}`;
  const newUrl = `${baseUrl}${newSearch}`;
  window.history.replaceState(
    {
      ...window.history.state,
      as: newUrl,
      url: newUrl,
    },
    "",
    newUrl
  );
};

export function getURLParams<T>(values: {
  [key: string]: string | string[] | number | undefined | null | T;
}): string {
  return Object.keys(values).reduce((params, key) => {
    const value = values[key];

    // ignore empty/undefined/null values, also don't add limit to the URL.
    if (!value || (isArray(value) && isEmpty(value))) {
      return params;
    }

    return params ? `${params}&${key}=${value}` : `${key}=${value}`;
  }, "");
}

type QueryParam = string | string[] | number;

export const floatParam = (a?: QueryParam | null) =>
  typeof a === "string" ? parseFloat(a) : undefined;

export const intParam = (a?: QueryParam | null) =>
  typeof a === "string" ? parseInt(a) : typeof a === "number" ? a : undefined;

export const stringParam = (a?: QueryParam | null) => (typeof a === "string" ? a : undefined);

export const arrayParam = <V>(a?: QueryParam | null): V[] | undefined =>
  (typeof a === "string" ? a?.split(",") : a) as unknown as V[] | undefined;

export const booleanParam = (b?: QueryParam | null) => (b ? b === "true" : undefined);
export const dateParam = (d?: QueryParam | null) => {
  if (!d) {
    return;
  }
  const date = dayjs(d as string, SERVER_DATE_FORMAT);
  const _date = date.isValid() ? date.format(SERVER_DATE_FORMAT) : undefined;
  return _date ?? undefined;
};

export const objectArrayParam = (g?: QueryParam | null) => {
  if (typeof window === "undefined") {
    // only in client mode
    return undefined;
  }

  if (Array.isArray(g)) {
    return undefined;
  }

  return `${g}`
    ?.split(",")
    .map(atob)
    .map((a) => JSON.parse(a));
};
