/* eslint-disable react/forbid-elements */
import React from "react";
import { Divider } from "antd";

import { Payment } from "../../../hooks/api/loans/useLoan";
import { formatCurrency } from "../../../utils/currency";
import { formatNumber } from "../../../utils/number";
import Watermark from "../../common/Watermark";
import InvoiceItem, { InvoiceItemTamil, InvoiceLabel } from "./InvoiceItem";
import PrintedAt from "./PrintedAt";

function Label({ children }: { children: React.ReactNode }) {
  return <div className="text-sm font-semibold text-gray-400">{children}</div>;
}

const NOT_AVAILABLE = "---";

const PRINT_SETTINGS = `
  @page {
    filter: grayscale(100%);
    size: portrait;
  }
`;

export default function PaymentInvoice({ payment }: { payment: Payment }) {
  const loan = payment.loanContract;

  return (
    <>
      <style type="text/css" media="print">
        {PRINT_SETTINGS}
      </style>

      <div className="flex flex-col items-center mt-6 px-1 mb-12">
        <div className="w-full max-w-sm">
          <div className="flex flex-col justify-center p-2 text-3xl font-bold text-center">
            <InvoiceLabel label="PAYMENT RECEIPT" className="text-2xl font-bold" />
            <div>
              #{loan.pledgeSheetNumber}-{payment.id}
            </div>
          </div>

          <InvoiceLabel
            label="CUSTOMER COPY"
            className="text-center font-base text-gray-400 font-bold"
          />

          <Divider className="border-gray-500 border-dashed" />

          <div className="text-sm px-1.5 flex flex-col text-gray-800 space-y-1">
            <div className="flex justify-between space-x-2 text-base pb-2">
              <InvoiceLabel label="Nidhi Customer ID" />
              <div className="text-gray-900 font-bold"># {loan.customer.id}</div>
            </div>

            <div className="text-base text-gray-900 font-bold"> {loan.customer.user.name}</div>
            <div>{loan.customer.user.phone}</div>
            <div>
              {loan.customer.careOfType} {loan.customer.careOfName}
            </div>
            <div>{loan.customer.address}</div>
          </div>

          <div className="relative py-3 mt-6 border px-3 rounded-lg space-y-3">
            <Watermark size={175} />

            <InvoiceLabel label="PAYMENT DETAILS" />
            <InvoiceItemTamil
              label="Receipt ID"
              highlight
              value={`${loan.pledgeSheetNumber}-${payment.id}`}
            />
            <InvoiceItemTamil label="Date" value={payment.paymentDate} date translateValue />
            <InvoiceItemTamil label="Amount" value={formatCurrency(payment.paymentAmount)} />

            <Divider />

            <InvoiceLabel label="LOAN DETAILS" />
            <InvoiceItem label="PSN #" highlight>
              {loan.pledgeSheetNumber}
            </InvoiceItem>
            <InvoiceItemTamil
              label="Open Date"
              value={loan.contractStartDate}
              date
              translateValue
            />
            <InvoiceItemTamil label="Amount" value={formatCurrency(loan.loanAmount)} />

            <InvoiceItemTamil label="Type" value={loan.pledgeItemType} translateValue />

            <InvoiceItemTamil
              label="Weight"
              value={
                loan.pledgeItemWeight
                  ? `${formatNumber(loan.pledgeItemWeight)} grams`
                  : NOT_AVAILABLE
              }
              translateValue={(value, t) => value.replaceAll("grams", t("grams"))}
            />
            <InvoiceItemTamil label="Description" value={loan.pledgeItemDesc || NOT_AVAILABLE} />
          </div>
          <PrintedAt />
          <Divider />
        </div>
      </div>
    </>
  );
}
