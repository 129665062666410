import { Divider, Skeleton } from "antd";

import useCustomersSearch, {
  CustomersSearchProps,
} from "../../../hooks/api/customers/useCustomersSearch";
import CustomersFilters from "./CustomerFilters";
import CustomersList from "./CustomersList";

export function Customers(props: CustomersSearchProps) {
  const customersSearch = useCustomersSearch(props);
  const { loading, total } = customersSearch;

  return (
    <div className="w-full h-full">
      <div className="text-2xl font-bold py-2 text-center capitalize">
        CUSTOMERS SEARCH{" "}
        <span className="text-base px-2 text-gray-600">
          Showing {loading ? <Skeleton.Button shape="round" active size="small" /> : total}{" "}
          Customers
        </span>
      </div>
      <CustomersFilters customersSearch={customersSearch} />

      <div className="py-0.5">
        <CustomersList customersSearch={customersSearch} />
      </div>
    </div>
  );
}

export const CustomersSearch = () => <Customers />;
