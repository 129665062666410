/* eslint-disable react/forbid-elements */

import { Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";

import { Loan } from "../../../hooks/api/loans/useLoan";
import useTranslation from "../../../hooks/useTranslation";
import { formatCurrency } from "../../../utils/currency";
import { TIMEZONE } from "../../../utils/date";
import { formatNumber, sum } from "../../../utils/number";

const PRINT_SETTINGS = `
@page {
  size: A4 portrait;
  margin: 0;
  filter: grayscale(100%);
}
`;

const formatDate = (date: string | Dayjs) => dayjs(date).tz(TIMEZONE).format("DD/MM/YYYY");

const PledgeSheet = ({ loan, customerCopy }: { loan: Loan; customerCopy: boolean }) => {
  const { t } = useTranslation();
  const containerTop = customerCopy ? 10 : 540;
  const containerLeft = 35;
  return (
    <>
      <div
        className="flex flex-col items-end text-right absolute"
        style={{ top: containerTop + 20, right: 50 }}
      >
        <div className="font-semibold text-lg">{loan.pledgeSheetNumber}</div>
        <div>{formatDate(loan.contractStartDate)}</div>
        <div className="pt-3">{formatCurrency(loan.loanAmount)}</div>
        <div className="font-bold text-xl">{t(loan.pledgeItemType)}</div>
      </div>

      <div
        className="flex w-full absolute"
        style={{ top: containerTop + 145, left: containerLeft }}
      >
        <div className="flex flex-col w-3/5">
          <div className="pl-40">{loan.customer.user.name}</div>
          <div className="pl-40">
            {loan.customer.careOfType} {loan.customer.careOfName}
          </div>
          <Typography.Paragraph className="whitespace-normal pl-40 mb-0" ellipsis={{ rows: 2 }}>
            {loan.customer.address}
          </Typography.Paragraph>
          <div className="font-semibold pl-40 -mt-4">{loan.customer.user.phone}</div>
        </div>
      </div>

      <div
        className="flex w-full pl-1 pr-12 items-start absolute"
        style={{ top: containerTop + 300, left: containerLeft }}
      >
        <div className="flex w-3/5">
          <div className="w-4 text-center">1.</div>
          <div className="flex pl-4 pr-1 flex-col whitespace-pre-wrap flex-grow">
            {loan.pledgeItemDesc} <div>மதிப்பீடு : {formatCurrency(loan.pledgeItemEstimate)}</div>
            {!customerCopy && (
              <div className="font-medium flex flex-wrap justify-center text-xl pt-0.5 text-center w-full">
                Tendered Amount:{" "}
                {loan.hasTopUps
                  ? loan.topUps.map((topUp, index) => (
                      <div key={topUp.id}>
                        {formatCurrency(
                          sum(topUp.loanAmount, -topUp.firstMonthInterest, -topUp.processingFees)
                        )}
                        {index + 1 !== loan.topUps.length ? " + " : ""}
                      </div>
                    ))
                  : formatCurrency(
                      sum(loan.loanAmount, -loan.firstMonthInterest, -loan.processingFees)
                    )}
              </div>
            )}
          </div>
        </div>
        <div className="w-2/5 flex justify-between">
          <div className="text-left">1</div>
          <div>{loan.pledgeItemWeight ? formatNumber(loan.pledgeItemWeight, 3) : ""} கிராம்</div>
          <div className="flex flex-col space-y-1 pr-10">
            {loan.hasTopUps
              ? loan.topUps.map((topUp) => (
                  <div key={topUp.id}>{formatCurrency(topUp.loanAmount)}</div>
                ))
              : formatCurrency(loan.loanAmount)}
          </div>
        </div>
      </div>

      <div
        className="absolute flex w-full justify-between"
        style={{ top: containerTop + 415, left: containerLeft }}
      >
        <div className="pl-60">
          {formatDate(dayjs(loan.contractStartDate).add(1, "months").subtract(1, "days"))}
        </div>
        <div className="font-semibold text-lg pr-20">{formatCurrency(loan.loanAmount)}</div>
      </div>
      {customerCopy && (
        <div
          className="absolute flex w-full justify-end pr-32"
          style={{ top: containerTop + 455, left: containerLeft }}
        >
          XXXXXXXXXXXXXXX
        </div>
      )}
    </>
  );
};

const PledgeCard = ({ loan }: { loan: Loan }) => {
  const top = 1060;
  const { t } = useTranslation();
  return (
    <div className="flex justify-evenly w-full absolute mt-1.5" style={{ top }}>
      <div className="w-1/3 p-2 text-center overflow-hidden">
        <div>{loan.customer.user.phone}</div>
        <Typography.Paragraph ellipsis={{ rows: 3 }} className="text-center">
          {loan.customer.address}
        </Typography.Paragraph>
      </div>

      <div className="w-1/3 px-2 flex flex-col items-center border-l border-r border-dashed border-gray-500 overflow-hidden">
        <div className="flex justify-between pb-1 font-bold w-full text-xl">
          <div>{loan.pledgeSheetNumber}</div>
          <div>{formatCurrency(loan.loanAmount, 2)}</div>
        </div>
        <div className="flex justify-between items-center font-medium w-full">
          <div className="w-1/2">
            <Typography.Text ellipsis>{loan.customer.user.name}</Typography.Text>
            <Typography.Text ellipsis>
              {loan.customer.careOfType} {loan.customer.careOfName}
            </Typography.Text>
          </div>
          <div className="text-xl">{formatDate(loan.contractStartDate)}</div>
        </div>
      </div>

      <div className="w-1/3 text-center overflow-hidden">
        <div className="font-bold">
          {t(loan.pledgeItemType)} /{" "}
          {loan.pledgeItemWeight ? formatNumber(loan.pledgeItemWeight, 3) : "--"} கிராம்
        </div>
        <div className="whitespace-nowrap">{loan.pledgeItemDesc}</div>
      </div>
    </div>
  );
};

export default function LoanInvoice({ loan }: { loan: Loan }) {
  return (
    <>
      <style type="text/css" media="print">
        {PRINT_SETTINGS}
      </style>

      <div className="mx-auto max-w-screen-lg flex flex-col text-black relative">
        <PledgeSheet customerCopy loan={loan} />
        <PledgeSheet customerCopy={false} loan={loan} />
        <PledgeCard loan={loan} />
      </div>
    </>
  );
}
