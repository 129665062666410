import { DeleteFilled, EditFilled, PlusCircleFilled } from "@ant-design/icons";
import { Card, Empty, Image, Typography } from "antd";

import useDocumentAction from "../../hooks/api/documents/useDocumentAction";
import { Document } from "../../hooks/api/documents/useUploadDocument";
import { FALLBACK_IMAGE } from "../../utils/image";
import ActionButton from "../common/ActionButton";
import ActionIconButton from "../common/ActionIconButton";
import DocumentActionModal from "./DocumentActionModal";

type Props = {
  customer: CustomerDetailsFragment;
  documents: NidhiDocumentFragment[];
  loan?: LoanFragment;
  onChange: (document: Document) => void;
};

export default function DocumentsList({ customer, loan, documents, onChange }: Props) {
  const documentAction = useDocumentAction();
  const { openForm } = documentAction;
  return (
    <>
      <DocumentActionModal
        {...documentAction}
        customer={customer}
        loan={loan}
        onChange={onChange}
      />

      <Card
        bodyStyle={{ padding: 4 }}
        title={<div className="text-xl font-bold">DOCUMENTS</div>}
        extra={
          <ActionButton
            cta="ADD DOCUMENT"
            perm="UPLOAD_DOCUMENT"
            onClick={() => openForm({ action: "add" })}
            icon={<PlusCircleFilled />}
          />
        }
      >
        {documents.length > 0 ? (
          <div className="flex flex-wrap justify-evenly">
            {documents.map((document) => (
              <Card
                headStyle={{ padding: 12 }}
                rootClassName="mr-3 mb-2"
                key={document.key}
                type="inner"
                className="w-64"
                bodyStyle={{ padding: 0 }}
                title={document.documentType.replaceAll("_", " ")}
                cover={
                  <Image
                    loading="lazy"
                    alt={document.documentType}
                    src={document.url}
                    fallback={FALLBACK_IMAGE}
                    className="rounded-xl w-full bg-gray-100"
                  />
                }
                extra={[
                  <ActionIconButton
                    key="edit"
                    className="text-green-500"
                    onClick={() => openForm({ document, action: "edit" })}
                    icon={<EditFilled />}
                    perm="UPLOAD_DOCUMENT"
                    type="text"
                  />,
                  <ActionIconButton
                    key="delete"
                    className="text-green-500"
                    onClick={() => openForm({ document, action: "delete" })}
                    icon={<DeleteFilled />}
                    perm="DELETE_DOCUMENT"
                    type="text"
                  />,
                ]}
              >
                <Typography.Paragraph
                  className="mt-1 mb-0 px-2 font-normal text-sm"
                  ellipsis={true}
                >
                  {document.filename}
                </Typography.Paragraph>
              </Card>
            ))}
          </div>
        ) : (
          <Empty className="py-2" />
        )}
      </Card>
    </>
  );
}
