import { Divider } from "antd";
import { FormikHelpers } from "formik";
import { gql, useMutation } from "urql";

import { LOAN_TOP_UP_FRAGMENT } from "../../../hooks/api/loans/useLoan";
import useError from "../../../hooks/useError";
import { trackEvent } from "../../../utils/analytics";
import { getLoanTopUpDataForAnalytics } from "../../../utils/analytics/data";
import Form from "../../form/Form";
import { FormButtons } from "../../form/FormButtons";
import { getLoanTopUpFormValidations, LoanTopUpForm, LoanTopUpFormData } from "./LoanTopUpForm";

const EDIT_LOAN_TOP_UP_MUTATION: EditLoanTopUpMutationDoc = gql`
  mutation EditLoanTopUp(
    $loanTopUpId: Int!
    $topUpDate: Date!
    $loanAmount: Decimal!
    $firstMonthInterest: Decimal!
    $processingFees: Decimal!
    $notes: String
  ) {
    editLoanTopUp(
      loanTopUpId: $loanTopUpId
      topUpDate: $topUpDate
      loanAmount: $loanAmount
      firstMonthInterest: $firstMonthInterest
      processingFees: $processingFees
      notes: $notes
    ) {
      success
      failureMessage
      loanTopUp {
        ...LoanTopUp
      }
    }
  }
  ${LOAN_TOP_UP_FRAGMENT}
`;

type FormData = LoanTopUpFormData;

type FormProps = {
  loan: CustomerLoanFragment;
  loanTopUp: LoanTopUpFragment;
  onClose: (done?: boolean) => void;
};

export default function EditLoanTopUp({ loanTopUp, loan, onClose }: FormProps) {
  const [, editLoanTopUp] = useMutation(EDIT_LOAN_TOP_UP_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return editLoanTopUp({
      loanTopUpId: loanTopUp.id,
      topUpDate: values.topUpDate,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      loanAmount: values.loanAmount!,
      firstMonthInterest: values.firstMonthInterest ?? 0,
      processingFees: values.processingFees ?? 0,
      notes: values.notes,
    })
      .then((response) => {
        if (response.data?.editLoanTopUp?.success && response.data.editLoanTopUp.loanTopUp) {
          trackEvent(
            { action: "Edit Loan Top Up" },
            getLoanTopUpDataForAnalytics(response.data.editLoanTopUp.loanTopUp)
          );
          onClose(true);
        } else {
          setError({
            customError: response.data?.editLoanTopUp?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to edit loan top up, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{
          topUpDate: loanTopUp.topUpDate,
          interestRate: loan.interestRate * 100,
          loanAmount: loanTopUp.loanAmount || null,
          firstMonthInterest: loanTopUp.firstMonthInterest,
          processingFees: loanTopUp.processingFees,
        }}
        validationSchema={getLoanTopUpFormValidations(loan)}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting, values }) => {
          return (
            <>
              <LoanTopUpForm loan={loan} values={values} edit />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
