import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { List } from "antd";
import clsx from "clsx";
import { FormikErrors, FormikTouched } from "formik";

import { showError } from "../../utils/form";

export default function FormError<T>({
  error,
  errors,
  touched,
}: {
  error?: string;
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
}) {
  const errorClass = "text-red-500 text-lg font-medium text-center mt-4";
  return error ? (
    <div className={clsx(errorClass, "mb-4")}>{error}</div>
  ) : showError(errors, touched) ? (
    <div className="flex flex-col space-y-2 w-full items-center">
      <div className={errorClass}>Fix the form validation errors!!!</div>
      <List<string>
        className="rounded-lg"
        size="small"
        dataSource={Object.values(errors)}
        renderItem={(item) => (
          <List.Item>
            <CloseOutlined color="current" className="text-red-400 font-semibold mr-2" />{" "}
            <span className="text-red-400">{item}</span>
          </List.Item>
        )}
      />
    </div>
  ) : (
    <></>
  );
}
