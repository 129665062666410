import type { Maybe } from "../gql/graphql";

export function formatNumber(value: number, digits?: number) {
  return Number(value).toLocaleString(
    "en-IN",
    digits
      ? {
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,
        }
      : {}
  );
}

export function sum(...args: Maybe<number | null>[]) {
  return args.reduce((result: number, value) => result + (value ? parseFloat(`${value}`) : 0), 0);
}

export function toNumber(value: Maybe<number>) {
  return value ? parseFloat(`${value}`) : 0;
}

export function roundToNearest5(value: number) {
  return Math.round(value / 5) * 5;
}
