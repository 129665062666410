import { useCallback, useMemo } from "react";
import { Card, Divider, Empty, Skeleton, Statistic } from "antd";
import dayjs from "dayjs";

import { TIMELINE_CHART_OPTIONS } from "../../constants/charts";
import { SERVER_DATE_FORMAT } from "../../constants/common";
import useOpenCloseLoansMetrics from "../../hooks/api/loans/useOpenCloseLoansMetrics";
import useMobile from "../../hooks/useMobile";
import { formatCurrency, formatMoneyHumanReadable } from "../../utils/currency";
import { formatNumber } from "../../utils/number";
import { Charts } from "../common/Charts";
import DateRangePickerFilter from "../common/DateRangePickerFilter";
import LoansSearchModal, { useLoansSearchModal } from "../loans/search/LoansSearchModal";
import TimelineSelector from "./ChartTimelineSelector";

type MetricOpenField = "openedLoansAmount" | "openedLoansCount";
type MetricCloseField = "closedLoansAmount" | "closedLoansCount";

type Field = { name: string; field: MetricOpenField | MetricCloseField; currency?: boolean };

function NoProfits() {
  return (
    <div className="w-full py-10 flex items-center justify-center">
      <Empty
        description={
          <div className="text-gray-600 text-lg font-medium">
            No realized profits for the selected time period.
          </div>
        }
      />
    </div>
  );
}

export default function OpenCloseLoans() {
  const openCloseLoansMetrics = useOpenCloseLoansMetrics({});
  const { metrics, filters, loading } = openCloseLoansMetrics;
  const timeline = filters.timeline || "quarter";
  const { isMobile } = useMobile();
  const {
    openLoansSearch,
    closeLoansSearch,
    showLoansSearch,
    filters: searchFilters,
  } = useLoansSearchModal();

  const total = useMemo(() => {
    return {
      openedLoansCount: metrics.reduce((count, t) => count + t.openedLoansCount, 0),
      openedLoansAmount: metrics.reduce((count, t) => count + t.openedLoansAmount, 0),
      closedLoansCount: metrics.reduce((count, t) => count + t.closedLoansCount, 0),
      closedLoansAmount: metrics.reduce((count, t) => count + t.closedLoansAmount, 0),
    };
  }, [metrics]);

  const getOptions = useCallback(
    ({
      series,
      currency,
    }: {
      series: Highcharts.SeriesColumnOptions[] | Highcharts.SeriesPieOptions[];
      currency?: boolean;
    }): Highcharts.Options => {
      return {
        ...TIMELINE_CHART_OPTIONS,
        series,

        yAxis: {
          labels: {
            enabled: !isMobile,
            formatter: function () {
              const label = this.axis.defaultLabelFormatter.call(this);

              // Use thousands separator for four-digit numbers too
              if (/^[\d].*$/.test(label)) {
                return currency
                  ? formatMoneyHumanReadable(this.value as number)
                  : formatNumber(this.value as number);
              }
              return label;
            },
          },
          title: undefined,
        },
      };
    },
    [isMobile]
  );

  const getData = useCallback(
    (
      fields: [
        { field: MetricOpenField; name: string; currency?: boolean },
        { field: MetricCloseField; name: string; currency?: boolean }
      ]
    ): Highcharts.SeriesColumnOptions[] => {
      const getBarLabel = (date: string) => {
        const startDate = dayjs(date);

        switch (timeline) {
          case "month":
            return startDate.format("MMM YY");
          case "week":
            return `${startDate.format("MMM DD")} - ${dayjs(date)
              .endOf(timeline)
              .format("MMM DD")}, ${startDate.format("YY")}`;
          case "quarter":
            return `Q${startDate.format("Q YYYY")}`;
          case "year":
            return startDate.format("YYYY");
          case "day":
            return startDate.format("MMM DD, YYYY");
        }
      };

      function getSeries(values: { [K: string]: number }, field: Field) {
        const data = Object.keys(values)
          .map((date) => ({ date, label: getBarLabel(date), value: values[date] }))
          .filter((value) => value.value)
          .sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf())
          .map((value) => {
            return {
              date: value.date,
              label: value.label,
              value: value.value,
              startDate: dayjs(value.date).startOf(timeline),
              endDate: dayjs(value.date).endOf(timeline),
            };
          });

        const _series: Highcharts.SeriesColumnOptions = {
          name: field.name,
          type: "column",
          color: ["openedLoansCount", "openedLoansAmount"].includes(field.field)
            ? "#f9a8d4"
            : "#c084fc",
          data: data.map((value) => {
            const startDate = filters.startDate
              ? dayjs.max(value.startDate, dayjs(filters.startDate))
              : value.startDate;

            const endDate = filters.endDate
              ? dayjs.min(value.endDate, dayjs(filters.endDate))
              : value.endDate;

            return {
              seriesName: field.name,
              key: value.label,
              name: value.label,
              label: value.label,
              y: value.value,
              val: field.currency ? formatCurrency(value.value) : formatNumber(value.value),
              valHuman: field.currency
                ? formatMoneyHumanReadable(value.value)
                : formatNumber(value.value),
              startDateServer: startDate?.format(SERVER_DATE_FORMAT),
              endDateServer: endDate?.format(SERVER_DATE_FORMAT),
              startDate: startDate?.format("MMM DD, YY"),
              endDate: endDate?.format("MMM DD, YY"),
            };
          }),
          events: {
            click(event) {
              const _event = event.point as unknown as {
                startDateServer: string;
                endDateServer: string;
              };
              if (["openedLoansCount", "openedLoansAmount"].includes(field.field)) {
                openLoansSearch({
                  contractStartDateMin: _event.startDateServer,
                  contractStartDateMax: _event.endDateServer,
                  first: 5,
                });
              } else {
                openLoansSearch({
                  closingDateMin: _event.startDateServer,
                  closingDateMax: _event.endDateServer,
                  first: 5,
                });
              }
            },
          },
          tooltip: {
            headerFormat: "",
            pointFormat: `<span style="font-size: 13px; font-weight: 700; padding-bottom: 24px;">{point.name}</span>
          <br />
          <span style="font-size: 12px;font-weight: 500;">{point.startDate} - {point.endDate}</span>
          <br />
          <b style="font-size: 13px; font-weight: 700">{point.seriesName}: {point.val}</b><br />`,
          },
          dataLabels: { enabled: true, format: "{point.valHuman}" },
          showInLegend: true,
        };
        return _series;
      }

      return fields.map((field) => {
        const values = {} as { [K: string]: number };
        metrics.forEach((value) => {
          const key = value.metricDate.clone().startOf(timeline).format(SERVER_DATE_FORMAT);
          values[key] = values[key] ? values[key] + value[field.field] : value[field.field];
        });

        return getSeries(values, field);
      });
    },
    [filters, metrics, timeline, openLoansSearch]
  );

  const { loansCountOptions, loansAmountOptions } = useMemo(() => {
    const loansCountOptions = getOptions({
      series: getData([
        { field: "openedLoansCount", name: "New Loans Count" },
        { field: "closedLoansCount", name: "Closed Loans Count" },
      ]),
    });
    const loansAmountOptions = getOptions({
      series: getData([
        { field: "openedLoansAmount", name: "New Loans Amount", currency: true },
        { field: "closedLoansAmount", name: "Closed Loans Amount", currency: true },
      ]),
      currency: true,
    });
    return { loansCountOptions, loansAmountOptions };
  }, [getData, getOptions]);

  return (
    <>
      <Card
        className="rounded-xl mb-2"
        bodyStyle={isMobile ? { padding: 0, paddingTop: 8 } : {}}
        title={
          <div className="flex flex-col justify-center my-5">
            <div className="text-3xl py-2 font-bold text-center text-gray-600 whitespace-normal">
              Newly Opened/Closed Loans
            </div>

            <div className="flex mt-2 sm:mb-2 items-center justify-center sm:justify-between space-x-2 space-y-2 flex-wrap">
              <Statistic
                className="rounded-xl max-w-xs w-full border py-2 px-4"
                title="Opened Loans Count / Amount"
                loading={loading}
                valueRender={() => (
                  <div className="flex  justify-between">
                    <div>{formatNumber(total.openedLoansCount)}</div>
                    <div>{formatMoneyHumanReadable(total.openedLoansAmount)}</div>
                  </div>
                )}
              />
              <Statistic
                className="rounded-xl border max-w-xs w-full py-2 px-4"
                title="Closed Loans Count / Amount"
                loading={loading}
                valueRender={() => (
                  <div className="flex justify-between w-full">
                    <div>{formatNumber(total.closedLoansCount)}</div>
                    <div>{formatMoneyHumanReadable(total.closedLoansAmount)}</div>
                  </div>
                )}
              />
            </div>

            <Divider className="mt-2 mb-1" />

            <div className="flex w-full flex-col-reverse sm:flex-row pt-3 items-center justify-center sm:justify-between flex-wrap">
              <div className="mr-2 mb-2 flex w-full max-w-sm justify-center sm:justify-start">
                <TimelineSelector {...openCloseLoansMetrics} />
              </div>
              <div className="mr-2 mb-2 flex w-full max-w-sm justify-center sm:justify-start">
                <DateRangePickerFilter {...openCloseLoansMetrics} />
              </div>
            </div>
          </div>
        }
      >
        <Skeleton active paragraph={{ rows: 15 }} title={false} loading={loading}>
          {metrics.length > 0 ? (
            <>
              <Charts key="loans-count" options={loansCountOptions} showDivider />
              <Charts key="loans-amount" options={loansAmountOptions} />
            </>
          ) : (
            <NoProfits />
          )}
        </Skeleton>
      </Card>

      <div className="py-10" />
      {showLoansSearch && <LoansSearchModal filters={searchFilters} onClose={closeLoansSearch} />}
    </>
  );
}
