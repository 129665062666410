import { useState } from "react";
import { Radio, RadioChangeEvent } from "antd";
import clsx from "clsx";

import type { Document } from "../../hooks/api/documents/useUploadDocument";
import useTablet from "../../hooks/useTablet";
import type { DocumentType } from "../../types/enum";
import {
  getCustomerDocumentName,
  getDocumentTypeTitle,
  getLoanDocumentName,
} from "../../utils/document";
import PhotoInput from "../common/webcam/PhotoInput";
import { FormFieldLabel } from "../form/FormFieldWrapper";

type Props = {
  customer: CustomerFragment;
  loan?: LoanFragment;
  onSucess: (document: Document) => void;
};

const getDocumentTypeOptions = (types: DocumentType[]): { label: string; value: string }[] =>
  types.map((type) => ({
    label: getDocumentTypeTitle(type).replace("CUSTOMER", "").trim(),
    value: type,
  }));

const LOAN_DOCUMENT_TYPES = getDocumentTypeOptions(["LOAN_ITEM", "OTHER"]);
const CUSTOMER_DOCUMENT_TYPES = getDocumentTypeOptions([
  "CUSTOMER_PHOTO",
  "CUSTOMER_ID_CARD",
  "OTHER",
]);

export default function AddDocument({ onSucess, customer, loan }: Props) {
  const [documentType, setDocumentType] = useState<DocumentType>();
  const _documentType = documentType ?? "OTHER";

  return (
    <>
      <div className="flex flex-col space-y-2 w-full">
        <div className="flex flex-col lg:flex-row pb-3 items-start justify-between w-full">
          <FormFieldLabel
            className="text-gray-500 text-base font-medium"
            label="Document Type"
            required
          />
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={loan ? LOAN_DOCUMENT_TYPES : CUSTOMER_DOCUMENT_TYPES}
            onChange={(event: RadioChangeEvent) => setDocumentType(event.target.value)}
          />
        </div>

        <div>
          <FormFieldLabel className="text-gray-500 text-base font-medium" label="Photo" required />
          <PhotoInput
            disabled={!documentType}
            filename={
              loan
                ? getLoanDocumentName(
                    customer.user.name || "",
                    _documentType,
                    loan.pledgeSheetNumber,
                    loan.pledgeItemType
                  )
                : getCustomerDocumentName(customer.user.name || "", _documentType)
            }
            documentType={_documentType}
            customerId={customer.id}
            assignToCustomer={loan ? false : true}
            linkToLoanId={loan?.id}
            onSelect={onSucess}
          />
        </div>
      </div>
    </>
  );
}
