import { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import { gql, useQuery } from "urql";

import { trackEvent } from "../../../utils/analytics";
import { TIMEZONE } from "../../../utils/date";
import {
  booleanParam,
  getQueryVariables,
  getURLParams,
  intParam,
  queryStringUpdate,
} from "../../../utils/query-params";
import { CUSTOMER_DETAILS_FRAGMENT } from "./useCustomer";

export const CUSTOMER_FRAGMENT: CustomerFragmentDoc = gql`
  fragment Customer on Customer {
    ...CustomerDetails
    loanMetric {
      id
      activeLoans
      closedLoans
      outstandingAmount
      repaidAmount
      totalInterest
      averageDaysToClose
      updatedAt
    }
  }
  ${CUSTOMER_DETAILS_FRAGMENT}
`;

export const CUSTOMERS_SEARCH_QUERY: CustomersSearchQueryDoc = gql`
  query CustomersSearch(
    $offset: Int!
    $first: Int!
    $order: [CustomerOrder!]
    $name: String
    $phone: String
    $address: String
    $careOf: String
    $activeLoansCountMin: Int
    $activeLoansCountMax: Int
    $closedLoansCountMin: Int
    $closedLoansCountMax: Int
    $outstandingAmountMin: Decimal
    $outstandingAmountMax: Decimal
    $repaidAmountMin: Decimal
    $repaidAmountMax: Decimal
    $paidInterestMin: Decimal
    $paidInterestMax: Decimal
    $daysToCloseMin: Int
    $daysToCloseMax: Int
    $dateJoinedMin: DateTime
    $dateJoinedMax: DateTime
    $isRepledgeCustomer: Boolean
  ) {
    customers(
      offset: $offset
      first: $first
      order: $order
      name: $name
      phone: $phone
      address: $address
      careOf: $careOf
      isRepledgeCustomer: $isRepledgeCustomer
      loanMetric_ActiveLoans_Gte: $activeLoansCountMin
      loanMetric_ActiveLoans_Lte: $activeLoansCountMax
      loanMetric_ClosedLoans_Gte: $closedLoansCountMin
      loanMetric_ClosedLoans_Lte: $closedLoansCountMax
      loanMetric_OutstandingAmount_Gte: $outstandingAmountMin
      loanMetric_OutstandingAmount_Lte: $outstandingAmountMax
      loanMetric_RepaidAmount_Gte: $repaidAmountMin
      loanMetric_RepaidAmount_Lte: $repaidAmountMax
      loanMetric_TotalInterest_Gte: $paidInterestMin
      loanMetric_TotalInterest_Lte: $paidInterestMax
      loanMetric_AverageDaysToClose_Gte: $daysToCloseMin
      loanMetric_AverageDaysToClose_Lte: $daysToCloseMax
      user_DateJoined_Gte: $dateJoinedMin
      user_DateJoined_Lte: $dateJoinedMax
    ) {
      totalCount
      edges {
        node {
          ...Customer
        }
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

const DEFAULT_CUSTOMERS_LIMIT = 10;

export type CustomersSearchResult = {
  // Filter variables and callbacks.
  variables: CustomersSearchQueryVariables;
  updateVariables: (variables: CustomersSearchQueryVariables) => void;
  resetVariables: () => void;

  // Data
  customers?: CustomerFragment[];
  total?: number;
  loading: boolean;
};

function flushToURL(variables: CustomersSearchQueryVariables) {
  queryStringUpdate(getURLParams(variables));
}

const DEFAULT_VARIABLES: CustomersSearchQueryVariables = {
  first: DEFAULT_CUSTOMERS_LIMIT,
  offset: 0,
};

export type CustomersSearchProps = {
  filters?: Partial<CustomersSearchQueryVariables>;
  inlineMode?: boolean;
};

export default function useCustomersSearch({
  filters: initialFilters,
  inlineMode,
}: CustomersSearchProps): CustomersSearchResult {
  function deseriableFilters(): CustomersSearchQueryVariables {
    const defaults = { ...DEFAULT_VARIABLES, ...(initialFilters || {}) };
    const _variables = getQueryVariables(inlineMode ? "" : window.location.search, defaults);
    return {
      ..._variables,
      offset: intParam(_variables.offset) || DEFAULT_VARIABLES.offset,
      first: intParam(_variables.first) || DEFAULT_VARIABLES.first,
      activeLoansCountMin: intParam(_variables.activeLoansCountMin),
      activeLoansCountMax: intParam(_variables.activeLoansCountMax),
      closedLoansCountMin: intParam(_variables.closedLoansCountMin),
      closedLoansCountMax: intParam(_variables.closedLoansCountMax),
      daysToCloseMin: intParam(_variables.closedLoansCountMax),
      daysToCloseMax: intParam(_variables.closedLoansCountMax),
      isRepledgeCustomer: booleanParam(`${_variables.isRepledgeCustomer}`),
    };
  }

  const [filters, setFilters] = useState<CustomersSearchQueryVariables>(deseriableFilters);

  const variables = useMemo(() => {
    return {
      ...filters,
      dateJoinedMin: filters.dateJoinedMin
        ? dayjs(filters.dateJoinedMin).tz(TIMEZONE).toISOString()
        : undefined,
      dateJoinedMax: filters.dateJoinedMax
        ? dayjs(filters.dateJoinedMax).tz(TIMEZONE).toISOString()
        : undefined,
    };
  }, [filters]);

  const [{ data, fetching }] = useQuery({ query: CUSTOMERS_SEARCH_QUERY, variables });

  const customers = useMemo(() => {
    if (!data || !data.customers?.edges) {
      return [];
    }

    return data.customers.edges
      .map((node) => node?.node)
      .filter((node) => !!node) as CustomerFragment[];
  }, [data]);

  const updateVariables = useCallback(
    (variables: CustomersSearchQueryVariables) => {
      setFilters(variables);
      if (!inlineMode) {
        flushToURL(variables);
      }
      trackEvent({ action: "Customer Search", label: "Update" }, variables);
    },
    [inlineMode]
  );

  const resetVariables = useCallback(() => {
    setFilters({ offset: 0, first: DEFAULT_CUSTOMERS_LIMIT });
    trackEvent({ action: "Customer Search", label: "Reset" });
    flushToURL(DEFAULT_VARIABLES);
  }, []);

  return {
    variables,
    customers,
    total: data?.customers?.totalCount,
    loading: fetching,

    updateVariables,
    resetVariables,
  };
}
