import React from "react";

import { FilterTag, getAmountText, getDatesText, getSelectedFilters } from "../../../utils/search";

const FILTERS: {
  [K in keyof LoansSearchQueryVariables]:
    | ((record: LoansSearchQueryVariables) => React.ReactNode)
    | null;
} = {
  offset: null,
  first: null,
  auctionable: (record) => (record.auctionable ? <FilterTag>Auctionable</FilterTag> : null),
  contractStartDateMin: (record) => (
    <FilterTag>
      Loan Open Date {getDatesText(record.contractStartDateMin, record.contractStartDateMax)}
    </FilterTag>
  ),
  contractStartDateMax: (record) =>
    !record.contractStartDateMin && (
      <FilterTag>
        Loan Open Date {getDatesText(record.contractStartDateMin, record.contractStartDateMax)}
      </FilterTag>
    ),
  closingDateMin: (record) => (
    <FilterTag>Closing Date {getDatesText(record.closingDateMin, record.closingDateMax)}</FilterTag>
  ),
  closingDateMax: (record) =>
    !record.closingDateMin && (
      <FilterTag>
        Closing Date {getDatesText(record.closingDateMin, record.closingDateMax)}
      </FilterTag>
    ),
  eventDates: (record) => {
    const dates = (record.eventDates || "").split(",");
    const minDate = dates[0];
    const maxDate = dates[1];
    return <FilterTag>Open/Close Date {getDatesText(minDate, maxDate)}</FilterTag>;
  },
  loanStatus: (record) => <FilterTag>{record.loanStatus}</FilterTag>,
  itemType: (record) => <FilterTag>{record.itemType}</FilterTag>,
  interestRateMin: (record) => (
    <FilterTag>
      Interest Rate {getAmountText(record.interestRateMin, record.interestRateMax)}
    </FilterTag>
  ),
  interestRateMax: (record) =>
    !record.interestRateMin && (
      <FilterTag>
        Interest Rate {getAmountText(record.interestRateMin, record.interestRateMax)}
      </FilterTag>
    ),
  loanAmountMin: (record) => (
    <FilterTag>Loan Amount {getAmountText(record.loanAmountMin, record.loanAmountMax)}</FilterTag>
  ),
  loanAmountMax: (record) =>
    !record.loanAmountMin && (
      <FilterTag>Loan Amount {getAmountText(record.loanAmountMin, record.loanAmountMax)}</FilterTag>
    ),
  itemWeightMin: (record) => (
    <FilterTag>Weight {getAmountText(record.itemWeightMin, record.itemWeightMax)}</FilterTag>
  ),
  itemWeightMax: (record) =>
    !record.itemWeightMin && (
      <FilterTag>Weight {getAmountText(record.itemWeightMin, record.itemWeightMax)}</FilterTag>
    ),
};

function _SelectedFilters({ filters }: { filters: LoansSearchQueryVariables }) {
  const selectedFilters = getSelectedFilters(filters);

  return (
    <div className="flex flex-wrap">
      {selectedFilters.map((filter) => {
        const display = FILTERS[filter];
        return display ? (
          <div className="pb-1" key={filter}>
            {display(filters)}
          </div>
        ) : null;
      })}
    </div>
  );
}

export default React.memo(_SelectedFilters);
