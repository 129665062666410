import { DateRangeFiltersResult } from "../../hooks/useDateRangeFilters";
import { toServerDate } from "../../utils/date";
import DateRangePicker, { DateRangePickerProps } from "./DateRangePicker";
import FilterLabel from "./FilterLabel";

type Props = Pick<DateRangeFiltersResult, "filters" | "updateFilters"> & DateRangePickerProps;

export default function DateRangePickerFilter({ filters, updateFilters, ...props }: Props) {
  const { startDate, endDate } = filters;

  return (
    <div className="mr-2 mb-2 flex flex-col px-0.5 items-start max-w-sm">
      <FilterLabel label="Date Range:" />
      <DateRangePicker
        showPredefinedRanges
        startDate={startDate}
        endDate={endDate}
        size="large"
        className="rounded-lg"
        handleChange={(startDate, endDate) =>
          updateFilters({
            ...filters,
            startDate: toServerDate(startDate),
            endDate: toServerDate(endDate),
          })
        }
        {...props}
      />
    </div>
  );
}
