import { Link } from "react-router-dom";
import clsx from "clsx";

import AnchorLink from "../common/AnchorLink";

export type Customer = Pick<
  CustomerDetailsFragment,
  "id" | "address" | "careOfType" | "careOfName"
> & { user: Omit<CustomerDetailsFragment["user"], "dateJoined"> };

export default function CustomerCard({
  customer,
  border,
  className,
}: {
  customer: Customer;
  border?: boolean;
  className?: string;
}) {
  return (
    <div
      className={clsx(
        className ? className : "p-2 text-sm",
        "flex flex-col space-y-1 w-full text-gray-500 whitespace-normal",
        border && "border border-gray-300 rounded-sm"
      )}
    >
      <Link to={`/customers/${customer.id}`} href={`/customers/${customer.id}`}>
        <AnchorLink className="text-base">{customer.user.name}</AnchorLink>
      </Link>

      <div>{customer.user.phone}</div>
      <div className="text-xs">
        {customer.careOfType} {customer.careOfName}
      </div>
      <div className="text-xs">{customer.address}</div>
    </div>
  );
}
