import React from "react";
import { Modal } from "antd";

import LoginForm from "./LoginForm";

export default function LoginModal() {
  return (
    <Modal title={false} centered closable={false} maskClosable={false} mask open footer={false}>
      <LoginForm
        onSuccess={() => {
          return;
        }}
      />
    </Modal>
  );
}
