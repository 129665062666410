import { useCallback, useMemo } from "react";
import { gql } from "@urql/core";
import { Card, Skeleton } from "antd";
import dayjs from "dayjs";
import { useQuery } from "urql";

import { SERVER_DATE_FORMAT } from "../../constants/common";
import { formatCurrency, formatMoneyHumanReadable } from "../../utils/currency";
import { toServerDateNonNull } from "../../utils/date";
import { Charts } from "../common/Charts";

const TOTAL_OUTSTANDING_QUERY: TotalOutstandingQueryDoc = gql`
  query TotalOutstanding($endDate: Date!) {
    loanMetrics(metricDate_Gte: "2019-01-01", metricDate_Lte: $endDate) {
      edges {
        node {
          metricDate
          totalOutstanding
        }
      }
    }
  }
`;

export default function PortfolioTimelineChart() {
  const [{ data, fetching }] = useQuery({
    query: TOTAL_OUTSTANDING_QUERY,
    variables: { endDate: toServerDateNonNull(dayjs()) },
    requestPolicy: "cache-and-network",
  });
  const metrics = useMemo(() => {
    const totalOutstandingByDate = (data?.loanMetrics?.edges || [])
      .map((edge) => edge?.node)
      .reduce((hash, node) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const { metricDate, totalOutstanding } = node!;
        if (!hash[metricDate]) {
          hash[metricDate] = 0;
        }
        hash[metricDate] += parseFloat(`${totalOutstanding}`);
        return hash;
      }, {} as { [K: string]: number });

    return Object.keys(totalOutstandingByDate)
      .map((date) => ({
        date: dayjs(date, SERVER_DATE_FORMAT),
        value: totalOutstandingByDate[date] || 0,
      }))
      .filter((metric) => metric.date.isoWeekday() < 6)
      .sort((a, b) => a.date.valueOf() - b.date.valueOf());
  }, [data]);

  function getOptions({ series }: { series: Highcharts.SeriesOptionsType[] }): Highcharts.Options {
    return {
      series,

      chart: {
        height: 600,
      },

      legend: { enabled: false },
      credits: { enabled: false },
      title: { text: undefined },
      navigator: { enabled: true },
      scrollbar: { enabled: true },

      rangeSelector: {
        dropdown: "responsive",
        enabled: true,
        inputEnabled: false,
      },

      yAxis: [
        {
          crosshair: {
            dashStyle: "Dash",
          },
          labels: {
            enabled: true,
            formatter: function () {
              const label = this.axis.defaultLabelFormatter.call(this);

              // Use thousands separator for four-digit numbers too
              if (/^[\d].*$/.test(label)) {
                return formatMoneyHumanReadable(this.value as number, 2);
              }
              return label;
            },
          },
          opposite: false,
        },
      ],

      plotOptions: {
        spline: {
          connectNulls: true,
        },
        series: {
          turboThreshold: 10000,
        },
      },

      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.displayValue}</b><br/>',
        valueDecimals: 2,
        split: true,
      },

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              chart: {
                height: 500,
              },

              subtitle: {
                text: undefined,
              },
              yAxis: [
                {
                  labels: {
                    enabled: false,
                  },
                },
                {
                  labels: {
                    enabled: false,
                  },
                },
              ],
              navigator: {
                enabled: false,
              },
            },
          },
        ],
      },
    };
  }

  const getSeries = useCallback((): Highcharts.SeriesOptionsType[] => {
    return [
      {
        id: "totalOutstanding",
        name: "Total Outstanding",
        color: "#a855f7",
        type: "spline",
        data: metrics.map((data) => ({
          x: data.date.valueOf(),
          y: data.value,
          date: data.date,
          displayValue: formatCurrency(data.value),
        })),
      },
    ];
  }, [metrics]);

  const options = useMemo(() => {
    return getOptions({ series: getSeries() });
  }, [getSeries]);

  return (
    <Card
      className="rounded-xl my-6"
      title={
        <div className="text-3xl font-bold text-center text-gray-600 whitespace-normal">
          Outstanding Loan Amount Timeline
        </div>
      }
    >
      <Skeleton paragraph={{ rows: 19 }} title={false} loading={fetching} active>
        <div className="flex w-full">
          <Charts constructorType="stockChart" options={options} />
        </div>
      </Skeleton>
    </Card>
  );
}
