import { gql } from "urql";

export const DAILY_LOANS_RECONCILIATION_FRAGMENT: DailyLoansRecociliationFragmentDoc = gql`
  fragment DailyLoansRecociliation on LoanMetric {
    id
    metricDate
    totalOutstanding
    openedLoansCount
    closedLoansCount
    openedLoansAmount
    closedLoansAmount
    investorInterestAmount
    commissionsAmount
  }
`;
