import React from "react";
import { useHistory } from "react-router";

import useUser from "../../hooks/useUser";
import Centered from "../common/Centered";
import LoginForm from "./LoginForm";

export default function LoginPage() {
  const { user } = useUser();
  const history = useHistory();

  if (user) {
    history.push("/");
    return <></>;
  }

  return (
    <Centered>
      <LoginForm onSuccess={() => history.push("/")} />
    </Centered>
  );
}
