import { useState } from "react";
import { ClearOutlined } from "@ant-design/icons";
import { Divider, Input, Radio } from "antd";
import clsx from "clsx";

import type { AggregationType, LegType, TransactionsOrder } from "../../../gql/graphql";
import { AccountTransactionsSearchResult } from "../../../hooks/api/accounts/useAccountTransactionsSearch";
import useDebounceFn from "../../../hooks/useDebounceFn";
import useMobile from "../../../hooks/useMobile";
import useTablet from "../../../hooks/useTablet";
import { toServerDate } from "../../../utils/date";
import ActionButton from "../../common/ActionButton";
import DateRangePicker from "../../common/DateRangePicker";
import FilterLabel from "../../common/FilterLabel";

const ORDER_OPTIONS: { value: TransactionsOrder; label: string }[] = [
  { value: "ASCENDING", label: "ASC" },
  { value: "DESCENDING", label: "DESC" },
];

const TYPE_OPTIONS: { value: LegType | ""; label: string }[] = [
  { value: "", label: "All" },
  { value: "CREDIT", label: "Credit" },
  { value: "DEBIT", label: "Debit" },
];

const GROUP_BY_OPTIONS: { value: AggregationType | ""; label: string }[] = [
  { value: "", label: "All" },
  { value: "DAILY", label: "Day" },
  { value: "MONTHLY", label: "Month" },
];

function Filter({ children }: { children: React.ReactChild[] }) {
  const { isTablet } = useTablet();
  return (
    <div className={clsx("flex flex-col", isTablet && "w-1/2 pr-1 pb-2 sm:pb-1")}>{children}</div>
  );
}

export default function AccountTransactionsFilters({
  updateVariables,
  resetVariables,
  variables,
}: Pick<AccountTransactionsSearchResult, "variables" | "updateVariables" | "resetVariables">) {
  const [search, setSearch] = useState<string | undefined>(variables.search);
  const { isMobile } = useMobile();

  const debouncedUpdateSearch = useDebounceFn((search: string) => {
    updateVariables({ ...variables, offset: 0, search });
  }, 200);

  return (
    <div className="flex flex-col border rounded-lg mb-0.5 w-full bg-gray-50">
      <div className="flex w-full flex-wrap justify-between items-center px-3 py-4 pb-3">
        <div className="text-xl pl-1 text-gray-700 font-bold pr-2 mb-1">TRANSACTIONS</div>
        <div className="flex flex-col">
          <DateRangePicker
            showPredefinedRanges
            startDate={variables.transactionsStartDate}
            endDate={variables.transactionsEndDate}
            className="rounded-lg"
            size="large"
            handleChange={(startDate, endDate) =>
              updateVariables({
                ...variables,
                offset: 0,
                transactionsStartDate: toServerDate(startDate),
                transactionsEndDate: toServerDate(endDate),
              })
            }
          />
        </div>

        <ActionButton
          size="large"
          cta="Reset"
          type="text"
          className="text-green-500 text-base"
          ghost={false}
          icon={<ClearOutlined />}
          onClick={() => {
            resetVariables();
            setSearch("");
          }}
        />
      </div>

      <Divider className="mt-1 mb-2" />
      <div className="flex p-2 flex-wrap items-center justify-between">
        <Filter>
          <FilterLabel label="Group By:" />
          <Radio.Group
            name="group"
            value={variables.groupBy || ""}
            buttonStyle="solid"
            size={isMobile ? "small" : "middle"}
            onChange={(e) => {
              updateVariables({
                ...variables,
                offset: 0,
                groupBy: e.target.value ? e.target.value : undefined,
              });
            }}
            options={GROUP_BY_OPTIONS}
            optionType="button"
          />
        </Filter>
        <Filter>
          <FilterLabel label="Order By:" />
          <Radio.Group
            name="order"
            value={variables.order || "DESCENDING"}
            buttonStyle="solid"
            size={isMobile ? "small" : "middle"}
            onChange={(e) => {
              updateVariables({ ...variables, offset: 0, order: e.target.value });
            }}
            options={ORDER_OPTIONS}
            optionType="button"
          />
        </Filter>
        <Filter>
          <FilterLabel label="Type:" />
          <Radio.Group
            name="type"
            value={variables.type || ""}
            buttonStyle="solid"
            size={isMobile ? "small" : "middle"}
            onChange={(e) => {
              updateVariables({
                ...variables,
                offset: 0,
                type: e.target.value ? e.target.value : undefined,
              });
            }}
            options={TYPE_OPTIONS}
            optionType="button"
          />
        </Filter>
        <Filter>
          <FilterLabel label="Search:" />
          <Input
            size={isMobile ? "small" : "middle"}
            value={search}
            allowClear
            onChange={(e) => {
              const value = e.target.value;
              setSearch(value);
              debouncedUpdateSearch(value);
            }}
          />
        </Filter>
      </div>
    </div>
  );
}
