import React from "react";
import { Radio, RadioChangeEvent, RadioGroupProps } from "antd";
import clsx from "clsx";
import { getIn } from "formik";

import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

type Props = RadioGroupProps & {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueParser?: (value: any) => any;
  children?: React.ReactNode;
};

const FormRadioField = (props: Props & WithFormFieldWrapperProps) => {
  const handleChange = (event: RadioChangeEvent) => {
    const {
      name,
      formik: { setFieldValue },
    } = props;
    setFieldValue(name, event.target.value);

    if (props.onChange) {
      props.onChange(event);
    }
  };

  const { formik, valueParser, className, children, ...rest } = props;
  const { name } = rest;
  const fieldValue = getIn(formik.values, name);

  return (
    <div className="flex flex-wrap w-full items-center">
      <Radio.Group
        className={clsx(className ? className : "flex-grow", children ? "mr-1 mb-1" : "")}
        value={valueParser ? valueParser(fieldValue) : fieldValue}
        {...rest}
        onChange={(event: RadioChangeEvent) => handleChange(event)}
      />
      {children}
    </div>
  );
};

export default withFormFieldWrapper<Props>(FormRadioField);
