import clsx from "clsx";

export default function LabelValue({
  label,
  value,
  valueClassName,
  extras,
  row,
  className,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
  valueClassName?: string;
  extras?: string;
  row?: boolean;
  className?: string;
}) {
  return (
    <div className={clsx("flex", row ? "flex-row items-center space-x-1" : "flex-col", className)}>
      <div className={clsx("font-medium text-gray-400", row ? "" : "pb-0.5")}>
        {label}
        {row ? ":" : ""}
      </div>
      <div className={clsx(valueClassName, row ? "text-sm" : "text-base")}>{value || "N/A"}</div>
      <div className="text-xs text-gray-600">{extras}</div>
    </div>
  );
}
