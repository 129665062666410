import useBranch, { Branch } from "./useBranch";
import useUser from "./useUser";

export default function useUserBranch(): Branch {
  const { user, loadingUser } = useUser();

  const { branch, loadingBranch } = useBranch(user?.selectedBranchId);

  return { branch, loadingBranch: loadingUser || loadingBranch };
}
