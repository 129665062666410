import { Modal } from "antd";

import { UseWebcamCaptureResult } from "../../../hooks/useWebcamCapture";
import WebcamCapture from "./WebcamCapture";

type WebcamCaptureModalProps = {
  onSelect: (img: string) => void;
} & UseWebcamCaptureResult;

export function WebcamCaptureModal({ open, closeWebcam, onSelect }: WebcamCaptureModalProps) {
  return open ? (
    <Modal
      open
      title={<div className="text-center text-2xl font-bold">TAKE PHOTO</div>}
      maskClosable
      style={{ borderRadius: 12 }}
      bodyStyle={{ padding: 0 }}
      closable
      width="100%"
      footer={false}
      onCancel={() => closeWebcam()}
      className="max-w-md rounded-xl"
    >
      <div className="flex w-full justify-center">
        <WebcamCapture onClose={closeWebcam} onSelect={onSelect} />
      </div>
    </Modal>
  ) : null;
}
