import { useCallback, useState } from "react";

export type LoanTopUpAction = "add" | "edit" | "delete";

type Selection = {
  loanTopUp?: LoanTopUpFragment;
  action?: LoanTopUpAction;
};

export type LoanTopUpActionResult = Selection & {
  openLoanTopUpForm: (selection: Selection) => void;
  closeLoanTopUpForm: () => void;

  showForm: boolean;
  loan: CustomerLoanFragment;
};

export default function useLoanTopUpAction(loan: CustomerLoanFragment): LoanTopUpActionResult {
  const [selection, setSelection] = useState<Selection>();
  const [showForm, setShowForm] = useState<boolean>(false);

  const openLoanTopUpForm = useCallback((selection: Selection) => {
    setSelection({ ...selection });
    setShowForm(true);
  }, []);

  const closeLoanTopUpForm = useCallback(() => {
    setShowForm(false);
    setSelection(undefined);
  }, []);

  return {
    loan,
    showForm,

    openLoanTopUpForm,
    closeLoanTopUpForm,
    ...selection,
  };
}
