import React from "react";
import { Button } from "antd";
import clsx from "clsx";

export default function AnchorLink({
  children,
  className,
  externalHref,
}: {
  children: React.ReactNode;
  externalHref?: string;
  className?: string;
}) {
  return (
    <Button
      type="link"
      style={{ paddingLeft: 0 }}
      className="p-0 border-0"
      size="small"
      href={externalHref}
      target={externalHref ? "_blank" : undefined}
    >
      <span className={clsx(className, "hover:underline hover:text-green-700")}>{children}</span>
    </Button>
  );
}
