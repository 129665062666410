import React from "react";
import { Link } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import { Divider, Typography } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";

import { LoansSearchResult } from "../../../hooks/api/loans/useLoansSearch";
import usePagination from "../../../hooks/usePagination";
import {
  formatCurrency,
  formatMetalPurity,
  formatRate,
  renderCurrencyZeroPaise,
} from "../../../utils/currency";
import { formatDate } from "../../../utils/date";
import { formatNumber, sum, toNumber } from "../../../utils/number";
import { getURLParams } from "../../../utils/query-params";
import AnchorLink from "../../common/AnchorLink";
import LabelValue from "../../common/LabelValue";
import LoanStatus from "../../common/LoanStatus";
import PledgeItemType from "../../common/PledgeItemType";
import QuestionTooltip from "../../common/QuestionTooltip";
import ResponsiveTable, { ResponsiveColumnType } from "../../common/ResponsiveTable";
import { LoanFiltersHeader } from "./LoanFilters";

type Props = {
  loansSearch: LoansSearchResult;
  title?: React.ReactNode;
  hideCustomer?: boolean;
};

export default function LoansList({ loansSearch, title, hideCustomer }: Props) {
  const { updateVariables, variables, loading, total, loans } = loansSearch;
  const { pagination, page } = usePagination({ variables, updateVariables, total });

  const renderLoanCard = (loan: CustomerLoanFragment) => {
    const labelValueContainer = "pr-2.5 pb-1";
    return (
      <div className="flex flex-col space-y-1">
        <div className="flex justify-between">
          <div className="w-1/3 flex flex-col">
            <Link href={`/loans/${loan.id}`} to={`/loans/${loan.id}`}>
              <AnchorLink>{loan.pledgeSheetNumber}</AnchorLink>
            </Link>
            <LoanStatus className="text-center w-min px-2" status={loan.loanStatus} />
          </div>
          <div className="w-1/3 flex flex-col space-y-1">
            <div className="pb-0.5">
              <PledgeItemType value={loan.pledgeItemType} className="px-2" />{" "}
              {loan.pledgeItemWeight && `${formatNumber(loan.pledgeItemWeight)} GRAMS`}
            </div>
            <Typography.Paragraph ellipsis={{ tooltip: loan.pledgeItemDesc }}>
              {loan.pledgeItemDesc}
            </Typography.Paragraph>
          </div>
          <div className="w-1/3 flex flex-col text-right">
            <div className="font-bold text-lg">{renderCurrencyZeroPaise(loan.loanAmount)}</div>
          </div>
        </div>

        {!hideCustomer && (
          <div className="flex justify-between">
            <div className="flex flex-col w-1/2">
              <Link to={`/customers/${loan.customer.id}`} href={`/customers/${loan.customer.id}`}>
                <AnchorLink>
                  <span className="text-sm">{loan.customer.user.name}</span>
                </AnchorLink>
              </Link>
              <div className="text-sm text-gray-500">
                {loan.customer.careOfType} {loan.customer.careOfName}
              </div>
            </div>
            <div className="flex flex-col space-y-0.5 w-1/2">
              <div className="text-right">{loan.customer.user.phone}</div>
              <Typography.Paragraph
                ellipsis={{ tooltip: loan.customer.address }}
                className="text-right font-normal"
              >
                {loan.customer.address}
              </Typography.Paragraph>
            </div>
          </div>
        )}

        <Divider orientation="center" className="my-1">
          <span className="text-gray-500 text-base">Details</span>
        </Divider>

        <div className="flex justify-between flex-wrap">
          <LabelValue
            className={labelValueContainer}
            label="Open Date"
            value={formatDate(loan.contractStartDate)}
          />
          <LabelValue
            className={labelValueContainer}
            label="Rate"
            value={formatRate(loan.interestRate)}
          />
          <LabelValue
            className={labelValueContainer}
            label="Interests"
            value={renderCurrencyZeroPaise(sum(loan.initialInterestAmount, loan.closingInterest))}
          />
          <LabelValue
            className={labelValueContainer}
            label="Commissions"
            value={renderCurrencyZeroPaise(sum(loan.initialCommissionsAmount, loan.commission))}
          />
          {loan.loanStatus === "ACTIVE" ? (
            <>
              <LabelValue
                className={labelValueContainer}
                label="Estimate"
                value={renderCurrencyZeroPaise(loan.pledgeItemEstimate)}
              />
              <LabelValue
                className={labelValueContainer}
                label="CLV"
                valueClassName={loan.auctionable ? "text-red-600" : "text-green-600"}
                value={renderCurrencyZeroPaise(loan.currentLoanValue)}
              />
              {!!(loan.partPaymentAmount && toNumber(loan.partPaymentAmount) > 0) && (
                <LabelValue
                  className={labelValueContainer}
                  label="Payments"
                  value={renderCurrencyZeroPaise(loan.partPaymentAmount)}
                />
              )}
            </>
          ) : (
            <>
              <LabelValue
                className={labelValueContainer}
                label="Close Date"
                value={formatDate(loan.closingDate)}
              />
              <LabelValue
                className={labelValueContainer}
                label="Annual Return"
                value={loan.annualizedRateOfReturn && formatRate(loan.annualizedRateOfReturn)}
              />
            </>
          )}
        </div>
      </div>
    );
  };
  function getColumns(): ResponsiveColumnType<CustomerLoanFragment>[] {
    const columns: ResponsiveColumnType<CustomerLoanFragment>[] = [
      {
        title: "PSN #",
        dataIndex: "pledgeSheetNumber",
        key: "psn",
        width: 125,
        fixed: "left",
        align: "left",
        render: (value, record) => (
          <div className="flex flex-col space-y-2">
            <Link href={`/loans/${record.id}`} to={`/loans/${record.id}`}>
              <AnchorLink>{value}</AnchorLink>
            </Link>
            {record.loanStatus && (
              <div>
                <LoanStatus className="text-center px-2 w-min" status={record.loanStatus} />
              </div>
            )}
          </div>
        ),
      },
    ];

    if (!hideCustomer) {
      columns.push({
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        width: 200,
        ellipsis: true,
        render: (customer: CustomerLoanFragment["customer"]) => (
          <div className="flex flex-col space-y-1 text-sm text-gray-600 whitespace-normal">
            <Link to={`/customers/${customer.id}`} href={`/customers/${customer.id}`}>
              <AnchorLink className="text-sm">{customer.user.name}</AnchorLink>
            </Link>
            {customer.user.phone}
            <Typography.Paragraph
              ellipsis={{
                tooltip: (
                  <div className="flex flex-col">
                    <div>
                      {customer.careOfType} {customer.careOfName}
                    </div>
                    {customer.address}
                  </div>
                ),
              }}
            >
              <div>
                {customer.careOfType} {customer.careOfName}
              </div>
              {customer.address}
            </Typography.Paragraph>
          </div>
        ),
      });
    }

    columns.push(
      {
        title: "Item",
        dataIndex: "pledgeItemType",
        key: "pledgeItemType",
        width: 250,
        render: (value, record) => (
          <div className="flex flex-col space-y-0.5">
            <div className="flex flex-wrap space-x-1 items-center pb-0.5">
              <PledgeItemType value={value} className="px-2" />
              <div className="pl-1">
                {record.pledgeItemPurity && record.pledgeItemPurityRatio
                  ? formatMetalPurity(record.pledgeItemPurity, record.pledgeItemPurityRatio)
                  : ""}
              </div>
            </div>
            <Typography.Paragraph ellipsis={{ tooltip: record.pledgeItemDesc }} className="pl-1">
              <span className="font-medium">
                {record.pledgeItemWeight && `${formatNumber(record.pledgeItemWeight)} GRAMS`}
              </span>
              {" / "}
              {record.pledgeItemDesc}
            </Typography.Paragraph>
            <LabelValue row label="Rate" value={formatRate(record.interestRate)} />
            <LabelValue row label="Estimate" value={formatCurrency(record.pledgeItemEstimate, 0)} />
          </div>
        ),
      },
      {
        title: "Dates",
        key: "dates",
        dataIndex: "contractStartDate",
        width: 200,
        render: (value, record) => (
          <div className="flex flex-col space-y-0.5">
            <LabelValue
              row={record.loanStatus === "CLOSED"}
              label="Open"
              value={formatDate(record.contractStartDate)}
            />
            {record.loanStatus === "CLOSED" && (
              <>
                <LabelValue row label="Close" value={formatDate(record.closingDate)} />
                <LabelValue
                  row
                  label="Duration"
                  value={dayjs(record.contractStartDate)
                    .startOf("day")
                    .from(dayjs(record.closingDate).add(1, "d").startOf("day"), true)}
                />
              </>
            )}
          </div>
        ),
      },
      {
        title: (
          <>
            <div className="flex items-center space-x-1">
              <div>Loan Amount</div>
              <QuestionTooltip>
                <div className="flex flex-col space-y-2">
                  <div>
                    <span className="font-medium text-base">Accured Interest</span> is the total
                    interest earned from the opening date until today minus the first month
                    interest.
                  </div>
                </div>
              </QuestionTooltip>
            </div>
          </>
        ),
        width: 200,
        key: "amount",
        dataIndex: "loanAmount",
        render: (value, record) => (
          <div className="flex flex-col">
            <div className="font-bold text-base mb-1">
              {renderCurrencyZeroPaise(record.loanAmount)}
            </div>
            {record.loanStatus === "ACTIVE" ? (
              <>
                <LabelValue
                  row
                  label="CLV"
                  valueClassName={clsx(
                    record.auctionable ? "text-red-600" : "text-green-600",
                    "font-semibold"
                  )}
                  value={renderCurrencyZeroPaise(record.currentLoanValue)}
                />
                {toNumber(record.pendingInterest) > 0 && (
                  <LabelValue
                    row
                    label="Accured Int."
                    value={renderCurrencyZeroPaise(record.pendingInterest)}
                  />
                )}
              </>
            ) : (
              <>
                {record.annualizedRateOfReturn && toNumber(record.annualizedRateOfReturn) && (
                  <LabelValue
                    row
                    label="Annual Return"
                    value={formatRate(record.annualizedRateOfReturn)}
                  />
                )}
              </>
            )}
          </div>
        ),
      },
      {
        title: (
          <>
            <div className="flex items-center space-x-1">
              <div>Payments</div>
              <QuestionTooltip>
                <div className="flex flex-col space-y-2">
                  <div>
                    <span className="font-medium text-base">Interest</span> is the sum of 1.25%
                    interest collected in the first month interest and the final closing interest.
                  </div>
                  <Divider />
                  <div>
                    <span className="font-medium text-base">Commissions</span> is the sum of the
                    initial commissions amount and the adjusted commissions amount.
                  </div>
                  <Divider />
                </div>
              </QuestionTooltip>
            </div>
          </>
        ),
        width: 200,
        key: "payments",
        dataIndex: "partPaymentAmount",
        render: (value, record) => (
          <div className="flex flex-col space-y-1">
            <LabelValue
              row
              label="Interest"
              value={renderCurrencyZeroPaise(
                sum(record.initialInterestAmount, record.closingInterest)
              )}
            />

            <LabelValue
              row
              label="Commissions"
              value={renderCurrencyZeroPaise(
                sum(record.initialCommissionsAmount, record.commission)
              )}
            />

            {record.loanStatus === "ACTIVE" &&
              record.partPaymentAmount &&
              toNumber(record.partPaymentAmount) > 0 && (
                <LabelValue
                  row
                  label="Payments"
                  value={renderCurrencyZeroPaise(record.partPaymentAmount)}
                />
              )}
          </div>
        ),
      }
    );

    return columns;
  }

  return (
    <ResponsiveTable<CustomerLoanFragment>
      renderCard={renderLoanCard}
      rowKey="id"
      scroll={{ x: 750, y: 650, scrollToFirstRowOnChange: true }}
      loading={loading}
      bordered
      title={
        title || loansSearch.inlineMode
          ? () =>
              title ? (
                title
              ) : (
                <div className="w-full justify-center flex">
                  <AnchorLink externalHref={`/loans/search?${getURLParams(loansSearch.variables)}`}>
                    <div className="flex items-center space-x-1 text-center text-lg font-bold">
                      <div>{loansSearch.total} Loans</div>
                      <div>
                        <LogoutOutlined className="text-sm pb-2 font-bold" rotate={-45} />
                      </div>
                    </div>
                  </AnchorLink>
                </div>
              )
          : undefined
      }
      columns={getColumns()}
      dataSource={loans}
      pagination={pagination}
      printSettings={{
        title: "LOANS SEARCH",
        orientation: "portrait",
        tableTitle: (
          <div className="flex flex-col items-center">
            <div className="text-2xl font-bold py-2 text-center capitalize">
              LOANS SEARCH{" "}
              <span className="text-base px-2 text-gray-600">
                Showing {loansSearch.variables.first} Loans - (Page {page.number + 1} / {page.total}
                )
              </span>
            </div>
            <LoanFiltersHeader loansSearch={loansSearch} />
          </div>
        ),
      }}
    />
  );
}
