import type { DocumentType, ItemType } from "../types/enum";
import { formatDateFilename } from "./date";

export const getCustomerDocumentName = (customerName: string, documentType: DocumentType) => {
  return `${customerName}_${documentType}.jpg`;
};

export const getLoanDocumentName = (
  customerName: string,
  documentType: DocumentType,
  psn: number,
  itemType: string
) => {
  return `${customerName || ""}_${psn}_${itemType}_${documentType}.jpg`;
};

export const getDocumentTypeTitle = (documentType: DocumentType) =>
  documentType.replaceAll("_", " ");
