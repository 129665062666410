import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Tag } from "antd";
import { gql, useQuery } from "urql";

import { OptionType, SelectValueType } from "../../types";
import useDebounce from "../useDebounce";

export const SEARCH_QUERY: SearchQueryDoc = gql`
  query Search($text: String!, $limit: Int) {
    search(text: $text, limit: $limit) {
      id
      objectId
      name
      phone
      careOf
      address
      type
      pledgeSheetNumber
      isRepledgeCustomer
    }
  }
`;

export type SearchResult = SearchQuery["search"][0];

export default function useSearch({ limit }: { limit?: number }) {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchText = useDebounce<string>(searchTerm.trim(), 100);
  const variables = useMemo(
    () => ({ text: debouncedSearchText, limit: limit || 10 }),
    [debouncedSearchText, limit]
  );

  const [{ data, fetching }] = useQuery({
    query: SEARCH_QUERY,
    variables,
    pause: !variables.text || variables.text.length < 2,
  });

  const options = useMemo(() => {
    const hash = (data?.search || []).reduce((hash, result) => {
      if (!hash[result.type]) {
        hash[result.type] = [];
      }
      hash[result.type].push(result);
      return hash;
    }, {} as { [K: string]: SearchResult[] });

    return Object.keys(hash).map((type) => {
      return {
        label: type.replace("_", " "),
        options: hash[type].map((value) => {
          const id = value.id;

          return {
            value: id,
            label: (
              <div className="py-0.5 flex flex-wrap justify-between items-start">
                <div className="flex w-full flex-col">
                  <div className="flex flex-wrap space-y-1 w-full justify-between font-normal">
                    <div className="text-base">{value.name}</div>
                    <div className="text-sm">{value.phone}</div>
                  </div>

                  <div className="flex flex-wrap justify-between items-start">
                    <div className="font-light text-sm">
                      <div>{value.careOf}</div>
                      <div>{value.address}</div>
                    </div>
                    {value.isRepledgeCustomer && (
                      <Tag className="rounded-full px-4 py-0.5 mx-2" color="magenta-inverse">
                        REPLEDGE
                      </Tag>
                    )}
                  </div>
                </div>

                {value.pledgeSheetNumber && (
                  <div className="text-sm font-medium">PSN# {value.pledgeSheetNumber}</div>
                )}
              </div>
            ),
            content: value,
          };
        }),
      };
    });
  }, [data]);

  const onSelect = useCallback((value: SelectValueType, option: OptionType) => {
    const result = option.content as SearchResult;
    history.push(`/${result.type == "CUSTOMERS" ? "customers" : "loans"}/${result.objectId}`);
    setSearchTerm("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { searchTerm, setSearchTerm, loading: fetching, options, onSelect };
}
