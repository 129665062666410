import React from "react";
import {
  BankOutlined,
  GoldFilled,
  LaptopOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import clsx from "clsx";

import type { AccountSubtype } from "../../gql/graphql";
import { CurrencyIcon } from "../../icons/Currency";
import UnsecuredLoan from "../../icons/UnsecuredLoan";

export const ACCOUNT_SUBTYPE_ICONS: {
  [K in AccountSubtype]: React.ComponentType<CustomIconComponentProps> | typeof Icon;
} = {
  BANK: BankOutlined,
  UNSECURED_LOAN: UnsecuredLoan,
  SECURED_LOAN: GoldFilled,
  CASH: CurrencyIcon,
  PROPERTY: LaptopOutlined,
  EMPLOYEE_PERSONAL: UserOutlined,
  ACCOUNTS_PAYABLE: WalletOutlined,
};

export function renderAccountIcon(accountSubtype?: AccountSubtype) {
  if (!accountSubtype) {
    return;
  }

  const IconComponent = ACCOUNT_SUBTYPE_ICONS[accountSubtype];
  return (
    <IconComponent
      className={clsx(
        accountSubtype === "SECURED_LOAN" ? "text-yellow-400" : "text-green-400",
        accountSubtype === "UNSECURED_LOAN"
          ? "text-2xl"
          : accountSubtype === "CASH"
          ? "text-base"
          : "text-lg"
      )}
      width={32}
      height={32}
      fill="currentcolor"
    />
  );
}
