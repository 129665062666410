import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const Svg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 512 366.25"
    width={props.width}
    height={props.height}
    fill="currentcolor"
  >
    <path
      fillRule="nonzero"
      d="M86.43 91.28c9.87 0 17.89 8.01 17.89 17.89s-8.02 17.89-17.89 17.89H61.29c-29.38 0-48.33-16.95-56.68-38.6C1.55 80.52.02 71.92 0 63.35 0 54.8 1.52 46.2 4.58 38.27 12.88 16.77 31.76 0 61.29 0h389.42c29.51 0 48.4 16.8 56.7 38.32 3.07 7.93 4.59 16.53 4.59 25.09-.01 8.57-1.55 17.17-4.61 25.11-8.33 21.62-27.29 38.54-56.68 38.54h-32.64c-9.88 0-17.89-8.01-17.89-17.89s8.01-17.89 17.89-17.89h32.64c12.22 0 20.04-6.86 23.42-15.62 1.48-3.85 2.23-8.05 2.23-12.25 0-4.21-.73-8.4-2.21-12.23-3.34-8.65-11.12-15.4-23.44-15.4h-73.43v196.76H134.72V35.78H61.29c-12.34 0-20.12 6.73-23.45 15.35-1.47 3.82-2.2 8.01-2.2 12.22 0 4.2.75 8.4 2.24 12.25 3.39 8.79 11.21 15.68 23.41 15.68h25.14zm198.55 158.78v38.91h26.56c4.46.19 7.62 1.66 9.45 4.44 4.95 7.42-1.82 14.76-6.51 19.93-13.25 14.61-43.39 41.11-49.96 48.78-4.97 5.5-12.07 5.5-17.04 0-6.79-7.92-38.57-35.94-51.23-50.19-4.38-4.94-9.81-11.67-5.24-18.52 1.83-2.78 4.99-4.25 9.45-4.44h26.56v-38.91h57.96zm55.35-214.28H172.79v118.38c19.73 0 35.83 16.1 35.83 35.83h95.88c0-19.73 16.09-35.83 35.83-35.83V35.78zM256 45.62c23.89 0 43.26 19.37 43.26 43.26 0 23.89-19.37 43.26-43.26 43.26-23.89 0-43.26-19.37-43.26-43.26 0-23.89 19.37-43.26 43.26-43.26z"
    />
  </svg>
);

export const WithdrawalIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Svg} {...props} />
);
