import React from "react";
import clsx from "clsx";

import useTranslation, { TranslatorType } from "../../../hooks/useTranslation";
import { formatDate, formatDateTamil } from "../../../utils/date";

export default function InvoiceItem({
  label,
  children,
  highlight,
}: {
  label: string | React.ReactNode;
  highlight?: boolean;
  children: React.ReactNode;
}) {
  return (
    <div className="flex justify-between flex-wrap">
      <div className="font-normal text-gray-700">{label}</div>
      <div className={clsx(highlight ? "font-medium text-sm" : "", "text-gray-900")}>
        {children}
      </div>
    </div>
  );
}

export function InvoiceLabel({
  label,
  labelSuffix,
  row,
  className,
}: {
  label: string;
  labelSuffix?: string;
  row?: boolean;
  className?: string;
}) {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        "flex",
        row ? "flex-row items-center" : "flex-col justify-center",
        className ? className : "text-sm font-semibold text-gray-400"
      )}
    >
      <div>
        {label}
        {labelSuffix}
      </div>
      {row && <div className="px-1.5">|</div>}
      <div>
        {t(label)}
        {labelSuffix}
      </div>
    </div>
  );
}

export function InvoiceItemTamil({
  label,
  value,
  translateValue,
  highlight,
  date,
}: {
  label: string;
  value: string;
  translateValue?: ((value: string, t: TranslatorType) => string) | boolean;
  highlight?: boolean;
  date?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <InvoiceItem label={label} highlight={highlight}>
        {date ? formatDate(value) : value}
      </InvoiceItem>
      <InvoiceItem label={t(label)} highlight={highlight}>
        {date
          ? formatDateTamil(value)
          : translateValue
          ? typeof translateValue === "function"
            ? translateValue(value, t)
            : t(value)
          : ""}
      </InvoiceItem>
    </div>
  );
}
