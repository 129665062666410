import { useCallback, useMemo, useState } from "react";
import { gql } from "@urql/core";
import { useQuery } from "urql";

import { graphqlClient } from "../../../gql/client";
import { trackEvent } from "../../../utils/analytics";
import {
  dateParam,
  getQueryVariables,
  getURLParams,
  intParam,
  queryStringUpdate,
} from "../../../utils/query-params";

export const TRANSACTION_LEG_FRAGMENT: TransactionLegFragmentDoc = gql`
  fragment TransactionLeg on TransactionLeg {
    id
    date
    amount
    description
    balance
  }
`;

const ACCOUNT_TRANSACTIONS_SEARCH_QUERY: AccountTransactionsSearchQueryDoc = gql`
  query AccountTransactionsSearch(
    $accountId: Int!
    $offset: Int!
    $first: Int!
    $transactionsStartDate: Date
    $transactionsEndDate: Date
    $search: String
    $order: TransactionsOrder
    $groupBy: AggregationType
    $type: LegType
  ) {
    accountTransactions(
      accountId: $accountId
      limit: $first
      offset: $offset
      startDate: $transactionsStartDate
      endDate: $transactionsEndDate
      search: $search
      order: $order
      aggregationType: $groupBy
      type: $type
    ) {
      total
      transactions {
        ...TransactionLeg
      }
    }
  }
  ${TRANSACTION_LEG_FRAGMENT}
`;

const DEFAULT_LIMIT = 10;

export type Variables = Omit<AccountTransactionsSearchQueryVariables, "accountId">;

export type AccountTransactionsSearchResult = {
  // Filter variables and callbacks.
  variables: Variables;
  updateVariables: (variables: Variables) => void;
  resetVariables: () => void;
  inlineMode: boolean;

  loading: boolean;

  // Data
  transactions?: TransactionLegFragment[];
  total?: number;
};

function flushToURL(variables: Variables) {
  queryStringUpdate(getURLParams({ ...variables, accountId: undefined }));
}

export type AccountTransactionSearchProps = {
  accountId: number;
  filters?: Partial<Variables>;
  inlineMode?: boolean;
};

export const DEFAULT_VARIABLES: Variables = {
  first: DEFAULT_LIMIT,
  offset: 0,
  order: "DESCENDING",
};

export async function refetchTransactions(accountId: number) {
  const variables = getVariablesFromUrlSearch({ accountId });
  await graphqlClient
    .query(
      ACCOUNT_TRANSACTIONS_SEARCH_QUERY,
      { accountId, ...variables },
      {
        requestPolicy: "network-only",
      }
    )
    .toPromise();
}

function getVariablesFromUrlSearch({
  accountId,
  inlineMode,
  ...initialFilters
}: Partial<Variables> & { accountId: number; inlineMode?: boolean }): Variables {
  const defaults = { ...DEFAULT_VARIABLES, ...(initialFilters || {}) };
  const _variables = getQueryVariables(inlineMode ? "" : window.location.search, defaults);
  return {
    ..._variables,
    offset: intParam(_variables.offset) || 0,
    first: intParam(_variables.first) || DEFAULT_LIMIT,
    transactionsStartDate: dateParam(_variables.transactionsStartDate),
    transactionsEndDate: dateParam(_variables.transactionsEndDate),
  };
}

export default function useAccountTransactionsSearch({
  accountId,
  filters: initialFilters,
  inlineMode,
}: AccountTransactionSearchProps): AccountTransactionsSearchResult {
  function deseriableFilters() {
    return getVariablesFromUrlSearch({ accountId, inlineMode, ...initialFilters });
  }

  const [filters, setFilters] = useState<Variables>(deseriableFilters);

  const variables = useMemo(() => {
    const variables = { ...filters, accountId };
    return variables;
  }, [filters, accountId]);

  const [{ data, fetching: loading }] = useQuery({
    query: ACCOUNT_TRANSACTIONS_SEARCH_QUERY,
    variables,
    requestPolicy: "cache-and-network",
  });

  const { transactions, total } = useMemo(() => {
    return {
      transactions: data?.accountTransactions?.transactions,
      total: data?.accountTransactions?.total,
    };
  }, [data]);

  const updateVariables = useCallback(
    (variables: Variables) => {
      setFilters(variables);
      if (!inlineMode) {
        flushToURL(variables);
      }
      trackEvent({ action: "Account Transactions Search", label: "Update" }, variables);
    },
    [inlineMode]
  );

  const resetVariables = useCallback(() => {
    setFilters(DEFAULT_VARIABLES);
    flushToURL(DEFAULT_VARIABLES);
    trackEvent({ action: "Account Transactions Search", label: "Reset" });
  }, []);

  return {
    inlineMode: !!inlineMode,
    variables,

    loading,
    transactions,
    total,

    updateVariables,
    resetVariables,
  };
}
