import clsx from "clsx";

import { BREAKPOINTS } from "../../../constants/common";
import { Account } from "../../../hooks/api/accounts/useAccount";
import useAccountTransactionsSearch from "../../../hooks/api/accounts/useAccountTransactionsSearch";
import usePagination from "../../../hooks/usePagination";
import { formatCurrency } from "../../../utils/currency";
import { formatDate } from "../../../utils/date";
import ResponsiveTable, { ResponsiveColumnType } from "../../common/ResponsiveTable";
import { AccountCards } from "../AccountDetails";
import AccountTransactionsFilters from "./AccountTransactionsFilters";
import SelectedFilters from "./SelectedFilters";

export const ACCOUNT_TRANSACTION_COLUMNS: ResponsiveColumnType<TransactionLegFragment>[] = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    fixed: "left",
    align: "left",
    width: 130,
    render: formatDate,
    cardable: { position: "top", containerClassName: "w-1/3" },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    ellipsis: true,
    cardable: { position: "bottom", valueClassName: "overflow-ellipsis text-gray-500 text-sm" },
  },
  {
    title: "Credit",
    dataIndex: "amount",
    key: "credit",
    width: 150,
    render: (value, leg) => (leg.amount > 0 ? formatCurrency(Math.abs(value)) : ""),
  },
  {
    title: "Debit",
    dataIndex: "amount",
    key: "debit",
    width: 150,
    render: (value, leg) => (leg.amount < 0 ? formatCurrency(Math.abs(value)) : ""),
    cardable: {
      position: "top",
      title: "Amount",
      containerClassName: "w-1/3",
      renderForCard: (value, leg) => (
        <span className={clsx(leg.amount > 0 ? "text-green-500" : "text-red-400")}>
          {formatCurrency(Math.abs(value))}&nbsp;{leg.amount > 0 ? "Cr" : "Dr"}
        </span>
      ),
    },
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    width: 175,
    render: (value) => formatCurrency(value),
    cardable: { position: "top", title: "Balance", containerClassName: "w-1/3 items-end" },
  },
];

export default function AccountTransactions({ account }: { account: Account }) {
  const accountTransactionsSearch = useAccountTransactionsSearch({ accountId: account.id });
  const { loading, variables, updateVariables, resetVariables, transactions, total } =
    accountTransactionsSearch;
  const { pagination, page } = usePagination({ variables, updateVariables, total });

  return (
    <div className="flex flex-col p-2 w-full">
      <AccountTransactionsFilters
        variables={variables}
        updateVariables={updateVariables}
        resetVariables={resetVariables}
      />

      <ResponsiveTable<TransactionLegFragment>
        rowKey="id"
        scroll={{ x: BREAKPOINTS.md, y: 435, scrollToFirstRowOnChange: true }}
        loading={loading}
        bordered
        columns={ACCOUNT_TRANSACTION_COLUMNS}
        dataSource={transactions}
        pagination={pagination}
        printSettings={{
          title: `${account.description}`,
          tableTitle: (
            <div className="flex w-full flex-col items-center">
              <div className="w-full text-center text-2xl font-bold pb-2">
                {account.description}
              </div>

              <AccountCards account={account} />

              <div className="flex flex-wrap px-2 py-1 mt-1 text-base font-medium">
                <span className="pr-2">Transactions Filters:</span>
                <SelectedFilters filters={variables} />
              </div>

              <div className="flex text-base px-2 pt-2 text-gray-600">
                Showing {total && total > variables.first ? variables.first : total} Transactions -
                (Page {page.number + 1} / {page.total})
              </div>
            </div>
          ),
          orientation: "portrait",
        }}
      />
    </div>
  );
}
