import { ACCOUNT_CATEGORIES_ORDERED, ACCOUNT_TYPES } from "../constants/accounts";
import { AccountCategoryEnum, AccountSubtype, AccountType } from "../gql/graphql";
import { arraySorter } from "./common";
import { getQueryVariables, getURLParams } from "./query-params";

export const getAccountPath = (accountId: number) =>
  `/accounts/${accountId}${window.location.search}`;

export const zeroBalance = (account: AccountDetailsFragment) =>
  parseInt(`${account.currentBalance}`) === 0;

export type AccountSearchParams = {
  searchText?: string;
  transactionsStartDate?: string;
  transactionsEndDate?: string;
};

export const getAccountsSearchParams = (params: AccountSearchParams) => {
  const searchParams = getQueryVariables(window.location.search, {});
  return getURLParams({ ...searchParams, ...params });
};

export const getCategoryTitle = (category: AccountCategoryEnum) => {
  const values = category.split("_");
  const type = values[0] as AccountType;
  let subtype = undefined;
  if (values.length > 1 && ACCOUNT_TYPES.includes(type)) {
    const type = values[0];
    subtype = category.replace(`${type}_`, "") as AccountSubtype;
  }
  return subtype ? `${type} / ${subtype.replaceAll("_", " ")}` : type;
};

export const getAccountCategory = (type: AccountType, subtype?: AccountSubtype) =>
  (subtype ? `${type}_${subtype}` : type) as AccountCategoryEnum;

export const groupAccountsByCategory = <T extends { type: AccountType; subtype?: AccountSubtype }>(
  accounts: T[]
): { [K in AccountCategoryEnum]: T[] } => {
  return accounts.reduce((categories, account) => {
    const key = getAccountCategory(account.type, account.subtype);

    if (!categories[key]) {
      categories[key] = [];
    }
    categories[key].push(account);
    return categories;
  }, {} as { [K in AccountCategoryEnum]: T[] });
};

export const accountsSorter = <T extends { category: AccountCategoryEnum }>(a: T, b: T) => {
  return arraySorter(a, b, "category", ACCOUNT_CATEGORIES_ORDERED);
};
