export default function Watermark({ size = 175 }: { size?: number }) {
  return (
    <div
      className="absolute w-full top-0 left-0 h-full flex items-center justify-center"
      style={{ zIndex: -10 }}
    >
      <div
        className="text-gray-100 opacity-40 font-bold uppercase transform -rotate-45 select-none"
        style={{ fontSize: size }}
      >
        NIDHI
      </div>
    </div>
  );
}
