import { useMemo, useState } from "react";
import { Divider, List, Modal, Tag } from "antd";

import AnchorLink from "../common/AnchorLink";
import CloseIcon from "../common/CloseIcon";

export function Permissions({
  permissions,
  tooltip,
}: {
  permissions: PermissionFragment[];
  tooltip?: boolean;
}) {
  if (!permissions.length) {
    return null;
  }

  return tooltip ? (
    <List<PermissionFragment>
      size="small"
      rowKey="id"
      dataSource={permissions}
      renderItem={(perm) => (
        <List.Item>
          <span className="text-white">{perm.description}</span>
        </List.Item>
      )}
    />
  ) : (
    <div className="flex flex-wrap">
      {permissions
        .filter((perm) => !!perm.permissionEnum)
        .sort((a, b) => a.description.localeCompare(b.description))
        .map((perm) => (
          <Tag className={"mr-2 mb-2 px-2 py-1.5 rounded-lg"} color="purple" key={perm.id}>
            {perm.description}
          </Tag>
        ))}
    </div>
  );
}

export default function StaffPermissions({ staff }: { staff: StaffFragment }) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { permissionsCount, permissions } = useMemo(() => {
    const perms = staff.groups.reduce(
      (perms, group) => perms.concat(group.permissions.filter((perm) => !!perm.permissionEnum)),
      staff.userPermissions
    );
    const uniquePerms = perms.reduce((hash, perm) => {
      hash[perm.id] = perm;
      return hash;
    }, {} as { [K: number]: PermissionFragment });

    const permissions = Object.values(uniquePerms);
    return { permissions, permissionsCount: permissions.length };
  }, [staff.userPermissions, staff.groups]);

  return !staff.isSuperuser ? (
    <>
      {permissionsCount ? (
        <AnchorLink className="cursor-pointer">
          <div onClick={() => setShowModal(true)}>View {permissionsCount} Perms</div>
        </AnchorLink>
      ) : (
        "--"
      )}
      {showModal && (
        <Modal
          title={
            <div className="text-center text-xl font-medium">
              {staff.name}&apos;s Permissions
              <Divider className="mt-2 mb-4" />
            </div>
          }
          centered
          closable
          maskClosable
          open
          className="max-w-2xl"
          closeIcon={<CloseIcon />}
          footer={false}
          width="100%"
          onCancel={() => setShowModal(false)}
        >
          <Permissions permissions={permissions} />
        </Modal>
      )}
    </>
  ) : (
    <>ALL</>
  );
}
