import React, { useEffect, useState } from "react";
import { Element, scroller } from "react-scroll";
import { AutoComplete, Collapse, Empty, Input, Skeleton } from "antd";
import clsx from "clsx";

import { ACCOUNT_CATEGORIES, ACCOUNT_CATEGORIES_ORDERED } from "../../constants/accounts";
import useAccountsSearch, { Account } from "../../hooks/api/accounts/useAccountsSearch";
import { getCategoryTitle, groupAccountsByCategory } from "../../utils/account";
import { formatCurrency } from "../../utils/currency";
import { renderAccountIcon } from "./utils";

const ACCOUNTS_CONTAINER_ID = "accounts-container";

type Props = {
  useDropdown?: boolean;
  selectedAccountId?: number;
  onAccountSelection: (account: Account) => void;
};

export default function AccountsSelector({
  useDropdown,
  onAccountSelection,
  selectedAccountId,
}: Props) {
  const [selectedAccount, setSelectedAccount] = useState<Account>();
  const _selectedAccountId = selectedAccount ? selectedAccount.id : selectedAccountId;
  const { fetching, accounts, searchText, updateSearchText } = useAccountsSearch({
    requestPolicy: "cache-and-network",
  });
  const [open, setOpen] = useState(false);

  function _onAccountSelection(account: Account) {
    setSelectedAccount(account);
    onAccountSelection(account);
    if (useDropdown) {
      setOpen(false);
    }
  }

  const accountsByCategory = groupAccountsByCategory(accounts);

  useEffect(() => {
    if (useDropdown) {
      return;
    }

    // do the scroll only for the URL update based account selection.
    if (accounts && selectedAccountId && !selectedAccount) {
      scroller.scrollTo(`${selectedAccountId}`, {
        duration: 1000,
        delay: 100,
        smooth: true,
        containerId: ACCOUNTS_CONTAINER_ID,
        offset: -100,
      });
    }
  }, [useDropdown, accounts, selectedAccountId, selectedAccount]);

  function renderAccounts() {
    return (
      <div
        className="w-full h-full accounts overflow-y-scroll border-b border-r"
        id={ACCOUNTS_CONTAINER_ID}
      >
        {fetching || accounts.length > 0 ? (
          <Skeleton
            loading={!accounts.length}
            className="p-2"
            paragraph={{ rows: 20 }}
            round
            active
          >
            <Collapse
              bordered={false}
              expandIconPosition="start"
              className="rounded-none w-full"
              defaultActiveKey={ACCOUNT_CATEGORIES}
            >
              {ACCOUNT_CATEGORIES_ORDERED.map((type) => {
                const _accounts = accountsByCategory[type];
                return _accounts ? (
                  <Collapse.Panel
                    key={type}
                    header={
                      <span className={clsx("font-semibold text-sm")}>
                        {getCategoryTitle(type)}
                      </span>
                    }
                  >
                    <div
                      key={type}
                      className={clsx(
                        "flex pb-2 w-full",
                        useDropdown ? "flex-row flex-wrap" : "flex-col space-y-1"
                      )}
                    >
                      {_accounts.map((account) => (
                        <React.Fragment key={account.id}>
                          <Element
                            key={account.id}
                            name={`${account.id}`}
                            className={useDropdown ? "w-full sm:w-1/2 p-1" : ""}
                          >
                            <div
                              key={account.id}
                              className={clsx(
                                useDropdown ? "p-3 w-full" : "py-1.5 px-2.5",
                                "m-1 cursor-pointer border border-gray-400 rounded-md",
                                account.id !== _selectedAccountId &&
                                  "hover:bg-green-50 hover:border-green-400",
                                account.id === _selectedAccountId
                                  ? "bg-green-100 border-green-500"
                                  : "bg-white"
                              )}
                              onClick={() => _onAccountSelection(account)}
                            >
                              <div className="flex justify-between">
                                <div
                                  className={clsx(
                                    "font-medium",
                                    useDropdown ? "text-base pb-1" : ""
                                  )}
                                >
                                  {account.description}
                                </div>
                                {renderAccountIcon(account.subtype)}
                              </div>
                              <div
                                className={clsx(
                                  "text-sm",
                                  useDropdown ? "text-gray-600 font-semibold" : "text-gray-500"
                                )}
                              >
                                Balance: {formatCurrency(account.currentBalance)}
                              </div>
                            </div>
                          </Element>
                        </React.Fragment>
                      ))}
                    </div>
                  </Collapse.Panel>
                ) : (
                  <></>
                );
              })}
            </Collapse>
          </Skeleton>
        ) : (
          <Empty description="No account found!" />
        )}
      </div>
    );
  }

  function renderInput() {
    return (
      <Input.Search
        value={searchText}
        size={useDropdown ? "large" : "middle"}
        loading={fetching}
        allowClear
        placeholder="Search for an account."
        onSearch={(value) => updateSearchText(value)}
        onChange={(event) => updateSearchText(event.target.value)}
      />
    );
  }

  return (
    <div className="flex h-full flex-col w-full">
      <div
        className={clsx(
          useDropdown
            ? "bg-gray-100 px-1.5 py-2 border-t border-r border-l rounded"
            : "px-2 py-3 border-r border-b"
        )}
      >
        {useDropdown ? (
          <AutoComplete
            value={searchText}
            popupMatchSelectWidth
            className="w-full"
            size="large"
            showSearch
            allowClear={false}
            notFoundContent={<Empty />}
            open={open}
            onDropdownVisibleChange={setOpen}
            dropdownRender={() => (
              <div
                className="w-full overflow-y-scroll"
                style={{ maxHeight: "calc(100vh - 400px)" }}
              >
                {renderAccounts()}
              </div>
            )}
          >
            {renderInput()}
          </AutoComplete>
        ) : (
          renderInput()
        )}
      </div>

      {!useDropdown && renderAccounts()}
    </div>
  );
}
