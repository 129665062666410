/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql } from "@urql/core";
import { Button, Divider } from "antd";
import { FormikHelpers } from "formik";
import { useMutation } from "urql";
import * as Yup from "yup";

import useError from "../../../hooks/useError";
import { Loan } from "../../../hooks/useLoanAction";
import { trackEvent } from "../../../utils/analytics";
import { getLoanDataForAnalytics } from "../../../utils/analytics/data";
import Form from "../../form/Form";
import FormNumberField from "../../form/FormNumberField";
import FormTextArea from "../../form/FormTextArea";
import { LOAN_CONTRACT_FRAGMENT } from "../fragments";

const DELETE_LOAN_MUTATION: DeleteLoanMutationDoc = gql`
  mutation DeleteLoan($loanId: Int!, $notes: String!) {
    deleteLoan(loanId: $loanId, loanNotes: $notes) {
      success
      failureMessage
      loan {
        ...Loan
      }
    }
  }
  ${LOAN_CONTRACT_FRAGMENT}
`;

type FormProps = {
  loan: Loan;
  onSuccess: () => void;
};

type FormData = {
  notes: string;
  psn: number | null;
};

export default function DeleteLoanForm({ loan, onSuccess }: FormProps) {
  const [, deleteLoan] = useMutation(DELETE_LOAN_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return deleteLoan({ loanId: loan.id, notes: values.notes })
      .then((response) => {
        if (response.data?.deleteLoan?.success && response.data.deleteLoan.loan) {
          onSuccess();
          const loan = response.data.deleteLoan.loan;
          trackEvent({ action: "Delete Loan" }, getLoanDataForAnalytics(loan));
        } else {
          setError({
            customError: response.data?.deleteLoan?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to close loan, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{ psn: null, notes: loan.loanNotes || "" }}
        validationSchema={{
          psn: Yup.number()
            .required("Enter the PSN to delete the loan.")
            .nullable(true)
            .equals([loan.pledgeSheetNumber], "Enter the PSN correctly to delete the loan."),
          notes: Yup.string().required("Enter the reason for deleting the loan.").nullable(),
        }}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormNumberField
                label="Pledge Sheet Number"
                helper={
                  <span className="text-gray-600 text-sm font-medium">
                    Enter the &quot;PLEDGE SHEET NUMBER&quot; of the loan below to proceed with the
                    deletion
                  </span>
                }
                precision={0}
                name="psn"
                placeholder={`${loan.pledgeSheetNumber}`}
                column
                required
              />

              <FormTextArea
                required
                helper="Reason for deleting this loan."
                label="Reason"
                name="notes"
                placeholder="Provide a reason for deleting this loan"
                column
              />

              <Divider />

              <Button
                shape="round"
                size="large"
                loading={isSubmitting}
                type="primary"
                danger
                htmlType="submit"
              >
                Submit
              </Button>
            </>
          );
        }}
      </Form>
    </div>
  );
}
