import { EditFilled } from "@ant-design/icons";
import { Divider, Skeleton } from "antd";

import useAccount, { Account as AccountType } from "../../hooks/api/accounts/useAccount";
import useAccountAction from "../../hooks/useAccountAction";
import useTablet from "../../hooks/useTablet";
import { getAccountPath } from "../../utils/account";
import { toTitleCase } from "../../utils/common";
import { formatCurrency, formatMoneyHumanReadable } from "../../utils/currency";
import ActionIconButton from "../common/ActionIconButton";
import Centered from "../common/Centered";
import EmptyPage from "../common/EmptyPage";
import Stats from "../common/Stats";
import AccountActions from "./AccountActions";
import AccountsBase from "./AccountsBase";
import AccountFormModal from "./forms/AccountFormModal";
import AccountTransactions from "./transactions/AccountTransactions";
import { renderAccountIcon } from "./utils";

export function AccountCards({ account }: { account: AccountDetailsFragment }) {
  return (
    <div className="flex w-full justify-between flex-wrap px-1">
      <Stats title="Type" value={account.type} containerClass="flex-grow min-w-max p-1" />
      {account.subtype && (
        <Stats
          title="Subtype"
          value={toTitleCase(account.subtype).toUpperCase()}
          containerClass="flex-grow p-1"
          valueRender={() => (
            <div className="flex justify-between space-x-2">
              <div>{account.subtype && toTitleCase(account.subtype).toUpperCase()}</div>
              {renderAccountIcon(account.subtype)}
            </div>
          )}
        />
      )}

      <Stats
        title="Current Balance"
        value={formatCurrency(account.currentBalance, 0)}
        containerClass="flex-grow p-1"
        valueRender={() => (
          <div className="flex justify-between space-x-2">
            <div>{formatCurrency(account.currentBalance, 0)}</div>
            <div>{formatMoneyHumanReadable(account.currentBalance)}</div>
          </div>
        )}
      />
    </div>
  );
}

function Account({ account }: { account: AccountType }) {
  const accountAction = useAccountAction();
  const { openForm, showForm } = accountAction;

  return (
    <>
      <div className="flex py-1.5 flex-wrap justify-between w-full">
        <div className="flex px-4 items-center space-x-1 text-2xl font-bold pt-2 flex-grow">
          {account.description}
          <ActionIconButton
            size="small"
            type="text"
            className="text-green-500"
            ghost={false}
            icon={<EditFilled />}
            onClick={() => openForm({ action: "edit", account })}
            perm="EDIT_ACCOUNT"
          />
        </div>

        <div className="flex space-x-2 pt-2 px-2">
          <AccountActions account={account} {...accountAction} />
        </div>
      </div>

      <Divider className="my-2" />

      <AccountCards account={account} />

      {showForm && <AccountFormModal {...accountAction} />}
    </>
  );
}

function AccountDetails({ accountId }: { accountId: number }) {
  const { fetching, account } = useAccount({ accountId });
  return (
    <>
      {fetching ? (
        <Centered>
          <Skeleton active paragraph={{ rows: 10 }} className="max-w-screen-sm" />
        </Centered>
      ) : account ? (
        <>
          <Account account={account} />
          <AccountTransactions account={account} />
        </>
      ) : (
        <EmptyPage
          title="Unable to load the account details!"
          back="/accounts/"
          backCta="Accounts Dashboard"
        />
      )}
    </>
  );
}

export default function AccountDetailsWithSelector() {
  const { isTablet } = useTablet();
  return (
    <AccountsBase
      useDrawerForAccountSelector={isTablet}
      onAccountSelection={(account) => {
        window.history.pushState("", "", getAccountPath(account.id));
      }}
    >
      {(selectedAccountId) =>
        selectedAccountId ? <AccountDetails accountId={selectedAccountId} /> : <></>
      }
    </AccountsBase>
  );
}
