import { useState } from "react";
import { Divider, Modal, Tag } from "antd";

import CloseIcon from "../common/CloseIcon";
import { Permissions } from "./StaffPermissions";

type GroupType = StaffFragment["groups"][0];

export default function Group({ group }: { group: GroupType }) {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <div className="mr-1 mb-2" key={group.id}>
        <Tag
          className="cursor-pointer rounded-lg px-2"
          color="purple"
          key={group.id}
          onClick={() => setShowModal(true)}
        >
          {group.name}
        </Tag>
      </div>

      <Modal
        title={
          <div className="text-center text-xl font-medium">
            {group.name}&apos;s Permissions <Divider className="mt-2 mb-4" />
          </div>
        }
        centered
        closable
        maskClosable
        open={showModal}
        className="max-w-2xl"
        closeIcon={<CloseIcon />}
        footer={false}
        width="100%"
        onCancel={() => setShowModal(false)}
      >
        <Permissions permissions={group.permissions} />
      </Modal>
    </>
  );
}
