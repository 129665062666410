import { message, Modal } from "antd";

import { PaymentActionResult } from "../../../hooks/usePaymentAction";
import { scrollToBottom } from "../../../utils/scroll";
import CloseIcon from "../../common/CloseIcon";
import LoanCard from "../../loans/LoanCard";
import AddPayment from "./AddPayment";
import DeletePayment from "./DeletePayment";
import EditPayment from "./EditPayment";

type Props = PaymentActionResult;

export default function PaymentFormModal(props: Props) {
  const { loan, action, payment, closePaymentForm, showForm } = props;

  function onClose(msg?: string) {
    if (msg) {
      message.success(msg);
    }
    closePaymentForm();
    scrollToBottom();
  }

  return showForm && action && loan ? (
    <Modal
      title={
        <>
          <h4 className="my-2 font-bold text-2xl text-center">
            {action.toLocaleUpperCase()} PAYMENT
          </h4>

          <LoanCard loan={loan} />
        </>
      }
      centered
      closable
      maskClosable
      open
      closeIcon={<CloseIcon />}
      footer={false}
      onCancel={() => closePaymentForm()}
    >
      {payment && action === "edit" ? (
        <EditPayment
          payment={payment}
          loan={loan}
          onClose={(done) => onClose(done ? "Successfully edited the payment." : undefined)}
        />
      ) : payment && action === "delete" ? (
        <DeletePayment
          loan={loan}
          payment={payment}
          onClose={(done) => onClose(done ? "Successfully deleted the payment." : undefined)}
        />
      ) : (
        <AddPayment
          loan={loan}
          onClose={(done) => onClose(done ? "Successfully added the payment." : undefined)}
        />
      )}
    </Modal>
  ) : null;
}
