import React from "react";
import clsx from "clsx";
import { connect, ErrorMessage, FormikProps } from "formik";

import QuestionTooltip from "../common/QuestionTooltip";

type SizeType = "small" | "middle" | "large";

type FormLabelProps = {
  label: string | React.ReactNode;
  className?: string;
  required?: boolean;
  tooltip?: string;
  helper?: string | React.ReactNode;
};

export type FormFieldWrapperProps = FormLabelProps & {
  name: string;
  category?: string;
  labelPosition?: "items-start" | "items-center" | "items-end";
  column?: boolean;
  hideErrorMessage?: boolean;
  size?: SizeType;

  children?: React.ReactChild | React.ReactChildren;
};

export const FormFieldLabel = ({ className, label, required, tooltip, helper }: FormLabelProps) => {
  return (
    <div className="flex flex-col">
      <div className={clsx(className, "flex items-center")}>
        {label}
        {required && <span className="text-red-500 px-0.5">*</span>}
        {tooltip && <QuestionTooltip>{tooltip}</QuestionTooltip>}
      </div>
      {helper && (
        <div className="pt-0.5 whitespace-normal text-sm font-medium text-gray-500">{helper}</div>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormikPropsType = any;

export interface WithFormFieldWrapperProps {
  name: string;
  size: SizeType;
  formik: FormikProps<FormikPropsType>;
}

export default function FormFieldWrapper(props: FormFieldWrapperProps) {
  const { name, label, column, helper, labelPosition, className, tooltip, required } = props;
  return (
    <div
      className={clsx(
        "flex my-3 w-full justify-start items-start",
        column
          ? "flex-col sm:justify-start items-start"
          : `flex-col sm:flex-row justify-between ${labelPosition ? labelPosition : "items-start"}`,
        className
      )}
    >
      {label && (
        <div
          className={clsx(
            "flex justify-start items-center h-full text-base font-medium",
            column ? "w-full" : "w-full sm:w-2/5",
            "mb-1"
          )}
        >
          <FormFieldLabel label={label} required={required} tooltip={tooltip} helper={helper} />
        </div>
      )}

      <div className={clsx("justify-start items-start", column ? "w-full" : "w-full sm:w-3/5")}>
        <div className="flex flex-col w-full">
          <>
            {props.children}
            {!props.hideErrorMessage && (
              <ErrorMessage name={name}>
                {(msg) => <div className="mt-1 font-medium text-red-500">{msg}</div>}
              </ErrorMessage>
            )}
          </>
        </div>
      </div>
    </div>
  );
}

export function withFormFieldWrapper<TProps>(
  WrappedComponent: React.ComponentType<TProps & WithFormFieldWrapperProps>
): React.ComponentType<TProps & FormFieldWrapperProps> {
  class InnerWrapper extends React.Component<
    TProps & FormFieldWrapperProps & { formik: FormikProps<FormikPropsType> }
  > {
    public render() {
      const {
        label,
        column,
        labelPosition,
        formik,
        hideErrorMessage,
        required,
        tooltip,
        helper,
        ...rest
      } = this.props;

      return (
        <FormFieldWrapper
          name={rest.name}
          label={label}
          column={column}
          labelPosition={labelPosition}
          hideErrorMessage={hideErrorMessage}
          required={required}
          tooltip={tooltip}
          helper={helper}
        >
          <WrappedComponent
            {...(rest as TProps)}
            size={(rest.size || "large") as SizeType}
            name={rest.name}
            formik={formik}
          />
        </FormFieldWrapper>
      );
    }
  }

  return connect<
    TProps & FormFieldWrapperProps,
    TProps & FormFieldWrapperProps & { formik: FormikProps<FormikPropsType> }
  >(InnerWrapper);
}
