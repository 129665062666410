import { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

import { toServerDateNonNull } from "../utils/date";
import { getQueryVariables, getURLParams, queryStringUpdate } from "../utils/query-params";

dayjs.extend(quarterOfYear);

export type DateRangeFilters = {
  startDate?: string;
  endDate?: string;
};

export type DateRangeFiltersResult = {
  filters: DateRangeFilters;
  updateFilters: (filters: DateRangeFilters) => void;
  resetFilters: () => void;

  // memoized with defaults.
  variables: DateRangeFilters;
};

type Props = { defaults?: Partial<DateRangeFilters> };

const DEFAULT_FILTERS: DateRangeFilters = {
  startDate: toServerDateNonNull(dayjs()),
  endDate: toServerDateNonNull(dayjs()),
};

export default function useDateRangeFilters(props: Props): DateRangeFiltersResult {
  function flushToURL(variables: DateRangeFilters) {
    queryStringUpdate(getURLParams(variables));
  }

  function deseriableVariables(): DateRangeFilters {
    const defaults = { ...DEFAULT_FILTERS, ...(props.defaults || {}) };
    const _variables = getQueryVariables(window.location.search, defaults);
    return { ..._variables };
  }

  const [filters, setFilters] = useState<DateRangeFilters>(deseriableVariables);
  const variables = useMemo(() => {
    return { ...props.defaults, ...filters };
  }, [filters, props.defaults]);

  const updateFilters = useCallback((filters: DateRangeFilters) => {
    setFilters(filters);
    flushToURL(filters);
  }, []);

  const resetFilters = useCallback(() => {
    setFilters({});
    flushToURL({});
  }, []);

  return { filters, variables, updateFilters, resetFilters };
}
