import { useLocation, useParams } from "react-router-dom";

export default function useQueryParams(searchField?: string) {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();

  let _id;
  if (searchField && search && !id) {
    const params = new URLSearchParams(search);
    _id = params.get(searchField);
  } else {
    _id = id;
  }

  return _id ? decodeURIComponent(_id) : null;
}
