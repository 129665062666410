import { useHistory } from "react-router-dom";
import { PlusCircleFilled } from "@ant-design/icons";
import { Divider } from "antd";
import { gql, useQuery } from "urql";

import useAccountAction from "../../hooks/useAccountAction";
import useMobile from "../../hooks/useMobile";
import { getAccountPath } from "../../utils/account";
import ActionButton from "../common/ActionButton";
import { ACCOUNTS_CATEGORY_FRAGMENT } from "../dashboard/AccountCategoryCard";
import AccountsDashboard from "../dashboard/AccountsDashboard";
import AccountsBase from "./AccountsBase";
import AccountFormModal from "./forms/AccountFormModal";

export const ACCOUNTS_DASHBOARD_QUERY: AccountsDashboardQueryDoc = gql`
  query AccountsDashboard {
    dashboard {
      accounts {
        categories {
          ...AccountCategory
        }
      }
    }
  }
  ${ACCOUNTS_CATEGORY_FRAGMENT}
`;

export default function Accounts() {
  const [{ data, fetching }] = useQuery({
    query: ACCOUNTS_DASHBOARD_QUERY,
    requestPolicy: "cache-and-network",
  });

  const history = useHistory();
  const accountAction = useAccountAction();
  const { openForm, showForm } = accountAction;
  const { isMobile } = useMobile();

  return (
    <AccountsBase
      useDrawerForAccountSelector={isMobile}
      onAccountSelection={(account) => history.push(getAccountPath(account.id))}
    >
      {() => (
        <div className="w-full h-full flex flex-col border rounded-tr-md rounded-br-md overflow-scroll">
          <div className="flex justify-center lg:justify-between flex-wrap px-2 pt-3.5 pb-2 items-center">
            <div className="flex flex-grow justify-center text-2xl pb-1 md:pb-0 md:text-3xl px-2 text-gray-700 font-bold">
              ACCOUNT BALANCES
            </div>
            <div className="px-2">
              <ActionButton
                cta="Create Account"
                icon={<PlusCircleFilled />}
                perm="EDIT_ACCOUNT"
                onClick={() => openForm({ action: "create" })}
              />
            </div>
          </div>

          <Divider className="mt-1 mb-3" />

          <div className="flex flex-wrap p-1">
            <AccountsDashboard
              data={data?.dashboard?.accounts}
              loading={fetching}
              className="flex items-stretch pr-2 pb-2 w-full lg:w-1/2 xl:w-1/3"
            />
          </div>

          {showForm && <AccountFormModal {...accountAction} />}
        </div>
      )}
    </AccountsBase>
  );
}
