import { ItemType } from "../types/enum";
import { toTitleCase } from "../utils/common";

export const ITEM_TYPES: ItemType[] = ["GOLD", "SILVER", "BRASS"];

export const ITEM_TYPE_OPTIONS = ITEM_TYPES.map((type) => ({
  label: toTitleCase(type),
  value: type,
}));

export const SILVER_INTEREST_RATE = 4;
export const INTEREST_RATES = [1.25, 2, 2.5, SILVER_INTEREST_RATE];
export const CUSTOM_INTEREST_RATE_OPTION_VALUE = 0;

export const INTEREST_RATE_OPTIONS: { label: string; value: number }[] = INTEREST_RATES.map(
  (rate) => ({ label: `${rate}%`, value: rate })
);
INTEREST_RATE_OPTIONS.push({ label: "Custom", value: CUSTOM_INTEREST_RATE_OPTION_VALUE });
