import { useState } from "react";
import { ArrowRightOutlined, CalendarOutlined, MoreOutlined } from "@ant-design/icons";
import { DatePicker, DatePickerProps, Dropdown, MenuProps } from "antd";
import clsx from "clsx";
import dayjs, { Dayjs } from "dayjs";

import { DISPLAY_DATE_FORMAT } from "../../constants/common";
import { DateRange, getFinancialYearRanges, toDayjs } from "../../utils/date";

export type DateRangePickerProps = Omit<DatePickerProps, "picker">;

type Props = DateRangePickerProps & {
  handleChange: (start: Date | undefined, end: Date | undefined) => void;

  startDate?: string;
  endDate?: string;
  showPredefinedRanges?: boolean;
  showLabel?: boolean;
};

const PREDEFINED_RANGES: DateRange[] = [
  { label: "Today", value: [dayjs(), dayjs()] },
  { label: "Yesterday", value: [dayjs().subtract(1, "days"), dayjs().subtract(1, "days")] },
  { label: dayjs().format("MMM YYYY"), value: [dayjs().startOf("month"), dayjs().endOf("month")] },
  {
    label: dayjs().subtract(1, "months").format("MMM YYYY"),
    value: [
      dayjs().subtract(1, "months").startOf("month"),
      dayjs().subtract(1, "months").endOf("month"),
    ],
  },
  ...getFinancialYearRanges(),
];

export default function DateRangePicker({
  handleChange,
  startDate,
  endDate,
  showPredefinedRanges,
  showLabel,
  ...rest
}: Props) {
  const [_, setPredefinedRange] = useState<string>();
  const size = rest.size ?? "middle";

  const startMoment = toDayjs(startDate);
  const endMoment = toDayjs(endDate);

  const _handleChange = (value: Dayjs | undefined | null, start: boolean) => {
    const handler = (start: Date | undefined, end: Date | undefined) => {
      handleChange(start, end);
      setPredefinedRange(undefined);
    };

    if (!value) {
      handler(start ? undefined : startMoment?.toDate(), !start ? undefined : endMoment?.toDate());
    } else {
      handler(
        start ? value.toDate() : startMoment?.toDate(),
        !start ? value.toDate() : endMoment?.toDate()
      );
    }
  };

  function handleRangeSelection(range: DateRange) {
    setPredefinedRange(range.label);
    handleChange(range.value[0].toDate(), range.value[1].toDate());
  }

  const menu: MenuProps = {
    items: PREDEFINED_RANGES.map((range) => ({
      key: range.label,
      icon: <CalendarOutlined />,
      label: range.label,
      onClick: () => handleRangeSelection(range),
    })),
  };

  return (
    <div className="flex flex-col space-y-1 max-w-sm">
      <div className="flex items-center justify-between space-x-1">
        <div className="flex flex-col w-full space-y-1">
          <DatePicker
            {...rest}
            value={startMoment}
            onChange={(value) => _handleChange(value, true)}
            format={DISPLAY_DATE_FORMAT}
          />
          {showLabel && <div className="text-xs pt-0.5 text-gray-500">Start</div>}
        </div>

        <div className={clsx("px-2 text-gray-400", showLabel ? "mb-6" : "")}>
          <ArrowRightOutlined />
        </div>

        <div className="flex flex-col w-full space-y-1">
          <DatePicker
            {...rest}
            value={endMoment}
            onChange={(value) => _handleChange(value, false)}
            format={DISPLAY_DATE_FORMAT}
          />
          {showLabel && <div className="text-xs pt-0.5 text-gray-500">End</div>}
        </div>

        {showPredefinedRanges && (
          <Dropdown placement="bottomRight" menu={menu} trigger={["click"]}>
            <div
              className={clsx(
                "border cursor-pointer border-gray-300 rounded-lg hover:border-green-500 hover:bg-green-100 justify-center",
                size === "large" ? "p-1.5" : size === "middle" ? "p-1" : "p-0.5"
              )}
            >
              <MoreOutlined className="text-green-800 mb-1" color="current" />
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  );
}
