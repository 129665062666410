import { useCallback, useMemo } from "react";
import { Card, Divider, Empty, Skeleton, Statistic } from "antd";
import dayjs from "dayjs";
import { startCase } from "lodash";

import { TIMELINE_CHART_OPTIONS } from "../../constants/charts";
import { SERVER_DATE_FORMAT } from "../../constants/common";
import useProfits from "../../hooks/api/loans/useProfits";
import useMobile from "../../hooks/useMobile";
import type { EventPoint } from "../../types/enum";
import { formatCurrency, formatMoneyHumanReadable } from "../../utils/currency";
import { Charts } from "../common/Charts";
import DateRangePickerFilter from "../common/DateRangePickerFilter";
import LoansSearchModal, { useLoansSearchModal } from "../loans/search/LoansSearchModal";
import TimelineSelector from "./ChartTimelineSelector";

function NoProfits() {
  return (
    <div className="w-full py-10 flex items-center justify-center">
      <Empty
        description={
          <div className="text-gray-600 text-lg font-medium">
            No realized revenue for the selected time period.
          </div>
        }
      />
    </div>
  );
}

export default function Profits() {
  const profitsSearch = useProfits({});
  const { profits, filters, loading } = profitsSearch;
  const timeline = filters.timeline || "quarter";
  const { isMobile } = useMobile();

  const {
    openLoansSearch,
    closeLoansSearch,
    showLoansSearch,
    filters: searchFilters,
  } = useLoansSearchModal();

  const { investorInterestAmounts, commissions } = useMemo(() => {
    return {
      investorInterestAmounts: (profits || []).reduce(
        (profit, t) => profit + t.investorInterestAmount,
        0
      ),
      commissions: (profits || []).reduce((profit, t) => profit + t.commissionsAmount, 0),
    };
  }, [profits]);
  const totalProfits = investorInterestAmounts + commissions;

  const getOptions = useCallback(
    ({
      series,
    }: {
      series: Highcharts.SeriesColumnOptions[] | Highcharts.SeriesPieOptions[];
    }): Highcharts.Options => {
      return {
        ...TIMELINE_CHART_OPTIONS,
        series,
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        legend: {
          enabled: true,
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          stackLabels: {
            enabled: true,
            formatter: function () {
              const label = `${this.total}`;

              // Use thousands separator for four-digit numbers too
              if (/^[\d].*$/.test(label)) {
                return formatMoneyHumanReadable(this.total as number);
              }
              return label;
            },
          },
          labels: {
            enabled: !isMobile,
            formatter: function () {
              const label = this.axis.defaultLabelFormatter.call(this);

              // Use thousands separator for four-digit numbers too
              if (/^[\d].*$/.test(label)) {
                return formatMoneyHumanReadable(this.value as number);
              }
              return label;
            },
          },
          title: undefined,
        },
      };
    },
    [isMobile]
  );

  const getData = useCallback((): Highcharts.SeriesColumnOptions[] => {
    const getBarLabel = (date: string) => {
      const startDate = dayjs(date);

      switch (timeline) {
        case "month":
          return startDate.format("MMM YY");
        case "week":
          return `${startDate.format("MMM DD")} - ${dayjs(date)
            .endOf(timeline)
            .format("MMM DD")}, ${startDate.format("YY")}`;
        case "quarter":
          return `Q${startDate.format("Q YYYY")}`;
        case "year":
          return startDate.format("YYYY");
        case "day":
          return startDate.format("MMM DD, YYYY");
      }
    };

    function getSeries(field: "investorInterestAmount" | "commissionsAmount", color: string) {
      const values = {} as { [K: string]: number };
      profits.forEach((value) => {
        const key = value.metricDate.clone().startOf(timeline).format(SERVER_DATE_FORMAT);
        values[key] = values[key] ? values[key] + value[field] : value[field];
      });

      const data = Object.keys(values)
        .map((date) => ({ date, label: getBarLabel(date), profit: values[date] }))
        .filter((value) => value.profit)
        .sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf())
        .map((value) => {
          return {
            date: value.date,
            label: value.label,
            profit: value.profit,
            startDate: dayjs(value.date).startOf(timeline),
            endDate: dayjs(value.date).endOf(timeline),
          };
        });

      const _series: Highcharts.SeriesColumnOptions = {
        name: startCase(field),
        type: "column",
        color,

        data: data.map((value) => {
          const startDate = filters.startDate
            ? dayjs.max(value.startDate, dayjs(filters.startDate))
            : value.startDate;

          const endDate = filters.endDate
            ? dayjs.min(value.endDate, dayjs(filters.endDate))
            : value.endDate;

          return {
            seriesName: startCase(field),
            key: value.label,
            name: value.label,
            label: value.label,
            y: value.profit,
            profit: formatCurrency(value.profit),
            profitHuman: formatMoneyHumanReadable(value.profit),
            startDateServer: startDate?.format(SERVER_DATE_FORMAT),
            endDateServer: endDate?.format(SERVER_DATE_FORMAT),
            startDate: startDate?.format("MMM DD, YY"),
            endDate: endDate?.format("MMM DD, YY"),
          };
        }),
        events: {
          click(event) {
            const _event = event.point as unknown as EventPoint;
            openLoansSearch({
              eventDates: `${_event.startDateServer},${_event.endDateServer}`,
              first: 5,
            });
          },
        },
        tooltip: {
          headerFormat: "",
          pointFormat: `<span style="font-size: 13px; font-weight: 700; padding-bottom: 24px;">{point.name}</span>
          <br />
          <span style="font-size: 12px;font-weight: 500;">{point.startDate} - {point.endDate}</span>
          <br />
          <b style="font-size: 13px; font-weight: 700">{point.seriesName}: {point.profit}</b><br />`,
        },
        dataLabels: { enabled: true, format: "{point.profitHuman}" },
        showInLegend: true,
      };
      return _series;
    }

    return [
      getSeries("investorInterestAmount", "#95de64"),
      getSeries("commissionsAmount", "#ffadd2"),
    ];
  }, [filters, profits, timeline, openLoansSearch]);

  const getItemSeries = useCallback((): Highcharts.SeriesPieOptions[] => {
    const _profits = {} as { [K: string]: { name: string; profit: number } };
    profits.forEach((profit) => {
      const name = profit.itemType;
      let mergedAccount = _profits[name];
      if (!mergedAccount) {
        mergedAccount = { name, profit: 0 };
        _profits[name] = mergedAccount;
      }
      mergedAccount.profit += profit.realizedProfits;
    });

    const data = Object.values(_profits);
    const accountsSeries: Highcharts.SeriesPieOptions = {
      type: "pie" as const,
      id: "accounts",
      name: "Accounts",
      dataLabels: {
        enabled: true,
        format:
          '<b style="font-size: 12px;">{point.name}: <span style="color: {point.pnlColor};">{point.percentage:.1f}%</span></b>',
        style: {
          color: "black",
        },
      },
      data: data
        .filter((account) => account.profit)
        .sort((a, b) => b.profit - a.profit)
        .map((account) => {
          return {
            name: account.name,
            y: account.profit,
            displayValue: account.profit
              ? formatMoneyHumanReadable(account.profit)
              : account.profit,
            totalValue: formatMoneyHumanReadable(totalProfits),
          };
        }),

      tooltip: {
        headerFormat: `<b>{point.key}<br />{point.percentage:.1f}%</b><hr />`,
        pointFormatter() {
          const point = this.options as EventPoint;
          return `<table>
          <tr><td>P/L</td><td class="chart-tooltip-value">${point.displayValue}</td></tr>
          <tr><td>Total P/L</td><td class="chart-tooltip-value">${point.totalValue}</td></tr>
        </table>`;
        },
      },
    };

    return [accountsSeries];
  }, [profits, totalProfits]);

  const options = useMemo(() => {
    return getOptions({ series: getData() });
  }, [getData, getOptions]);

  const itemSeriesOptions = useMemo(() => {
    return getOptions({ series: getItemSeries() });
  }, [getItemSeries, getOptions]);

  return (
    <>
      <Card
        className="rounded-xl mb-2"
        bodyStyle={isMobile ? { padding: 0, paddingTop: 8 } : {}}
        title={
          <div className="flex flex-col justify-center my-5">
            <div className="text-3xl font-bold text-center text-gray-600 whitespace-normal">
              Realized Revenue
            </div>
            <div className="text-md text-center text-gray-400 font-medium whitespace-normal">
              Interest & Commissions
            </div>

            <div className="flex mt-4 px-2 items-start justify-center sm:justify-between space-x-2 flex-wrap">
              <Statistic
                title={<div className="text-lg font-bold text-gray-500">Interest Amount</div>}
                valueRender={() => (
                  <div className="text-2xl font-bold text-green-500">
                    {formatCurrency(investorInterestAmounts)}
                  </div>
                )}
                loading={loading}
              />
              <Statistic
                title={<div className="text-lg font-bold text-gray-500">Commissions</div>}
                valueRender={() => (
                  <div className="text-2xl font-bold text-green-500">
                    {formatCurrency(commissions)}
                  </div>
                )}
                loading={loading}
              />

              <Divider className="mt-2 mb-1" />

              <div className="flex w-full flex-col-reverse sm:flex-row pt-3 items-center justify-center sm:justify-between flex-wrap">
                <div className="mr-2 mb-2 flex w-full max-w-sm justify-center sm:justify-start">
                  <TimelineSelector {...profitsSearch} />
                </div>
                <div className="mr-2 mb-2 flex w-full max-w-sm justify-center sm:justify-start">
                  <DateRangePickerFilter {...profitsSearch} />
                </div>
              </div>
            </div>
          </div>
        }
      >
        <Skeleton active paragraph={{ rows: 15 }} title={false} loading={loading}>
          {profits.length > 0 ? <Charts key={timeline} options={options} /> : <NoProfits />}
        </Skeleton>
      </Card>

      <Card
        bodyStyle={isMobile ? { padding: 0, paddingTop: 8 } : {}}
        title={
          <div className="text-center text-xl font-bold text-gray-500">Revenue By Item Type</div>
        }
        className="my-2 rounded-xl"
      >
        <Skeleton active paragraph={{ rows: 10 }} title={false} loading={loading}>
          {profits.length > 0 ? (
            <Charts key={timeline} options={itemSeriesOptions} />
          ) : (
            <NoProfits />
          )}
        </Skeleton>
      </Card>

      <div className="py-10" />
      {showLoansSearch && <LoansSearchModal filters={searchFilters} onClose={closeLoansSearch} />}
    </>
  );
}
