import { useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";

import AccountsSelector from "./AccountsSelector";

export default function AccountsBase({
  children,
  onAccountSelection,
  useDrawerForAccountSelector,
}: {
  onAccountSelection: (account: AccountDetailsFragment) => void;
  children: (selectedAccountId?: number) => React.ReactElement;
  useDrawerForAccountSelector?: boolean;
}) {
  const { id } = useParams<{ id: string }>();
  const [selectedAccountId, setSelectedAccountId] = useState<number>(parseInt(id));

  function renderAccountSelector() {
    return (
      <AccountsSelector
        useDropdown={useDrawerForAccountSelector}
        selectedAccountId={selectedAccountId}
        onAccountSelection={(account) => {
          setSelectedAccountId(account.id);
          onAccountSelection(account);
        }}
      />
    );
  }

  return (
    <div
      className={clsx(
        "w-full h-full border rounded-md flex",
        useDrawerForAccountSelector ? "flex-col relative" : "flex-row"
      )}
    >
      <div className={clsx(useDrawerForAccountSelector ? "w-full" : "w-64 h-full flex-shrink-0")}>
        {renderAccountSelector()}
      </div>
      <div className="w-full h-full flex-shrink">{children(selectedAccountId)}</div>
    </div>
  );
}
