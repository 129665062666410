import { useMemo } from "react";
import { useHistory } from "react-router";
import { gql } from "@urql/core";
import { message, Skeleton } from "antd";
import { FormikHelpers } from "formik";
import { useMutation, useQuery } from "urql";

import { INVALID_ID } from "../../constants/common";
import { CUSTOMER_DETAILS_FRAGMENT } from "../../hooks/api/customers/useCustomer";
import { DOCUMENT_CONNECTION_FRAGMENT } from "../../hooks/api/documents/useDocument";
import useError from "../../hooks/useError";
import useQueryParams from "../../hooks/useQueryParams";
import { trackEvent } from "../../utils/analytics";
import Centered from "../common/Centered";
import EmptyPage from "../common/EmptyPage";
import CustomerForm, { cleanFormValues, FormData } from "./CustomerForm";

const EDIT_CUSTOMER_QUERY: EditCustomerFormQueryDoc = gql`
  query EditCustomerForm($id: Int!) {
    customers(id: $id) {
      edges {
        node {
          ...CustomerDetails
          primaryCustomer {
            ...CustomerDetails
          }
          documents {
            ...DocumentConnection
          }
        }
      }
    }
  }
  ${CUSTOMER_DETAILS_FRAGMENT}
  ${DOCUMENT_CONNECTION_FRAGMENT}
`;

const EDIT_CUSTOMER_MUTATION: EditCustomerMutationDoc = gql`
  mutation EditCustomer(
    $customerId: Int!
    $name: String!
    $address: String!
    $gender: Gender!
    $phone: String
    $occupation: String
    $careOfName: String
    $careOfType: String
    $primaryCustomerId: Int
    $photoDocId: Int
    $photoIdProofDocId: Int
    $isRepledgeCustomer: Boolean!
  ) {
    editCustomer(
      customerId: $customerId
      phone: $phone
      name: $name
      address: $address
      gender: $gender
      occupation: $occupation
      careOfName: $careOfName
      careOfType: $careOfType
      primaryCustomerId: $primaryCustomerId
      photoDocId: $photoDocId
      photoIdProofDocId: $photoIdProofDocId
      isRepledgeCustomer: $isRepledgeCustomer
    ) {
      success
      failureMessage
      customer {
        ...CustomerDetails
        documents {
          ...DocumentConnection
        }
      }
    }
  }
  ${CUSTOMER_DETAILS_FRAGMENT}
  ${DOCUMENT_CONNECTION_FRAGMENT}
`;

type Props = {
  customer: CustomerDetailsFragment;
  primaryCustomer?: CustomerDetailsFragment;
  documents: NidhiDocumentFragment[];
};

function EditCustomerForm({ customer, primaryCustomer, documents }: Props) {
  const [, editCustomer] = useMutation(EDIT_CUSTOMER_MUTATION);
  const history = useHistory();

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return editCustomer({ customerId: customer.id, ...cleanFormValues(values) })
      .then((response) => {
        if (response.data?.editCustomer?.success && response.data?.editCustomer?.customer) {
          message.success("Successfully updated customer.");
          trackEvent({ action: "Edit Customer" });
          history.push(`/customers/${customer.id}`);
        } else {
          setError({
            customError: response.data?.editCustomer?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to add customer, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  const photoDoc = documents.find((doc) => doc.documentType === "CUSTOMER_PHOTO");
  const photoIdProofDoc = documents.find((doc) => doc.documentType === "CUSTOMER_ID_CARD");

  return (
    <CustomerForm
      customerId={customer.id}
      initialValues={{
        name: customer.user.name || "",
        phone: customer.user.phone ? customer.user.phone.replace("+91", "") : undefined,
        gender: customer.gender,
        address: customer.address,
        occupation: customer.occupation,
        careOfName: customer.careOfName,
        careOfType: customer.careOfType,
        primaryCustomerId: primaryCustomer ? primaryCustomer.id : undefined,
        primaryCustomer: primaryCustomer
          ? {
              id: `${primaryCustomer.id}`,
              objectId: primaryCustomer.id,
              name: primaryCustomer.user.name || "",
              phone: primaryCustomer.user.phone,
              address: primaryCustomer.address,
              careOf: `${primaryCustomer.careOfName} ${primaryCustomer.careOfType}`,
              isRepledgeCustomer: primaryCustomer.isRepledgeCustomer,
            }
          : undefined,

        isRepledgeCustomer: customer.isRepledgeCustomer,

        photoDocId: photoDoc?.id,
        photoDoc,

        photoIdProofDocId: photoIdProofDoc?.id,
        photoIdProofDoc,
      }}
      onSubmit={onSubmit}
      error={error}
      edit
      onCancel={() => history.push(`/customers/${customer.id}`)}
    />
  );
}

export default function EditCustomer() {
  const customerId = useQueryParams();

  const variables = useMemo(
    () => ({ id: customerId ? parseInt(customerId) : INVALID_ID }),
    [customerId]
  );

  const [{ data, fetching }] = useQuery({
    query: EDIT_CUSTOMER_QUERY,
    variables,
    pause: !customerId,
  });

  const customer = data?.customers?.edges?.[0]?.node;
  const documents = (customer?.documents.edges || []).map(
    (edge) => edge?.node as NidhiDocumentFragment
  );

  return fetching ? (
    <Centered>
      <Skeleton active paragraph={{ rows: 10 }} className="max-w-screen-sm" />
    </Centered>
  ) : customer ? (
    <div className="w-full justify-center flex py-4">
      <EditCustomerForm
        customer={customer}
        primaryCustomer={customer.primaryCustomer}
        documents={documents}
      />
    </div>
  ) : (
    <EmptyPage
      title="Unable to load the customer!"
      back="/customers/search"
      backCta="Search Customers"
    />
  );
}
