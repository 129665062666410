/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useHistory } from "react-router-dom";
import { Divider, message } from "antd";
import { FormikHelpers } from "formik";
import { gql, useMutation } from "urql";

import { ACCOUNT_DETAILS_FRAGMENT } from "../../../../hooks/api/accounts/useAccount";
import useError from "../../../../hooks/useError";
import { getAccountPath } from "../../../../utils/account";
import { trackEvent } from "../../../../utils/analytics";
import { getAccountDataForAnalytics } from "../../../../utils/analytics/data";
import Form from "../../../form/Form";
import { FormButtons } from "../../../form/FormButtons";
import { AccountForm, AccountFormData, getAccountFormValidations } from "./AccountForm";

const CREATE_ACCOUNT_MUTATION: CreateAccountMutationDoc = gql`
  mutation CreateAccount(
    $name: String!
    $type: AccountType!
    $subtype: AccountSubtype
    $verboseName: String
    $openingBalance: Decimal
  ) {
    createAccount(
      name: $name
      type: $type
      subtype: $subtype
      verboseName: $verboseName
      openingBalance: $openingBalance
    ) {
      success
      failureMessage
      account {
        ...AccountDetails
        name
        code
        verboseName
      }
    }
  }
  ${ACCOUNT_DETAILS_FRAGMENT}
`;

type FormData = AccountFormData;

type FormProps = {
  onClose: (done?: boolean) => void;
};

export default function CreateAccount({ onClose }: FormProps) {
  const [, createAccount] = useMutation(CREATE_ACCOUNT_MUTATION);
  const history = useHistory();

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();
    if (!values.type) {
      formikActions.setSubmitting(false);
      return;
    }

    return createAccount({
      name: values.name,
      verboseName: values.verboseName,
      type: values.type === "BANK" || values.type === "UNSECURED_LOAN" ? "ASSET" : values.type!,
      subtype: values.type === "BANK" || values.type === "UNSECURED_LOAN" ? values.type : undefined,
      openingBalance: values.openingBalance,
    })
      .then(async (response) => {
        if (response.data?.createAccount?.success && response.data.createAccount.account) {
          const account = response.data.createAccount.account;
          trackEvent({ action: "Create Account" }, getAccountDataForAnalytics(account));
          message.success(`Successfully created a new account: ${account.code}.${account.name}`);
          history.push(getAccountPath(account.id));
          onClose();
        } else {
          setError({
            customError: response.data?.createAccount?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to add payment, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{ name: "", type: null }}
        validationSchema={getAccountFormValidations()}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <AccountForm />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
