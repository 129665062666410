import { useMemo, useState } from "react";
import { Divider, message, Select, Skeleton } from "antd";
import clsx from "clsx";
import { gql, useMutation, useQuery } from "urql";

import { CURRENT_USER_FRAGMENT } from "../../context/CurrentUserContext";
import useTablet from "../../hooks/useTablet";
import useUser from "../../hooks/useUser";
import { BRANCH_FRAGMENT } from "../staffs/gql";

const BRANCHES_QUERY: BranchesQueryDoc = gql`
  query Branches {
    branches {
      edges {
        node {
          ...Branch
        }
      }
    }
  }
  ${BRANCH_FRAGMENT}
`;

const SELECT_BRANCH_MUTATION: SelectBranchMutationDoc = gql`
  mutation SelectBranch($branchId: Int!) {
    branchSelector(branchId: $branchId) {
      responseCode
      user {
        ...CurrentUser
        lastAccessedBranch {
          id
          name
          code
        }
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

export const Branch = ({ branch }: { branch: BranchFragment }) => {
  return (
    <div className="flex flex-col space-y-2">
      <div className="text-xl font-medium py-2">
        {branch.name} ({branch.code})
      </div>
      <div className="text-base">
        {branch.address}, {branch.city}
      </div>
      <Divider className="my-1" />
    </div>
  );
};

export default function BranchSelector() {
  const { user, loadingUser } = useUser();
  const { isTablet } = useTablet();
  const [{ data, fetching }] = useQuery({ query: BRANCHES_QUERY, pause: !user });
  const [, mutation] = useMutation(SELECT_BRANCH_MUTATION);
  const [switching, setSwitching] = useState(false);
  const selectedBranchId = user?.selectedBranchId ?? undefined;

  async function onBranchSelection(branchId?: number) {
    if (!branchId || branchId === selectedBranchId) {
      return;
    }

    setSwitching(true);
    await mutation({ branchId })
      .then((response) => {
        if (response.data?.branchSelector?.responseCode === "SUCCESS") {
          const branch = response.data.branchSelector.user?.lastAccessedBranch;
          message.success(`Switched to ${branch?.name} (${branch?.code}) branch.`);
          window.location.reload();
        } else {
          message.error(
            `Branch change operation failed with code: ${response.data?.branchSelector?.responseCode}`
          );
        }
      })
      .catch(() => {
        message.error("Failed to switch branch, unknown error!");
      })
      .finally(() => setSwitching(false));
  }

  const branches = useMemo(() => {
    if (data) {
      return (data.branches?.edges || []).map((branch) => branch?.node as BranchFragment);
    }
    return [];
  }, [data]);

  return (
    <div className="h-full items-center flex w-20 md:w-36 lg:w-72 overflow-hidden">
      {fetching || loadingUser ? (
        <Skeleton.Input size="large" block active className="w-full mt-2" />
      ) : user ? (
        <div className="w-full pt-0.5">
          <Select<number>
            size="large"
            loading={switching}
            disabled={switching}
            className="w-full border border-purple-400 rounded-lg"
            allowClear={false}
            value={selectedBranchId}
            onChange={onBranchSelection}
            filterOption={(input, option) =>
              ((option?.label || "") as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionLabelProp="label"
            popupMatchSelectWidth={!isTablet}
          >
            {branches.map((branch) => (
              <Select.Option
                key={branch.id}
                value={branch.id}
                label={
                  <div className="font-bold text-pink-500 tracking-tight lg:tracking-wide">
                    {(isTablet ? branch.code : `${branch.name} (${branch.code})`).toUpperCase()}
                  </div>
                }
              >
                <div className="flex flex-col py-1 w-72">
                  <div className="font-medium text-base pb-1.5">
                    {branch.name.toUpperCase()} ({branch.code})
                  </div>
                  <div className="whitespace-pre-wrap text-sm leading-relaxed">
                    {branch.address}, {branch.city}
                  </div>
                  <Divider className="mt-2 mb-0.5" />
                </div>
              </Select.Option>
            ))}
          </Select>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
