import { useCallback, useState } from "react";

export type DocumentAction = "add" | "edit" | "delete";

type Selection = {
  document?: NidhiDocumentFragment;
  action?: DocumentAction;
};

export type DocumentActionResult = Selection & {
  openForm: (selection: Selection) => void;
  closeForm: () => void;
  showForm: boolean;
};

export default function useDocumentAction(): DocumentActionResult {
  const [selection, setSelection] = useState<Selection>();
  const [showForm, setShowForm] = useState<boolean>(false);

  const openForm = useCallback((selection: Selection) => {
    setSelection({ ...selection });
    setShowForm(true);
  }, []);

  const closeForm = useCallback(() => {
    setShowForm(false);
    setSelection(undefined);
  }, []);

  return { showForm, openForm, closeForm, ...selection };
}
