import { useCallback, useMemo } from "react";
import { Card, Divider, Skeleton } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { gql, useQuery } from "urql";

import { TIMELINE_CHART_OPTIONS } from "../../constants/charts";
import { SERVER_DATE_FORMAT } from "../../constants/common";
import useLoanMetrics from "../../hooks/api/loans/useLoanMetrics";
import useMobile from "../../hooks/useMobile";
import type { EventPoint } from "../../types/enum";
import { formatCurrency, formatMoneyHumanReadable } from "../../utils/currency";
import { formatNumber, sum, toNumber } from "../../utils/number";
import { Charts } from "../common/Charts";
import DateRangePickerFilter from "../common/DateRangePickerFilter";
import Stats from "../common/Stats";
import LoansSearchModal, { useLoansSearchModal } from "../loans/search/LoansSearchModal";
import TimelineSelector from "./ChartTimelineSelector";

const ACTIVE_LOANS_QUERY: ActiveLoansQueryDoc = gql`
  query ActiveLoans(
    $startDate: Date
    $endDate: Date
    $itemType: GoldLoansLoanContractPledgeItemTypeChoices
  ) {
    loanContracts(
      loanStatus: ACTIVE
      order: CONTRACT_START_DATE_ASC
      first: 5000
      contractStartDate_Gte: $startDate
      contractStartDate_Lte: $endDate
      pledgeItemType: $itemType
    ) {
      edges {
        node {
          contractStartDate
          loanAmount
          pendingInterest
        }
      }
    }
  }
`;

type Loan = { date: Dayjs; amount: number; interest: number };
type Field = keyof Pick<Loan, "amount" | "interest">;
const FIELDS: Field[] = ["amount", "interest"];

export default function OutstandingLoansChart() {
  const loanMetrics = useLoanMetrics({
    filters: { timeline: "year", startDate: undefined, endDate: undefined },
  });
  const { filters, variables } = loanMetrics;
  const { isMobile } = useMobile();
  const [{ data, fetching: loading }] = useQuery({
    query: ACTIVE_LOANS_QUERY,
    variables,
    requestPolicy: "network-only",
  });
  const loans: Loan[] = (data?.loanContracts?.edges || [])
    .map((edge) => edge?.node)
    .map((node) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const loan = node!;
      return {
        date: dayjs(loan.contractStartDate),
        amount: toNumber(loan.loanAmount),
        interest: Math.max(0, toNumber(loan.pendingInterest)),
      };
    });

  const timeline = filters.timeline || "year";

  const {
    openLoansSearch,
    closeLoansSearch,
    showLoansSearch,
    filters: searchFilters,
  } = useLoansSearchModal();

  const { totalLoans, totalOutstanding, totalPendingInterest } = useMemo(() => {
    return {
      totalLoans: loans.length,
      totalOutstanding: sum(...loans.map((loan) => loan.amount)),
      totalPendingInterest: sum(...loans.map((loan) => loan.interest)),
    };
  }, [loans]);

  const getOptions = useCallback(
    ({ series }: { series: Highcharts.SeriesColumnOptions[] }): Highcharts.Options => {
      return {
        ...TIMELINE_CHART_OPTIONS,
        series,

        yAxis: {
          labels: {
            enabled: !isMobile,
            formatter: function () {
              const label = this.axis.defaultLabelFormatter.call(this);

              // Use thousands separator for four-digit numbers too
              if (/^[\d].*$/.test(label)) {
                return formatMoneyHumanReadable(this.value as number);
              }
              return label;
            },
          },
          title: undefined,
        },
      };
    },
    [isMobile]
  );

  const getData = useCallback((): Highcharts.SeriesColumnOptions[] => {
    const getBarLabel = (date: string) => {
      const startDate = dayjs(date);

      switch (timeline) {
        case "month":
          return startDate.format("MMM YY");
        case "week":
          return `${startDate.format("MMM DD")} - ${dayjs(date)
            .endOf(timeline)
            .format("MMM DD")}, ${startDate.format("YY")}`;
        case "quarter":
          return `Q${startDate.format("Q YYYY")}`;
        case "year":
          return startDate.format("YYYY");
        case "day":
          return startDate.format("MMM DD, YYYY");
      }
    };

    function getSeries(field: Field, values: { [K: string]: number }) {
      const data = Object.keys(values)
        .map((date) => ({ date, label: getBarLabel(date), value: values[date] }))
        .filter((value) => value.value)
        .sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf())
        .map((value) => {
          return {
            date: value.date,
            label: value.label,
            value: value.value,
            startDate: dayjs(value.date).startOf(timeline),
            endDate: dayjs(value.date).endOf(timeline),
          };
        });

      const _series: Highcharts.SeriesColumnOptions = {
        name: field === "amount" ? "Outstanding Amount" : "Pending Interest",
        type: "column",
        color: field === "amount" ? "#f9a8d4" : "#4ade80",
        data: data.map((value) => {
          return {
            key: value.label,
            name: value.label,
            label: value.label,
            y: value.value,
            val: formatCurrency(value.value),
            valHuman: formatMoneyHumanReadable(value.value),
            startDateServer: value.startDate.format(SERVER_DATE_FORMAT),
            endDateServer: value.endDate.format(SERVER_DATE_FORMAT),
            startDate: value.startDate.format("MMM DD, YY"),
            endDate: value.endDate.format("MMM DD, YY"),
          };
        }),
        events: {
          click(event) {
            const _event = event.point as unknown as EventPoint;
            openLoansSearch({
              contractStartDateMin: _event.startDateServer,
              contractStartDateMax: _event.endDateServer,
              loanStatus: "ACTIVE",
              order: "CONTRACT_START_DATE_ASC",
              first: 5,
            });
          },
        },
        tooltip: {
          headerFormat: "",
          pointFormat: `<span style="font-size: 13px; font-weight: 700; padding-bottom: 24px;">{series.name} - {point.name}</span>
          <br />
          <span style="font-size: 12px;font-weight: 500;">{point.startDate} - {point.endDate}</span>
          <br />
          <b style="font-size: 13px; font-weight: 700">{point.val}</b><br />`,
        },
        dataLabels: {
          enabled: true,
          format: "{point.valHuman}",
        },
        showInLegend: true,
      };
      return _series;
    }

    return FIELDS.map((field) => {
      const _values = {} as { [K: string]: number };
      loans.forEach((value) => {
        const key = value.date.clone().startOf(timeline).format(SERVER_DATE_FORMAT);
        const _value = value[field];
        _values[key] = _values[key] ? _values[key] + _value : _value;
      });
      return getSeries(field, _values);
    });
  }, [loans, timeline, openLoansSearch]);

  const options = useMemo(() => {
    return getOptions({ series: getData() });
  }, [getData, getOptions]);

  return (
    <>
      <Card
        className="rounded-xl mb-2"
        bodyStyle={isMobile ? { padding: 0, paddingTop: 8 } : {}}
        title={
          <div className="flex flex-col justify-center my-5">
            <div className="text-2xl font-bold text-center mb-0.5 text-gray-600 whitespace-normal">
              Outstanding Amount & Pending Interest
            </div>

            <div className="flex mt-2 sm:mb-2 items-center justify-center sm:justify-between flex-wrap">
              <Stats title="Active Loans" loading={loading} value={formatNumber(totalLoans)} />
              <Stats
                title="Total Outstanding Amount"
                loading={loading}
                value={formatCurrency(totalOutstanding, 0)}
              />
              <Stats
                title="Total Pending Interest"
                loading={loading}
                value={formatCurrency(totalPendingInterest, 0)}
              />
            </div>

            <Divider className="mt-2 mb-1" />

            <div className="flex w-full flex-col-reverse sm:flex-row pt-3 items-center justify-center sm:justify-between flex-wrap">
              <div className="mr-2 mb-2 flex w-full max-w-sm justify-center sm:justify-start">
                <TimelineSelector {...loanMetrics} timelines={["month", "quarter", "year"]} />
              </div>
              <div className="mr-2 mb-2 flex w-full max-w-sm justify-center sm:justify-start">
                <DateRangePickerFilter {...loanMetrics} />
              </div>
            </div>
          </div>
        }
      >
        <Skeleton active paragraph={{ rows: 15 }} title={false} loading={loading}>
          <Charts key={timeline} options={options} />
        </Skeleton>
      </Card>

      <div className="py-10" />
      {showLoansSearch && <LoansSearchModal filters={searchFilters} onClose={closeLoansSearch} />}
    </>
  );
}
