import { Divider, Empty } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { FormikHelpers } from "formik";
import { gql, useMutation } from "urql";
import * as Yup from "yup";

import type { AccountSubtype, AccountType } from "../../../gql/graphql";
import { refetchTransactions } from "../../../hooks/api/accounts/useAccountTransactionsSearch";
import useError from "../../../hooks/useError";
import { trackEvent } from "../../../utils/analytics";
import { getAccountDataForAnalytics } from "../../../utils/analytics/data";
import { formatCurrency } from "../../../utils/currency";
import { getPlaceholderDate, toServerDateNonNull } from "../../../utils/date";
import Form from "../../form/Form";
import FormAccountSearchField from "../../form/FormAccountSearchField";
import { FormButtons } from "../../form/FormButtons";
import FormDateField from "../../form/FormDateField";
import FormMoneyField from "../../form/FormMoneyField";
import FormTextArea from "../../form/FormTextArea";

const TRANSFER_AMOUNT_MUTATION: TransferAmountMutationDoc = gql`
  mutation TransferAmount(
    $fromAccountId: Int!
    $toAccountId: Int!
    $amount: Decimal!
    $date: Date!
    $description: String!
  ) {
    transferAmount(
      fromAccountId: $fromAccountId
      toAccountId: $toAccountId
      amount: $amount
      date: $date
      description: $description
    ) {
      success
      failureMessage
      transactions {
        id
        legs {
          id
          account {
            id
            currentBalance
          }
        }
      }
    }
  }
`;

type FormData = {
  toAccountId: number | null;
  toAccount?: AccountDetailsFragment;

  amount: number | null;
  date: string | Dayjs;
  description: string;
};

type FormProps = {
  account: AccountDetailsFragment;
  onClose: (done?: boolean) => void;
};

const VALID_ACCOUNT_TYPES: AccountType[] = ["ASSET", "INCOME", "LIABILITY"];

const TRANSFER_TO_ACCOUNT_TYPES: {
  [K in AccountType]: { types?: AccountType[]; subtypes?: AccountSubtype[] };
} = {
  ASSET: { types: ["ASSET"], subtypes: ["BANK", "CASH"] },
  INCOME: {},
  EQUITY: {},
  LIABILITY: { types: ["LIABILITY"], subtypes: ["ACCOUNTS_PAYABLE"] },
  EXPENSE: {},
};

export default function TransferAmount({ account, onClose }: FormProps) {
  const [, transfer] = useMutation(TRANSFER_AMOUNT_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    if (!values.amount) {
      formikActions.setSubmitting(false);
      return;
    }

    return transfer({
      fromAccountId: account.id,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      toAccountId: values.toAccountId!,
      amount: values.amount,
      date: toServerDateNonNull(values.date),
      description: values.description,
    })
      .then(async (response) => {
        if (response.data?.transferAmount?.success && response.data.transferAmount.transactions) {
          trackEvent(
            { action: "Transfer Amount" },
            {
              from: getAccountDataForAnalytics(account),
              to: values.toAccount && getAccountDataForAnalytics(values.toAccount),
            }
          );
          refetchTransactions(account.id);
          onClose(true);
        } else {
          setError({
            customError: response.data?.transferAmount?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({
          customError: "Failed to perform the investor account operation, please try again!",
        });
      })
      .finally(() => {
        formikActions.setSubmitting(false);
      });
  }

  const formValidations: {
    [K in keyof FormData]: Yup.AnySchema;
  } = {
    toAccountId: Yup.number().nullable().required("Receiving account is required."),
    amount: Yup.number()
      .nullable()
      .required("Amount is required.")
      .min(1, "Amount should be greater than 1.")
      .max(
        account.currentBalance,
        `Amount can't be greater than the current balance - ${formatCurrency(
          account.currentBalance,
          0
        )}`
      ),
    date: Yup.date().nullable().required("Date of deposit/withdrawal is required."),
    description: Yup.string().nullable().required("Description for the transfer is required."),
  };

  return VALID_ACCOUNT_TYPES.includes(account.type) ? (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{
          toAccountId: null,
          amount: null,
          description: "",
          date: dayjs().startOf("day"),
        }}
        validationSchema={formValidations}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormAccountSearchField
                name="toAccountId"
                accountField="toAccount"
                label="Receiving account"
                column
                helper="Select the account to which the amount is being transfered too!"
                placeholder="Select an account"
                excludeAccountIds={[account.id]}
                accountsSearchProps={{ ...TRANSFER_TO_ACCOUNT_TYPES[account.type] }}
              />

              <FormDateField
                label="Date"
                column
                name="date"
                placeholder={getPlaceholderDate()}
                required
                disabledDate={(date) => date < dayjs().subtract(1, "month") || date > dayjs()}
              />

              <FormMoneyField
                column
                min={1}
                label="Amount"
                name="amount"
                placeholder="1,000"
                required
              />

              <FormTextArea
                column
                required
                label="Description"
                name="description"
                placeholder="Transfer to bank."
              />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  ) : (
    <Empty
      description={`Transfer option isn't supported for this account type: ${account.type.toUpperCase()}`}
    />
  );
}
