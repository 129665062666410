import { Tag } from "antd";
import clsx from "clsx";

import type { GoldLoansLoanContractLoanStatusChoices } from "../../gql/graphql";

export default function LoanStatus({
  status,
  className,
}: {
  status: GoldLoansLoanContractLoanStatusChoices;
  className?: string;
}) {
  return (
    <Tag
      color={status === "CLOSED" ? "purple" : "pink"}
      className={clsx(className, "flex justify-center rounded-lg")}
    >
      {status}
    </Tag>
  );
}
