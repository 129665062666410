import { useState } from "react";
import { EditFilled } from "@ant-design/icons";
import { Modal, Tag, Tooltip } from "antd";
import { gql, useQuery } from "urql";

import { formatDate } from "../../utils/date";
import ActionIconButton from "../common/ActionIconButton";
import CloseIcon from "../common/CloseIcon";
import ResponsiveTable, { ResponsiveColumnType } from "../common/ResponsiveTable";
import EditStaffForm from "./EditStaff";
import { STAFF_FRAGMENT, STAFF_PERMISSIONS_QUERY } from "./gql";
import Group from "./Group";
import StaffPermissions from "./StaffPermissions";

const STAFFS_QUERY: StaffsQueryDoc = gql`
  query Staffs {
    staffs {
      ...Staff
    }
  }
  ${STAFF_FRAGMENT}
`;

export default function Staffs() {
  const [{ data, fetching }] = useQuery({ query: STAFFS_QUERY });
  // Lazy load this data for edit use.
  useQuery({ query: STAFF_PERMISSIONS_QUERY });

  const [selectedStaff, setSelectedStaff] = useState<StaffFragment | undefined>(undefined);

  const columns: ResponsiveColumnType<StaffFragment>[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 125,
      fixed: "left",
      align: "left",
      render: (value, record) => (
        <div className="flex flex-col space-y-1">
          <div className="text-base">{record.name}</div>
          <div className="text-sm font-medium text-gray-500">{record.phone}</div>
        </div>
      ),
      cardable: { position: "top", containerClassName: "w-1/3" },
    },
    {
      title: "Joined On",
      dataIndex: "dateJoined",
      key: "dateJoined",
      width: 125,
      render: (value) => formatDate(value),
      cardable: { position: "top", containerClassName: "w-1/3 text-center" },
    },
    {
      title: "Roles",
      dataIndex: "groups",
      key: "groups",
      width: 200,
      render: (value, record) => {
        return (
          <div className="flex flex-wrap">
            {record.isSuperuser && (
              <div className="mr-1 mb-1">
                <Tag className="rounded-lg px-2" key="superuser" color="purple">
                  SuperUser
                </Tag>
              </div>
            )}
            {record.groups.map((group) => (
              <Group group={group} key={group.id} />
            ))}
          </div>
        );
      },
      cardable: { position: "body" },
    },
    {
      title: "Permissions",
      key: "permissions",
      dataIndex: "userPermissions",
      width: 150,
      render: (value, staff) => <StaffPermissions key={staff.id} staff={staff} />,
    },
    {
      title: "Branches",
      key: "branches",
      dataIndex: "branches",
      width: 150,
      render: (value, staff) => {
        return staff.isSuperuser ? (
          "ALL"
        ) : (
          <div className="flex flex-wrap">
            {staff.branches.map((branch) => (
              <Tooltip
                key={branch.id}
                className="rounded-lg"
                title={
                  <div className="flex flex-col space-y-2">
                    {branch.name}
                    <div className="flex">
                      {branch.address} {branch.city}
                    </div>
                  </div>
                }
              >
                <div className="mr-1 mb-1" key={branch.id}>
                  <Tag className="cursor-pointer rounded-lg px-2" color="magenta" key={branch.id}>
                    {branch.name}
                  </Tag>
                </div>
              </Tooltip>
            ))}
          </div>
        );
      },
      cardable: { position: "body" },
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "id",
      width: 75,
      render: (value, staff) => (
        <ActionIconButton
          icon={<EditFilled />}
          disabled={staff.isSuperuser}
          perm="IS_SUPERUSER"
          onClick={() => setSelectedStaff(staff)}
        />
      ),
      cardable: { position: "top", containerClassName: "w-1/3 text-right" },
    },
  ];

  return (
    <>
      <ResponsiveTable<StaffFragment>
        printSettings={false}
        bordered
        rowKey="id"
        scroll={{ x: 650 }}
        title={() => <div className="text-center text-2xl font-bold">Nidhi Staffs</div>}
        loading={fetching}
        dataSource={data?.staffs || []}
        columns={columns}
      />

      {!!selectedStaff && (
        <Modal
          title={
            <>
              <h4 className="my-2 font-bold text-2xl text-center">
                EDIT {selectedStaff.name}&apos;s PERMISSIONS
              </h4>
            </>
          }
          centered
          closable
          maskClosable
          open
          closeIcon={<CloseIcon />}
          footer={false}
          onCancel={() => setSelectedStaff(undefined)}
        >
          <EditStaffForm staff={selectedStaff} onSuccess={() => setSelectedStaff(undefined)} />
        </Modal>
      )}
    </>
  );
}
