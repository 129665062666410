import type { Document } from "../../hooks/api/documents/useUploadDocument";
import PhotoInput from "../common/webcam/PhotoInput";

export default function EditDocument({
  document,
  onSuccess,
}: {
  document: NidhiDocumentFragment;
  onSuccess: (document: Document) => void;
}) {
  return <PhotoInput document={document} {...document} onSelect={onSuccess} />;
}
