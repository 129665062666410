import React from "react";
import { getIn } from "formik";

import DateRangePicker, { DateRangePickerProps } from "../common/DateRangePicker";
import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

type Props = DateRangePickerProps & {
  endDateName: string;
};

const FormDateRangeField = (props: Props & WithFormFieldWrapperProps) => {
  const { name, endDateName, formik, ...rest } = props;

  return (
    <DateRangePicker
      {...rest}
      showLabel
      placeholder="DD/MM/YYYY"
      startDate={getIn(formik.values, name)}
      endDate={getIn(formik.values, endDateName)}
      handleChange={(startDate, endDate) => {
        formik.setFieldValue(name, startDate, true);
        formik.setFieldValue(endDateName, endDate, true);
      }}
    />
  );
};

export default withFormFieldWrapper<Props>(FormDateRangeField);
