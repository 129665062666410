/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useHistory } from "react-router";
import { gql } from "@urql/core";
import { Button, Divider, message, Skeleton } from "antd";
import dayjs from "dayjs";
import { FormikHelpers } from "formik";
import { useMutation } from "urql";
import * as Yup from "yup";

import useCustomer from "../../../hooks/api/customers/useCustomer";
import { DOCUMENT_CONNECTION_FRAGMENT } from "../../../hooks/api/documents/useDocument";
import useError from "../../../hooks/useError";
import useUser from "../../../hooks/useUser";
import { trackEvent } from "../../../utils/analytics";
import { getLoanDataForAnalytics } from "../../../utils/analytics/data";
import { toServerDateNonNull } from "../../../utils/date";
import { sum } from "../../../utils/number";
import Centered from "../../common/Centered";
import Form from "../../form/Form";
import FormCustomerSearchField, { CustomerSearchResult } from "../../form/FormCustomerSearchField";
import { LOAN_CONTRACT_FRAGMENT } from "../fragments";
import { LOAN_FORM_VALIDATIONS, LoanForm, LoanFormData } from "./LoanForm";

const ADD_LOAN_MUTATION: AddLoanMutationDoc = gql`
  mutation AddLoan(
    $customerId: Int!
    $pledgeSheetNumber: Int
    $autoGeneratePSN: Boolean
    $contractStartDate: String!
    $itemType: ItemType!
    $itemName: String!
    $itemWeight: Decimal!
    $estimatedValue: Decimal!
    $interestRate: Decimal!
    $loanAmount: Decimal!
    $firstMonthInterest: Decimal!
    $processingFees: Decimal!
    $notes: String
    $itemPhotoDocId: Int
  ) {
    addLoan(
      customerId: $customerId
      pledgeSheetNumber: $pledgeSheetNumber
      autoGeneratePsn: $autoGeneratePSN
      contractStartDate: $contractStartDate
      itemType: $itemType
      itemName: $itemName
      itemWeight: $itemWeight
      estimatedValue: $estimatedValue
      interestRate: $interestRate
      loanAmount: $loanAmount
      firstMonthInterest: $firstMonthInterest
      processingFees: $processingFees
      loanNotes: $notes
      itemPhotoDocId: $itemPhotoDocId
    ) {
      success
      failureMessage
      loan {
        ...Loan
        documents {
          ...DocumentConnection
        }
      }
    }
  }
  ${LOAN_CONTRACT_FRAGMENT}
  ${DOCUMENT_CONNECTION_FRAGMENT}
`;

type FormData = LoanFormData & {
  customerId: number | null;
  customer?: CustomerSearchResult | null;
};

type Props = {
  customer?: CustomerDetailsFragment;
};

function AddLoanForm({ customer }: Props) {
  const history = useHistory();
  const { user } = useUser();
  const [, addLoan] = useMutation(ADD_LOAN_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return addLoan({
      customerId: values.customerId!,
      pledgeSheetNumber: values.pledgeSheetNumber,
      autoGeneratePSN: !!values.autoGeneratePSN,
      contractStartDate: values.contractStartDate,
      itemType: values.itemType!,
      itemName: values.itemName,
      itemWeight: values.itemWeight!,
      estimatedValue: values.estimatedValue!,
      interestRate: values.interestRate! / 100,
      loanAmount: values.loanAmount!,
      firstMonthInterest: sum(values.firstMonthInterest),
      processingFees: values.processingFees ?? 0,
      notes: values.notes,
      itemPhotoDocId: values.itemPhotoDocId,
    })
      .then((response) => {
        if (response.data?.addLoan?.success && response.data?.addLoan?.loan) {
          const loan = response.data.addLoan.loan;
          message.success(`Successfully recorded the loan with PSN # ${loan.pledgeSheetNumber}`);
          trackEvent({ action: "Add Loan" }, getLoanDataForAnalytics(loan));
          history.push(`/loans/${loan.id}`);
        } else {
          setError({
            gqlError: response.error,
            customError: response.data?.addLoan?.failureMessage,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to add loan, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-md pb-12 md:pb-24">
      <h4 className="mt-2 mb-5 font-bold text-2xl text-center">ADD LOAN</h4>

      <Form<FormData>
        initialValues={{
          customerId: customer ? customer.id : null,
          customer: customer
            ? {
                id: `${customer.id}`,
                objectId: customer.id,
                name: customer.user.name || "",
                phone: customer.user.phone,
                address: customer.address,
                careOf: `${customer.careOfName} ${customer.careOfType}`,
                isRepledgeCustomer: customer.isRepledgeCustomer,
              }
            : null,
          pledgeSheetNumber: undefined,
          autoGeneratePSN: user?.selectedBranchCode === "KAR",
          contractStartDate: toServerDateNonNull(dayjs()),
          itemType: null,
          itemWeight: null,
          itemName: "",
          estimatedValue: null,
          interestRate: null,
          loanAmount: null,
          firstMonthInterest: null,
          processingFees: null,
        }}
        validationSchema={{
          customerId: Yup.number().nullable().required("Please select a customer."),
          ...LOAN_FORM_VALIDATIONS,
        }}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting, values }) => {
          return (
            <>
              <FormCustomerSearchField
                label="Customer"
                name="customerId"
                customerField="customer"
                required
                placeholder="Type customer name or phone number."
              />

              <LoanForm
                customerId={values.customer?.objectId}
                customerName={values.customer?.name}
                repledgeCustomer={values.customer?.isRepledgeCustomer}
                values={values}
              />

              <Divider />

              <Button
                shape="round"
                size="large"
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </>
          );
        }}
      </Form>
    </div>
  );
}

export default function AddLoan() {
  const { customer, fetching } = useCustomer();

  return fetching ? (
    <Centered>
      <Skeleton paragraph={{ rows: 10 }} className="p-2 w-full max-w-screen-md" />
    </Centered>
  ) : (
    <div className="w-full justify-center flex py-4 sm:pb-12 pb-24">
      <AddLoanForm customer={customer} />
    </div>
  );
}
