import { Divider } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { FormikHelpers } from "formik";
import { gql, useMutation } from "urql";
import * as Yup from "yup";

import { refetchTransactions } from "../../../hooks/api/accounts/useAccountTransactionsSearch";
import useError from "../../../hooks/useError";
import { trackEvent } from "../../../utils/analytics";
import { getAccountDataForAnalytics } from "../../../utils/analytics/data";
import { getPlaceholderDate, toServerDateNonNull } from "../../../utils/date";
import Form from "../../form/Form";
import { FormButtons } from "../../form/FormButtons";
import FormDateField from "../../form/FormDateField";
import FormMoneyField from "../../form/FormMoneyField";
import FormTextArea from "../../form/FormTextArea";

const RECORD_INCOME_MUTATION: RecordIncomeMutationDoc = gql`
  mutation RecordIncome($amount: Decimal!, $date: Date!, $description: String!) {
    recordIncome(amount: $amount, date: $date, description: $description) {
      success
      failureMessage
      transactions {
        id
        legs {
          id
          account {
            id
            currentBalance
          }
        }
      }
    }
  }
`;

type FormData = {
  amount: number | null;
  date: string | Dayjs;
  description: string;
};

type FormProps = {
  account: AccountDetailsFragment;
  onClose: (done?: boolean) => void;
};

const FORM_VALIDATIONS: {
  [K in keyof FormData]: Yup.AnySchema;
} = {
  amount: Yup.number()
    .nullable()
    .required("Amount is required.")
    .test("non-zero", "Income amount can't be ZERO!", (value) => {
      return value !== 0;
    }),
  description: Yup.string().nullable().required("Description is required."),
  date: Yup.date().nullable().required("Date is required"),
};

export default function RecordIncome({ account, onClose }: FormProps) {
  const [, record] = useMutation(RECORD_INCOME_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    if (!values.amount) {
      formikActions.setSubmitting(false);
      return;
    }

    return record({
      amount: values.amount,
      date: toServerDateNonNull(values.date),
      description: values.description,
    })
      .then(async (response) => {
        if (response.data?.recordIncome?.success && response.data.recordIncome.transactions) {
          trackEvent({ action: "Record Income" }, getAccountDataForAnalytics(account));
          refetchTransactions(account.id);
          onClose(true);
        } else {
          setError({
            gqlError: response.error,
            customError: response.data?.recordIncome?.failureMessage,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to record income, please try again!" });
      })
      .finally(() => {
        formikActions.setSubmitting(false);
      });
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{ amount: null, description: "", date: dayjs().startOf("day") }}
        validationSchema={FORM_VALIDATIONS}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormDateField
                label="Record Date"
                column
                name="date"
                placeholder={getPlaceholderDate()}
                required
                disabledDate={(date) => date < dayjs().subtract(1, "month") || date > dayjs()}
              />

              <FormMoneyField
                column
                label="Amount"
                name="amount"
                placeholder="1,000"
                helper="Enter the income amount, for reversal/corrections enter the amount in negative like -500."
                required
              />

              <FormTextArea
                column
                required
                label="Description"
                name="description"
                placeholder="Description about the income."
              />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
