import React from "react";

import Loading from "../components/common/Loading";
import LoginModal from "../components/login/LoginModal";
import useUser from "./useUser";

export function WithLogin({ children }: { children: React.ReactElement }) {
  const { user, loadingUser } = useUser();
  return (
    <>
      <Loading loading={loadingUser}>{user ? children : <></>}</Loading>
      {!!(!loadingUser && !user) && <LoginModal />}
    </>
  );
}

export default function withLogin(Component: React.ComponentType): React.ComponentType {
  // eslint-disable-next-line react/display-name
  return ({ ...props }) => (
    <WithLogin>
      <Component {...props} />
    </WithLogin>
  );
}
