import { Divider } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { FormikHelpers } from "formik";
import { gql, useMutation } from "urql";
import * as Yup from "yup";

import type { InvestorOperation } from "../../../gql/graphql";
import { refetchTransactions } from "../../../hooks/api/accounts/useAccountTransactionsSearch";
import useError from "../../../hooks/useError";
import { trackEvent } from "../../../utils/analytics";
import { getAccountDataForAnalytics } from "../../../utils/analytics/data";
import { toTitleCase } from "../../../utils/common";
import { formatCurrency } from "../../../utils/currency";
import { getPlaceholderDate, toServerDateNonNull } from "../../../utils/date";
import Form from "../../form/Form";
import FormAccountSearchField from "../../form/FormAccountSearchField";
import { FormButtons } from "../../form/FormButtons";
import FormDateField from "../../form/FormDateField";
import FormMoneyField from "../../form/FormMoneyField";
import FormTextArea from "../../form/FormTextArea";

const INVESTOR_OPERATION_MUTATION: InvestorOperationMutationDoc = gql`
  mutation InvestorOperation(
    $operation: InvestorOperation!
    $investorAccountId: Int!
    $assetAccountId: Int
    $amount: Decimal!
    $date: Date!
    $description: String!
  ) {
    investorOperations(
      operation: $operation
      investorAccountId: $investorAccountId
      assetAccountId: $assetAccountId
      amount: $amount
      date: $date
      description: $description
    ) {
      success
      failureMessage
      transactions {
        id
        legs {
          id
          account {
            id
            currentBalance
          }
        }
      }
    }
  }
`;

type FormData = {
  amount: number | null;
  date: string | Dayjs;
  description: string;

  bankAccountId?: number;
  bankAccount?: AccountDetailsFragment;
};

type FormProps = {
  account: AccountDetailsFragment;
  operation: InvestorOperation;
  onClose: (done?: boolean) => void;
};

export default function InvestorOperations({ operation, account, onClose }: FormProps) {
  const [, operate] = useMutation(INVESTOR_OPERATION_MUTATION);

  const { error, setError, resetError } = useError();
  const investorAccount = !account.subtype;

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    if (!values.amount) {
      formikActions.setSubmitting(false);
      return;
    }

    return operate({
      operation,
      investorAccountId: account.id,
      assetAccountId: values.bankAccountId,
      amount: values.amount,
      date: toServerDateNonNull(values.date),
      description: values.description,
    })
      .then(async (response) => {
        if (
          response.data?.investorOperations?.success &&
          response.data.investorOperations.transactions
        ) {
          trackEvent({ action: "Investor Operation" }, getAccountDataForAnalytics(account));
          refetchTransactions(account.id);
          onClose(true);
        } else {
          setError({
            gqlError: response.error,
            customError: response.data?.investorOperations?.failureMessage,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({
          customError: "Failed to perform the investor account operation, please try again!",
        });
      })
      .finally(() => {
        formikActions.setSubmitting(false);
      });
  }

  const amountValidation = Yup.number()
    .nullable()
    .required("Amount is required.")
    .min(1, "Amount should be greater than 1.");

  const descriptionValidation = Yup.string().nullable();

  const formValidations: {
    [K in keyof FormData]: Yup.AnySchema;
  } = {
    amount:
      operation === "WITHDRAW" && investorAccount
        ? amountValidation.max(
            account.currentBalance,
            `Amount can't be greater than the current balance - ${formatCurrency(
              account.currentBalance,
              0
            )}`
          )
        : amountValidation,
    date: Yup.date().nullable().required("Date of deposit/withdrawal is required"),
    description: investorAccount
      ? descriptionValidation.optional()
      : descriptionValidation.required("Provide a valid description."),
  };
  const minDate = dayjs.min(dayjs().startOf("quarter"), dayjs().subtract(1, "months"));
  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{
          amount: null,
          description: "",
          date: dayjs().startOf("day"),
        }}
        validationSchema={formValidations}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormDateField
                label="Date"
                column
                name="date"
                placeholder={getPlaceholderDate()}
                required
                disabledDate={(date) => (minDate && date < minDate) || date > dayjs()}
              />

              <FormMoneyField
                column
                label="Amount"
                name="amount"
                placeholder={`${toTitleCase(operation)} amount`}
                required
              />

              <FormTextArea
                column
                required={!investorAccount}
                label="Description"
                name="description"
                placeholder="Description about the transaction."
              />

              <FormAccountSearchField
                name="bankAccountId"
                column
                helper={`Choose the bank account if the amount is ${
                  operation === "DEPOSIT" ? "deposited" : "withdrawn"
                } ${operation === "DEPOSIT" ? "to" : "from"} a bank account.`}
                label="Bank Account"
                placeholder="Select a bank account"
                accountField="bankAccount"
                accountsSearchProps={{
                  types: ["ASSET", "LIABILITY"],
                  subtypes: ["BANK", "ACCOUNTS_PAYABLE"],
                }}
              />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
