import React, { useLayoutEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  AuditOutlined,
  BankOutlined,
  BarChartOutlined,
  DashboardOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { BackTop, Button, Divider, Layout, Menu } from "antd";
import clsx from "clsx";

import { NIDHI_LAYOUT_CONTAINER } from "../../constants/common";
import usePermissions from "../../hooks/api/usePermissions";
import useTablet from "../../hooks/useTablet";
import useUser from "../../hooks/useUser";
import Logo from "../../icons/Logo";
import type { PermissionType } from "../../types/enum";
import { sanitizePath } from "../../utils/common";
import { scrollToTop } from "../../utils/scroll";
import Actions from "./Actions";
import BranchSelector from "./BranchSelector";
import SearchBar from "./SearchBar";

const BACKGROUND_COLOR = "bg-green-100";

export default function NidhiLayout({ children }: { children: React.ReactNode }) {
  const { user, loadingUser } = useUser();
  const history = useHistory();
  const location = useLocation();
  const { hasPerm } = usePermissions();
  const { isTablet } = useTablet();
  const containerRef = useRef<HTMLDivElement>(null);

  const [siderCollapsed, setSiderCollapsed] = useState<boolean>(!!isTablet);

  const rootPath = `/${location.pathname.split("/")[1]}`;
  useLayoutEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  function getMenuItem(label: string, path: string, permission?: PermissionType) {
    const sanitizedPath = sanitizePath(path);
    return !permission || hasPerm(permission) ? (
      <Menu.Item key={sanitizedPath}>
        <Link href={sanitizedPath} to={sanitizedPath}>
          {label}
        </Link>
      </Menu.Item>
    ) : (
      <></>
    );
  }

  const menu = (
    <Menu
      mode={isTablet ? "horizontal" : "inline"}
      selectedKeys={[sanitizePath(location.pathname), rootPath]}
      defaultOpenKeys={isTablet ? undefined : [rootPath]}
      onClick={() => {
        if (isTablet) {
          setSiderCollapsed(true);
        }
      }}
    >
      {hasPerm("VIEW_DASHBOARD") && (
        <Menu.Item icon={<DashboardOutlined />} key="/dashboard">
          <Link href="/dashboard" to="/dashboard">
            Dashboard
          </Link>
        </Menu.Item>
      )}

      {hasPerm("VIEW_CUSTOMER") && (
        <Menu.SubMenu icon={<UserOutlined />} key="/customers" title="Customers">
          {hasPerm("EDIT_CUSTOMER") && getMenuItem("Add Customer", "/customers/add")}
          {getMenuItem("Search", "/customers/search")}
        </Menu.SubMenu>
      )}

      {hasPerm("VIEW_LOAN") && (
        <Menu.SubMenu icon={<AuditOutlined />} key="/loans" title="Loans">
          {hasPerm("EDIT_LOAN") && getMenuItem("Add Loan", "/loans/add")}
          {getMenuItem("Search", "/loans/search")}
          {getMenuItem("Active", "/loans/active")}
          {getMenuItem("Closed", "/loans/closed")}
        </Menu.SubMenu>
      )}

      {hasPerm("VIEW_ACCOUNTS_PNL") && (
        <Menu.SubMenu icon={<BarChartOutlined />} key="/charts" title="Charts">
          {getMenuItem("Revenue", "/charts/profits")}
          {getMenuItem("New/Closed Loans", "/charts/loans")}
          {getMenuItem("Portfolio Timeline", "/charts/portfolio")}
          {getMenuItem("Outstanding Loans", "/charts/outstanding-loans")}
        </Menu.SubMenu>
      )}

      {hasPerm("VIEW_ACCOUNT") && (
        <Menu.SubMenu icon={<BankOutlined />} key="/accounts" title="Accounts">
          {getMenuItem("Dashboard", "/accounts/")}
          {getMenuItem("Add Expense", "/accounts/add-expense", "RECORD_EXPENSE")}
          {getMenuItem("Loans In/Out", "/accounts/loan-in-out")}
          {getMenuItem("Day Book", "/accounts/day-book")}
          {getMenuItem("Balance Sheet", "/accounts/balance-sheet")}
          {getMenuItem("Investor Interests", "/accounts/investors", "HANDLE_INVESTORS")}
        </Menu.SubMenu>
      )}
    </Menu>
  );

  const logo = (
    <div
      className={clsx(BACKGROUND_COLOR, "flex items-center justify-center h-14 cursor-pointer")}
      onClick={() => history.push("/")}
    >
      <Logo className={clsx(siderCollapsed || isTablet ? "text-5xl" : "text-7xl")} />
    </div>
  );

  return (
    <div className={clsx("flex w-full justify-center mx-auto")}>
      <Layout className={clsx("max-w-screen-2xl w-full h-screen mx-auto")}>
        {!isTablet && (
          <Layout.Sider
            style={{ background: "white" }}
            collapsible
            collapsed={siderCollapsed}
            onCollapse={(collapsed) => setSiderCollapsed(collapsed)}
            width={200}
            className={BACKGROUND_COLOR}
            breakpoint="lg"
            trigger={null}
          >
            <div className="h-full border-r">
              {logo}
              <Divider className="m-0" />
              {menu}
            </div>
          </Layout.Sider>
        )}

        <Layout id={NIDHI_LAYOUT_CONTAINER}>
          <Layout.Header className={clsx(BACKGROUND_COLOR, "p-0", isTablet ? "h-28" : "h-14")}>
            <div className="w-full h-full justify-center items-center flex flex-col">
              <div className="w-full flex justify-between items-center space-x-2 px-3 sm:px-4 h-14">
                {isTablet ? (
                  <div className="pr-1">{logo}</div>
                ) : (
                  <div
                    className="border border-green-500 bg-white rounded-md p-1.5 justify-center cursor-pointer hover:bg-green-200"
                    onClick={() => setSiderCollapsed(!siderCollapsed)}
                  >
                    <div className="flex items-end h-full text-lg text-green-800">
                      {siderCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </div>
                  </div>
                )}

                {!!(hasPerm("VIEW_CUSTOMER") || hasPerm("VIEW_LOAN") || loadingUser) && (
                  <SearchBar loading={loadingUser} />
                )}

                <div className="flex items-center space-x-3">
                  <BranchSelector />

                  {user ? (
                    <div className="mt-0">
                      <Actions user={user} />
                    </div>
                  ) : (
                    <Button shape="round" size="large" href="/accounts/login" type="primary">
                      Log In
                    </Button>
                  )}
                </div>
              </div>
              {isTablet && <div className="w-full h-16">{menu}</div>}
            </div>
          </Layout.Header>

          <Divider className="m-0" />

          <Layout.Content
            id={NIDHI_LAYOUT_CONTAINER}
            className="bg-white w-full h-full overflow-scroll max-h-full p-2"
            ref={containerRef}
          >
            {children}
            <BackTop target={() => containerRef.current ?? window} visibilityHeight={1000} />
          </Layout.Content>
        </Layout>
      </Layout>
    </div>
  );
}
