import React from "react";
import { Skeleton } from "antd";

import useLoansSearch, { LoansSearchProps } from "../../../hooks/api/loans/useLoansSearch";
import LoanFilters from "./LoanFilters";
import LoansList from "./LoansList";

export function Loans(props: LoansSearchProps) {
  const loansSearch = useLoansSearch(props);
  const { total, loading } = loansSearch;

  return (
    <div className="w-full h-full">
      <div className="text-2xl font-bold py-2 text-center capitalize">
        LOANS SEARCH{" "}
        <span className="text-base px-2 text-gray-600">
          Showing {loading ? <Skeleton.Button shape="round" active size="small" /> : total} Loans
        </span>
      </div>

      <LoanFilters loansSearch={loansSearch} />

      <div className="py-0.5">
        <LoansList loansSearch={loansSearch} />
      </div>
    </div>
  );
}

export const LoansSearch = () => <Loans />;

export const ActiveLoans = () => (
  <Loans filters={{ loanStatus: "ACTIVE", order: ["CONTRACT_START_DATE_DESC", "ID_DESC"] }} />
);

export const ClosedLoans = () => (
  <Loans filters={{ loanStatus: "CLOSED", order: "CLOSING_DATE_DESC" }} />
);
