import { useMemo } from "react";
import { TableProps } from "antd";
import { gql, useQuery } from "urql";

import { arraySorter, toTitleCase, toUnderscoreCase } from "../../../utils/common";
import { formatCurrency } from "../../../utils/currency";
import { formatDate } from "../../../utils/date";
import { sum } from "../../../utils/number";
import AnchorLink from "../../common/AnchorLink";
import ResponsiveTable, { ResponsiveColumnType } from "../../common/ResponsiveTable";

type Summary = {
  disbursedLoans: number;
  closedLoans: number;
  investorInterest: number;
  commissions: number;
};

const DAILY_LOAN_FRAGMENT: DailyLoanFragmentDoc = gql`
  fragment DailyLoan on LoanContract {
    id
    pledgeSheetNumber

    contractStartDate
    loanAmount
    initialInterestAmount
    initialCommissionsAmount

    closingDate
    closingInterest
    commission

    hasTopUps
    topUps(eventDates: $eventDates) {
      edges {
        node {
          id
          initial
          contractStartDate: topUpDate
          loanAmount
          initialInterestAmount
          initialCommissionsAmount
        }
      }
    }
  }
`;

const DAILY_LOANS_QUERY: DailyLoansQueryDoc = gql`
  query DailyLoans($eventDates: String!, $limit: Int!) {
    loanContracts(order: PLEDGE_SHEET_NUMBER_DESC, eventDates: $eventDates, first: $limit) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          ...DailyLoan
        }
      }
    }
  }
  ${DAILY_LOAN_FRAGMENT}
`;

type Action = "Open" | "Close" | "TopUp";
type DailyLoan = Omit<DailyLoanFragment, "hasTopUps" | "topUps"> & {
  action: Action;
};
const ACTIONS: Action[] = ["Open", "TopUp", "Close"];
const ACTIONS_ORDER: Action[] = ["Open", "TopUp", "Close"];

const DailyLoansTable = ({
  size,
  date,
}: {
  date: string;
} & Pick<TableProps<DailyLoan>, "size">) => {
  const variables = useMemo(() => {
    // 10K is the max number of loans we can fetch in one-go.
    return { eventDates: `${date},${date}`, limit: 10000 };
  }, [date]);

  const [{ data, fetching }] = useQuery({ query: DAILY_LOANS_QUERY, variables });

  const { loans, hasNextPage } = useMemo(() => {
    if (!data || !data.loanContracts?.edges) {
      return { loans: [], hasNextPage: false };
    }

    const loans = data.loanContracts.edges
      .map((edge) => edge?.node)
      .reduce((loans, loan) => {
        if (!loan) {
          return loans;
        }

        if (loan.topUps?.edges && loan.topUps.edges.length > 0) {
          const _loans = loan.topUps.edges
            .map((edge) => edge?.node)
            .reduce((loans, node) => {
              if (!node) {
                return loans;
              }

              loans.push({
                action: node.initial ? "Open" : "TopUp",
                ...node,
                id: loan.id,
                pledgeSheetNumber: loan.pledgeSheetNumber,
              });

              return loans;
            }, [] as DailyLoan[]);

          // Add the loan itself to account for the closure.
          if (loan.closingDate === date) {
            _loans.push({ ...loan, action: "Close" });
          }

          return loans.concat(_loans);
        } else {
          const _loans: DailyLoan[] = [];
          if (loan.contractStartDate === date) {
            _loans.push({ ...loan, action: "Open" });
          }

          if (loan.closingDate === date) {
            _loans.push({ ...loan, action: "Close" });
          }

          return loans.concat(_loans);
        }
      }, [] as DailyLoan[])
      .sort((a, b) => arraySorter(a, b, "action", ACTIONS_ORDER));

    return { loans, hasNextPage: !!data?.loanContracts?.pageInfo.hasNextPage };
  }, [data, date]);

  const columns: ResponsiveColumnType<DailyLoan>[] = [
    {
      title: "PSN",
      key: "psn",
      dataIndex: "pledgeSheetNumber",
      render: (value, record) => (
        <AnchorLink externalHref={`/loans/${record.id}`}>{value}</AnchorLink>
      ),
      sorter: (a, b) => a.pledgeSheetNumber - b.pledgeSheetNumber,
      cardable: { position: "top" },
    },
    {
      key: "loanAmount",
      title: "Disbursed Amount",
      dataIndex: "loanAmount",
      render: (value, record) => (record.action !== "Close" ? formatCurrency(value) : "--"),
      cardable: { position: "body" },
    },
    {
      title: "Returned Amount",
      key: "returnedLoanAmount",
      dataIndex: "loanAmount",
      render: (value, record) => (record.action === "Close" ? formatCurrency(value) : "--"),
      cardable: { position: "body" },
    },
    {
      key: "initialInterestAmount",
      title: "Investor Interest",
      dataIndex: "initialInterestAmount",
      render: (value, record) =>
        formatCurrency(
          record.action === "Close" ? record.closingInterest : record.initialInterestAmount
        ),
      cardable: { position: "body" },
    },
    {
      key: "commission",
      title: "Commissions",
      dataIndex: "commission",
      render: (value, record) =>
        formatCurrency(
          record.action === "Close" ? record.commission : record.initialCommissionsAmount
        ),
      cardable: { position: "body" },
    },
    {
      title: "Operation",
      dataIndex: "action",
      key: "action",
      filters: ACTIONS.map((action) => ({ text: action, value: action })),
      onFilter: (value, record) => record.action === value,
      sorter: (a, b) => a.action.localeCompare(b.action),
      cardable: { position: "top", valueClassName: "text-sm font-medium" },
    },
  ];

  return (
    <ResponsiveTable<DailyLoan>
      size={size}
      rowKey={(record, index) => `${record.action}:${record.id}:${index}`}
      columns={columns}
      bordered
      dataSource={loans}
      loading={fetching}
      pagination={
        hasNextPage
          ? {
              size: "small",
              responsive: true,
              position: ["bottomCenter"],
              showSizeChanger: true,
              className: "pt-3",
            }
          : false
      }
      footer={(pageData) => {
        const summary: Summary = {
          disbursedLoans: 0,
          closedLoans: 0,
          investorInterest: 0,
          commissions: 0,
        };
        pageData.reduce((hash, loan) => {
          const open = loan.action !== "Close";
          if (open) {
            hash.disbursedLoans = sum(hash.disbursedLoans, loan.loanAmount);
            hash.investorInterest = sum(hash.investorInterest, loan.initialInterestAmount);
            hash.commissions = sum(hash.commissions, loan.initialCommissionsAmount);
          } else {
            hash.closedLoans = sum(hash.closedLoans, loan.loanAmount);
            hash.investorInterest = sum(hash.investorInterest, loan.closingInterest);
            hash.commissions = sum(hash.commissions, loan.commission);
          }
          return hash;
        }, summary);

        return (
          <div className="flex justify-evenly flex-grow">
            {Object.keys(summary).map((key, index) => (
              <div className="flex flex-col" key={key}>
                <div className="text-gray-500 text-sm font-medium">
                  {toTitleCase(toUnderscoreCase(key).replace("_", " "))}
                </div>
                <div className="text-pink-600 font-semibold">
                  {formatCurrency(summary[key as keyof Summary])}
                </div>
              </div>
            ))}
          </div>
        );
      }}
      printSettings={{
        title: `DAILY LOANS IN/OUT FOR ${formatDate(date)}`,
      }}
    />
  );
};

export default DailyLoansTable;
