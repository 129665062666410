import { useCallback } from "react";
import {
  DefaultNamespace,
  KeyPrefix,
  Namespace,
  useTranslation as usei18nextTranslation,
  UseTranslationOptions,
  UseTranslationResponse,
} from "react-i18next";

export default function useTranslation<
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined
>(
  ns?: N | Readonly<N>,
  options?: UseTranslationOptions<TKPrefix>
): UseTranslationResponse<N, TKPrefix> {
  const translation = usei18nextTranslation(ns, options);
  const { t: trans, ...rest } = translation;

  const t = useCallback(
    (key: string) => {
      return trans(key.trim().toLowerCase() as never);
    },
    [trans]
  );

  return { t, ...rest };
}

export type TranslatorType = UseTranslationResponse<
  Namespace,
  KeyPrefix<Namespace> | undefined
>["t"];
