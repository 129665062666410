export function getLoanDataForAnalytics(loan: LoanFragment) {
  return { psn: loan.pledgeSheetNumber, amount: loan.loanAmount, status: loan.loanStatus };
}

export function getPaymentDataForAnalytics(payment: PaymentFragment) {
  return { date: payment.paymentDate, amount: payment.paymentAmount };
}

export function getAccountDataForAnalytics(account: AccountDetailsFragment) {
  return { ...account };
}

export function getLoanTopUpDataForAnalytics(loanTopUp: LoanTopUpFragment) {
  return { date: loanTopUp.topUpDate, amount: loanTopUp.loanAmount };
}

export function getCustomerDataForAnalytics(customer: CustomerFragment) {
  return {};
}
