import { useCallback, useMemo } from "react";
import { TablePaginationConfig } from "antd";

export type PageVariables = { offset: number; first: number };

type Props<T extends PageVariables> = {
  total?: number;
  variables: T;
  updateVariables: (variables: T) => void;
};

export default function usePagination<T extends PageVariables>({
  variables,
  updateVariables,
  total,
}: Props<T>) {
  const page = useMemo(() => {
    return {
      number: variables.offset / variables.first,
      size: variables.first,
      total: total ? Math.ceil(total / variables.first) : 0,
    };
  }, [variables, total]);

  const onPageChange = useCallback(
    (_page: number, pageSize?: number) => {
      const number = _page - 1;
      const newPage = { ...page };
      let changed = false;
      if (page.number !== number) {
        newPage.number = number;
        changed = true;
      }
      if (pageSize && page.size !== pageSize) {
        newPage.size = pageSize;
        changed = true;
      }

      if (changed) {
        updateVariables({
          ...variables,
          offset: newPage.number * newPage.size,
          first: newPage.size,
        });
      }
    },
    [page, updateVariables, variables]
  );

  const pagination: TablePaginationConfig = useMemo(() => {
    return {
      responsive: true,
      hideOnSinglePage: false,
      position: ["bottomCenter"],
      current: page.number + 1,
      pageSize: page.size,
      showSizeChanger: true,
      total,
      onChange: onPageChange,
    };
  }, [onPageChange, page.number, page.size, total]);

  return { page, onPageChange, pagination };
}
