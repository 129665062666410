import { useCallback, useState } from "react";

export type PaymentAction = "add" | "edit" | "delete";

type Selection = {
  payment?: PaymentFragment;
  action?: PaymentAction;
};

export type PaymentActionResult = Selection & {
  openPaymentForm: (selection: Selection) => void;
  closePaymentForm: () => void;

  showForm: boolean;
  loan: CustomerLoanFragment;
};

export default function usePaymentAction(loan: CustomerLoanFragment): PaymentActionResult {
  const [selection, setSelection] = useState<Selection>();
  const [showForm, setShowForm] = useState<boolean>(false);

  const openPaymentForm = useCallback((selection: Selection) => {
    setSelection({ ...selection });
    setShowForm(true);
  }, []);

  const closePaymentForm = useCallback(() => {
    setShowForm(false);
    setSelection(undefined);
  }, []);

  return {
    loan,
    showForm,

    openPaymentForm,
    closePaymentForm,
    ...selection,
  };
}
