import React, { useCallback, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useReactToPrint } from "react-to-print";
import { message } from "antd";

export type Props = { title: string };

export function usePrint({ title }: Props) {
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: title,
    onAfterPrint: () => setPrinting(false),
    onPrintError: () => {
      message.error("Failed to print invoice.");
      setPrinting(false);
    },
  });
  const [printing, setPrinting] = useState(false);

  const print = useCallback(
    (component: React.ReactNode) => {
      setPrinting(true);

      ReactDOM.render(
        <div ref={componentRef}>{component}</div>,
        document.getElementById("nidhi-printable-content")
      );

      setTimeout(() => handlePrint(), 50);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { print, printing };
}
