import { useHistory } from "react-router";
import { EditFilled, LogoutOutlined, PlusCircleFilled } from "@ant-design/icons";
import { gql } from "@urql/core";
import { Alert, Descriptions, Divider, Skeleton, Tag } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import { useClient, useQuery } from "urql";

import { NOT_AVAILABLE } from "../../constants/common";
import { CUSTOMER_DETAILS_FRAGMENT } from "../../hooks/api/customers/useCustomer";
import { DOCUMENT_CONNECTION_FRAGMENT } from "../../hooks/api/documents/useDocument";
import useLoansSearch from "../../hooks/api/loans/useLoansSearch";
import useQueryParams from "../../hooks/useQueryParams";
import { formatCurrency } from "../../utils/currency";
import { formatDate } from "../../utils/date";
import { getURLParams } from "../../utils/query-params";
import ActionButton from "../common/ActionButton";
import AnchorLink from "../common/AnchorLink";
import Centered from "../common/Centered";
import EmptyPage from "../common/EmptyPage";
import DocumentsList from "../documents/DocumentsList";
import LoansList from "../loans/search/LoansList";
import CustomerCard from "./CustomerCard";

const CUSTOMER_QUERY: CustomerDetailsQueryDoc = gql`
  query CustomerDetails($id: Int!) {
    customers(id: $id) {
      edges {
        node {
          ...CustomerDetails
          primaryCustomer {
            ...CustomerDetails
          }
          loanMetric {
            id
            activeLoans
            closedLoans
            outstandingAmount
            repaidAmount
            totalInterest
            averageDaysToClose
            updatedAt
          }
          documents {
            ...DocumentConnection
          }
        }
      }
    }
  }
  ${CUSTOMER_DETAILS_FRAGMENT}
  ${DOCUMENT_CONNECTION_FRAGMENT}
`;

export function Customer({ data }: { data: CustomerDetailsQuery["customers"] }) {
  const client = useClient();
  const customer = data?.edges[0]?.node;
  const metric = customer?.loanMetric;
  const history = useHistory();

  if (!customer) {
    return <></>;
  }

  const documents = (customer?.documents?.edges || []).map(
    (doc) => doc?.node as NidhiDocumentFragment
  );

  const titleFontSizeClasses = "text-lg sm:text-xl lg:text-2xl";
  const primaryCustomer = customer.primaryCustomer;

  return (
    <>
      {!!primaryCustomer && (
        <div className="mt-2">
          <Alert
            type="error"
            className="rounded-2xl  text-center p-2 sm:p-4 md:p-6 lg:p-8"
            showIcon={false}
            message={
              <div>
                <div className="font-bold text-lg md:text-xl mb-2">
                  THIS CUSTOMER HAS BEEN ASSIGNED A PRIMARY CUSTOMER
                </div>
                <Divider className="my-2" />
                <CustomerCard
                  customer={primaryCustomer}
                  className="text-base font-bold text-gray-700"
                />
              </div>
            }
            banner
          />
        </div>
      )}

      <Descriptions
        title={
          <div className="flex flex-wrap justify-between space-y-1 mt-3 mx-1 items-center">
            <div className={clsx(titleFontSizeClasses, "flex items-center")}>
              {customer.user.name}: {customer.user.phone}
              {customer.isRepledgeCustomer && (
                <Tag className="rounded-full px-4 py-0.5 mx-2" color="magenta-inverse">
                  REPLEDGE
                </Tag>
              )}
            </div>

            <div className="flex space-x-2 justify-between w-full sm:w-auto">
              <ActionButton
                cta="Edit"
                icon={<EditFilled />}
                perm="EDIT_CUSTOMER"
                onClick={() => history.push(`/customers/edit/${customer.id}`)}
              />
              <ActionButton
                cta="Add Loan"
                icon={<PlusCircleFilled />}
                perm="EDIT_LOAN"
                onClick={() => history.push(`/loans/add?customer_id=${customer.id}`)}
              />
            </div>
          </div>
        }
        bordered
        colon={false}
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Name">{customer.user.name}</Descriptions.Item>
        <Descriptions.Item label="Phone">{customer.user.phone}</Descriptions.Item>

        <Descriptions.Item label="Address" span={2}>
          <div>
            {customer.careOfType} {customer.careOfName}
          </div>
          <div>{customer.address}</div>
        </Descriptions.Item>

        <Descriptions.Item label="Gender">
          {customer.gender === "UNKNOWN" ? NOT_AVAILABLE : customer.gender}
        </Descriptions.Item>
        <Descriptions.Item label="Occupation">
          {customer.occupation || NOT_AVAILABLE}
        </Descriptions.Item>

        <Descriptions.Item label="Customer Since" span={2}>
          {customer.user.dateJoined ? formatDate(customer.user.dateJoined) : "NA"}
        </Descriptions.Item>

        {!!metric && (
          <>
            <Descriptions.Item label="Active Loans Count">
              <div className="font-medium text-pink-600">{metric.activeLoans || "NIL"}</div>
            </Descriptions.Item>
            <Descriptions.Item label="Outstanding Loan Amount">
              <div className="font-medium text-pink-600">
                {formatCurrency(metric.outstandingAmount)}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Closed Loans Count">
              <div className="font-medium text-purple-600">{metric.closedLoans || "NIL"}</div>
            </Descriptions.Item>

            <Descriptions.Item label="Repaid Loan Amount">
              <div className="font-medium text-purple-600">
                {formatCurrency(metric.repaidAmount)}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Total Interest Paid">
              <div className="font-medium text-purple-600">
                {formatCurrency(metric.totalInterest)}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Avg. Time To Close">
              {metric.averageDaysToClose ? (
                <div className="font-medium text-purple-600">
                  {dayjs.duration(metric.averageDaysToClose, "days").humanize()}
                </div>
              ) : (
                NOT_AVAILABLE
              )}
            </Descriptions.Item>
          </>
        )}
      </Descriptions>

      <div className="py-4" />
      <DocumentsList
        documents={documents}
        customer={customer}
        onChange={async () =>
          await client
            .query(CUSTOMER_QUERY, { id: customer.id }, { requestPolicy: "network-only" })
            .toPromise()
        }
      />
    </>
  );
}

function CustomerLoans({
  customerId,
  loanStatus,
  first,
  order,
}: { customerId: number } & Partial<LoansSearchQueryVariables>) {
  const loansSearch = useLoansSearch({
    filters: {
      customerId,
      loanStatus,
      order,
      first,
    },
    inlineMode: true,
  });
  const { total, loading } = loansSearch;

  return loading || (total && total > 0) ? (
    <LoansList
      loansSearch={loansSearch}
      hideCustomer
      title={
        <div className="w-full justify-center flex">
          <AnchorLink externalHref={`/loans/search?${getURLParams(loansSearch.variables)}`}>
            <div className="flex items-center space-x-1 text-center text-lg font-bold">
              <div>
                {loansSearch.total} {loanStatus} Loans
              </div>
              <div>
                <LogoutOutlined className="text-sm pb-2" rotate={-45} />
              </div>
            </div>
          </AnchorLink>
        </div>
      }
    />
  ) : null;
}

export function CustomerDetails({ id }: { id: number }) {
  const [{ data, fetching }] = useQuery({
    query: CUSTOMER_QUERY,
    variables: { id },
    pause: !id,
  });

  const customer = data?.customers?.edges?.[0]?.node;

  return fetching ? (
    <Centered>
      <Skeleton active paragraph={{ rows: 10 }} className="max-w-screen-sm" />
    </Centered>
  ) : customer ? (
    <>
      <Customer data={data.customers} />
      <div className="py-2" />
      <CustomerLoans
        customerId={id}
        first={5}
        loanStatus="ACTIVE"
        order={["CONTRACT_START_DATE_DESC", "ID_DESC"]}
      />
      <div className="py-2" />
      <CustomerLoans customerId={id} first={5} loanStatus="CLOSED" order="CLOSING_DATE_DESC" />
      <div className="py-8" />
    </>
  ) : (
    <EmptyPage
      title="Unable to load the customer!"
      back="/customers/search"
      backCta="Search Customers"
    />
  );
}

export default function CustomerDetailsPage() {
  const id = useQueryParams();
  return id ? <CustomerDetails id={parseInt(id)} /> : null;
}
