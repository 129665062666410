import { Divider } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { FormikHelpers } from "formik";
import { gql, useMutation } from "urql";
import * as Yup from "yup";

import { refetchTransactions } from "../../../../hooks/api/accounts/useAccountTransactionsSearch";
import useError from "../../../../hooks/useError";
import { trackEvent } from "../../../../utils/analytics";
import { getAccountDataForAnalytics } from "../../../../utils/analytics/data";
import { formatCurrency } from "../../../../utils/currency";
import { getPlaceholderDate, toServerDateNonNull } from "../../../../utils/date";
import Form from "../../../form/Form";
import { FormButtons } from "../../../form/FormButtons";
import FormDateField from "../../../form/FormDateField";
import FormMoneyField from "../../../form/FormMoneyField";
import FormTextArea from "../../../form/FormTextArea";

const RECORD_PAYMENT_MUTATION: RecordLoanPaymentMutationDoc = gql`
  mutation RecordLoanPayment(
    $borrowerAccountId: Int!
    $date: Date!
    $amount: Decimal
    $interest: Decimal
    $description: String
  ) {
    recordUnsecuredLoanPayment(
      borrowerAccountId: $borrowerAccountId
      amount: $amount
      interest: $interest
      date: $date
      description: $description
    ) {
      success
      failureMessage
      transactions {
        id
        legs {
          id
          account {
            id
            currentBalance
          }
        }
      }
    }
  }
`;

type FormData = {
  date: string | Dayjs;
  amount?: number;
  interest?: number;
  description?: string;
};

type FormProps = {
  account: AccountDetailsFragment;
  onClose: (done?: boolean) => void;
};

export default function RecordLoanPayment({ account, onClose }: FormProps) {
  const [, record] = useMutation(RECORD_PAYMENT_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return record({
      borrowerAccountId: account.id,
      date: toServerDateNonNull(values.date),
      amount: values.amount,
      interest: values.interest,

      description: values.description,
    })
      .then(async (response) => {
        if (
          response.data?.recordUnsecuredLoanPayment?.success &&
          response.data.recordUnsecuredLoanPayment.transactions
        ) {
          trackEvent({ action: "Record Loan Payment" }, getAccountDataForAnalytics(account));
          refetchTransactions(account.id);
          onClose(true);
        } else {
          setError({
            customError: response.data?.recordUnsecuredLoanPayment?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to disburse loan, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  const formValidatons: {
    [K in keyof FormData]: Yup.AnySchema;
  } = {
    date: Yup.date().nullable().required("Payment date is required"),
    amount: Yup.number()
      .nullable()
      .optional()
      .min(1, "Amount should be greater than 1.")
      .max(
        account.currentBalance,
        `Loan repayment amount can't be greater than the current balance - ${formatCurrency(
          account.currentBalance
        )}`
      ),
    interest: Yup.number()
      .nullable()
      .optional()
      .min(1, "Amount should be greater than 1.")
      .max(
        account.currentBalance,
        `Interest can't be more than the balance itself - ${formatCurrency(account.currentBalance)}`
      ),
    description: Yup.string().nullable().optional(),
  };

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{
          date: dayjs().startOf("day"),
          amount: undefined,
          interest: undefined,
          description: undefined,
        }}
        validationSchema={formValidatons}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormDateField
                label="Date"
                column
                name="date"
                placeholder={getPlaceholderDate()}
                required
                disabledDate={(date) => date < dayjs().subtract(1, "month") || date > dayjs()}
              />

              <FormMoneyField
                column
                label="Repaid Loan Amount"
                name="amount"
                placeholder="10,000"
                helper="The loan amount repaid by the borrower, skip this input if borrower is paying only interest."
              />
              <FormMoneyField
                column
                label="Interest"
                name="interest"
                placeholder="1,000"
                helper="The interest paid by the borrower, skip this input if borrower is paying back the loan amount only."
              />

              <FormTextArea
                column
                label="Description"
                name="description"
                placeholder="Optional description"
              />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
