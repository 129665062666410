import { useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import { gql, useQuery } from "urql";

import useLoanMetrics, { LoanMetricsProps, LoanMetricsResult } from "./useLoanMetrics";

const LOAN_METRIC_FRAGMENT: OpenCloseLoansMetricFragmentDoc = gql`
  fragment OpenCloseLoansMetric on LoanMetric {
    id
    metricDate
    itemType
    openedLoansCount
    closedLoansCount
    openedLoansAmount
    closedLoansAmount
  }
`;

const LOAN_METRICS_QUERY: LoanMetricsQueryDoc = gql`
  query LoanMetrics(
    $startDate: Date
    $endDate: Date
    $itemType: GoldLoansLoanMetricItemTypeChoices
    $haveEvent: Boolean
  ) {
    loanMetrics(
      metricDate_Gte: $startDate
      metricDate_Lte: $endDate
      itemType: $itemType
      haveEvent: $haveEvent
    ) {
      edges {
        node {
          ...OpenCloseLoansMetric
        }
      }
    }
  }
  ${LOAN_METRIC_FRAGMENT}
`;

export type OpenCloseLoansMetric = Omit<OpenCloseLoansMetricFragment, "metricDate"> & {
  metricDate: Dayjs;
};

export type OpenCloseLoansMetricsResult = LoanMetricsResult & {
  metrics: OpenCloseLoansMetric[];
  loading: boolean;
};

type Props = LoanMetricsProps;

export default function useOpenCloseLoansMetrics(props: Props): OpenCloseLoansMetricsResult {
  const loanMetrics = useLoanMetrics(props);
  const variables = useMemo(() => {
    return { ...loanMetrics.variables, haveEvent: true };
  }, [loanMetrics.variables]);

  const [{ data, fetching }] = useQuery({
    query: LOAN_METRICS_QUERY,
    variables,
    requestPolicy: "network-only",
  });

  const metrics = useMemo(() => {
    return data?.loanMetrics?.edges
      ? data.loanMetrics.edges
          .filter((edge) => !!edge?.node)
          .map((edge) => edge?.node)
          .map((node) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const profit = node!;
            return {
              ...profit,
              openedLoansAmount: parseFloat(`${profit.openedLoansAmount}`),
              closedLoansAmount: parseFloat(`${profit.closedLoansAmount}`),
              metricDate: dayjs(profit.metricDate),
            };
          })
      : [];
  }, [data]);

  return { ...loanMetrics, metrics, loading: fetching };
}
