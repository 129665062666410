import { useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { Skeleton, Typography } from "antd";
import { gql, useQuery } from "urql";

import { formatCurrency } from "../../utils/currency";
import { formatDate } from "../../utils/date";
import { formatNumber } from "../../utils/number";
import { getURLParams } from "../../utils/query-params";
import AnchorLink from "../common/AnchorLink";
import ResponsiveTable, { ResponsiveColumnType } from "../common/ResponsiveTable";

export const CUSTOMER_WIDGETS_QUERY: CustomerWidgetsQueryDoc = gql`
  query CustomerWidgets(
    $order: [CustomerOrder!]
    $activeLoansCountMin: Int
    $closedLoansCountMin: Int
  ) {
    customers(
      order: $order
      loanMetric_ActiveLoans_Gte: $activeLoansCountMin
      loanMetric_ClosedLoans_Gte: $closedLoansCountMin
      first: 5
    ) {
      edges {
        node {
          id
          address
          careOfType
          careOfName
          user {
            id
            name
            phone
            dateJoined
          }
          loanMetric {
            outstandingAmount
            activeLoans
            closedLoans
            repaidAmount
            totalInterest
          }
        }
      }
    }
  }
`;

type Column = "ACTIVE_LOANS" | "CLOSED_LOANS";

export default function CustomersWidget({
  variables,
  additionalColumns = ["ACTIVE_LOANS"],
}: {
  variables: CustomerWidgetsQueryVariables;
  additionalColumns?: Column[];
}) {
  const [ref, inView] = useInView({ triggerOnce: true });
  const [{ data, fetching }] = useQuery({
    query: CUSTOMER_WIDGETS_QUERY,
    variables,
    pause: !inView,
  });
  const customers = (data?.customers?.edges || [])
    .map((edge) => edge?.node)
    .filter((customer) => !!customer);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const customer = customers[0]!;
  type Customer = typeof customer;

  const columns: ResponsiveColumnType<Customer>[] = useMemo(() => {
    const cols: ResponsiveColumnType<Customer>[] = [
      {
        title: "Name",
        key: "name",
        dataIndex: "user",
        width: 150,
        fixed: "left",
        align: "left",
        render: (value, record) => (
          <>
            <Link to={`/customers/${record.id}`} href={`/customers/${record.id}`}>
              <AnchorLink>
                <span className="text-sm">{record.user.name}</span>
              </AnchorLink>
            </Link>
            <Typography.Paragraph
              className="text-sm text-gray-500"
              ellipsis={{ tooltip: `${record.careOfType} ${record.careOfName}` }}
            >
              {record.careOfType} {record.careOfName}
            </Typography.Paragraph>
          </>
        ),
        cardable: { position: "top", containerClassName: "w-1/2" },
        ellipsis: true,
      },
      {
        title: "Phone",
        key: "phone",
        dataIndex: "user",
        width: 150,
        render: (value, record) => record.user.phone,
        cardable: {
          position: "top",
          containerClassName: "w-1/2",
          renderForCard: (_, record) => (
            <div className="flex flex-col">
              <div className="text-right">{record.user.phone}</div>
              <Typography.Text
                ellipsis={{ tooltip: record.address }}
                className="text-right font-normal"
              >
                <span>{record.address}</span>
              </Typography.Text>
            </div>
          ),
        },
      },
      {
        title: "Customer Since",
        key: "dateJoined",
        dataIndex: "user",
        width: 150,
        render: (value, record) => formatDate(record.user.dateJoined),
        ellipsis: true,
        cardable: { position: "body" },
      },
      {
        title: "Address",
        key: "address",
        width: 200,
        dataIndex: "address",
      },
    ];

    for (let i = 0; i < additionalColumns.length; i++) {
      const column = additionalColumns[i];
      switch (column) {
        case "ACTIVE_LOANS":
          cols.push({
            title: "Active Loans",
            dataIndex: "loanMetric",
            key: "activeLoans",
            width: 125,
            render: (value, record) => (
              <>
                {formatCurrency(record.loanMetric?.outstandingAmount)}&nbsp;
                {!!record.loanMetric?.activeLoans && (
                  <>({formatNumber(record.loanMetric.activeLoans)})</>
                )}
              </>
            ),
            cardable: { position: "body" },
          });
          break;

        case "CLOSED_LOANS":
          cols.push({
            title: "Closed Loans",
            key: "closedLoans",
            dataIndex: "loanMetric",
            width: 125,
            render: (value, record) => (
              <>
                {formatCurrency(record.loanMetric?.repaidAmount)}&nbsp;
                {!!record.loanMetric?.closedLoans && (
                  <>({formatNumber(record.loanMetric.closedLoans)})</>
                )}
              </>
            ),
            cardable: { position: "body" },
          });

          cols.push({
            title: "Paid Interest",
            key: "paidInterest",
            dataIndex: "loanMetric",
            width: 125,
            render: (value, record) => formatCurrency(record.loanMetric?.totalInterest),
            cardable: { position: "body" },
          });
          break;
      }
    }

    return cols;
  }, [additionalColumns]);

  return (
    <div ref={ref}>
      <Skeleton className="p-2" paragraph={{ rows: 8 }} title={false} loading={fetching} active>
        <ResponsiveTable<Customer>
          printSettings={false}
          scroll={{ x: 600 }}
          columns={columns}
          dataSource={customers as Customer[]}
          pagination={false}
          rowKey="id"
          loading={fetching}
          size="small"
          footer={() => {
            const url = `/customers/search/?${getURLParams(variables)}`;
            return (
              <div className="flex justify-center">
                <Link href={url} to={url}>
                  <AnchorLink>
                    <span className="text-base font-normal">See More</span>
                  </AnchorLink>
                </Link>
              </div>
            );
          }}
        />
      </Skeleton>
    </div>
  );
}
