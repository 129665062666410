import React from "react";
import { Button, Result } from "antd";

import Loading from "../components/common/Loading";
import type { PermissionType } from "../types/enum";
import usePermissions from "./api/usePermissions";

function Unauthorized() {
  return (
    <Result
      status="403"
      title="Unauthorized"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" href="/" shape="round">
          Back Home
        </Button>
      }
    />
  );
}

export function WithPermission({
  children,
  permission,
}: {
  permission: PermissionType;
  children: React.ReactElement;
}) {
  const { hasPerm, loading } = usePermissions();
  return <Loading loading={loading}>{hasPerm(permission) ? children : <Unauthorized />}</Loading>;
}

export default function withPermission(
  Component: React.ComponentType,
  permission: PermissionType
): React.ComponentType {
  // eslint-disable-next-line react/display-name
  return ({ ...props }) => (
    <WithPermission permission={permission}>
      <Component {...props} />
    </WithPermission>
  );
}
