import { isNull } from "./common";
import { toNumber } from "./number";

const SYMBOL = "₹";
const LABELS = ["", " Thousand", " Lakh", " Crore"];

export const format = (paise: number, precision?: number) => {
  if (precision === undefined) {
    precision = 2;
  }

  const parts = parse(paise);
  const stringParts = [];
  if (precision > 0) {
    stringParts.unshift(parts.paise.padStart(2, "0").slice(0, precision));
  }
  stringParts.unshift(parts.rupeeParts.join(",").padEnd(1, "0"));
  return stringParts.join(".");
};

export const formatWithSymbol = (paise: number, precision?: number) => {
  return [SYMBOL, format(paise, precision)].join("");
};

const REGULARS: { [K: string]: string }[] = [
  {
    "1": "One",
    "2": "Two",
    "3": "Three",
    "4": "Four",
    "5": "Five",
    "6": "Six",
    "7": "Seven",
    "8": "Eight",
    "9": "Nine",
  },
  {
    "2": "Twenty",
    "3": "Thirty",
    "4": "Forty",
    "5": "Fifty",
    "6": "Sixty",
    "7": "Seventy",
    "8": "Eighty",
    "9": "Ninety",
  },
];

const EXCEPTIONS: { [K: string]: string } = {
  "10": "Ten",
  "11": "Eleven",
  "12": "Twelve",
  "13": "Thirteen",
  "14": "Fourteen",
  "15": "Fifteen",
  "16": "Sixteen",
  "17": "Seventeen",
  "18": "Eighteen",
  "19": "Nineteen",
};

const partInWords = (part: string) => {
  if (parseInt(part) === 0) {
    return;
  }

  const digits = part.split("");
  const words = [];
  if (digits.length === 3) {
    const value = digits.shift();
    if (value && parseInt(value)) {
      words.push([REGULARS[0][value], "Hundred"].join(" "));
    }
  }

  if (EXCEPTIONS[digits.join("")]) {
    words.push(EXCEPTIONS[digits.join("")]);
  } else {
    words.push(
      digits
        .reverse()
        .reduce((memo, el, i) => {
          memo.unshift(REGULARS[i][el]);
          return memo;
        }, [] as string[])
        .filter((w) => w)
        .join(" ")
    );
  }
  return words.filter((w) => w.trim().length).join(" and ");
};

const words = (paise: number) => {
  const parsedParts = parse(paise);
  const rupeeTextParts = parsedParts.rupeeParts
    .filter((w) => w)
    .reverse()
    .reduce((previousValue, currentValue, i) => {
      if (parseInt(currentValue) > 0)
        previousValue.push([partInWords(currentValue), LABELS[i]].join(""));
      return previousValue;
    }, [] as string[]);

  const text = [];
  if (rupeeTextParts.length)
    text.push(
      [
        rupeeTextParts
          .reverse()
          .filter((w) => w)
          .join(", "),
        "Rupees",
      ].join(" ")
    );

  if (parseInt(parsedParts.paise)) {
    text.push([partInWords(parsedParts.paise), "Paise"].join(" "));
  }

  return text.join(", ");
};

export const inrToWords = (amount: number) => {
  const _amount = toNumber(amount);
  if (isNaN(_amount) || isNull(_amount)) {
    return "";
  }

  return _amount === 0 ? "Zero Rupees" : words(parseFloat(_amount.toFixed(2)) * 100);
};

const parse = (paise: number) => {
  const littleEndian = paise.toString().split("").reverse();
  const rupeeParts = [];
  const paisePart: string[] = [];
  Array(2)
    .fill(2)
    .forEach(() => {
      const part = littleEndian.shift();
      if (part) {
        paisePart.unshift(part);
      }
    });
  const cycle = [3, 2, 2];
  let cycleIndex = 0;
  while (littleEndian.length > 0) {
    const part: string[] = [];
    const cycleSize = cycle[cycleIndex % cycle.length];
    Array(cycleSize)
      .fill(cycleSize)
      .forEach(() => {
        const _part = littleEndian.shift();
        if (_part) {
          part.unshift(_part);
        }
      });
    rupeeParts.unshift(part.join(""));
    cycleIndex++;
  }

  return { rupeeParts: rupeeParts, paise: paisePart.join("") };
};
