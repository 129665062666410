import { AccountCategoryEnum } from "../../gql/graphql";
import { AccountCategories } from "./AccountCategoryCard";

export default function AccountsDashboard(props: {
  data?: DashboardDataQuery["dashboard"]["accounts"];
  loading: boolean;
  className: string;
  categoriesToShow?: AccountCategoryEnum[];
}) {
  return <AccountCategories {...props} data={props.data?.categories || []} />;
}
