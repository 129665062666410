import { useCallback, useState } from "react";

import { DocumentType } from "../../../types/enum";
import { getApiUrl, getCSRFToken } from "../../../utils/api";
import useError from "../../useError";

export type Document = {
  id: number;
  key: string;
  url: string;
  filename: string;
};

export type UseUploadDocumentProps = {
  documentType: DocumentType;
  filename: string;

  customerId?: number;
  document?: Document;
  assignToCustomer?: boolean;
  linkToLoanId?: number;

  onSelect: (document: Document) => void;
};

type UploadStatus = "active" | "success" | "exception" | "resync";

export default function useUploadDocument(props: UseUploadDocumentProps) {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus | null>(null);
  const [document, setDocument] = useState<Document | undefined>(props.document);
  const { error, setError, resetError } = useError();

  const upload = useCallback(
    async ({ image, documentKey }: { image: string; documentKey?: string }) => {
      setUploadStatus("active");
      resetError();

      const imageBlob = await fetch(image).then((res) => res.blob());
      const formData = new FormData();
      const { customerId, documentType, filename, assignToCustomer, linkToLoanId } = props;
      if (customerId) {
        formData.append("customerId", `${customerId}`);
      }

      formData.append("documentType", documentType.toString());
      formData.append("file", imageBlob, filename);

      if (documentKey) {
        formData.append("documentKey", documentKey);
      }

      if (assignToCustomer) {
        formData.append("assignToCustomer", assignToCustomer ? "true" : "false");
      }

      if (linkToLoanId) {
        formData.append("linkToLoanId", `${linkToLoanId}`);
      }

      await fetch(getApiUrl("/api/document/upload/"), {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: {
          Accept: "application/json",
          ...getCSRFToken(),
        },
        redirect: "error",
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            const document = response;
            setDocument(document);
            setUploadStatus("success");
            props.onSelect(document);
          } else {
            setError({ customError: response.message });
            setUploadStatus("exception");
          }
        })
        .catch((error) => {
          setError({ customError: error.toString() });
          setUploadStatus("exception");
        });
    },
    [props, setError, resetError]
  );

  const resetStatus = useCallback(() => {
    setUploadStatus("resync");
  }, []);

  return { upload, uploadStatus, document, error, resetStatus };
}
