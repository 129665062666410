import { gql } from "@urql/core";
import { Button, Divider } from "antd";
import { FormikHelpers } from "formik";
import { useMutation } from "urql";
import * as Yup from "yup";

import { CURRENT_USER_FRAGMENT } from "../../context/CurrentUserContext";
import useError from "../../hooks/useError";
import useUser from "../../hooks/useUser";
import Form from "../form/Form";
import FormPhoneField from "../form/FormPhoneField";
import FormTextField from "../form/FormTextField";

const LOGIN_MUTATION: LoginMutationDoc = gql`
  mutation Login($phone: String!, $password: String!) {
    login(phone: $phone, password: $password) {
      user {
        ...CurrentUser
      }
      responseCode
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

type FormData = {
  phone: string;
  password: string;
};

type Props = {
  onSuccess: () => void;
};

export default function LoginForm({ onSuccess }: Props) {
  const { reloadUser } = useUser();
  const [, login] = useMutation(LOGIN_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return login({
      phone: values.phone,
      password: values.password,
    })
      .then((response) => {
        if (response.data?.login?.responseCode == "LOGIN_SUCCESS") {
          reloadUser();
          onSuccess();
        } else {
          setError({ customError: response.data?.login?.responseCode, gqlError: response.error });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Login Failed, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm">
      <h4 className="mt-2 mb-5 font-bold text-2xl text-center">Login</h4>
      <Form<FormData>
        initialValues={{ phone: "", password: "" }}
        validationSchema={{
          phone: Yup.string()
            .nullable()
            .required("Phone is required")
            .min(10, "Phone number should have 10 digits.")
            .max(10, "Phone number should have 10 digits."),
          password: Yup.string().required("Password is required"),
        }}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormPhoneField column label="Phone" name="phone" autoComplete="username" required />
              <FormTextField
                type="password"
                label="Password"
                column
                autoComplete="current-password"
                name="password"
                placeholder="Password"
                required
              />
              <Divider />
              <Button
                shape="round"
                size="large"
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                Log In
              </Button>
            </>
          );
        }}
      </Form>
    </div>
  );
}
