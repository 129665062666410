/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createContext, ReactNode, useEffect, useMemo } from "react";
import { gql, useQuery } from "urql";

import { setUser } from "../utils/analytics";

export const CURRENT_USER_FRAGMENT: CurrentUserFragmentDoc = gql`
  fragment CurrentUser on User {
    id
    firstName
    lastName
    phone
    permissions
    selectedBranchId
    selectedBranchCode
  }
`;

export const CURRENT_USER_QUERY: CurrentUserQueryDoc = gql`
  query CurrentUser {
    user {
      ...CurrentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

export interface CurrentUserContextType {
  user?: CurrentUserFragment;
  reloadUser: () => void;
  loadingUser: boolean;
}

const CurrentUserContext = createContext<CurrentUserContextType>({
  reloadUser: () => console.warn("User context misuse"),
  loadingUser: false,
});

export const CurrentUserContextProvider = ({ children }: { children: ReactNode }) => {
  const [{ data, fetching: loadingUser }, reFetchData] = useQuery({
    query: CURRENT_USER_QUERY,
  });

  const reloadUser = () => reFetchData({ requestPolicy: "network-only" });

  const user = useMemo(() => {
    return data?.user ? data.user : undefined;
  }, [data]);

  useEffect(() => {
    if (user) {
      setUser(user);
    }
  }, [user]);

  return (
    <CurrentUserContext.Provider value={{ user, reloadUser, loadingUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
};
export default CurrentUserContext;
