import dayjs from "dayjs";

import type {
  AccountCategoryEnum,
  AccountSubtype,
  AccountType,
  BooksAccountSubtypeChoices,
  BooksAccountTypeChoices,
} from "../gql/graphql";

export const ACCOUNT_TYPES: (BooksAccountTypeChoices | AccountType)[] = [
  "EQUITY",
  "LIABILITY",
  "INCOME",
  "EXPENSE",
  "ASSET",
];

export const ACCOUNT_SUBTYPES: (BooksAccountSubtypeChoices | AccountSubtype)[] = [
  "UNSECURED_LOAN",
  "SECURED_LOAN",
  "BANK",
  "CASH",
  "PROPERTY",
  "EMPLOYEE_PERSONAL",
  "ACCOUNTS_PAYABLE",
];

export const ACCOUNT_CATEGORIES_ORDERED: AccountCategoryEnum[] = [
  "EQUITY",
  "LIABILITY",
  "LIABILITY_EMPLOYEE_PERSONAL",
  "LIABILITY_ACCOUNTS_PAYABLE",
  "INCOME",
  "EXPENSE",
  "ASSET",
  "ASSET_CASH",
  "ASSET_BANK",
  "ASSET_PROPERTY",
  "ASSET_SECURED_LOAN",
  "ASSET_UNSECURED_LOAN",
];

export const ACCOUNT_CATEGORIES = [...ACCOUNT_CATEGORIES_ORDERED].sort((a, b) =>
  a.localeCompare(b)
);

export const OperationAccountCodes = {
  OTHER_INCOME: 450,
  COMMISSIONS: 430,
};

export const ACCOUNT_CUT_OFF_DATE = dayjs("2023-03-31");
