import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Divider, InputNumber, Switch } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { connect, FormikHelpers } from "formik";
import { gql, useClient } from "urql";
import * as Yup from "yup";

import {
  CUSTOM_INTEREST_RATE_OPTION_VALUE,
  INTEREST_RATE_OPTIONS,
  INTEREST_RATES,
  ITEM_TYPE_OPTIONS,
  ITEM_TYPES,
  SILVER_INTEREST_RATE,
} from "../../../constants/loan";
import { INVESTOR_INTEREST_RATE } from "../../../constants/rate";
import type { GoldLoansLoanContractLoanStatusChoices, ItemType } from "../../../gql/graphql";
import { Document } from "../../../hooks/api/documents/useUploadDocument";
import { Loan } from "../../../hooks/useLoanAction";
import { isNull } from "../../../utils/common";
import { formatRate, renderCurrencyZeroPaise } from "../../../utils/currency";
import { getLoanDocumentName } from "../../../utils/document";
import { inrToWords } from "../../../utils/inr";
import { getProcessingFees } from "../../../utils/loan";
import { roundToNearest5, sum, toNumber } from "../../../utils/number";
import FormDateField from "../../form/FormDateField";
import FormMoneyField from "../../form/FormMoneyField";
import FormNumberField from "../../form/FormNumberField";
import FormPhotoInput from "../../form/FormPhotoInput";
import FormRadioField from "../../form/FormRadioField";
import FormTextArea from "../../form/FormTextArea";
import FormTextField from "../../form/FormTextField";
import FormValue from "../../form/FormValue";

export type LoanAmountFormData = {
  interestRate: number | null;
  loanAmount: number | null;
  firstMonthInterest: number | null;
  initialInvestorInterest?: number | null;
  processingFees: number | null;
  notes?: string;
};

export type LoanFormData = LoanAmountFormData & {
  pledgeSheetNumber: number | undefined;
  autoGeneratePSN?: boolean;
  contractStartDate: string;
  itemType: ItemType | null;
  itemName: string;
  itemWeight: number | null;
  estimatedValue: number | null;

  itemPhotoDocId?: number;
  itemPhotoDoc?: Document;
};

export function cleanFormValues(values: LoanFormData) {
  const input = { ...values };

  delete input["itemPhotoDoc"];

  return input;
}

export const LOAN_AMOUNT_FORM_VALIDATIONS: { [K in keyof LoanAmountFormData]: Yup.AnySchema } = {
  interestRate: Yup.number()
    .nullable()
    .required("Interest rate is required")
    .min(0.1, "Interest rate should be greater than 0.1%")
    .max(10, "Interest rate should be less than 10%"),
  loanAmount: Yup.number()
    .nullable()
    .required("Disbursed loan amount is required")
    .min(5, "Loan amount should be atleast greater than 5 rupees."),
  firstMonthInterest: Yup.number()
    .nullable()
    .required("First month interest is required")
    .when("initialInvestorInterest", (initialInvestorInterest, schema) => {
      return initialInvestorInterest
        ? schema.min(
            initialInvestorInterest,
            `First month interest should be at-least ${renderCurrencyZeroPaise(
              initialInvestorInterest
            )} to cover ${formatRate(INVESTOR_INTEREST_RATE)} rate.`
          )
        : schema;
    }),
  processingFees: Yup.number().nullable().required("Processing fees is required"),
  notes: Yup.string().nullable().optional(),
};

export const LOAN_FORM_VALIDATIONS: { [K in keyof LoanFormData]: Yup.AnySchema } = {
  pledgeSheetNumber: Yup.number().when("autoGeneratePSN", (autoGeneratePSN) => {
    return autoGeneratePSN
      ? Yup.number().nullable().optional()
      : Yup.number()
          .nullable()
          .required("PSN is required")
          .max(1000000, "Input PSN seems incorrect, it can't be above 10 Lakh.");
  }),
  autoGeneratePSN: Yup.boolean().nullable().optional(),
  contractStartDate: Yup.date().nullable().required("Pledge date is required"),
  itemType: Yup.string()
    .nullable()
    .oneOf(ITEM_TYPES, "Choose one of item type")
    .required("Item type is required."),
  itemName: Yup.string().nullable().required("Item name is required").min(3),
  itemWeight: Yup.number()
    .nullable()
    .required("Item weight is required")
    .min(0.001, "Item weight can't be less than 0.001 grams")
    .max(1000, "Item weight can't be more than 1000 grams"),
  estimatedValue: Yup.number()
    .nullable()
    .required("Item estimated value is required")
    .min(5, "Item estimate should be atleast greater than 5 rupees."),

  ...LOAN_AMOUNT_FORM_VALIDATIONS,
};

type LoanAmountFormProps = {
  edit?: boolean;
  interestRate?: number | null;
  hideLoanAmountField?: boolean;
  column?: boolean;
  values: LoanAmountFormData;
  disabled?: boolean;
};

export function _LoanAmountForm({
  edit,
  values,
  formik,
  hideLoanAmountField,
  column,
  disabled,
}: LoanAmountFormProps & {
  formik: FormikHelpers<LoanAmountFormData>;
}) {
  const { initialInvestorInterest, firstMonthInterest, processingFees } = useMemo(() => {
    if (!values.interestRate || !values.loanAmount) {
      return {
        firstMonthInterest: null,
        initialInvestorInterest: null,
        processingFees: null,
      };
    }
    const firstMonthInterest = roundToNearest5((values.interestRate * values.loanAmount) / 100);
    const initialInvestorInterest = Math.round(values.loanAmount * INVESTOR_INTEREST_RATE);
    const processingFees = getProcessingFees(values.loanAmount);
    return { firstMonthInterest, processingFees, initialInvestorInterest };
  }, [values.interestRate, values.loanAmount]);

  useEffect(() => {
    if (edit) {
      return;
    }

    formik.setValues({ ...values, firstMonthInterest, processingFees });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstMonthInterest, processingFees, edit]);

  useEffect(() => {
    formik.setFieldValue(
      "initialInvestorInterest",
      toNumber(values.firstMonthInterest ?? 0) === 0 ? null : initialInvestorInterest
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.firstMonthInterest, initialInvestorInterest]);

  const tenderedAmount = sum(
    values.loanAmount,
    -(values.firstMonthInterest ?? 0),
    -(values.processingFees ?? 0)
  );

  return (
    <>
      {!hideLoanAmountField && (
        <FormMoneyField
          column={column}
          required
          label="Top Up Loan Amount"
          name="loanAmount"
          placeholder="5,000"
          disabled={disabled}
        />
      )}

      <FormMoneyField
        required
        column={column}
        label="First Month Interest"
        name="firstMonthInterest"
        placeholder="125"
        defaultValue={firstMonthInterest ?? undefined}
        disabled={disabled}
        helper={
          <div className="flex flex-col">
            {!!firstMonthInterest && (
              <div className="text-gray-600">
                Calculated Interest:{" "}
                <span className="text-pink-600 font-semibold">
                  {renderCurrencyZeroPaise(firstMonthInterest)}
                </span>
              </div>
            )}
          </div>
        }
      />

      <FormMoneyField
        required
        column={column}
        helper='Enter "0" if there are no processing fees.'
        label="Processing Fees"
        name="processingFees"
        placeholder="200"
        disabled={disabled}
      />
      <FormTextArea
        column={column}
        helper="Enter any notes about this loan."
        label="Notes"
        name="notes"
        placeholder="Notes about this loan..."
      />
      <Divider className="my-2" />

      {!disabled && (
        <>
          <FormValue
            required
            column={column}
            label="Tendered Amount"
            name="tenderedAmount"
            helper={`Loan Amount - First Month Interest - Processing Fees`}
            value={
              <div className="p-0.5 pt-1 md:pt-0 text-center">
                <div className="text-2xl font-semibold">
                  {renderCurrencyZeroPaise(tenderedAmount)}
                </div>
                <div className="text-lg text-gray-600 font-medium">
                  {inrToWords(toNumber(tenderedAmount))}
                </div>
              </div>
            }
          />
        </>
      )}
    </>
  );
}

export const LoanAmountForm: React.ComponentType<LoanAmountFormProps> = connect<
  LoanAmountFormProps,
  LoanAmountFormData
>(_LoanAmountForm);

type LoanFormProps = {
  edit?: boolean;
  loanId?: number;
  loanStatus?: GoldLoansLoanContractLoanStatusChoices;
  hasTopUps?: boolean;
  customerId?: number;
  customerName?: string;
  repledgeCustomer?: boolean;
  values: LoanFormData;
};

const GET_NEXT_PSN_QUERY: GetNextPsnQueryDoc = gql`
  query GetNextPsn {
    dashboard {
      loanStats {
        nextPsn
      }
    }
  }
`;

export function _LoanForm({
  customerId,
  customerName,
  repledgeCustomer,
  loanId,
  loanStatus,
  hasTopUps,
  values,
  formik,
  edit,
}: LoanFormProps & { formik: FormikHelpers<LoanFormData> }) {
  const disableActiveLoanAmountFields = hasTopUps || loanStatus === "CLOSED";
  const client = useClient();
  const [fetchingPSN, setFetchingPSN] = useState(false);
  const [customRate, setCustomRate] = useState(
    () => values.interestRate && !INTEREST_RATES.includes(toNumber(values.interestRate || 0))
  );

  const autoGeneratePsn = useCallback(
    async () => {
      setFetchingPSN(true);
      const psn = await client
        .query(GET_NEXT_PSN_QUERY, {}, { requestPolicy: "network-only" })
        .toPromise()
        .then((data) => data?.data?.dashboard?.loanStats?.nextPsn)
        .finally(() => setFetchingPSN(false));
      formik.setFieldValue("pledgeSheetNumber", psn);
      formik.setFieldError("pledgeSheetNumber", undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFetchingPSN]
  );

  useEffect(() => {
    // don't compute the PSN in edit mode or the PSN is already updated.
    if (edit || values.pledgeSheetNumber) {
      return;
    }

    if (values.autoGeneratePSN && !values.pledgeSheetNumber) {
      autoGeneratePsn();
    }
  }, [values.autoGeneratePSN, values.pledgeSheetNumber, edit, autoGeneratePsn]);

  useEffect(() => {
    if (customRate || edit) {
      return;
    }

    let rate;
    if (repledgeCustomer) {
      rate = 1.25;
    } else if (values.itemType === "SILVER") {
      rate = SILVER_INTEREST_RATE;
    } else if (values.loanAmount) {
      if (values.loanAmount < 10000) {
        rate = 2.5;
      } else if (values.loanAmount >= 10000) {
        rate = 2;
      }
    }

    if (rate) {
      formik.setFieldValue("interestRate", rate);
    } else if (values.interestRate) {
      formik.setFieldValue("interestRate", undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, values.itemType, values.loanAmount, customRate, repledgeCustomer]);

  return (
    <>
      <FormNumberField
        label="Pledge Sheet Number (PSN)"
        name="pledgeSheetNumber"
        placeholder="Pledge Sheet Number"
        required
        autoComplete="off"
        disabled={!!values.autoGeneratePSN || loanStatus === "CLOSED"}
        hideErrorMessage={!!values.autoGeneratePSN}
      >
        {!edit ? (
          <div className="mt-1 ml-2">
            <Switch
              size="default"
              checkedChildren={<span className="font-bold">Get PSN</span>}
              unCheckedChildren={<span className="font-bold">Get PSN</span>}
              checked={!!values.autoGeneratePSN}
              loading={fetchingPSN}
              onChange={(checked) => {
                formik.setFieldValue("autoGeneratePSN", checked);
                if (!checked) {
                  formik.setFieldValue("pledgeSheetNumber", null);
                }
              }}
            />
          </div>
        ) : undefined}
      </FormNumberField>

      <FormDateField
        label="Pledge Date"
        name="contractStartDate"
        placeholder="Pledge Date"
        required
        disabled={disableActiveLoanAmountFields}
        disabledDate={(date) => date && date > dayjs()}
      />

      <FormRadioField
        optionType="button"
        buttonStyle="solid"
        label="Item Type"
        name="itemType"
        options={ITEM_TYPE_OPTIONS}
        required
      />

      <FormTextField
        label="Item Name"
        name="itemName"
        placeholder="Describe the item, eg: Kolusu, Ring"
        required
      />

      <FormNumberField
        addonAfter="grams"
        label="Item Weight"
        name="itemWeight"
        placeholder="0.955"
        precision={3}
        required
      />

      <FormPhotoInput
        label="Item Photo"
        name="itemPhotoDocId"
        documentField="itemPhotoDoc"
        documentType="LOAN_ITEM"
        customerId={customerId}
        linkToLoanId={loanId}
        filename={getLoanDocumentName(
          customerName || "",
          "LOAN_ITEM",
          values.pledgeSheetNumber ?? 0,
          values.itemType?.toString() ?? ""
        )}
        disabled={!customerId || !values.itemType || !values.pledgeSheetNumber}
      />

      <FormMoneyField required label="Estimated Value" name="estimatedValue" placeholder="1,000" />

      <FormMoneyField
        required
        label="Loan Value Disbursed"
        disabled={disableActiveLoanAmountFields}
        name="loanAmount"
        placeholder="5,000"
      />

      <FormRadioField
        optionType="button"
        buttonStyle="solid"
        label="Interest Rate %"
        name="interestRate"
        className="flex-grow-0 mr-3"
        options={INTEREST_RATE_OPTIONS}
        valueParser={(value) => (customRate ? CUSTOM_INTEREST_RATE_OPTION_VALUE : value)}
        required
        onChange={(event) =>
          setCustomRate(event.target.value === CUSTOM_INTEREST_RATE_OPTION_VALUE)
        }
        disabled={disableActiveLoanAmountFields}
      >
        {customRate ? (
          <InputNumber
            size="large"
            required
            addonAfter="%"
            className="custom-rate w-24"
            type="number"
            controls={false}
            precision={2}
            step={0.000000000000000001}
            value={values.interestRate}
            onChange={(value: number | null) => {
              formik.setFieldValue("interestRate", value);
            }}
            disabled={disableActiveLoanAmountFields}
          />
        ) : undefined}
      </FormRadioField>

      <LoanAmountForm
        values={values}
        edit={edit}
        hideLoanAmountField
        disabled={disableActiveLoanAmountFields}
      />
    </>
  );
}

export const LoanForm: React.ComponentType<LoanFormProps> = connect<LoanFormProps, LoanFormData>(
  _LoanForm
);

export type CloseLoanFormData = {
  closingDate: string | null | Dayjs;
  finalInterestAmount: number | null;
  closingPayment: number | null;
};

export const CLOSE_FORM_VALIDATIONS: { [K in keyof CloseLoanFormData]: Yup.AnySchema } = {
  finalInterestAmount: Yup.number()
    .required("Final interest amount is required, enter 0 if no interest.")
    .nullable(true),
  closingDate: Yup.date().required("Loan Closing Date is required").nullable(),
  closingPayment: Yup.number().nullable().required("Closing payment is required"),
};

type CloseLoanFormProps = {
  loan: Omit<Loan, "customer">;
  column?: boolean;
  values: CloseLoanFormData;
  edit?: boolean;
};

function _CloseLoanForm({
  loan,
  column,
  values,
  formik,
  edit,
}: CloseLoanFormProps & {
  formik: FormikHelpers<CloseLoanFormData>;
}) {
  const closingPayment = useMemo(
    () => sum(loan.loanAmount, values.finalInterestAmount, -loan.partPaymentAmount),
    [loan.loanAmount, values.finalInterestAmount, loan.partPaymentAmount]
  );

  const includeFirstMonth = !toNumber(loan.firstMonthInterest);

  const { finalInterestAmount, loanPeriod } = useMemo(() => {
    if (!values.closingDate || !loan.loanAmount || !loan.contractStartDate || !loan.interestRate) {
      return { finalInterestAmount: null, loanPeriod: null, investorInterest: null };
    }

    // Excluding the first month.
    const months =
      dayjs(values.closingDate).subtract(1, "D").diff(dayjs(loan.contractStartDate), "months") +
      (includeFirstMonth ? 1 : 0);

    const finalInterestAmount = roundToNearest5(loan.loanAmount * loan.interestRate * months);
    const investorInterest = roundToNearest5(loan.loanAmount * INVESTOR_INTEREST_RATE * months);

    return { finalInterestAmount, loanPeriod: `${months} months`, investorInterest };
  }, [
    loan.loanAmount,
    loan.interestRate,
    loan.contractStartDate,
    values.closingDate,
    includeFirstMonth,
  ]);

  useEffect(() => {
    formik.setFieldValue("closingPayment", closingPayment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closingPayment]);

  useEffect(() => {
    if (edit) {
      return;
    }

    formik.setFieldValue("finalInterestAmount", finalInterestAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, finalInterestAmount]);

  const contractStartDate = dayjs(loan.contractStartDate).startOf("day");
  return (
    <>
      <FormDateField
        label="Closing Date"
        column={column}
        name="closingDate"
        placeholder="Return Date"
        required
        disabledDate={(date) => date < contractStartDate || date > dayjs()}
      />

      <FormValue
        label="Calculated Closing Interest"
        name="closingInterest"
        value={
          loanPeriod && (
            <div>
              Calculated closing interest for loan period of{" "}
              <span className="font-bold text-pink-600">{loanPeriod}</span>{" "}
              <span className="italic">
                ({includeFirstMonth ? "including" : "excluding"} first month)
              </span>{" "}
              is{" "}
              <span className="font-bold text-lg text-pink-700">
                {renderCurrencyZeroPaise(finalInterestAmount)} ({inrToWords(finalInterestAmount)})
              </span>
              .
            </div>
          )
        }
        column={column}
      />
      <Divider className="my-2" />

      <FormMoneyField
        label="Collected Closing Interest"
        column={column}
        // helper={
        //   <div className="flex flex-col">
        //     {!!finalInterestAmount && (
        //       <div className="text-gray-600">
        //         Calculated Interest:
        //         <span className="text-pink-600">
        //           {renderCurrencyZeroPaise(finalInterestAmount)}
        //         </span>
        //       </div>
        //     )}
        //   </div>
        // }
        name="finalInterestAmount"
        placeholder="1000"
        required
      />
      <FormValue
        required
        label="Collected Cash Amount"
        name="closingPayment"
        column={column}
        helper={
          <>
            Loan Amount + Closing Interest - Part Payments{" "}
            <div className="text-base font-medium text-red-500">
              Ensure you have collected below cash amount from the customer before closing the loan.
            </div>
          </>
        }
        value={
          <>
            <div className="text-2xl mt-2 p-0.5 font-bold text-center">
              {renderCurrencyZeroPaise(values.closingPayment)}
              {values.closingPayment && (
                <div className="font-semibold text-base text-gray-500">
                  {inrToWords(values.closingPayment)}
                </div>
              )}
            </div>
          </>
        }
      />
    </>
  );
}

export const CloseLoanForm: React.ComponentType<CloseLoanFormProps> = connect<
  CloseLoanFormProps,
  CloseLoanFormData
>(_CloseLoanForm);
