import React from "react";
import { Card, Statistic } from "antd";
import clsx from "clsx";
import { gql, useQuery } from "urql";

import { ITEM_TYPES } from "../../constants/loan";
import type { AccountCategoryEnum, ItemType } from "../../gql/graphql";
import usePermissions from "../../hooks/api/usePermissions";
import { formatCurrency, formatMoneyHumanReadable } from "../../utils/currency";
import { formatDate } from "../../utils/date";
import { formatNumber } from "../../utils/number";
import { ACCOUNTS_CATEGORY_FRAGMENT } from "./AccountCategoryCard";
import AccountsDashboard from "./AccountsDashboard";
import CustomersWidget from "./CustomerWidgets";
import LoansWidget from "./LoansWidget";
import PledgeItemCard, { PledgeItem } from "./PledgeItemCard";

const MAIN_DASHBOARD_CATEGORIES: AccountCategoryEnum[] = ["ASSET_CASH"];

export const DASHBOARD_QUERY: DashboardDataQueryDoc = gql`
  query DashboardData {
    dashboard {
      id
      loanStats {
        totalOutstanding
        openLoansCount
        oldestActiveLoan {
          id
          contractStartDate
        }
        latestActiveLoan {
          id
          contractStartDate
        }
        nextPsn
      }
      pledgeItems {
        id
        itemType
        count
        weightInGrams
        ratePerGram
      }
      accounts {
        categories(categories: ASSET_CASH) {
          ...AccountCategory
        }
      }
    }
  }
  ${ACCOUNTS_CATEGORY_FRAGMENT}
`;

function Widget({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <div className={clsx("flex items-stretch w-full pr-2 py-6")}>
      <Card
        className="rounded-xl w-full"
        bodyStyle={{ padding: 0 }}
        title={
          <div className="text-lg text-center font-bold text-gray-600 whitespace-normal">
            {title}
          </div>
        }
      >
        {children}
      </Card>
    </div>
  );
}

function MainCards() {
  const [{ data, fetching }] = useQuery({
    query: DASHBOARD_QUERY,
    requestPolicy: "cache-and-network",
  });
  const { hasPerm } = usePermissions();

  const totalOutstanding = data?.dashboard?.loanStats?.totalOutstanding;

  const pledgeItems = (data?.dashboard?.pledgeItems || []).reduce((hash, item) => {
    hash[item.itemType] = item;
    return hash;
  }, {} as { [K in ItemType]: PledgeItem });

  const cardContainerCls = "flex items-stretch w-full pr-2 pb-2";
  const statsCls = "mr-2 mb-2 sm:mr-3";

  return (
    <div className="flex flex-wrap w-full items-stretch">
      {hasPerm("VIEW_RATE") &&
        ITEM_TYPES.map((itemType) => (
          <PledgeItemCard
            key={itemType}
            itemType={itemType}
            pledgeItem={pledgeItems[itemType]}
            loading={fetching}
            className={`${cardContainerCls} md:w-1/2 lg:w-1/3`}
          />
        ))}

      {hasPerm("VIEW_ACCOUNT") && (
        <AccountsDashboard
          loading={fetching}
          data={data?.dashboard?.accounts}
          className={`${cardContainerCls} md:w-1/2 lg:w-1/4`}
          categoriesToShow={MAIN_DASHBOARD_CATEGORIES}
        />
      )}

      {hasPerm("VIEW_DASHBOARD") && (
        <>
          <div className={clsx(cardContainerCls, "md:w-1/2 lg:w-1/3")}>
            <Card className="rounded-xl w-full bg-purple-50">
              <div className="flex flex-wrap justify-between">
                <Statistic
                  loading={fetching}
                  title="Total Outstanding"
                  value={formatCurrency(totalOutstanding, 0)}
                  className={statsCls}
                />
                <Statistic
                  loading={fetching}
                  title="Total Outstanding Abbr."
                  valueRender={() => formatMoneyHumanReadable(totalOutstanding)}
                  className={statsCls}
                />
                <Statistic
                  loading={fetching}
                  title="Active Loans Count"
                  value={formatNumber(data?.dashboard?.loanStats?.openLoansCount || 0)}
                  className={statsCls}
                />
              </div>
            </Card>
          </div>

          <div className={clsx(cardContainerCls, "md:w-1/2 lg:w-1/3")}>
            <Card className="rounded-xl w-full bg-indigo-50">
              <div className="flex flex-wrap justify-between">
                <Statistic
                  loading={fetching}
                  title="Oldest Loan Contract Date"
                  value={formatDate(
                    data?.dashboard?.loanStats?.oldestActiveLoan?.contractStartDate
                  )}
                  className={statsCls}
                />
                <Statistic
                  loading={fetching}
                  title="Latest Loan Contract Date"
                  valueRender={() =>
                    formatDate(data?.dashboard?.loanStats?.latestActiveLoan?.contractStartDate)
                  }
                  className={statsCls}
                />
                <Statistic
                  loading={fetching}
                  title="Next PSN"
                  valueRender={() => <>{data?.dashboard?.loanStats?.nextPsn}</>}
                  className={statsCls}
                />
              </div>
            </Card>
          </div>
        </>
      )}

      <Widget title="Recently Opened Loans">
        <LoansWidget
          variables={{ status: "ACTIVE", order: ["CONTRACT_START_DATE_DESC", "ID_DESC"] }}
          additionalColumns={["firstMonthInterest", "processingFees"]}
        />
      </Widget>

      <Widget title="Recently Closed Loans">
        <LoansWidget
          variables={{ status: "CLOSED", order: ["CLOSING_DATE_DESC"] }}
          additionalColumns={["closedOn", "bookedProfit", "annualizedRateOfReturn"]}
        />
      </Widget>

      <Widget title="New Customers">
        <CustomersWidget variables={{ order: "DATE_JOINED_DESC", activeLoansCountMin: 1 }} />
      </Widget>

      <Widget title="Top Outstanding Loan Customers">
        <CustomersWidget variables={{ order: "OUTSTANDING_AMOUNT_DESC" }} />
      </Widget>

      <Widget title="Top Accured Interest Loans">
        <LoansWidget
          variables={{ status: "ACTIVE", order: ["ACCURED_INTEREST_DESC"] }}
          additionalColumns={["pendingInterest"]}
        />
      </Widget>

      <Widget title="Recent Part Payments">
        <LoansWidget
          variables={{
            status: "ACTIVE",
            order: ["LAST_PART_PAYMENT_DESC"],
            partPaymentAmountMin: 1,
          }}
          additionalColumns={["partPaymentAmount"]}
        />
      </Widget>

      <Widget title="Top Repaid Customers">
        <CustomersWidget
          variables={{ order: "REPAID_AMOUNT_DESC", closedLoansCountMin: 1 }}
          additionalColumns={["CLOSED_LOANS"]}
        />
      </Widget>
    </div>
  );
}

export default function Dashboard() {
  return (
    <div className="w-full py-2 pb-24">
      <MainCards />
    </div>
  );
}
