import { Divider } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { FormikHelpers } from "formik";
import { gql, useMutation } from "urql";
import * as Yup from "yup";

import { refetchTransactions } from "../../../hooks/api/accounts/useAccountTransactionsSearch";
import useError from "../../../hooks/useError";
import { trackEvent } from "../../../utils/analytics";
import { getAccountDataForAnalytics } from "../../../utils/analytics/data";
import { formatCurrency } from "../../../utils/currency";
import { getPlaceholderDate, toServerDateNonNull } from "../../../utils/date";
import Form from "../../form/Form";
import FormAccountSearchField from "../../form/FormAccountSearchField";
import { FormButtons } from "../../form/FormButtons";
import FormDateField from "../../form/FormDateField";
import FormMoneyField from "../../form/FormMoneyField";
import FormTextArea from "../../form/FormTextArea";

const REINVEST_COMMISSIONS_MUTATION: ReinvestCommissionsMutationDoc = gql`
  mutation ReinvestCommissions(
    $investorAccountId: Int!
    $amount: Decimal!
    $date: Date!
    $description: String!
  ) {
    reinvestCommisions(
      investorAccountId: $investorAccountId
      amount: $amount
      date: $date
      description: $description
    ) {
      success
      failureMessage
      transactions {
        id
        legs {
          id
          account {
            id
            currentBalance
          }
        }
      }
    }
  }
`;

type FormData = {
  investorAccountId: number | null;
  investorAccount?: AccountDetailsFragment;

  amount: number | null;
  date: string | Dayjs;
  description: string;
};

type FormProps = {
  account: AccountDetailsFragment;
  onClose: (done?: boolean) => void;
};

export default function ReinvestCommissions({ account, onClose }: FormProps) {
  const [, record] = useMutation(REINVEST_COMMISSIONS_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    if (!values.amount) {
      formikActions.setSubmitting(false);
      return;
    }

    return record({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      investorAccountId: values.investorAccountId!,
      amount: values.amount,
      date: toServerDateNonNull(values.date),
      description: values.description,
    })
      .then(async (response) => {
        if (
          response.data?.reinvestCommisions?.success &&
          response.data.reinvestCommisions.transactions
        ) {
          trackEvent({ action: "Reinvest Commissions" }, getAccountDataForAnalytics(account));
          refetchTransactions(account.id);
          onClose(true);
        } else {
          setError({
            customError: response.data?.reinvestCommisions?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to reinvest commissions, please try again!" });
      })
      .finally(() => {
        formikActions.setSubmitting(false);
      });
  }

  const formValidations: {
    [K in keyof FormData]: Yup.AnySchema;
  } = {
    investorAccountId: Yup.number()
      .nullable()
      .required("Liability account to reinvest is required."),
    amount: Yup.number()
      .nullable()
      .required("Amount is required.")
      .min(1, "Amount should be greater than 1.")
      .max(
        account.currentBalance,
        `Amount can't be greater than the commissions balance - ${formatCurrency(
          account.currentBalance,
          0
        )}`
      ),
    description: Yup.string().nullable().required("Description is required."),
    date: Yup.date().nullable().required("Date is required"),
  };

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{
          investorAccountId: null,
          amount: null,
          description: "",
          date: dayjs().startOf("day"),
        }}
        validationSchema={formValidations}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormAccountSearchField
                name="investorAccountId"
                accountField="investorAccount"
                label="Investor account"
                column
                helper="Select the account to which the commissions amount is being reinvested."
                placeholder="Select an account"
                excludeAccountIds={[account.id]}
                accountsSearchProps={{ types: "LIABILITY" }}
              />

              <FormDateField
                label="Record Date"
                column
                name="date"
                placeholder={getPlaceholderDate()}
                required
                disabledDate={(date) => date < dayjs().subtract(1, "month") || date > dayjs()}
              />

              <FormMoneyField column label="Amount" name="amount" placeholder="1,000" required />

              <FormTextArea
                column
                required
                label="Description"
                name="description"
                placeholder="Note about the reinvestment."
              />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
