import { useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import { gql, useQuery } from "urql";

import useLoanMetrics, { LoanMetricsProps, LoanMetricsResult } from "./useLoanMetrics";

const PROFIT_FRAGMENT: ProfitFragmentDoc = gql`
  fragment Profit on LoanMetric {
    id
    metricDate
    itemType
    realizedProfits
    investorInterestAmount
    commissionsAmount
  }
`;

const PROFITS_QUERY: ProfitsQueryDoc = gql`
  query Profits(
    $startDate: Date
    $endDate: Date
    $itemType: GoldLoansLoanMetricItemTypeChoices
    $profits: Decimal
  ) {
    loanMetrics(
      metricDate_Gte: $startDate
      metricDate_Lte: $endDate
      itemType: $itemType
      realizedProfits_Gt: $profits
    ) {
      edges {
        node {
          ...Profit
        }
      }
    }
  }
  ${PROFIT_FRAGMENT}
`;

export type Profit = Omit<ProfitFragment, "metricDate"> & { metricDate: Dayjs };

export type ProfitsResult = LoanMetricsResult & {
  profits: Profit[];
  loading: boolean;
};

type Props = LoanMetricsProps;

export default function useProfits(props: Props): ProfitsResult {
  const loanMetrics = useLoanMetrics(props);

  const variables = useMemo(() => {
    return { ...loanMetrics.variables, profits: 0 };
  }, [loanMetrics.variables]);

  const [{ data, fetching }] = useQuery({
    query: PROFITS_QUERY,
    variables,
    requestPolicy: "cache-and-network",
  });

  const profits = useMemo(() => {
    return data?.loanMetrics?.edges
      ? data.loanMetrics.edges
          .filter((edge) => !!edge?.node)
          .map((edge) => edge?.node)
          .map((node) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const profit = node!;
            return {
              ...profit,

              realizedProfits: parseFloat(`${profit.realizedProfits}`),
              investorInterestAmount: parseFloat(`${profit.investorInterestAmount}`),
              commissionsAmount: parseFloat(`${profit.commissionsAmount}`),

              metricDate: dayjs(profit.metricDate),
            };
          })
      : [];
  }, [data]);

  return { ...loanMetrics, profits, loading: fetching };
}
