import { useHistory } from "react-router";
import { gql } from "@urql/core";
import { message } from "antd";
import { FormikHelpers } from "formik";
import { useMutation } from "urql";

import { CUSTOMER_DETAILS_FRAGMENT } from "../../hooks/api/customers/useCustomer";
import useError from "../../hooks/useError";
import { trackEvent } from "../../utils/analytics";
import CustomerForm, { cleanFormValues, FormData } from "./CustomerForm";

const ADD_CUSTOMER_MUTATION: AddCustomerMutationDoc = gql`
  mutation AddCustomer(
    $name: String!
    $address: String!
    $gender: Gender!
    $phone: String
    $occupation: String
    $careOfName: String
    $careOfType: String
    $photoDocId: Int
    $photoIdProofDocId: Int
    $isRepledgeCustomer: Boolean!
  ) {
    addCustomer(
      phone: $phone
      name: $name
      address: $address
      gender: $gender
      occupation: $occupation
      careOfName: $careOfName
      careOfType: $careOfType
      photoDocId: $photoDocId
      photoIdProofDocId: $photoIdProofDocId
      isRepledgeCustomer: $isRepledgeCustomer
    ) {
      success
      failureMessage
      customer {
        ...CustomerDetails
      }
    }
  }
  ${CUSTOMER_DETAILS_FRAGMENT}
`;

function AddCustomerForm() {
  const history = useHistory();
  const [, addCustomer] = useMutation(ADD_CUSTOMER_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return addCustomer(cleanFormValues(values))
      .then((response) => {
        if (response.data?.addCustomer?.success && response.data?.addCustomer?.customer) {
          const customer = response.data?.addCustomer?.customer;
          message.success("Successfully created customer.");
          trackEvent({ action: "Add Customer" });
          history.push(`/customers/${customer.id}`);
        } else {
          setError({
            customError: response.data?.addCustomer?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to add customer, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <CustomerForm
      initialValues={{
        name: "",
        phone: "",
        gender: "M",
        address: "",
        occupation: "",
        careOfName: "",
        careOfType: "",
        isRepledgeCustomer: false,
      }}
      onSubmit={onSubmit}
      error={error}
    />
  );
}

export default function AddCustomer() {
  return (
    <div className="w-full justify-center flex py-4">
      <AddCustomerForm />
    </div>
  );
}
