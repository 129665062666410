import { Empty, message, Modal } from "antd";

import { AccountActionResult } from "../../../hooks/useAccountAction";
import { camel2title } from "../../../utils/common";
import CloseIcon from "../../common/CloseIcon";
import CreateAccount from "./account/CreateAccount";
import EditAccount from "./account/EditAccount";
import InvestorOperations from "./InvestorOperations";
import RecordExpense from "./RecordExpense";
import RecordIncome from "./RecordIncome";
import ReinvestCommissions from "./ReinvestCommissions";
import TransferAmount from "./TransferAmount";
import DisburseLoan from "./unsecured-loans/DisburseLoan";
import RecordLoanPayment from "./unsecured-loans/RecordLoanPayment";

type Props = AccountActionResult;

export default function AccountFormModal(props: Props) {
  const { action, account, closeForm, showForm } = props;

  function onClose(msg?: string) {
    if (msg) {
      message.success(msg);
    }
    closeForm();
  }

  function renderAccountForm() {
    if (account) {
      switch (action) {
        case "edit":
          return (
            <EditAccount
              account={account}
              onClose={(done) => onClose(done ? "Successfully edited the account." : undefined)}
            />
          );
        case "recordExpense":
          return (
            <RecordExpense
              account={account}
              onClose={(done) => onClose(done ? "Successfully recorded the expense." : undefined)}
              column
            />
          );
        case "recordIncome":
          return (
            <RecordIncome
              account={account}
              onClose={(done) => onClose(done ? "Successfully recorded the income." : undefined)}
            />
          );
        case "deposit":
        case "withdraw":
          return (
            <InvestorOperations
              operation={action === "deposit" ? "DEPOSIT" : "WITHDRAW"}
              account={account}
              onClose={(done) =>
                onClose(done ? `Successfully performed the ${action}.` : undefined)
              }
            />
          );
        case "transferAmount":
          return (
            <TransferAmount
              account={account}
              onClose={(done) => onClose(done ? "Successfully transfered the amount." : undefined)}
            />
          );
        case "disburseLoan":
          return (
            <DisburseLoan
              account={account}
              onClose={(done) => onClose(done ? "Successfully disbursed the loan." : undefined)}
            />
          );
        case "recordLoanPayment":
          return (
            <RecordLoanPayment
              account={account}
              onClose={(done) =>
                onClose(done ? "Successfully recorded the loan payment." : undefined)
              }
            />
          );
        case "reinvestCommissions":
          return (
            <ReinvestCommissions
              account={account}
              onClose={(done) =>
                onClose(done ? "Successfully reinvested the commissions." : undefined)
              }
            />
          );
      }
    } else {
      switch (action) {
        case "create":
          return (
            <CreateAccount
              onClose={(done) => onClose(done ? "Successfully added the account." : undefined)}
            />
          );
      }
    }

    return <Empty description={`${action && camel2title(action)} not yet implemented!!`} />;
  }

  function getTitle() {
    switch (action) {
      case "recordExpense":
        return `RECORD ${account?.name.toUpperCase()} EXPENSE`;
      case "recordIncome":
        return `RECORD EXTRA INCOME`;
      case "reinvestCommissions":
        return "REINVEST COMMISSIONS";
      case "deposit":
        return `DEPOSIT INTO ${account?.name.toUpperCase()}`;
      case "withdraw":
        return `WIHTDRAW FROM ${account?.name.toUpperCase()}`;
      case "transferAmount":
        return `TRANSFER AMOUNT FROM ${account?.name.toUpperCase()} ACCOUNT`;
      case "disburseLoan":
        return `RECORD LOAN DISBURSAL FOR ${account?.name.toUpperCase()}`;
      case "recordLoanPayment":
        return `RECORD LOAN PAYMENT FOR ${account?.name.toUpperCase()}`;
      case "edit":
      case "create":
        return `${action.toUpperCase()} ACCOUNT`;
      default:
        return "UNDER CONSTRUCTION";
    }
  }

  return showForm && action ? (
    <Modal
      title={<h4 className="pb-3 font-bold text-2xl text-center border-b">{getTitle()}</h4>}
      centered
      closable
      maskClosable
      open
      closeIcon={<CloseIcon />}
      footer={false}
      onCancel={() => closeForm()}
    >
      {renderAccountForm()}
    </Modal>
  ) : null;
}
