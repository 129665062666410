/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React from "react";
import { Form as FormikForm, Formik, FormikConfig, FormikValues } from "formik";
import * as Yup from "yup";

import FormError from "./FormError";

export const isFunction = (obj: unknown): obj is Function => typeof obj === "function";
export const isEmptyChildren = (children: unknown): boolean => React.Children.count(children) === 0;

interface Props<T> extends FormikConfig<T> {
  validationSchema?: { [K in keyof FormikValues]: any };
}

export default function Form<T>(props: Props<T> & { error?: string }) {
  const { validationSchema, onSubmit, ...rest } = props;
  const schema = validationSchema ? Yup.object().shape(validationSchema) : undefined;

  return (
    <Formik<any> {...rest} onSubmit={onSubmit} validationSchema={schema}>
      {(formikProps) => {
        const { component, render, children, error } = props;
        return (
          <FormikForm className="flex flex-col w-full">
            {component
              ? React.createElement(component as any, props)
              : render
              ? render(formikProps)
              : children // children come last, always called
              ? isFunction(children)
                ? children(formikProps)
                : !isEmptyChildren(children)
                ? React.Children.only(children)
                : null
              : null}

            <FormError error={error} errors={formikProps.errors} touched={formikProps.touched} />
          </FormikForm>
        );
      }}
    </Formik>
  );
}
