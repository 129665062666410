import { useCallback, useState } from "react";

import { Account } from "./api/accounts/useAccount";

export type AccountAction =
  | "create"
  | "edit"
  | "recordExpense"
  | "deposit"
  | "withdraw"
  | "transferAmount"
  | "disburseLoan"
  | "recordLoanPayment"
  | "recordIncome"
  | "reinvestCommissions";

type Selection = {
  account?: Account;
  action?: AccountAction;
};

export type AccountActionResult = Selection & {
  openForm: (selection: Selection) => void;
  closeForm: () => void;

  showForm: boolean;
};

export default function useAccountAction(): AccountActionResult {
  const [selection, setSelection] = useState<Selection>();
  const [showForm, setShowForm] = useState<boolean>(false);

  const openForm = useCallback((selection: Selection) => {
    setSelection({ ...selection });
    setShowForm(true);
  }, []);

  const closeForm = useCallback(() => {
    setShowForm(false);
    setSelection(undefined);
  }, []);

  return {
    showForm,

    openForm,
    closeForm,
    ...selection,
  };
}
