import { Divider } from "antd";
import { FormikHelpers } from "formik";
import { gql, useMutation } from "urql";
import * as Yup from "yup";

import { Account, ACCOUNT_DETAILS_FRAGMENT } from "../../../../hooks/api/accounts/useAccount";
import useError from "../../../../hooks/useError";
import { trackEvent } from "../../../../utils/analytics";
import { getAccountDataForAnalytics } from "../../../../utils/analytics/data";
import Form from "../../../form/Form";
import { FormButtons } from "../../../form/FormButtons";
import { AccountForm, AccountFormData } from "./AccountForm";

const EDIT_ACCOUNT_MUTATION: EditAccountMutationDoc = gql`
  mutation EditAccount($accountId: Int!, $name: String!, $verboseName: String) {
    editAccount(accountId: $accountId, name: $name, verboseName: $verboseName) {
      success
      failureMessage
      account {
        ...AccountDetails
        name
        verboseName
      }
    }
  }
  ${ACCOUNT_DETAILS_FRAGMENT}
`;

type FormData = AccountFormData;

type FormProps = {
  account: Account;
  onClose: (done?: boolean) => void;
};

export default function EditAccount({ account, onClose }: FormProps) {
  const [, edit] = useMutation(EDIT_ACCOUNT_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return edit({
      accountId: account.id,
      name: values.name,
      verboseName: values.verboseName,
    })
      .then((response) => {
        if (response.data?.editAccount?.success && response.data.editAccount.account) {
          trackEvent(
            { action: "Edit Account" },
            getAccountDataForAnalytics(response.data.editAccount.account)
          );

          onClose(true);
        } else {
          setError({
            customError: response.data?.editAccount?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to edit account, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{
          name: account.name,
          verboseName: account.verboseName,
          type: null,
        }}
        validationSchema={{
          name: Yup.string().nullable().required("Account name is required."),
          verboseName: Yup.string().nullable().optional(),
        }}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <AccountForm edit />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
