import { Card, Statistic } from "antd";
import clsx from "clsx";

import type { ItemType } from "../../types/enum";
import { formatCurrency } from "../../utils/currency";
import { formatNumber } from "../../utils/number";
import UpdateRate from "./forms/UpdateRateForm";

export type PledgeItem = DashboardDataQuery["dashboard"]["pledgeItems"][0];

export default function PledgeItemCard({
  itemType,
  pledgeItem,
  className,
  loading,
}: {
  itemType: ItemType;
  pledgeItem?: PledgeItem;
  className?: string;
  loading: boolean;
}) {
  return (
    <div key={itemType} className={className}>
      <Card
        className={clsx(
          "rounded-xl w-full",
          itemType === "GOLD"
            ? "bg-yellow-50"
            : itemType === "SILVER"
            ? "bg-gray-50"
            : "bg-yellow-100"
        )}
        title={
          <div
            className={clsx(
              "text-2xl font-bold w-full text-center",
              itemType === "GOLD"
                ? "text-yellow-500"
                : itemType === "SILVER"
                ? "text-gray-600"
                : "text-yellow-900"
            )}
          >
            {itemType}
            <UpdateRate metal={itemType} currentRate={pledgeItem?.ratePerGram || 0} />
          </div>
        }
      >
        <div className="flex flex-wrap justify-between">
          <Statistic
            loading={loading}
            title="Count"
            value={pledgeItem?.count}
            className="mr-2 mb-2 sm:mr-3"
          />
          <Statistic
            loading={loading}
            title="Weight (kg)"
            value={pledgeItem ? formatNumber(pledgeItem?.weightInGrams / 1000) : ""}
            suffix=" kg"
            className="mr-2 mb-2 sm:mr-3"
          />
          <Statistic
            loading={loading}
            title="Rate per gram"
            value={formatCurrency(pledgeItem?.ratePerGram)}
            className="mr-2 mb-2 sm:mr-3"
          />
        </div>
      </Card>
    </div>
  );
}
