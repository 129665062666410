import {
  LaptopOutlined,
  PlusOutlined,
  RetweetOutlined,
  SwapOutlined,
  WalletOutlined,
} from "@ant-design/icons";

import { OperationAccountCodes } from "../../constants/accounts";
import { AccountActionResult } from "../../hooks/useAccountAction";
import { DepositIcon } from "../../icons/Deposit";
import { DisburseLoanIcon } from "../../icons/DisburseLoan";
import { WithdrawalIcon } from "../../icons/Withdrawal";
import { zeroBalance } from "../../utils/account";
import ActionButton from "../common/ActionButton";

export default function AccountActions({
  account,
  openForm,
}: { account: AccountDetailsFragment } & AccountActionResult) {
  switch (account.type) {
    case "EXPENSE":
      return (
        <ActionButton
          cta="Record Expense"
          icon={<WalletOutlined />}
          onClick={() => openForm({ action: "recordExpense", account })}
          perm="RECORD_EXPENSE"
        />
      );

    case "EQUITY":
    case "LIABILITY":
      return (
        <>
          <ActionButton
            cta="Transfer"
            icon={<SwapOutlined />}
            onClick={() => openForm({ action: "transferAmount", account })}
            perm="IS_SUPERUSER"
          />
          <ActionButton
            cta="Deposit"
            icon={<DepositIcon className="text-2xl" />}
            onClick={() => openForm({ action: "deposit", account })}
            perm="HANDLE_INVESTORS"
          />
          <ActionButton
            cta="Withdraw"
            icon={<WithdrawalIcon className="text-2xl" />}
            onClick={() => openForm({ action: "withdraw", account })}
            perm="HANDLE_INVESTORS"
          />
        </>
      );

    case "ASSET":
      switch (account.subtype) {
        case "BANK":
        case "CASH":
          return (
            <ActionButton
              cta="Transfer Money"
              icon={<SwapOutlined />}
              onClick={() => openForm({ action: "transferAmount", account })}
              perm="TRANSFER_AMOUNT"
            />
          );
        case "EMPLOYEE_PERSONAL":
          return (
            <>
              <ActionButton
                cta="Deposit"
                icon={<DepositIcon className="text-2xl" />}
                onClick={() => openForm({ action: "deposit", account })}
                perm="EDIT_ACCOUNT"
              />
              <ActionButton
                cta="Withdraw"
                icon={<WithdrawalIcon className="text-2xl" />}
                onClick={() => openForm({ action: "withdraw", account })}
                perm="EDIT_ACCOUNT"
              />
            </>
          );
        case "UNSECURED_LOAN":
          return (
            <>
              <ActionButton
                cta="Disburse Loan"
                icon={<DisburseLoanIcon />}
                onClick={() => openForm({ action: "disburseLoan", account })}
                perm="EDIT_LOAN"
              />
              <ActionButton
                cta="Record Payment"
                icon={<PlusOutlined />}
                disabled={zeroBalance(account)}
                onClick={() => openForm({ action: "recordLoanPayment", account })}
                perm="EDIT_LOAN"
              />
            </>
          );
        case "PROPERTY":
          return (
            <ActionButton
              cta="Record Purchase"
              icon={<LaptopOutlined />}
              onClick={() => openForm({ action: "recordExpense", account })}
              perm="RECORD_EXPENSE"
            />
          );

        default:
          return <></>;
      }

    case "INCOME":
      return (
        <>
          {account.code === OperationAccountCodes.OTHER_INCOME ? (
            <ActionButton
              cta="Record Income"
              icon={<WalletOutlined />}
              onClick={() => openForm({ action: "recordIncome", account })}
              perm="RECORD_INCOME"
            />
          ) : account.code === OperationAccountCodes.COMMISSIONS ? (
            <ActionButton
              cta="Reinvest Commissions"
              icon={<RetweetOutlined />}
              onClick={() => openForm({ action: "reinvestCommissions", account })}
              perm="HANDLE_INVESTORS"
            />
          ) : (
            <></>
          )}
        </>
      );

    default:
      return <></>;
  }
}
