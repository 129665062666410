import React, { useCallback, useState } from "react";
import { Modal } from "antd";

import { Variables } from "../../../hooks/api/loans/useLoansSearch";
import useMobile from "../../../hooks/useMobile";
import CloseIcon from "../../common/CloseIcon";
import { Loans } from "./LoansSearch";

type Props = {
  filters: Partial<Variables>;
  onClose: (done?: boolean) => void;
};

export default function LoansSearchModal({ filters, onClose }: Props) {
  const { isMobile } = useMobile();

  return (
    <Modal
      title={false}
      centered
      closable
      maskClosable
      open
      closeIcon={<CloseIcon />}
      footer={false}
      width={isMobile ? "100%" : "95%"}
      onCancel={() => onClose()}
      style={{ marginTop: 16 }}
    >
      <Loans filters={filters} inlineMode />
    </Modal>
  );
}

export function useLoansSearchModal() {
  const [showLoansSearch, setLoansSearch] = useState<boolean>();
  const [filters, setFilters] = useState<Partial<Variables>>({});

  const openLoansSearch = useCallback((filters: Partial<Variables>) => {
    setFilters(filters);
    setLoansSearch(true);
  }, []);

  const closeLoansSearch = useCallback(() => {
    setLoansSearch(false);
  }, []);

  return {
    openLoansSearch,
    closeLoansSearch,
    showLoansSearch,
    filters,
  };
}
