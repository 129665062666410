import { useMemo } from "react";
import { DeleteFilled, EditFilled, PlusCircleFilled, PrinterFilled } from "@ant-design/icons";
import { Typography } from "antd";
import dayjs from "dayjs";

import { Loan } from "../../hooks/api/loans/useLoan";
import usePaymentAction from "../../hooks/usePaymentAction";
import { usePrint } from "../../hooks/usePrint";
import { formatCurrency } from "../../utils/currency";
import { formatDate } from "../../utils/date";
import { getPaymentInvoiceTitle } from "../../utils/loan";
import ActionButton from "../common/ActionButton";
import ActionIconButton from "../common/ActionIconButton";
import ResponsiveTable, { ResponsiveColumnType } from "../common/ResponsiveTable";
import PaymentInvoice from "../loans/invoice/PaymentInvoice";
import PaymentFormModal from "./forms/PaymentFormModal";

function PrintPayment({ payment, loan }: { payment: PaymentFragment; loan: Loan }) {
  const { loanPayment, printablePayment } = useMemo(() => {
    const loanPayment = { ...payment, loanContract: loan };
    return { loanPayment, printablePayment: <PaymentInvoice payment={loanPayment} /> };
  }, [payment, loan]);

  const { print, printing } = usePrint({ title: getPaymentInvoiceTitle(loanPayment) });

  return (
    <ActionIconButton
      icon={<PrinterFilled />}
      loading={printing}
      onClick={() => print(printablePayment)}
    />
  );
}

export default function LoanPayments({ loan }: { loan: Loan }) {
  const paymentActions = usePaymentAction(loan);
  const { openPaymentForm } = paymentActions;

  const columns = useMemo(() => {
    const columns: ResponsiveColumnType<PaymentFragment>[] = [
      {
        title: "Date",
        dataIndex: "paymentDate",
        key: "paymentDate",
        width: 150,
        fixed: "left",
        align: "left",
        render: (value) => formatDate(value),
        sorter: (a, b) => dayjs(a.paymentDate).diff(dayjs(b.paymentDate)),
        cardable: { position: "top", containerClassName: "w-1/3" },
      },
      {
        title: "Amount",
        dataIndex: "paymentAmount",
        key: "paymentDate",
        width: 150,
        render: (value) => formatCurrency(value),
        sorter: (a, b) => a.paymentAmount - b.paymentAmount,
        cardable: { position: "top", title: "Amount", containerClassName: "w-1/3" },
      },
      {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
        width: 125,
        render: (value) => (
          <Typography.Paragraph ellipsis={{ tooltip: value }}>
            {value || "---"}
          </Typography.Paragraph>
        ),
        ellipsis: true,
        cardable: { position: "bottom" },
      },
    ];
    if (loan.loanStatus === "ACTIVE") {
      columns.push({
        title: "Actions",
        key: "actions",
        dataIndex: "id",
        width: 150,
        render: (value, payment) => (
          <div className="flex space-x-2">
            <ActionIconButton
              icon={<EditFilled />}
              onClick={() => openPaymentForm({ payment, action: "edit" })}
              perm="EDIT_PAYMENT"
            />
            <ActionIconButton
              icon={<DeleteFilled />}
              onClick={() => openPaymentForm({ payment, action: "delete" })}
              perm="DELETE_PAYMENT"
            />

            <PrintPayment loan={loan} payment={payment} />
          </div>
        ),
        cardable: { position: "top", containerClassName: "w-1/3 items-end" },
      });
    }
    return columns;
  }, [loan, openPaymentForm]);

  return (
    <>
      <ResponsiveTable<PaymentFragment>
        printSettings={false}
        rowKey="id"
        scroll={{ x: 500 }}
        bordered
        title={() => (
          <div className="flex items-center justify-between">
            <div className="text-center text-xl font-bold">
              {loan.payments.length ? loan.payments.length : "NO"} PAYMENTS
            </div>
            {loan.loanStatus === "ACTIVE" && (
              <ActionButton
                cta="ADD PAYMENT"
                perm="EDIT_PAYMENT"
                onClick={() => openPaymentForm({ action: "add" })}
                icon={<PlusCircleFilled />}
              />
            )}
          </div>
        )}
        columns={columns}
        dataSource={loan.payments}
        pagination={{ hideOnSinglePage: true }}
      />

      <PaymentFormModal {...paymentActions} />
    </>
  );
}
