import { useMemo } from "react";
import { Button, Divider } from "antd";
import { FormikHelpers } from "formik";
import { gql, useMutation, useQuery } from "urql";

import useError from "../../hooks/useError";
import Form from "../form/Form";
import FormSelectField from "../form/FormSelectField";
import { STAFF_FRAGMENT, STAFF_PERMISSIONS_QUERY } from "./gql";

const EDIT_STAFF_MUTATION: EditStaffMutationDoc = gql`
  mutation EditStaff(
    $userId: Int!
    $groupIds: [Int!]!
    $permissionIds: [Int!]!
    $branchIds: [Int!]!
  ) {
    updateStaff(
      userId: $userId
      groupIds: $groupIds
      permissionIds: $permissionIds
      branchIds: $branchIds
    ) {
      success
      error
      staff {
        ...Staff
      }
    }
  }
  ${STAFF_FRAGMENT}
`;

type FormData = {
  groupIds: number[];
  permissionIds: number[];
  branchIds: number[];
};

type Props = {
  staff: StaffFragment;
  onSuccess: () => void;
};

export default function EditStaffForm({ staff, onSuccess }: Props) {
  const [{ data, fetching }] = useQuery({ query: STAFF_PERMISSIONS_QUERY });

  const { permissions, groups, branches } = useMemo(() => {
    if (data) {
      return {
        branches: (data.branches?.edges || []).map((branch) => branch?.node as BranchFragment),
        permissions: data.permissions,
        groups: data.groups,
      };
    }
    return { branches: [], permissions: [], groups: [] };
  }, [data]);

  const { error, setError, resetError } = useError();
  const [_, editStaff] = useMutation(EDIT_STAFF_MUTATION);

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return editStaff({ ...values, userId: staff.id })
      .then((response) => {
        if (response.data?.updateStaff?.success) {
          onSuccess();
        } else {
          setError({ customError: response.data?.updateStaff?.error, gqlError: response.error });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to update staff, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{
          groupIds: staff.groups.map((group) => group.id),
          permissionIds: staff.userPermissions.map((perm) => perm.id),
          branchIds: staff.branches.map((branch) => branch.id),
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormSelectField
                name="groupIds"
                label="Roles"
                column
                loading={fetching}
                mode="multiple"
                showSearch
                filterOption={(input, option) =>
                  ((option?.label || "") as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={groups.map((group) => ({ label: group.name, value: group.id }))}
                disabled={fetching}
              />

              <FormSelectField
                name="permissionIds"
                label="Permissions"
                column
                loading={fetching}
                mode="multiple"
                showSearch
                filterOption={(input, option) =>
                  ((option?.label || "") as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={permissions.map((perm) => ({ label: perm.description, value: perm.id }))}
                disabled={fetching}
              />

              <FormSelectField
                name="branchIds"
                label="Branches"
                column
                loading={fetching}
                mode="multiple"
                showSearch
                filterOption={(input, option) =>
                  ((option?.label || "") as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={branches.map((branch) => ({ label: branch.name, value: branch.id }))}
                disabled={fetching}
              />
              <Divider />

              <Button
                shape="round"
                size="large"
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>

              {error && <div className="text-red-500 text-center my-2">{error}</div>}
            </>
          );
        }}
      </Form>
    </div>
  );
}
