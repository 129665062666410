import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { Button, ConfigProvider, Result } from "antd";
import { Provider } from "urql";

import "./styles/app.css";

import AccountDetailsWithSelector from "./components/accounts/AccountDetails";
import Accounts from "./components/accounts/Accounts";
import AddExpense from "./components/accounts/AddExpense";
import InvestorInterests from "./components/accounts/investors/InvestorInterests";
import LoansInOut from "./components/accounts/loans-reconciliation/LoansInOut";
import BalanceSheet from "./components/accounts/transactions/BalanceSheet";
import DayBook from "./components/accounts/transactions/DayBook";
import OpenCloseLoans from "./components/charts/OpenCloseLoans";
import OutstandingLoansChart from "./components/charts/OutstandingLoans";
import PortfolioTimelineChart from "./components/charts/PortfolioTimeline";
import Profits from "./components/charts/Profits";
import AddCustomer from "./components/customers/AddCustomer";
import CustomerDetails from "./components/customers/CustomerDetails";
import EditCustomer from "./components/customers/EditCustomer";
import { CustomersSearch } from "./components/customers/search/CustomersSearch";
import Dashboard from "./components/dashboard/Dashboard";
import NidhiLayout from "./components/layout/Layout";
import AddLoan from "./components/loans/forms/AddLoan";
import EditLoan from "./components/loans/forms/EditLoan";
import LoanInvoicePage from "./components/loans/invoice/LoanInvoicePage";
import LoanDetailsPage from "./components/loans/LoanDetails";
import { ActiveLoans, ClosedLoans, LoansSearch } from "./components/loans/search/LoansSearch";
import Login from "./components/login/LoginPage";
import Home from "./components/pages/HomePage";
import TestPage from "./components/pages/TestPage";
import Staffs from "./components/staffs/StaffPage";
import { CurrentUserContextProvider } from "./context/CurrentUserContext";
import { graphqlClient } from "./gql/client";
import usePageTracker from "./hooks/usePageTracker";
import withLogin from "./hooks/withLogin";
import withPermission from "./hooks/withPermissions";
import { initDayJs } from "./utils/dayjs";

initDayJs();

function NotFound() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" href="/" shape="round">
          Back Home
        </Button>
      }
    />
  );
}

const PRIMARY_COLOR = "#10b981";

const App = () => {
  usePageTracker();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: PRIMARY_COLOR,
          colorLink: PRIMARY_COLOR,
          colorLinkHover: PRIMARY_COLOR,
        },
      }}
    >
      <Provider value={graphqlClient}>
        <CurrentUserContextProvider>
          <Switch>
            <Route exact path="/loans/invoice/:id" component={withLogin(LoanInvoicePage)} />

            <NidhiLayout>
              <Switch>
                {/* home, dashboard, login */}
                <Route exact path="/" component={Home} />
                {process.env.NODE_ENV === "development" && (
                  <Route exact path="/dev" component={TestPage} />
                )}

                <Route exact path="/accounts/login" component={Login} />
                <Route
                  exact
                  path="/dashboard"
                  component={withPermission(Dashboard, "VIEW_DASHBOARD")}
                />

                {/* customer */}
                <Route
                  exact
                  path="/customers/add"
                  component={withPermission(AddCustomer, "EDIT_CUSTOMER")}
                />
                <Route
                  exact
                  path="/customers/search"
                  component={withPermission(CustomersSearch, "VIEW_CUSTOMER")}
                />
                <Route
                  exact
                  path="/customers/edit/:id"
                  component={withPermission(EditCustomer, "EDIT_CUSTOMER")}
                />
                <Route
                  exact
                  path="/customers/:id"
                  component={withPermission(CustomerDetails, "VIEW_CUSTOMER")}
                />
                <Route exact path="/customers">
                  <Redirect strict exact path="/customers/" to="/customers/search" />
                </Route>

                {/* loans */}
                <Route exact path="/loans/add" component={withPermission(AddLoan, "EDIT_LOAN")} />
                <Route
                  exact
                  path="/loans/edit/:id"
                  component={withPermission(EditLoan, "EDIT_LOAN")}
                />
                <Route
                  exact
                  path="/loans/search"
                  component={withPermission(LoansSearch, "VIEW_LOAN")}
                />
                <Route
                  exact
                  path="/loans/active"
                  component={withPermission(ActiveLoans, "VIEW_LOAN")}
                />
                <Route
                  exact
                  path="/loans/closed"
                  component={withPermission(ClosedLoans, "VIEW_LOAN")}
                />
                <Route
                  exact
                  path="/loans/:id"
                  component={withPermission(LoanDetailsPage, "VIEW_LOAN")}
                />

                <Route exact path="/loans">
                  <Redirect strict exact path="/loans/" to="/loans/search" />
                </Route>

                {/* charts */}
                <Route
                  exact
                  path="/charts/profits"
                  component={withPermission(Profits, "VIEW_ACCOUNTS_PNL")}
                />
                <Route
                  exact
                  path="/charts/loans"
                  component={withPermission(OpenCloseLoans, "VIEW_ACCOUNTS_PNL")}
                />
                <Route
                  exact
                  path="/charts/portfolio"
                  component={withPermission(PortfolioTimelineChart, "VIEW_ACCOUNTS_PNL")}
                />
                <Route
                  exact
                  path="/charts/outstanding-loans"
                  component={withPermission(OutstandingLoansChart, "VIEW_ACCOUNTS_PNL")}
                />

                {/* Accounts */}
                <Route
                  exact
                  path="/accounts/loan-in-out"
                  component={withPermission(LoansInOut, "VIEW_LOAN_METRIC")}
                />
                <Route
                  exact
                  path="/accounts/day-book"
                  component={withPermission(DayBook, "VIEW_ACCOUNT")}
                />
                <Route
                  exact
                  path="/accounts/investors"
                  component={withPermission(InvestorInterests, "HANDLE_INVESTORS")}
                />
                <Route
                  exact
                  path="/accounts/balance-sheet"
                  component={withPermission(BalanceSheet, "VIEW_ACCOUNT")}
                />
                <Route
                  exact
                  path="/accounts/add-expense"
                  component={withPermission(AddExpense, "RECORD_EXPENSE")}
                />
                <Route
                  exact
                  path="/accounts/:id"
                  component={withPermission(AccountDetailsWithSelector, "VIEW_ACCOUNT")}
                />
                <Route
                  exact
                  path="/accounts"
                  component={withPermission(Accounts, "VIEW_ACCOUNT")}
                />

                {/* Staffs */}
                <Route exact path="/staffs" component={withPermission(Staffs, "IS_SUPERUSER")} />

                {/* 404 page */}
                <Route path="*" component={NotFound} />
              </Switch>
            </NidhiLayout>
          </Switch>
        </CurrentUserContextProvider>
      </Provider>
    </ConfigProvider>
  );
};

export default withRouter(App);
