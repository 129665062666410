import { useMemo, useState } from "react";
import { Button, Collapse, Divider } from "antd";
import clsx from "clsx";
import { connect, FormikHelpers } from "formik";

import { CustomersSearchResult } from "../../../hooks/api/customers/useCustomersSearch";
import { FieldPair } from "../../../types";
import { toServerDate } from "../../../utils/date";
import {
  changesCount,
  getFieldPairsValidationSchema,
  getSelectedFiltersCount,
} from "../../../utils/search";
import Form from "../../form/Form";
import FormDateRangeField from "../../form/FormDateRangeField";
import FormMoneyRangeField from "../../form/FormMoneyRangeField";
import FormNumberRangeField from "../../form/FormNumberRangeField";
import FormSelectField from "../../form/FormSelectField";
import FormSwitchField from "../../form/FormSwitchField";
import FormTextField from "../../form/FormTextField";
import { SORT_OPTIONS } from "./CustomerSort";
import SelectedFilters from "./SelectedFilters";

type FormData = Omit<CustomersSearchQueryVariables, "first" | "offset">;

const FILTER_FIELD_PAIRS: FieldPair<FormData>[] = [
  { minField: "activeLoansCountMin", maxField: "activeLoansCountMax" },
  { minField: "closedLoansCountMin", maxField: "closedLoansCountMax" },
  { minField: "daysToCloseMin", maxField: "dateJoinedMax" },
  { minField: "outstandingAmountMin", maxField: "outstandingAmountMax" },
  { minField: "repaidAmountMin", maxField: "repaidAmountMax" },
  { minField: "paidInterestMin", maxField: "paidInterestMax" },
  { minField: "dateJoinedMin", maxField: "dateJoinedMax", isDate: true },
];

const VALIDATION_SCHEMA = getFieldPairsValidationSchema<FormData>(FILTER_FIELD_PAIRS);

type Props = {
  customersSearch: CustomersSearchResult;
};

export const CustomerFiltersHeader = ({ customersSearch: { variables } }: Props) => {
  const selectedFiltersCount = useMemo(() => {
    return getSelectedFiltersCount<CustomersSearchQueryVariables>(variables);
  }, [variables]);

  return (
    <div className="flex flex-wrap items-start justify-start space-x-1 space-y-1">
      <div className="text-xl font-semibold pb-1 pr-1">
        Customer Filters {selectedFiltersCount > 0 ? `(${selectedFiltersCount})` : ""}
      </div>
      <SelectedFilters filters={variables} />
    </div>
  );
};

export default function CustomersFilters({ customersSearch }: Props) {
  const { variables, updateVariables, resetVariables } = customersSearch;
  const selectedFiltersCount = useMemo(() => {
    return getSelectedFiltersCount<CustomersSearchQueryVariables>(variables);
  }, [variables]);

  const [open, setOpen] = useState(() => selectedFiltersCount === 0);

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    formikActions.setSubmitting(true);
    updateVariables({
      ...variables,
      ...values,

      dateJoinedMin: toServerDate(values.dateJoinedMin),
      dateJoinedMax: toServerDate(values.dateJoinedMax),

      offset: 0,
    });
    formikActions.setSubmitting(false);
    setOpen(false);
  }

  const closeButton = (
    <Button size="large" shape="round" onClick={() => setOpen(false)}>
      Close
    </Button>
  );

  return (
    <Collapse
      bordered={false}
      onChange={() => setOpen(!open)}
      activeKey={open ? ["filters"] : []}
      expandIconPosition="start"
    >
      <Collapse.Panel
        key="filters"
        header={<CustomerFiltersHeader customersSearch={customersSearch} />}
      >
        <div className="w-full flex justify-center">
          <Form<FormData>
            initialValues={variables}
            validationSchema={VALIDATION_SCHEMA}
            onReset={(values: FormData, formikHelpers: FormikHelpers<FormData>) => {
              formikHelpers.setSubmitting(true);
              resetVariables();
              formikHelpers.setSubmitting(false);
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values }) => {
              const changes = changesCount(values, variables);
              const selectedFiltersCount = getSelectedFiltersCount(values);

              return (
                <div className="flex space-y-2 w-full flex-wrap">
                  <FiltersForm values={values} />

                  <Divider />

                  <div
                    className={clsx("w-full flex justify-between flex-wrap space-x-2 space-y-3")}
                  >
                    <div className="hidden sm:flex">{closeButton}</div>

                    <div className="w-full flex sm:max-w-xs md:max-w-sm lg:max-w-md justify-end">
                      <Button
                        className="w-full"
                        disabled={isSubmitting || changes === 0}
                        htmlType="submit"
                        type="primary"
                        size="large"
                        shape="round"
                      >
                        Submit {changes ? `${changes} changes` : ""}
                      </Button>
                    </div>

                    <Button
                      disabled={isSubmitting || selectedFiltersCount === 0}
                      size="large"
                      shape="round"
                      htmlType="reset"
                      onClick={() => {
                        Object.keys(values).forEach((field) => {
                          const _field = field as keyof FormData;
                          values[_field] = undefined;
                        });
                      }}
                    >
                      Reset {selectedFiltersCount || ""} Filters
                    </Button>
                    <div className="flex sm:hidden">{closeButton}</div>
                  </div>
                </div>
              );
            }}
          </Form>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
}

type FilterFormProps = {
  values: FormData;
};

function _FiltersForm(_: FilterFormProps & { formik: FormikHelpers<FormData> }) {
  const fieldContainerClass = "w-full sm:w-1/2 lg:w-1/3 px-3";

  return (
    <div className="flex w-full flex-wrap">
      <div className={fieldContainerClass}>
        <FormTextField column label="Name" name="name" placeholder="Perumal" />
      </div>

      <div className={fieldContainerClass}>
        <FormTextField column label="Phone" name="phone" placeholder="987642" />
      </div>

      <div className={fieldContainerClass}>
        <FormTextField column label="Address" name="address" placeholder="16 Sunnambu" />
      </div>

      <div className={fieldContainerClass}>
        <FormTextField column label="Care Of" name="careOf" placeholder="Ravi" />
      </div>

      <div className={fieldContainerClass}>
        <FormSwitchField
          column
          label="Repledge Customer?"
          name="isRepledgeCustomer"
          treatFalseAsNull
        />
      </div>

      <div className={fieldContainerClass}>
        <FormSelectField
          column
          label="Sort Customers By"
          name="order"
          placeholder="Select a sorting"
          options={SORT_OPTIONS}
        />
      </div>

      <div className={fieldContainerClass}>
        <FormDateRangeField
          column
          label="Joining Date"
          name="dateJoinedMin"
          endDateName="dateJoinedMax"
          size="large"
        />
      </div>

      <div className={fieldContainerClass}>
        <FormNumberRangeField
          label="Active Loans Count"
          column
          name="activeLoansCountMin"
          maxFieldName="activeLoansCountMax"
        />
      </div>

      <div className={fieldContainerClass}>
        <FormNumberRangeField
          label="Closed Loans Count"
          column
          name="closedLoansCountMin"
          maxFieldName="closedLoansCountMax"
        />
      </div>

      <div className={fieldContainerClass}>
        <FormMoneyRangeField
          label="Outstanding Loan Amount"
          column
          name="outstandingAmountMin"
          maxFieldName="outstandingAmountMax"
        />
      </div>

      <div className={fieldContainerClass}>
        <FormMoneyRangeField
          label="Repaid Loan Amount"
          column
          name="repaidAmountMin"
          maxFieldName="repaidAmountMax"
        />
      </div>

      <div className={fieldContainerClass}>
        <FormMoneyRangeField
          label="Total Paid Interest"
          column
          name="paidInterestMin"
          maxFieldName="paidInterestMax"
        />
      </div>

      <div className={fieldContainerClass}>
        <FormNumberRangeField
          label="Days To Close"
          column
          name="daysToCloseMin"
          maxFieldName="daysToCloseMax"
        />
      </div>
    </div>
  );
}

const FiltersForm: React.ComponentType<FilterFormProps> = connect<FilterFormProps, FormData>(
  _FiltersForm
);
