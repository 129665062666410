import { useMemo } from "react";
import { AutoComplete, AutoCompleteProps, Empty } from "antd";

import { AccountCategoryEnum } from "../../gql/graphql";
import useAccountsSearch, {
  AccountsSearchVariblesProps,
} from "../../hooks/api/accounts/useAccountsSearch";
import { OptionType, SelectValueType } from "../../types";
import { getCategoryTitle, groupAccountsByCategory } from "../../utils/account";
import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

type Props = AutoCompleteProps & {
  accountField: string;
  accountsSearchProps?: AccountsSearchVariblesProps;
  excludeAccountIds?: number[];
};

function AccountSearchFormField(props: Props & WithFormFieldWrapperProps) {
  const { name, accountField, formik, accountsSearchProps, ...rest } = props;

  const { accounts, fetching, searchText, updateSearchText } = useAccountsSearch({
    ...accountsSearchProps,
    inlineMode: true,
  });

  const options = useMemo(() => {
    if (!accounts.length) {
      return [];
    }

    const accountsByCategory = groupAccountsByCategory(accounts);
    return Object.keys(accountsByCategory)
      .sort((a, b) => a.localeCompare(b))
      .map((category) => {
        const _category = category as AccountCategoryEnum;
        const accounts = accountsByCategory[_category];
        return {
          label: getCategoryTitle(_category),
          options: accounts
            .filter(
              (account) => !props.excludeAccountIds || !props.excludeAccountIds.includes(account.id)
            )
            .map((account) => ({
              value: account.id,
              label: account.description,
              content: account,
            })),
        };
      });
  }, [accounts, props.excludeAccountIds]);

  function setValue(value: SelectValueType, account?: AccountDetailsFragment) {
    const {
      name,
      formik: { setFieldValue },
    } = props;

    setFieldValue(name, account?.id ?? null);
    setFieldValue(accountField, account ?? null);
  }

  return (
    <>
      <AutoComplete
        value={searchText}
        popupMatchSelectWidth
        options={options}
        className="w-full"
        onSearch={updateSearchText}
        allowClear
        notFoundContent={!searchText || fetching ? "Start typing ..." : <Empty />}
        onSelect={(value: SelectValueType, option: OptionType) => {
          const account = option.content as AccountDetailsFragment;
          setValue(value, account);
          updateSearchText(account.description);
        }}
        onChange={(value) => (!value ? setValue(null) : undefined)}
        {...rest}
      />
    </>
  );
}

export default withFormFieldWrapper<Props>(AccountSearchFormField);
