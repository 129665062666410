import React from "react";
import clsx from "clsx";

import useTablet from "../../hooks/useTablet";
import LabelValue from "./LabelValue";
import PrintableTable, { ColumnType, PrintableTableProps, RecordType } from "./PrintableTable";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ColumnRenderFunction<T> = (value: any | undefined, record: T) => React.ReactNode;

export type ResponsiveColumnType<T> = Omit<ColumnType<T>, "render" | "title" | "dataIndex"> & {
  cardable?: {
    // top items comes without title unless explicitly specified.
    position: "top" | "body" | "bottom";
    // Override for the column title.
    title?: string;
    containerClassName?: string;
    valueClassName?: string;
    renderForCard?: ColumnRenderFunction<T>;
  };
  title: string | React.ReactNode;
  dataIndex: keyof T;

  render?: ColumnRenderFunction<T>;
};

type Props<T> = PrintableTableProps<T> & {
  columns: ResponsiveColumnType<T>[];
  renderCard?: (record: T) => React.ReactNode;
};

function Card<T>({ record, columns }: { record: T; columns: ResponsiveColumnType<T>[] }) {
  const defaultColumnRender: ColumnRenderFunction<T> = (value, record) => value;
  const labelValueCls = "mr-1.5 mb-0.5";
  function renderColumn(column: ResponsiveColumnType<T>) {
    const renderColumn = column.cardable?.renderForCard ?? column.render ?? defaultColumnRender;
    return renderColumn(record[column.dataIndex], record);
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center w-full justify-between flex-wrap flex-grow-0">
        {columns
          .filter((column) => column.cardable && column.cardable.position === "top")
          .map((column) => {
            return (
              <div
                key={column.key}
                className={clsx("flex flex-col", column.cardable?.containerClassName)}
              >
                {column.cardable?.title && (
                  <div className="text-sm text-gray-500">{column.cardable?.title}</div>
                )}
                <div className={clsx(column.cardable?.valueClassName ?? "text-base font-medium")}>
                  {renderColumn(column)}
                </div>
              </div>
            );
          })}
      </div>

      <div className="flex flex-wrap w-full justify-between items-start mt-1">
        {columns
          .filter((column) => column.cardable && column.cardable.position === "body")
          .map((column) => (
            <div key={column.key} className={column.cardable?.containerClassName}>
              <LabelValue
                key={column.key}
                className={labelValueCls}
                label={column.cardable?.title ?? column.title}
                value={renderColumn(column)}
                valueClassName={column.cardable?.valueClassName}
              />
            </div>
          ))}
      </div>

      <div className="flex items-center w-full justify-between flex-wrap">
        {columns
          .filter(
            (column) =>
              column.cardable && column.cardable.position === "bottom" && !!record[column.dataIndex]
          )
          .map((column) => (
            <div key={column.key} className={clsx(column.cardable?.containerClassName)}>
              <span className={column.cardable?.valueClassName ?? "text-base text-gray-500"}>
                {renderColumn(column)}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
}

export default function ResponsiveTable<T extends RecordType>({ renderCard, ...props }: Props<T>) {
  const { isTablet } = useTablet();

  const responsiveTabletColumns: ColumnType<T>[] = [
    {
      title: "Records",
      key: "id",
      dataIndex: "id",
      width: "100%",
      render: (value, record) =>
        renderCard ? renderCard(record) : <Card<T> record={record} columns={props.columns} />,
    },
  ];
  const { printSettings, ...rest } = props;

  return isTablet ? (
    <PrintableTable<T>
      {...props}
      columns={responsiveTabletColumns}
      showHeader={false}
      scroll={{ x: undefined }}
      // print the original table.
      printableTableProps={!!printSettings ? { printSettings, ...rest } : undefined}
    />
  ) : (
    <PrintableTable<T> {...props} />
  );
}
