import type { Payment } from "../hooks/api/loans/useLoan";
import { formatDate } from "./date";

export const getLoanInvoiceTitle = (loan: CustomerLoanFragment) =>
  `${loan.customer.user.name?.replaceAll(" ", "")}_${loan.pledgeSheetNumber}_${formatDate(
    loan.contractStartDate
  )
    .replaceAll(" ", "_")
    .replaceAll(",", "_")}`;

export const getPaymentInvoiceTitle = (payment: Payment) =>
  `${payment.loanContract.customer.user.name?.replaceAll(" ", "")}_${
    payment.loanContract.pledgeSheetNumber
  }_${formatDate(payment.paymentDate).replaceAll(" ", "_").replaceAll(",", "_")}`;

export const getProcessingFees = (loanAmount: number) => {
  return loanAmount >= 10000 ? 50 : loanAmount >= 5000 ? 25 : 10;
};
