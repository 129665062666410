import React, { useMemo } from "react";
import { useHistory } from "react-router";
import {
  CloseCircleFilled,
  DeleteFilled,
  EditFilled,
  PrinterFilled,
  UnlockOutlined,
} from "@ant-design/icons";
import { Card, CardProps, Descriptions, Skeleton } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import { useClient } from "urql";

import { NIL, NOT_AVAILABLE } from "../../constants/common";
import useLoan, { GET_LOAN_QUERY, Loan as LoanType } from "../../hooks/api/loans/useLoan";
import useLoanAction from "../../hooks/useLoanAction";
import { usePrint } from "../../hooks/usePrint";
import {
  formatCurrency as formatCurrencyUtil,
  formatMetalPurity,
  formatRate,
} from "../../utils/currency";
import { formatDate, formatDateTime } from "../../utils/date";
import { getLoanInvoiceTitle } from "../../utils/loan";
import { formatNumber, sum, toNumber } from "../../utils/number";
import ActionButton from "../common/ActionButton";
import Centered from "../common/Centered";
import EmptyPage from "../common/EmptyPage";
import LoanStatus from "../common/LoanStatus";
import PledgeItemType from "../common/PledgeItemType";
import QuestionTooltip from "../common/QuestionTooltip";
import CustomerCard from "../customers/CustomerCard";
import DocumentsList from "../documents/DocumentsList";
import LoanPayments from "../payments/LoanPayments";
import LoanTopUps from "../top-ups/LoanTopUps";
import LoanFormModal from "./forms/LoanFormModal";
import LoanInvoice from "./invoice/LoanInvoiceA4";

const formatCurrency = (amount: number | null | undefined) => formatCurrencyUtil(amount, 0);

function LoanDescriptions(props: CardProps & { title: React.ReactNode }) {
  return (
    <div className="py-2 rounded-lg">
      <Card className="rounded-lg" bordered bodyStyle={{ padding: 0 }} {...props}>
        <Descriptions bordered column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
          {props.children}
        </Descriptions>
      </Card>
    </div>
  );
}

function Loan({ loan }: { loan: LoanType }) {
  const history = useHistory();
  const client = useClient();

  const { print, printing } = usePrint({ title: getLoanInvoiceTitle(loan) });
  const printableInvoice = useMemo(() => <LoanInvoice loan={loan} />, [loan]);

  const { customer } = loan;
  const { openLoanForm, closeLoanForm, showLoanForm, action, loan: selectedLoan } = useLoanAction();

  const highlightedClass = clsx("text-sm font-bold");
  const titleFontSizeClasses = "text-lg sm:text-xl lg:text-2xl";
  return (
    <>
      <Card
        className="rounded-lg"
        bordered={false}
        headStyle={{ paddingLeft: 8, paddingRight: 8 }}
        bodyStyle={{ padding: 4 }}
        title={
          <div className="pb-2 flex flex-wrap justify-between space-y-1 items-start mt-3">
            <div>
              <CustomerCard className="p-0" customer={customer} />
            </div>

            <div className="flex flex-col space-y-1">
              <div className={titleFontSizeClasses}>PSN# {loan.pledgeSheetNumber}</div>
              <LoanStatus
                status={loan.loanStatus}
                className="text-sm rounded-md px-8 py-1 font-bold"
              />
            </div>

            <div className="flex space-x-2 justify-between w-full sm:w-auto">
              <ActionButton
                cta="Print"
                icon={<PrinterFilled />}
                perm="VIEW_LOAN"
                onClick={() => print(printableInvoice)}
                loading={printing}
                iconOnlyForMobile
              />

              <ActionButton
                cta="Edit"
                icon={<EditFilled />}
                perm="EDIT_LOAN"
                onClick={() => history.push(`/loans/edit/${loan.id}`)}
                iconOnlyForMobile
              />

              {loan.loanStatus === "ACTIVE" ? (
                <ActionButton
                  cta="Close"
                  icon={<CloseCircleFilled />}
                  perm="EDIT_LOAN"
                  onClick={() => openLoanForm({ ...loan, customer }, "close")}
                  iconOnlyForMobile
                />
              ) : loan.loanStatus === "CLOSED" ? (
                <ActionButton
                  cta="Reopen"
                  icon={<UnlockOutlined />}
                  perm="REVIVE_LOAN"
                  onClick={() => openLoanForm({ ...loan, customer }, "reopen")}
                  iconOnlyForMobile
                />
              ) : (
                <></>
              )}

              <ActionButton
                cta="Delete"
                icon={<DeleteFilled />}
                perm="DELETE_LOAN"
                onClick={() => openLoanForm({ ...loan, customer }, "delete")}
                iconOnlyForMobile
              />
            </div>
          </div>
        }
      >
        <div className="py-2" />
        <LoanDescriptions title="Item Details">
          <Descriptions.Item label="Item Type">
            <div className="flex flex-wrap  font-medium items-center">
              <div className="mr-2">
                {loan.pledgeItemType ? (
                  <PledgeItemType className="text-base font-medium" value={loan.pledgeItemType} />
                ) : (
                  NOT_AVAILABLE
                )}
              </div>
              {loan.pledgeItemPurity && loan.pledgeItemPurityRatio
                ? formatMetalPurity(loan.pledgeItemPurity, loan.pledgeItemPurityRatio)
                : ""}
            </div>
          </Descriptions.Item>

          <Descriptions.Item label="Item Weight">
            {loan.pledgeItemWeight ? `${formatNumber(loan.pledgeItemWeight)} grams` : NOT_AVAILABLE}
          </Descriptions.Item>

          <Descriptions.Item label="Item Description">
            {loan.pledgeItemDesc || NOT_AVAILABLE}
          </Descriptions.Item>

          <Descriptions.Item label="Item Estimated Value">
            {loan.pledgeItemEstimate ? formatCurrency(loan.pledgeItemEstimate) : NOT_AVAILABLE}
          </Descriptions.Item>

          <Descriptions.Item label="Notes" span={2}>
            {loan.loanNotes || NOT_AVAILABLE}
          </Descriptions.Item>
        </LoanDescriptions>

        <LoanDescriptions title="Loan Opening Details">
          <Descriptions.Item label="Loan Open Date">
            <div className={clsx(highlightedClass, "text-pink-700")}>
              {formatDate(loan.contractStartDate)}
            </div>
          </Descriptions.Item>

          <Descriptions.Item label="Amount">
            <div className={highlightedClass}>{formatCurrency(loan.loanAmount)}</div>
          </Descriptions.Item>

          <Descriptions.Item label="Interest Rate">
            {formatRate(loan.interestRate)}
          </Descriptions.Item>
          <Descriptions.Item label="First Month Interest">
            {formatCurrency(loan.firstMonthInterest)}
          </Descriptions.Item>

          <Descriptions.Item label="Processing Fees">
            {formatCurrency(loan.processingFees)}
          </Descriptions.Item>
        </LoanDescriptions>

        {loan.loanStatus === "ACTIVE" ? (
          <LoanDescriptions title="Loan Value">
            <Descriptions.Item
              label={
                <div className="flex space-x-2">
                  <div>Current Loan Value </div>
                  <QuestionTooltip>
                    <>
                      This is the value of the loan based on the purity minus the accured interest.
                      <div>
                        This is an indicator which tells us whether we will be able to recover the
                        loan amount after deducting the accured interests and considering the part
                        payments.
                      </div>
                    </>
                  </QuestionTooltip>
                </div>
              }
            >
              {loan.currentLoanValue ? (
                <div className="flex flex-col space-y-1">
                  <div
                    className={clsx(
                      loan.auctionable ? "text-red-600" : "text-green-600",
                      highlightedClass
                    )}
                  >
                    {formatCurrency(loan.currentLoanValue)}
                  </div>
                  <div className="text-xs text-gray-500">
                    Updated @ {formatDateTime(loan.currentLoanValueUpdatedAt)}
                  </div>
                </div>
              ) : (
                NOT_AVAILABLE
              )}
            </Descriptions.Item>

            {loan.partPaymentAmount && toNumber(loan.partPaymentAmount) && (
              <Descriptions.Item label="Part Payments">
                <div className="flex flex-col">
                  <div className="font-medium pb-0.5">{formatCurrency(loan.partPaymentAmount)}</div>
                  <div className="text-xs text-gray-500">
                    Updated @ {formatDateTime(loan.partPaymentUpdatedAt)}
                  </div>
                </div>
              </Descriptions.Item>
            )}

            {toNumber(loan.pendingInterest) > 0 && (
              <Descriptions.Item
                label={
                  <div className="flex flex-col">
                    <div className="flex space-x-2">
                      <div>Accured Interest </div>
                      <QuestionTooltip>
                        <>
                          This is the total interest accurred for this loan minus the first month
                          interest & part payments.
                          <div>
                            This typically indicates the interest amount that needs to be paid by
                            the customer upon closing.
                          </div>
                        </>
                      </QuestionTooltip>
                    </div>
                    <div className="text-xs text-gray-500">Pending accured interest</div>
                  </div>
                }
              >
                <div className="flex flex-col">
                  <div className="font-medium pb-0.5">{formatCurrency(loan.pendingInterest)}</div>
                  <div className="text-xs text-gray-500">
                    Updated @ {formatDateTime(loan.accuredInterestUpdatedAt)}
                  </div>
                </div>
              </Descriptions.Item>
            )}
          </LoanDescriptions>
        ) : (
          <LoanDescriptions title="Loan Closing Details">
            <Descriptions.Item label="Loan Close Date">
              <div className={clsx(highlightedClass, "text-purple-700")}>
                {formatDate(loan.closingDate)}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Final Interest">
              <span className="font-medium">
                {formatCurrency(sum(loan.closingInterest, loan.commission))}
              </span>
            </Descriptions.Item>

            <Descriptions.Item label="Closing Payment">
              <span className="font-medium">{formatCurrency(loan.closingPayment)}</span>
            </Descriptions.Item>

            {loan.partPaymentAmount && toNumber(loan.partPaymentAmount) > 0 && (
              <Descriptions.Item label="Part Payments">
                {formatCurrency(loan.partPaymentAmount)}
              </Descriptions.Item>
            )}

            <Descriptions.Item label="Annual Rate of Return">
              <span className="font-bold text-green-800">
                {formatRate(loan.annualizedRateOfReturn)}
              </span>
            </Descriptions.Item>

            <Descriptions.Item label="Time To Close" span={2}>
              {loan.contractStartDate && loan.closingDate
                ? dayjs(loan.contractStartDate)
                    .startOf("day")
                    .from(dayjs(loan.closingDate).add(1, "d").startOf("day"), true)
                : NOT_AVAILABLE}
            </Descriptions.Item>
          </LoanDescriptions>
        )}

        <LoanDescriptions title="Accounting Details">
          <Descriptions.Item label="Initial Investor Interest">
            {formatCurrency(loan.initialInterestAmount)}
          </Descriptions.Item>

          <Descriptions.Item label="Initial Commissions">
            {formatCurrency(loan.initialCommissionsAmount)}
          </Descriptions.Item>
          {loan.loanStatus === "CLOSED" && (
            <>
              <Descriptions.Item label="Closing Investor Interest">
                {loan.closingInterest ? formatCurrency(loan.closingInterest) : NIL}
              </Descriptions.Item>

              <Descriptions.Item label="Closing Commissions">
                {loan.commission ? formatCurrency(loan.commission) : NIL}
              </Descriptions.Item>

              <Descriptions.Item label="Actual Return Collected">
                <div className="font-bold text-black-800">
                  {formatCurrency(
                    (loan.closingInterest ? toNumber(loan.closingInterest) : 0) +
                      (loan.commission ? toNumber(loan.commission) : 0)
                  )}
                </div>
              </Descriptions.Item>

              <Descriptions.Item label="Expected Return">
                <div className={clsx("text-blue-600", highlightedClass)}>
                  {loan.expectedReturn ? formatCurrency(loan.expectedReturn) : NOT_AVAILABLE}
                </div>
              </Descriptions.Item>
            </>
          )}
        </LoanDescriptions>
      </Card>

      {showLoanForm && selectedLoan && action && (
        <LoanFormModal loan={selectedLoan} action={action} onClose={closeLoanForm} />
      )}

      <div className="py-2 flex flex-col space-y-1">
        <DocumentsList
          documents={loan.documents}
          customer={loan.customer}
          loan={loan}
          onChange={async () =>
            await client
              .query(GET_LOAN_QUERY, { id: loan.id }, { requestPolicy: "network-only" })
              .toPromise()
          }
        />
        <LoanTopUps loan={loan} />
        <LoanPayments loan={loan} />
      </div>

      <div className="py-6" />
    </>
  );
}

export default function LoanDetailsPage() {
  const { loan, fetching } = useLoan();

  return fetching ? (
    <Centered>
      <Skeleton active paragraph={{ rows: 10 }} className="max-w-screen-sm" />
    </Centered>
  ) : loan ? (
    <>
      <Loan loan={loan} />
    </>
  ) : (
    <EmptyPage
      title="Unable to load the loan details!"
      back="/loans/search"
      backCta="Search Loans"
    />
  );
}
