import { ReloadOutlined } from "@ant-design/icons";

import useRefreshMetrics from "../../../hooks/api/loans/useRefreshMetrics";
import ActionButton from "../../common/ActionButton";

type Props = {
  variables: { startDate?: string; endDate?: string };
};

export default function RefreshMetricsAction({ variables }: Props) {
  const { refresh, refreshing } = useRefreshMetrics();

  return (
    <ActionButton
      cta="Refresh"
      perm="EDIT_LOAN_METRIC"
      icon={<ReloadOutlined />}
      loading={refreshing}
      disabled={!variables.startDate || !variables.endDate}
      onClick={() => {
        if (variables.startDate && variables.endDate) {
          refresh({ startDate: variables.startDate, endDate: variables.endDate });
        }
      }}
    />
  );
}
