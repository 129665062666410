import { Button } from "antd";

export function FormButtons({
  isSubmitting,
  onCancel,
  cta,
}: {
  isSubmitting: boolean;
  cta?: string;
  onCancel: () => void;
}) {
  return (
    <>
      <div className="flex w-full space-x-2 sm:space-x-4">
        <Button
          shape="round"
          className="w-1/3"
          size="large"
          loading={isSubmitting}
          type="default"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          className="w-2/3"
          shape="round"
          size="large"
          loading={isSubmitting}
          type="primary"
          htmlType="submit"
        >
          {cta ? cta : "Submit"}
        </Button>
      </div>
    </>
  );
}
