import React from "react";

import {
  FilterTag,
  getAmountText,
  getDatesText,
  getNumberText,
  getSelectedFilters,
  getTextForFilterPair,
} from "../../../utils/search";

const FILTERS: {
  [K in keyof CustomersSearchQueryVariables]:
    | ((record: CustomersSearchQueryVariables) => React.ReactNode)
    | null;
} = {
  offset: null,
  first: null,
  name: (record) => <FilterTag>Name: {record.name}</FilterTag>,
  phone: (record) => <FilterTag>Phone: {record.phone}</FilterTag>,
  address: (record) => <FilterTag>Address: {record.address}</FilterTag>,
  careOf: (record) => <FilterTag>Care Of: {record.careOf}</FilterTag>,
  activeLoansCountMin: (record) =>
    getTextForFilterPair(
      record,
      "Active Loans Count",
      "activeLoansCountMin",
      "activeLoansCountMax",
      getNumberText
    ),
  activeLoansCountMax: (record) =>
    getTextForFilterPair(
      record,
      "Active Loans Count",
      "activeLoansCountMin",
      "activeLoansCountMax",
      getNumberText,
      true
    ),
  closedLoansCountMin: (record) =>
    getTextForFilterPair(
      record,
      "Closed Loans Count",
      "closedLoansCountMin",
      "closedLoansCountMax",
      getNumberText
    ),
  closedLoansCountMax: (record) =>
    getTextForFilterPair(
      record,
      "Closed Loans Count",
      "closedLoansCountMin",
      "closedLoansCountMax",
      getNumberText,
      true
    ),
  outstandingAmountMin: (record) =>
    getTextForFilterPair(
      record,
      "Outstanding Amount",
      "outstandingAmountMin",
      "outstandingAmountMax",
      getAmountText
    ),
  outstandingAmountMax: (record) =>
    getTextForFilterPair(
      record,
      "Outstanding Amount",
      "outstandingAmountMin",
      "outstandingAmountMax",
      getAmountText,
      true
    ),
  repaidAmountMin: (record) =>
    getTextForFilterPair(
      record,
      "Repaid Amount",
      "repaidAmountMin",
      "repaidAmountMax",
      getAmountText
    ),
  repaidAmountMax: (record) =>
    getTextForFilterPair(
      record,
      "Repaid Amount",
      "repaidAmountMin",
      "repaidAmountMax",
      getAmountText,
      true
    ),
  paidInterestMin: (record) =>
    getTextForFilterPair(
      record,
      "Intered Paid",
      "paidInterestMin",
      "paidInterestMax",
      getAmountText
    ),
  paidInterestMax: (record) =>
    getTextForFilterPair(
      record,
      "Intered Paid",
      "paidInterestMin",
      "paidInterestMax",
      getAmountText,
      true
    ),
  daysToCloseMin: (record) =>
    getTextForFilterPair(
      record,
      "Days To Close",
      "daysToCloseMin",
      "daysToCloseMax",
      getNumberText
    ),
  daysToCloseMax: (record) =>
    getTextForFilterPair(
      record,
      "Days To Close",
      "daysToCloseMin",
      "daysToCloseMax",
      getNumberText,
      true
    ),
  dateJoinedMin: (record) =>
    getTextForFilterPair(record, "Joining Date", "dateJoinedMin", "dateJoinedMax", getDatesText),
  dateJoinedMax: (record) =>
    getTextForFilterPair(
      record,
      "Joining Date",
      "dateJoinedMin",
      "dateJoinedMax",
      getDatesText,
      true
    ),
};

function _SelectedFilters({ filters }: { filters: CustomersSearchQueryVariables }) {
  const selectedFilters = getSelectedFilters<CustomersSearchQueryVariables>(filters);

  return (
    <div className="flex flex-wrap">
      {selectedFilters.map((filter) => {
        const display = FILTERS[filter];
        return display ? (
          <div className="pb-1" key={filter}>
            {display(filters)}
          </div>
        ) : null;
      })}
    </div>
  );
}

export default React.memo(_SelectedFilters);
