import { useMemo, useState } from "react";
import { Button, Collapse, Divider } from "antd";
import clsx from "clsx";
import { connect, FormikHelpers } from "formik";
import isArray from "lodash/isArray";

import type { LoanContractOrder } from "../../../gql/graphql";
import { LoansSearchResult } from "../../../hooks/api/loans/useLoansSearch";
import { FieldPair } from "../../../types";
import { toServerDate } from "../../../utils/date";
import {
  changesCount,
  getFieldPairsValidationSchema,
  getSelectedFiltersCount,
} from "../../../utils/search";
import Form from "../../form/Form";
import FormDateRangeField from "../../form/FormDateRangeField";
import FormMoneyRangeField from "../../form/FormMoneyRangeField";
import FormNumberRangeField from "../../form/FormNumberRangeField";
import FormRadioField from "../../form/FormRadioField";
import FormSelectField from "../../form/FormSelectField";
import FormSwitchField from "../../form/FormSwitchField";
import SelectedFilters from "./SelectedFilters";

type FormData = Omit<LoansSearchQueryVariables, "first" | "offset">;

const FILTER_FIELD_PAIRS: FieldPair<FormData>[] = [
  { minField: "loanAmountMin", maxField: "loanAmountMax" },
  { minField: "interestRateMin", maxField: "interestRateMax" },
  { minField: "itemWeightMin", maxField: "itemWeightMax" },
  { minField: "contractStartDateMin", maxField: "contractStartDateMax", isDate: true },
  { minField: "closingDateMin", maxField: "closingDateMax", isDate: true },
];

const VALIDATION_SCHEMA = getFieldPairsValidationSchema(FILTER_FIELD_PAIRS);

const _SORT_OPTIONS: { label: string; value: LoanContractOrder[] }[] = [
  {
    label: "Open Date Ascending",
    value: ["CONTRACT_START_DATE_ASC", "ID_ASC"],
  },
  {
    label: "Open Date Descending",
    value: ["CONTRACT_START_DATE_DESC", "ID_DESC"],
  },
  {
    label: "Closing Date Ascending",
    value: ["CLOSING_DATE_ASC"],
  },
  {
    label: "Closing Date Descending",
    value: ["CLOSING_DATE_DESC"],
  },
];

const SORT_OPTIONS = _SORT_OPTIONS.map((option) => ({
  ...option,
  value: `${option.value.join("|")}`,
}));

type Props = {
  loansSearch: LoansSearchResult;
};

export const LoanFiltersHeader = ({
  loansSearch: { variables },
}: {
  loansSearch: LoansSearchResult;
}) => {
  const selectedFiltersCount = useMemo(() => {
    return getSelectedFiltersCount<LoansSearchQueryVariables>(variables);
  }, [variables]);
  return (
    <div className="flex flex-wrap items-center space-x-1 space-y-1 leading-none">
      <div className="text-xl font-bold pb-1 pr-1">
        Loan Filters {selectedFiltersCount > 0 ? `(${selectedFiltersCount})` : ""}
      </div>
      <SelectedFilters filters={variables} />
    </div>
  );
};

export default function LoanFilters({ loansSearch }: Props) {
  const { variables, updateVariables, resetVariables } = loansSearch;
  const selectedFiltersCount = useMemo(() => {
    return getSelectedFiltersCount<LoansSearchQueryVariables>(variables);
  }, [variables]);
  const [open, setOpen] = useState(() => selectedFiltersCount === 0);

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    formikActions.setSubmitting(true);
    updateVariables({
      ...variables,
      ...values,
      loanStatus: values.loanStatus || undefined,
      itemType: values.itemType || undefined,

      contractStartDateMin: toServerDate(values.contractStartDateMin),
      contractStartDateMax: toServerDate(values.contractStartDateMax),
      closingDateMin: toServerDate(values.closingDateMin),
      closingDateMax: toServerDate(values.closingDateMax),

      offset: 0,
    });
    formikActions.setSubmitting(false);
    setOpen(false);
  }

  const closeButton = (
    <Button size="large" shape="round" onClick={() => setOpen(false)}>
      Close
    </Button>
  );

  return (
    <Collapse
      bordered={false}
      onChange={() => setOpen(!open)}
      activeKey={open ? ["filters"] : []}
      expandIconPosition="start"
    >
      <Collapse.Panel key="filters" header={<LoanFiltersHeader loansSearch={loansSearch} />}>
        <div className="w-full flex justify-center">
          <Form<FormData>
            initialValues={variables}
            validationSchema={VALIDATION_SCHEMA}
            onReset={(values: FormData, formikHelpers: FormikHelpers<FormData>) => {
              formikHelpers.setSubmitting(true);
              resetVariables();
              formikHelpers.setSubmitting(false);
            }}
            validateOnChange
            validateOnBlur
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values }) => {
              const changes = changesCount<LoansSearchQueryVariables>(values, variables);
              const selectedFiltersCount = getSelectedFiltersCount(values);

              return (
                <div className="flex space-y-2 w-full flex-wrap">
                  <FiltersForm values={values} />

                  <Divider />

                  <div
                    className={clsx("w-full flex justify-between flex-wrap space-x-2 space-y-3")}
                  >
                    <div className="hidden sm:flex">{closeButton}</div>

                    <div className="w-full flex sm:max-w-xs md:max-w-sm lg:max-w-md justify-end">
                      <Button
                        className="w-full"
                        disabled={isSubmitting || changes === 0}
                        htmlType="submit"
                        type="primary"
                        size="large"
                        shape="round"
                      >
                        Submit {changes ? `${changes} changes` : ""}
                      </Button>
                    </div>

                    <Button
                      disabled={isSubmitting || selectedFiltersCount === 0}
                      size="large"
                      shape="round"
                      htmlType="reset"
                      onClick={() => {
                        Object.keys(values).forEach((field) => {
                          const _field = field as keyof FormData;
                          values[_field] = undefined;
                        });
                      }}
                    >
                      Reset {selectedFiltersCount || ""} Filters
                    </Button>
                    <div className="flex sm:hidden">{closeButton}</div>
                  </div>
                </div>
              );
            }}
          </Form>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
}

type FilterFormProps = {
  values: FormData;
};

function _FiltersForm({ formik }: FilterFormProps & { formik: FormikHelpers<FormData> }) {
  const fieldContainerClass = "w-full sm:w-1/2 lg:w-1/3 px-3";

  return (
    <div className="flex space-y-2 w-full flex-wrap">
      <div className={fieldContainerClass}>
        <FormRadioField
          column
          optionType="button"
          buttonStyle="solid"
          label="Loan Status"
          name="loanStatus"
          options={[
            { value: "", label: "ALL" },
            { value: "ACTIVE", label: "ACTIVE" },
            { value: "CLOSED", label: "CLOSED" },
          ]}
          size="middle"
        />
      </div>

      <div className={fieldContainerClass}>
        <FormRadioField
          column
          optionType="button"
          buttonStyle="solid"
          label="Item Type"
          name="itemType"
          options={[
            { value: "", label: "ALL" },
            { value: "GOLD", label: "GOLD" },
            { value: "SILVER", label: "SILVER" },
            { value: "BRASS", label: "BRASS" },
          ]}
          size="middle"
        />
      </div>

      <div className={fieldContainerClass}>
        <FormSwitchField
          column
          label="Auctionable?"
          treatFalseAsNull
          name="auctionable"
          onChange={(checked) => {
            if (checked) {
              formik.setFieldValue("loanStatus", "ACTIVE");
            }
          }}
        />
      </div>

      <div className={fieldContainerClass}>
        <FormSelectField
          column
          label="Sort Loans By"
          name="order"
          placeholder="Select a sorting"
          options={SORT_OPTIONS}
          parseValue={(value) => value.split("|")}
          formatValue={(value) => (isArray(value) ? value.join("|") : value)}
        />
      </div>

      <div className={fieldContainerClass}>
        <FormDateRangeField
          column
          label="Loan Open Date"
          name="contractStartDateMin"
          endDateName="contractStartDateMax"
          size="large"
        />
      </div>

      <div className={fieldContainerClass}>
        <FormDateRangeField
          column
          label="Loan Close Date"
          name="closingDateMin"
          endDateName="closingDateMax"
          size="large"
        />
      </div>

      <div className={fieldContainerClass}>
        <FormMoneyRangeField
          label="Loan Amount"
          column
          name="loanAmountMin"
          maxFieldName="loanAmountMax"
        />
      </div>

      <div className={fieldContainerClass}>
        <FormNumberRangeField
          addonAfter="%"
          label="Interest Rate"
          column
          name="interestRateMin"
          maxFieldName="interestRateMax"
        />
      </div>

      <div className={fieldContainerClass}>
        <FormNumberRangeField
          column
          addonAfter="grams"
          label="Item Weight Min."
          name="itemWeightMin"
          maxFieldName="itemWeightMax"
        />
      </div>
    </div>
  );
}

const FiltersForm: React.ComponentType<FilterFormProps> = connect<FilterFormProps, FormData>(
  _FiltersForm
);
