import { DatePicker, DatePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { getIn } from "formik";

import { DISPLAY_DATE_FORMAT, SERVER_DATE_FORMAT } from "../../constants/common";
import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

const FormDateField = (props: DatePickerProps & WithFormFieldWrapperProps & { name: string }) => {
  const handleChange = (data: Dayjs | null, dateString: string | string[]) => {
    const {
      name,
      formik: { setFieldValue },
    } = props;
    setFieldValue(name, data ? data.format(SERVER_DATE_FORMAT) : null);
  };

  const { name, formik } = props;
  const fieldValue = getIn(formik.values, name);
  const value = fieldValue ? dayjs(fieldValue, SERVER_DATE_FORMAT) : null;

  return (
    <DatePicker
      {...props}
      value={value}
      onChange={handleChange}
      format={DISPLAY_DATE_FORMAT}
      presets={[
        { label: "Today", value: dayjs() },
        { label: "Yesterday", value: dayjs().add(-1, "d") },
      ]}
    />
  );
};

export default withFormFieldWrapper<DatePickerProps>(FormDateField);
