import { CombinedError, gql } from "urql";

export function getApiUrl(path: string) {
  const server = process.env.REACT_APP_BACKEND_SERVER || "http://localhost:8000";
  const domain = server.replace(/\/$/, "");
  return `${domain}${path}`;
}

export function getCookie(cname: string) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getCSRFToken() {
  return { "X-CSRFToken": getCookie("csrftoken") };
}

export function getErrorMessage(gqlError?: CombinedError, customError?: string) {
  if (customError) {
    return customError;
  }

  return gqlError?.toString() ?? "Unknown error occurred!";
}
