import { useMemo } from "react";
import { gql } from "@urql/core";
import { useQuery } from "urql";

import { LOAN_CONTRACT_FRAGMENT } from "../../../components/loans/fragments";
import useQueryParams from "../../useQueryParams";
import { CUSTOMER_DETAILS_FRAGMENT } from "../customers/useCustomer";
import { DOCUMENT_CONNECTION_FRAGMENT } from "../documents/useDocument";

export const PAYMENT_FRAGMENT: PaymentFragmentDoc = gql`
  fragment Payment on Payment {
    id
    paymentDate
    paymentAmount
    notes
  }
`;

export const LOAN_TOP_UP_FRAGMENT: LoanTopUpFragmentDoc = gql`
  fragment LoanTopUp on LoanTopUp {
    id
    topUpDate
    loanAmount
    firstMonthInterest
    initialInterestAmount
    initialCommissionsAmount
    processingFees
    initial
    notes
  }
`;

export const GET_LOAN_QUERY: GetLoanQueryDoc = gql`
  query GetLoan($id: Int!) {
    loanContracts(id: $id) {
      edges {
        node {
          ...Loan
          topUps {
            edges {
              node {
                ...LoanTopUp
              }
            }
          }
          payments {
            edges {
              node {
                ...Payment
              }
            }
          }
          customer {
            ...CustomerDetails
            documents {
              ...DocumentConnection
            }
          }
          documents {
            ...DocumentConnection
          }
        }
      }
    }
  }
  ${DOCUMENT_CONNECTION_FRAGMENT}
  ${LOAN_CONTRACT_FRAGMENT}
  ${LOAN_TOP_UP_FRAGMENT}
  ${PAYMENT_FRAGMENT}
  ${CUSTOMER_DETAILS_FRAGMENT}
`;

export type Loan = LoanFragment & {
  customer: CustomerDetailsFragment;
  topUps: LoanTopUpFragment[];
  payments: PaymentFragment[];
  documents: NidhiDocumentFragment[];
};

export default function useLoan(): { loan?: Loan; fetching: boolean } {
  const id = useQueryParams("loan_id");
  const variables = useMemo(() => ({ id: id ? parseInt(id) : -1 }), [id]);
  const [{ data, fetching }] = useQuery({ query: GET_LOAN_QUERY, variables, pause: !id });

  const _loan = data?.loanContracts?.edges?.[0]?.node;
  const loan: Loan | undefined = useMemo(() => {
    if (!_loan) {
      return;
    }

    const customerDocuments = (_loan.customer.documents.edges || []).map(
      (edge) => edge?.node as NidhiDocumentFragment
    );
    const loanDocuments = (_loan.documents.edges || []).map(
      (edge) => edge?.node as NidhiDocumentFragment
    );
    const documents = [...loanDocuments, ...customerDocuments];

    return {
      ..._loan,
      documents,
      topUps: (_loan.topUps.edges || []).map((edge) => edge?.node as LoanTopUpFragment),
      payments: (_loan.payments.edges || []).map((edge) => edge?.node as PaymentFragment),
    };
  }, [_loan]);

  return { loan, fetching };
}

export type Payment = PaymentFragment & { loanContract: Loan };
