import { useCallback, useEffect, useState } from "react";
import {
  CameraOutlined,
  CheckOutlined,
  CloseOutlined,
  FileDoneOutlined,
  FileExclamationOutlined,
  FileSyncOutlined,
  PaperClipOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { Image, Progress } from "antd";
import clsx from "clsx";

import useUploadDocument, {
  UseUploadDocumentProps,
} from "../../../hooks/api/documents/useUploadDocument";
import useWebcamCapture from "../../../hooks/useWebcamCapture";
import { FALLBACK_IMAGE } from "../../../utils/image";
import ActionButton from "../ActionButton";
import AnchorLink from "../AnchorLink";
import WebcamCapture from "./WebcamCapture";
import { WebcamCaptureModal } from "./WebcamCaptureModal";

export type PhotoInputProps = {
  disabled?: boolean;
  // instead of opening a new modal for the webcam, show the webcam in the same div.
  useModalForWebcam?: boolean;
} & UseUploadDocumentProps;

export default function PhotoInput({ disabled, useModalForWebcam, ...props }: PhotoInputProps) {
  const useWebcam = useWebcamCapture();
  const { openWebcam, closeWebcam, open } = useWebcam;
  const [image, setImage] = useState<string | null>(null);
  const [uploadPercent, setUploadPercent] = useState<number>(0);

  const { upload, uploadStatus, document } = useUploadDocument(props);

  const uploading = uploadStatus === "active";

  const onSelect = useCallback(
    (image: string) => {
      setImage(image);
      return upload({ ...props, image, documentKey: document ? document.key : undefined });
    },
    [document, props, upload]
  );

  useEffect(() => {
    if (uploadStatus === "active") {
      setUploadPercent(0);
      for (let i = 1; i <= 3; i++) {
        setTimeout(() => setUploadPercent(i * 30), 750 * i);
      }
    } else {
      setUploadPercent(0);
    }
  }, [uploadStatus]);

  const uploadStatusColor =
    uploadStatus === "exception"
      ? "text-red-500"
      : uploadStatus === "success"
      ? "text-green-400"
      : uploadStatus === "resync"
      ? "text-yellow-600"
      : "text-green-500";

  return (
    <div className="flex flex-col">
      {useModalForWebcam && <WebcamCaptureModal {...useWebcam} onSelect={onSelect} />}

      {open && !useModalForWebcam ? (
        <WebcamCapture onClose={closeWebcam} onSelect={onSelect} />
      ) : (
        <>
          <div className="flex w-full relative">
            <Image
              preview={!!(image || document?.url)}
              loading="eager"
              src={image || document?.url || "error"}
              fallback={FALLBACK_IMAGE}
              className="rounded-xl  w-full bg-gray-100"
              style={{ minWidth: 250 }}
            />
            <div className="flex absolute bottom-1 left-0 w-full px-1">
              <ActionButton
                type={useModalForWebcam ? "default" : "primary"}
                className={clsx(image || document?.url ? "w-full" : "w-60", "justify-center")}
                cta={document?.url ? "Retake Photo" : "Take Photo"}
                onClick={openWebcam}
                disabled={disabled || uploading}
                icon={<CameraOutlined />}
                ghost={false}
              />
            </div>
          </div>

          <div className="w-full">
            {uploadStatus === "active" ? (
              <Progress type="line" showInfo percent={uploadPercent} status={uploadStatus} />
            ) : uploadStatus || !!document ? (
              <div
                className={clsx(
                  "flex justify-between pr-1 space-x-1 items-center w-full",
                  uploadStatusColor
                )}
              >
                <AnchorLink
                  externalHref={document?.url}
                  className={clsx("text-current", uploadStatusColor)}
                >
                  <div className="flex items-center">
                    {uploadStatus === "exception" ? (
                      <FileExclamationOutlined />
                    ) : uploadStatus === "success" ? (
                      <FileDoneOutlined />
                    ) : uploadStatus === "resync" ? (
                      <FileSyncOutlined />
                    ) : (
                      <PaperClipOutlined />
                    )}
                    <div className="px-0.5" />{" "}
                    {document?.filename ? document.filename : props.filename}
                  </div>
                </AnchorLink>
                <div className="mb-0.5">
                  {uploadStatus === "success" ? (
                    <CheckOutlined />
                  ) : uploadStatus === "exception" ? (
                    <CloseOutlined />
                  ) : uploadStatus === "resync" ? (
                    <RedoOutlined />
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}
