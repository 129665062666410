import React from "react";

import { TIMEZONE } from "../../../utils/date";
import { InvoiceLabel } from "./InvoiceItem";

export default function PrintedAt() {
  return (
    <div className="flex text-gray-500 justify-end space-x-2 text-xs py-1 text-right">
      <InvoiceLabel label="Printed At" labelSuffix=":" className="text-gray-400 text-xs" />
      <div className="flex flex-col flex-grow-0">
        {new Intl.DateTimeFormat("en", {
          dateStyle: "long",
          timeStyle: "short",
          timeZone: TIMEZONE,
        }).format(new Date())}
        <div>
          {new Intl.DateTimeFormat("ta", {
            dateStyle: "long",
            timeStyle: "short",
            timeZone: TIMEZONE,
          }).format(new Date())}
        </div>
      </div>
    </div>
  );
}
