import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AutoComplete, AutoCompleteProps, Divider } from "antd";
import { getIn } from "formik";
import { gql, useQuery } from "urql";

import useDebounce from "../../hooks/useDebounce";
import { OptionType, SelectValueType } from "../../types";
import AnchorLink from "../common/AnchorLink";
import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

const CUSTOMER_SEARCH_QUERY: CustomerSearchQueryDoc = gql`
  query CustomerSearch($text: String!, $limit: Int, $primaryCustomersOnly: Boolean) {
    search(
      text: $text
      limit: $limit
      types: CUSTOMERS
      primaryCustomersOnly: $primaryCustomersOnly
    ) {
      id
      objectId
      name
      phone
      careOf
      address
      isRepledgeCustomer
    }
  }
`;

export type CustomerSearchResult = CustomerSearchQuery["search"][0];

const AddCustomerLink = () => (
  <Link to="/customers/add" href="/customers/add">
    <AnchorLink className="text-xs">ADD NEW CUSTOMER</AnchorLink>
  </Link>
);

type Props = AutoCompleteProps & {
  customerField: string;
  disabledCustomerIds?: number[];
  primaryCustomersOnly?: boolean;
};

function CustomerSearchFormField(props: Props & WithFormFieldWrapperProps) {
  const { name, disabledCustomerIds, customerField, primaryCustomersOnly, formik, ...rest } = props;
  const customer = getIn(formik.values, customerField);

  const [searchTerm, setSearchTerm] = useState<string>(
    customer ? `${customer.name} (${customer.phone})` : ""
  );
  const debouncedSearchText = useDebounce<string>(searchTerm.trim(), 100);
  const variables = useMemo(
    () => ({ text: debouncedSearchText, limit: 10, primaryCustomersOnly }),
    [debouncedSearchText, primaryCustomersOnly]
  );

  const [{ data }] = useQuery({
    query: CUSTOMER_SEARCH_QUERY,
    variables,
    pause: !variables.text || variables.text.length < 2,
  });

  const options = useMemo(() => {
    const results = data?.search;
    if (!results || !results.length) {
      return [];
    }

    return [
      {
        label: (
          <div className="flex justify-between">
            <div>CUSTOMERS</div>
            <AddCustomerLink />
          </div>
        ),
        options: (data?.search || []).map((value) => {
          return {
            value: value.objectId,
            disabled: !!disabledCustomerIds?.includes(value.objectId),
            label: (
              <div className="flex flex-col">
                <div className="flex flex-wrap justify-between mb-0.5">
                  <div className="text-base font-normal">{value.name}</div>
                  <div className="font-light text-sm">{value.phone}</div>
                </div>
                <div className="text-xs">{value.careOf}</div>
                <div className="text-xs">{value.address}</div>
              </div>
            ),
            content: value,
          };
        }),
      },
    ];
  }, [data, disabledCustomerIds]);

  function setValue(value: SelectValueType, customer?: CustomerSearchResult) {
    const {
      name,
      formik: { setFieldValue },
    } = props;

    setFieldValue(name, value);
    setFieldValue(customerField, customer);
  }

  return (
    <>
      <AutoComplete
        value={searchTerm}
        popupMatchSelectWidth
        options={options}
        className="w-full"
        onSearch={setSearchTerm}
        allowClear
        notFoundContent={
          !searchTerm ? (
            "Start typing ..."
          ) : searchTerm.length < 2 ? (
            "Type more ..."
          ) : (
            <div className="flex flex-col space-y-1 justify-center items-center">
              <div className="text-sm font-medium capitalize">NO CUSTOMERS FOUND!!</div>
              <AddCustomerLink />
            </div>
          )
        }
        onSelect={(value: SelectValueType, option: OptionType) => {
          const customer = option.content as CustomerSearchResult;
          setValue(value, customer);
          setSearchTerm(`${customer.name} (${customer.phone})`);
        }}
        onChange={(value) => (!value ? setValue(null) : undefined)}
        {...rest}
      />

      {customer && (
        <>
          <div className="flex flex-col p-2">
            <div className="text-sm">{customer.careOf}</div>
            <div className="text-sm">{customer.address}</div>
          </div>
          <Divider className="my-1" />
        </>
      )}
    </>
  );
}

export default withFormFieldWrapper<Props>(CustomerSearchFormField);
