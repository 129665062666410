import React from "react";
import { Input, InputProps } from "antd";
import { getIn } from "formik";

import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

type Props = InputProps & { prefix?: string };

const FormTextField = (props: Props & WithFormFieldWrapperProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      name,
      formik: { setFieldValue },
    } = props;
    const newValue = event.target.value;
    setFieldValue(name, newValue);
  };

  const { formik, ...rest } = props;
  const { name } = rest;
  const fieldValue = getIn(formik.values, name);
  return props.type === "password" ? (
    <Input.Password {...rest} value={fieldValue} onChange={handleChange} />
  ) : (
    <Input {...rest} value={fieldValue} onChange={handleChange} />
  );
};

export default withFormFieldWrapper<Props>(FormTextField);
