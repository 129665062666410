import type { CustomerOrderType } from "../../../types/enum";
import { toTitleCase } from "../../../utils/common";

const SORT_ORDERS: CustomerOrderType[] = [
  "ACTIVE_LOANS_COUNT_ASC",
  "ACTIVE_LOANS_COUNT_DESC",
  "CLOSED_LOANS_COUNT_ASC",
  "CLOSED_LOANS_COUNT_DESC",
  "OUTSTANDING_AMOUNT_ASC",
  "OUTSTANDING_AMOUNT_DESC",
  "REPAID_AMOUNT_ASC",
  "REPAID_AMOUNT_DESC",
  "DAYS_TO_CLOSE_ASC",
  "DAYS_TO_CLOSE_DESC",
  "DATE_JOINED_ASC",
  "DATE_JOINED_DESC",
];

export const SORT_OPTIONS: { label: string; value: CustomerOrderType }[] = SORT_ORDERS.map(
  (order) => ({
    label: toTitleCase(order).replaceAll("_", " "),
    value: order,
  })
);
