import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Card, Statistic } from "antd";
import { gql } from "urql";

import { ACCOUNT_CATEGORIES_ORDERED, ACCOUNT_TYPES } from "../../constants/accounts";
import type { AccountCategoryEnum, AccountType } from "../../gql/graphql";
import { accountsSorter, getAccountsSearchParams, getCategoryTitle } from "../../utils/account";
import { formatCurrency, formatMoneyHumanReadable } from "../../utils/currency";

export const ACCOUNTS_CATEGORY_FRAGMENT: AccountCategoryFragmentDoc = gql`
  fragment AccountCategory on AccountCategory {
    category
    balance
  }
`;

export function AccountCategories({
  data,
  loading,
  className,
  categoriesToShow,
}: {
  data: AccountCategoryFragment[];
  loading: boolean;
  categoriesToShow?: AccountCategoryEnum[];
  className?: string;
}) {
  const categories = useMemo(() => {
    if (loading) {
      return (categoriesToShow || ACCOUNT_CATEGORIES_ORDERED).map((category) => ({
        category: category,
        balance: 0,
      }));
    } else {
      data.sort(accountsSorter);
      return data;
    }
  }, [data, loading, categoriesToShow]);
  return (
    <>
      {categories.map((category) => (
        <AccountCategoryCard
          key={category.category}
          category={category.category}
          className={className}
          data={category}
          loading={loading}
        />
      ))}
    </>
  );
}

function getSearchText(category: AccountCategoryEnum) {
  const values = category.split("_");
  if (values.length > 1 && ACCOUNT_TYPES.includes(values[0] as AccountType)) {
    const subtype = values.slice(1).join("_");
    return subtype.toLowerCase();
  }
  return category.replaceAll("_", " ").toLowerCase();
}

export default function AccountCategoryCard({
  category,
  data,
  className,
  loading,
}: {
  category: AccountCategoryEnum;
  data?: AccountCategoryFragment;
  className?: string;
  loading: boolean;
}) {
  const history = useHistory();
  return (
    <div key={category} className={className}>
      <Card
        className={
          "rounded-xl w-full cursor-pointer bg-gray-50 hover:bg-green-50 border border-gray-200"
        }
        title={
          <div className="text-2xl p-2 font-bold whitespace-pre-wrap w-full text-center text-gray-500">
            {getCategoryTitle(category)}
          </div>
        }
        onClick={() => {
          history.push(
            `/accounts/?${getAccountsSearchParams({
              searchText: getSearchText(category),
            })}`
          );
        }}
      >
        <div className="flex flex-wrap justify-between">
          <Statistic
            loading={loading}
            title="Balance"
            value={`${formatCurrency(data?.balance, 0)}`}
            className="mx-1.5 sm:mx-0 mb-2"
          />
          <Statistic
            loading={loading}
            title="Balance Abbr."
            value={formatMoneyHumanReadable(data?.balance)}
            className="mx-1.5 sm:mx-0 mb-2"
          />
        </div>
      </Card>
    </div>
  );
}
