import { Link } from "react-router-dom";

import AnchorLink from "../common/AnchorLink";

export default function AccountLink({ id, description }: { id: number; description: string }) {
  return (
    <div>
      <Link to={`/accounts/${id}`} href={`/accounts/${id}`} target="_blank">
        <AnchorLink className="text-left text-sm whitespace-pre-wrap">{description}</AnchorLink>
      </Link>
    </div>
  );
}
