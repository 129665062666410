import { gql } from "@urql/core";

export const LOAN_CONTRACT_FRAGMENT: LoanFragmentDoc = gql`
  fragment Loan on LoanContract {
    id
    pledgeSheetNumber
    loanStatus
    contractStartDate
    interestRate
    pledgeItemType
    pledgeItemWeight
    pledgeItemDesc
    pledgeItemEstimate
    firstMonthInterest
    processingFees
    initialInterestAmount
    initialCommissionsAmount
    closingInterest
    commission
    loanAmount
    partPaymentAmount
    partPaymentUpdatedAt
    closingPayment
    closingDate
    loanNotes
    hasTopUps
    currentLoanValue
    currentLoanValueUpdatedAt
    auctionable
    pledgeItemPurity
    pledgeItemPurityRatio
    accuredInterest
    accuredInterestUpdatedAt
    pendingInterest
    annualizedRateOfReturn
    expectedReturn
  }
`;
