import { useMemo, useRef, useState } from "react";
import { Card, DatePicker, Divider, Input } from "antd";
import clsx from "clsx";
import dayjs, { Dayjs } from "dayjs";
import { gql, useQuery } from "urql";

import { ACCOUNT_CATEGORIES_ORDERED } from "../../../constants/accounts";
import { accountsSorter, getCategoryTitle } from "../../../utils/account";
import { formatCurrency } from "../../../utils/currency";
import { formatDate, toServerDateNonNull } from "../../../utils/date";
import { sum } from "../../../utils/number";
import {
  dateParam,
  getQueryVariables,
  getURLParams,
  queryStringUpdate,
} from "../../../utils/query-params";
import FilterLabel from "../../common/FilterLabel";
import ResponsiveTable, { ResponsiveColumnType } from "../../common/ResponsiveTable";
import AccountLink from "../AccountLink";

const BALANCE_SHEET_QUERY: BalanceSheetQueryDoc = gql`
  query BalanceSheet($asOf: Date!) {
    balanceSheet(asOf: $asOf) {
      id
      description
      category
      type
      sign
      balance(asOf: $asOf)
    }
  }
`;

type BalanceSheetAccount = BalanceSheetQuery["balanceSheet"][0];

const DEFAULT_AS_OF = dayjs().subtract(1, "M").endOf("M");

type BalanceSheetParams = { asOf: string };

const COLUMNS: ResponsiveColumnType<BalanceSheetAccount>[] = [
  {
    title: "Account",
    key: "id",
    dataIndex: "id",
    render: (value, account) => <AccountLink {...account} />,
  },
  {
    title: "Type / Subtype",
    dataIndex: "category",
    key: "category",
    filters: ACCOUNT_CATEGORIES_ORDERED.map((category) => ({
      value: category,
      text: getCategoryTitle(category),
    })),
    onFilter: (value, record) => record.category.includes(value as string),
    sorter: (a, b) => a.category.localeCompare(b.category),
    render: getCategoryTitle,
    defaultPrintable: false,
  },
  {
    title: "Credit",
    key: "credit",
    dataIndex: "balance",
    render: (value, account) => (account.sign == 1 ? formatCurrency(value) : "--"),
  },
  {
    title: "Debit",
    key: "debit",
    dataIndex: "balance",
    render: (value, account) => (account.sign == -1 ? formatCurrency(value) : "--"),
  },
];

function BalanceCard({ account }: { account: BalanceSheetAccount }) {
  return (
    <div className="flex justify-between">
      <div className="flex flex-col">
        <AccountLink {...account} />
        <span className="text-gray-500 font-medium">{getCategoryTitle(account.category)}</span>
      </div>
      <div className="flex flex-col items-end text-base font-medium">
        <div className={clsx(account.sign === 1 ? "text-green-500" : "text-red-400")}>
          {account.sign === 1 ? "Credit" : "Debit"}
        </div>
        <div>{formatCurrency(account.balance)}</div>
      </div>
    </div>
  );
}

export default function BalanceSheet() {
  function getAsOf(): Dayjs {
    const params = getQueryVariables(window.location.search, {} as BalanceSheetParams);
    const asOf = dateParam(params.asOf);
    return asOf ? dayjs(asOf) : DEFAULT_AS_OF;
  }

  const [asOf, setAsOf] = useState<Dayjs>(getAsOf);
  const [searchText, setSearchText] = useState("");

  function onDateChange(value: Dayjs | null | undefined) {
    const asOf = value ? value : DEFAULT_AS_OF;
    setAsOf(asOf);
    queryStringUpdate(getURLParams({ asOf: toServerDateNonNull(asOf) }));
  }

  const variables = useMemo(() => {
    return { asOf: toServerDateNonNull(asOf) };
  }, [asOf]);
  const [{ data, fetching }] = useQuery({ query: BALANCE_SHEET_QUERY, variables });

  const { accounts } = useMemo(() => {
    if (data) {
      const accounts = data.balanceSheet
        .filter(
          (account) =>
            parseInt(`${account.balance}`) !== 0 &&
            account.description.toLowerCase().includes(searchText.toLowerCase())
        )
        .sort(accountsSorter);

      return { accounts };
    }
    {
      return { accounts: [] };
    }
  }, [data, searchText]);

  return (
    <Card
      headStyle={{ padding: 0 }}
      bodyStyle={{ padding: 0 }}
      title={
        <div className="flex w-full flex-col bg-gray-50 py-3 pr-2 pl-0.5">
          <div className="flex flex-col items-center">
            <div className="text-3xl pl-2 mb-2 lg:mb-0 font-bold text-gray-600 whitespace-normal">
              Balance Sheet
            </div>
            <div className="text-lg font-medium text-center bg-yellow-100 text-gray-600 rounded-xl px-5 py-1">
              {formatDate(variables.asOf)}
            </div>
          </div>
          <Divider className="my-1" />
          <div className="flex justify-between items-end pl-2 pb-1">
            <div className="flex flex-col px-1.5 items-start w-1/2">
              <FilterLabel label="Accounts Search:" />
              <Input.Search
                value={searchText}
                size="large"
                className="w-full max-w-sm"
                loading={fetching}
                allowClear
                placeholder="Search for an account."
                onSearch={(value) => setSearchText(value || "")}
                onChange={(event) => setSearchText(event.target.value || "")}
              />
            </div>

            <div className="flex flex-col px-0.5 items-start max-w-sm">
              <FilterLabel label="Date:" />
              <DatePicker
                allowClear={false}
                picker="date"
                size="large"
                className="w-48"
                value={dayjs(variables.asOf)}
                onChange={onDateChange}
              />
            </div>
          </div>
        </div>
      }
    >
      <ResponsiveTable<BalanceSheetAccount>
        rowKey="id"
        bordered
        dataSource={accounts}
        columns={COLUMNS}
        loading={fetching}
        pagination={false}
        footer={(data) => {
          const credit = sum(
            ...data.filter((account) => account.sign === 1).map((account) => account.balance)
          );
          const debit = sum(
            ...data.filter((account) => account.sign === -1).map((account) => account.balance)
          );
          return (
            <div className="flex w-full justify-evenly items-end">
              <div className="flex space-x-2 items-center">
                <div className="text-green-500 text-xs sm:text-lg font-bold">
                  {formatCurrency(credit)} Cr.
                </div>
              </div>
              <div className="flex space-x-1 items-center">
                <div className="text-red-400 text-xs sm:text-lg font-bold">
                  {formatCurrency(debit)} Dr.
                </div>
              </div>
            </div>
          );
        }}
        printSettings={{
          title: `BALANCE SHEET AS-OF ${formatDate(variables.asOf)}`,
        }}
        renderCard={(record) => <BalanceCard account={record} />}
      />
    </Card>
  );
}
