import { Divider } from "antd";
import dayjs from "dayjs";
import { FormikHelpers } from "formik";
import { gql, useClient, useMutation } from "urql";

import { GET_LOAN_QUERY, LOAN_TOP_UP_FRAGMENT } from "../../../hooks/api/loans/useLoan";
import useError from "../../../hooks/useError";
import { trackEvent } from "../../../utils/analytics";
import { getLoanTopUpDataForAnalytics } from "../../../utils/analytics/data";
import { toServerDateNonNull } from "../../../utils/date";
import Form from "../../form/Form";
import { FormButtons } from "../../form/FormButtons";
import { getLoanTopUpFormValidations, LoanTopUpForm, LoanTopUpFormData } from "./LoanTopUpForm";

const ADD_LOAN_TOP_UP_MUTATION: AddLoanTopUpMutationDoc = gql`
  mutation AddLoanTopUp(
    $loanContractId: Int!
    $topUpDate: Date!
    $loanAmount: Decimal!
    $firstMonthInterest: Decimal!
    $processingFees: Decimal!
    $notes: String
  ) {
    addLoanTopUp(
      loanId: $loanContractId
      topUpDate: $topUpDate
      loanAmount: $loanAmount
      firstMonthInterest: $firstMonthInterest
      processingFees: $processingFees
      notes: $notes
    ) {
      success
      failureMessage
      loanTopUp {
        ...LoanTopUp
      }
    }
  }
  ${LOAN_TOP_UP_FRAGMENT}
`;

type FormData = LoanTopUpFormData;
type FormProps = {
  loan: CustomerLoanFragment;
  onClose: (done?: boolean) => void;
};
export default function AddLoanTopUp({ loan, onClose }: FormProps) {
  const [, addTopUp] = useMutation(ADD_LOAN_TOP_UP_MUTATION);
  const client = useClient();

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return addTopUp({
      loanContractId: loan.id,
      topUpDate: values.topUpDate,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      loanAmount: values.loanAmount!,
      firstMonthInterest: values.firstMonthInterest ?? 0,
      processingFees: values.processingFees ?? 0,
      notes: values.notes,
    })
      .then(async (response) => {
        if (response.data?.addLoanTopUp?.success && response.data.addLoanTopUp.loanTopUp) {
          const loanTopUp = response.data.addLoanTopUp.loanTopUp;
          trackEvent({ action: "Add Loan Top Up" }, getLoanTopUpDataForAnalytics(loanTopUp));
          await client
            .query(GET_LOAN_QUERY, { id: loan.id }, { requestPolicy: "network-only" })
            .toPromise();
          onClose();
        } else {
          setError({
            customError: response.data?.addLoanTopUp?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to add loan top up, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{
          topUpDate: toServerDateNonNull(dayjs()),
          interestRate: loan.interestRate * 100,
          loanAmount: null,
          firstMonthInterest: null,
          processingFees: null,
          notes: "",
        }}
        validationSchema={getLoanTopUpFormValidations(loan)}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting, values }) => {
          return (
            <>
              <LoanTopUpForm values={values} loan={loan} />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
