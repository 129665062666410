import * as Yup from "yup";

import { toTitleCase } from "../../../../utils/common";
import FormMoneyField from "../../../form/FormMoneyField";
import FormRadioField from "../../../form/FormRadioField";
import FormTextField from "../../../form/FormTextField";

type AccountType = "BANK" | "UNSECURED_LOAN" | "LIABILITY" | "EXPENSE";

const VALID_ACCOUNT_TYPES: AccountType[] = ["BANK", "UNSECURED_LOAN", "LIABILITY", "EXPENSE"];

export type AccountFormData = {
  name: string;
  type: AccountType | null;
  verboseName?: string;
  openingBalance?: number;
};

const ACCOUNT_TYPE_OPTIONS = VALID_ACCOUNT_TYPES.map((type) => ({
  value: type,
  label: toTitleCase(type),
}));

export function getAccountFormValidations(): {
  [K in keyof AccountFormData]: Yup.AnySchema;
} {
  return {
    name: Yup.string().nullable().required("Account name is required."),
    type: Yup.string()
      .nullable()
      .oneOf(VALID_ACCOUNT_TYPES, "Choose one of account type")
      .required("Account type is required."),
    verboseName: Yup.string().nullable().optional(),
    openingBalance: Yup.number().nullable().optional(),
  };
}

type FormProps = { edit?: boolean };

export function AccountForm({ edit }: FormProps) {
  return (
    <>
      <FormTextField column label="Name" name="name" placeholder="Name for the account." required />

      <FormTextField
        column
        label="Verbose Name"
        name="verboseName"
        placeholder="Verbose name for the account"
      />

      {!edit && (
        <>
          <FormRadioField
            column
            optionType="button"
            buttonStyle="solid"
            label="Type"
            name="type"
            options={ACCOUNT_TYPE_OPTIONS}
            required
          />

          <FormMoneyField
            column
            label="Opening Balance"
            name="openingBalance"
            placeholder="Account's opening balance."
          />
        </>
      )}
    </>
  );
}
