import { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";

import { SERVER_DATE_FORMAT } from "../../../constants/common";
import type { ItemType } from "../../../types/enum";
import { getQueryVariables, getURLParams, queryStringUpdate } from "../../../utils/query-params";

export type Timeline = "year" | "quarter" | "month" | "week" | "day";

export type LoanMetricsFilters = {
  startDate?: string;
  endDate?: string;
  itemType?: ItemType;
  timeline?: Timeline;
};

export type LoanMetricsResult = {
  // Filter variables and callbacks.
  filters: LoanMetricsFilters;
  variables: Omit<LoanMetricsFilters, "timeline">;
  updateFilters: (filters: LoanMetricsFilters) => void;
  resetFilters: () => void;
};

export type LoanMetricsProps = { filters?: Partial<LoanMetricsFilters> };

const DEFAULT_FILTERS: Partial<LoanMetricsFilters> = {
  startDate: dayjs().subtract(1, "y").startOf("quarter").format(SERVER_DATE_FORMAT),
  endDate: dayjs().endOf("quarter").format(SERVER_DATE_FORMAT),
  timeline: "quarter",
};

export default function useLoanMetrics(props: LoanMetricsProps): LoanMetricsResult {
  function flushToURL(variables: LoanMetricsFilters) {
    queryStringUpdate(getURLParams(variables));
  }

  function deseriableVariables(): LoanMetricsFilters {
    const defaults: Partial<LoanMetricsFilters> = { ...DEFAULT_FILTERS, ...(props.filters || {}) };
    const _variables = getQueryVariables(window.location.search, defaults);
    return { ..._variables };
  }

  const [filters, setFilters] = useState<LoanMetricsFilters>(deseriableVariables);
  const variables = useMemo(() => {
    const variables = { ...filters };
    delete variables["timeline"];
    return variables;
  }, [filters]);

  const updateFilters = useCallback((filters: LoanMetricsFilters) => {
    setFilters(filters);
    flushToURL(filters);
  }, []);

  const resetFilters = useCallback(() => {
    setFilters({});
    flushToURL({});
  }, []);

  return { filters, variables, updateFilters, resetFilters };
}
