type EventNameType = "page" | "track" | "modal" | "user";

export type EventType = {
  type: EventNameType;
  data?: {
    pushPageEvent?: boolean;
    event?: { action: string; label?: string };
    user?: UserType;
    path?: string;
    search?: string;
    data?: { [id: string]: unknown };
  };
};

type UserType = {
  id: number;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
};

const enabled = process.env.NODE_ENV === "production";

const DYNAMIC_PAGE_REGEX = new RegExp(/\/([\d]+)$/im);

const getPagePath = () => {
  const pathname = window.location.pathname;
  const data = DYNAMIC_PAGE_REGEX.exec(pathname);
  const objectId = data && data[1];
  const path = (objectId ? pathname.replace(objectId, "") : pathname).replace(/\/$/, "");
  return path ? path : "/";
};

export const trackPageView = (event?: {
  path?: string;
  search?: string;
  data?: { [id: string]: unknown };
}): void => {
  const path = event?.path ? event.path : getPagePath();
  const search = event?.search || window.location.search;
  const url = `${window.location.pathname}${window.location.search}`;
  const data = event ? event.data : {};

  if (!enabled || !window.analytics) {
    console.log("[analytics] Track Page View", { path, search, url, data });
    return;
  }

  window.analytics.page(path, {
    path,
    search,
    url,
    ...data,
  });
};

export const trackEvent = (
  event: { action: string; label?: string },
  data?: { [id: string]: unknown }
): void => {
  if (!enabled || !window.analytics) {
    console.log("[analytics] Track Event", { event, data });
    return;
  }

  if (!data) {
    data = {};
  }

  // Segment accepts all args passed as event properties
  const allData = Object.assign(
    {
      url: `${window.location.pathname}${window.location.search}`,
    },
    event,
    data
  );
  window.analytics.track(event.action, allData);
};

export const setUser = (user: UserType, pushPageEvent?: boolean): void => {
  if (!enabled || !window.analytics) {
    console.log("[analytics] Set user", { user, pushPageEvent });
    return;
  }

  const id = user.id;

  window.analytics.identify(String(id), {
    phone: user.phone,
  });

  if (pushPageEvent) {
    trackPageView();
  }
};
