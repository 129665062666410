import React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export default function QuestionTooltip({ children }: { children: React.ReactNode }) {
  return (
    <Tooltip title={children}>
      <QuestionCircleOutlined className="text-xs text-gray-600" color="current" />
    </Tooltip>
  );
}
