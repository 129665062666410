import { useMemo } from "react";
import { Card } from "antd";
import dayjs from "dayjs";
import { gql, useQuery } from "urql";

import { BREAKPOINTS, SERVER_DATE_FORMAT } from "../../../constants/common";
import useDateRangeFilters from "../../../hooks/useDateRangeFilters";
import { renderCurrencyZeroPaise } from "../../../utils/currency";
import { formatDate } from "../../../utils/date";
import DateRangePickerFilter from "../../common/DateRangePickerFilter";
import ResponsiveTable, { ResponsiveColumnType } from "../../common/ResponsiveTable";
import DailyLoansTable from "./DailyLoansTable";
import { DAILY_LOANS_RECONCILIATION_FRAGMENT } from "./gql";
import RefreshMetricsAction from "./RefreshMetricsAction";

const DAILY_RECONCILIATION_QUERY: DailyLoansReconciliationQueryDoc = gql`
  query DailyLoansReconciliation($startDate: Date, $endDate: Date) {
    loanMetrics(metricDate_Gte: $startDate, metricDate_Lte: $endDate) {
      edges {
        node {
          ...DailyLoansRecociliation
        }
      }
    }
  }
  ${DAILY_LOANS_RECONCILIATION_FRAGMENT}
`;

type Metric = DailyLoansRecociliationFragment;

const COLUMNS: ResponsiveColumnType<Metric>[] = [
  {
    title: "Date",
    key: "date",
    dataIndex: "metricDate",
    render: formatDate,
    width: 125,
    cardable: { position: "top" },
  },
  {
    title: "Opened Loans",
    key: "openedLoansCount",
    dataIndex: "openedLoansCount",
    render: (value, record) => (
      <div className="whitespace-nowrap">
        {renderCurrencyZeroPaise(record.openedLoansAmount)} ({record.openedLoansCount})
      </div>
    ),
    cardable: { position: "body" },
  },
  {
    title: "Closed Loans",
    key: "closedLoansCount",
    dataIndex: "closedLoansCount",
    render: (value, record) => (
      <div className="whitespace-nowrap">
        {renderCurrencyZeroPaise(record.closedLoansAmount)} ({record.closedLoansCount})
      </div>
    ),
    cardable: { position: "body" },
  },
  {
    title: "Interest",
    key: "investorInterestAmount",
    dataIndex: "investorInterestAmount",
    render: renderCurrencyZeroPaise,
    cardable: { position: "body" },
  },
  {
    title: "Commissions",
    key: "commissionsAmount",
    dataIndex: "commissionsAmount",
    render: renderCurrencyZeroPaise,
    cardable: { position: "body" },
  },
  {
    title: "Total Outstanding",
    key: "totalOutstanding",
    dataIndex: "totalOutstanding",
    render: renderCurrencyZeroPaise,
    cardable: { position: "top", valueClassName: "font-medium text-base text-gray-600" },
    defaultPrintable: false,
  },
];

export default function LoansInOut() {
  const dateRangeFilters = useDateRangeFilters({
    defaults: {
      startDate: dayjs().subtract(1, "days").format(SERVER_DATE_FORMAT),
      endDate: dayjs().format(SERVER_DATE_FORMAT),
    },
  });

  const [{ data, fetching }] = useQuery({
    query: DAILY_RECONCILIATION_QUERY,
    variables: dateRangeFilters.variables,
    requestPolicy: "cache-and-network",
  });

  const metrics = useMemo(() => {
    const metricByDate = (data?.loanMetrics?.edges || [])
      .filter((edge) => !!edge?.node)
      .map((edge) => edge?.node)
      .map((node) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const metric = node!;
        return {
          ...metric,
          openedLoansAmount: parseFloat(`${metric.openedLoansAmount}`),
          closedLoansAmount: parseFloat(`${metric.closedLoansAmount}`),
          investorInterestAmount: parseFloat(`${metric.investorInterestAmount}`),
          commissionsAmount: parseFloat(`${metric.commissionsAmount}`),
          totalOutstanding: parseFloat(`${metric.totalOutstanding}`),
        };
      })
      .reduce((hash, value) => {
        const key = value.metricDate;
        let metric = hash[key];
        if (!metric) {
          metric = { ...value };
          hash[key] = metric;
        } else {
          metric.openedLoansCount += value.openedLoansCount;
          metric.openedLoansAmount += value.openedLoansAmount;
          metric.closedLoansAmount += value.closedLoansAmount;
          metric.closedLoansCount += value.closedLoansCount;
          metric.investorInterestAmount += value.investorInterestAmount;
          metric.commissionsAmount += value.commissionsAmount;
          metric.totalOutstanding += value.totalOutstanding;
        }
        return hash;
      }, {} as { [K: string]: DailyLoansRecociliationFragment });

    // Eliminate NIL entries.
    return Object.values(metricByDate).filter(
      (metric) => !!metric.openedLoansAmount || !!metric.closedLoansAmount
    );
  }, [data]);

  function expandedRowRender(row: Metric) {
    return <DailyLoansTable size="small" date={row.metricDate} />;
  }

  return (
    <Card
      bodyStyle={{ padding: 0 }}
      title={
        <div className="flex flex-col py-2">
          <div className="text-3xl lg:text-4xl mb-1 lg:mb-0 w-full font-bold text-center text-gray-600">
            Loans In/Out
          </div>
          <div className="flex justify-between flex-grow flex-wrap items-center">
            <DateRangePickerFilter {...dateRangeFilters} />
            <div className="pt-4">
              <RefreshMetricsAction
                variables={{
                  startDate: dateRangeFilters.variables.startDate,
                  endDate: dateRangeFilters.variables.endDate,
                }}
              />
            </div>
          </div>
        </div>
      }
    >
      <ResponsiveTable<Metric>
        rowKey="id"
        expandable={{ expandedRowRender, indentSize: 0 }}
        bordered
        dataSource={metrics}
        columns={COLUMNS}
        loading={fetching}
        scroll={{ x: BREAKPOINTS.md }}
        pagination={{ showSizeChanger: true, position: ["bottomCenter"] }}
        printSettings={{
          title: `Loans In/Out For Period ${formatDate(
            dateRangeFilters.variables.startDate
          )} - ${formatDate(dateRangeFilters.variables.startDate)}`,
          hideExpandInPrint: false,
        }}
      />
    </Card>
  );
}
