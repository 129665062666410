import { Switch, SwitchProps } from "antd";
import { getIn } from "formik";

import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

type Props = SwitchProps & { treatFalseAsNull?: boolean };

const FormSwitchField = (props: Props & WithFormFieldWrapperProps) => {
  const { formik, onChange, treatFalseAsNull, ...rest } = props;

  const handleChange = (checked: boolean) => {
    const {
      name,
      formik: { setFieldValue },
    } = props;

    if (!!checked) {
      setFieldValue(name, checked);
    } else if (treatFalseAsNull) {
      setFieldValue(name, null);
    } else {
      setFieldValue(name, checked);
    }
  };

  const { name } = rest;
  const fieldValue = getIn(formik.values, name);

  return (
    <div>
      <Switch
        {...rest}
        checked={fieldValue}
        onChange={(checked, event) => {
          handleChange(checked);
          onChange && onChange(checked, event);
        }}
      />
    </div>
  );
};

export default withFormFieldWrapper<Props>(FormSwitchField);
