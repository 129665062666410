import React from "react";
import { InputNumber, InputNumberProps } from "antd";
import { getIn } from "formik";

import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

type Props = InputNumberProps<number> & {
  inputStyle?: React.CSSProperties;
  children?: React.ReactElement;
};

const FormNumberField = (props: Props & WithFormFieldWrapperProps) => {
  const handleChange = (value: number | null) => {
    const {
      name,
      formik: { setFieldValue },
    } = props;
    setFieldValue(name, value);
  };

  const { type, inputStyle, style, formik, children, ...rest } = props;
  const { name } = rest;
  const fieldValue = getIn(formik.values, name);
  return (
    <div className="flex flex-wrap w-full items-center justify-between">
      <InputNumber
        precision={0}
        {...rest}
        className="flex-grow"
        type="number"
        value={fieldValue}
        controls={false}
        step={0.0000000000000000000000000001}
        onChange={handleChange}
        style={{ ...inputStyle }}
      />
      {children}
    </div>
  );
};

export default withFormFieldWrapper<Props>(FormNumberField);
