import dayjs, { Dayjs, isDayjs } from "dayjs";

import { DISPLAY_DATE_FORMAT, SERVER_DATE_FORMAT } from "../constants/common";

export const isDate = (obj: unknown) => obj instanceof Date;

export const toServerDateNonNull = (date: Dayjs | string | Date) => {
  if (isDate(date)) {
    return dayjs(date).format(SERVER_DATE_FORMAT);
  }

  if (isDayjs(date)) {
    return date.format(SERVER_DATE_FORMAT);
  }

  return date as string;
};

export const toServerDate = (
  date: Dayjs | string | Date | null | undefined
): string | undefined => {
  return date ? toServerDateNonNull(date) : undefined;
};

export const TIMEZONE = "Asia/Calcutta";

export const formatDate = (date?: string) =>
  date ? dayjs(date).tz(TIMEZONE).format("MMM DD, YYYY") : "--";

export const toDisplayDate = (date: Dayjs) => date.format("MMM DD, YYYY");

export const formatDateTime = (date?: string | Dayjs) =>
  date ? dayjs(date).tz(TIMEZONE).format("MMM DD, YYYY HH:mm") : "--";

export const formatDateFilename = (date?: string) =>
  date ? dayjs(date).tz(TIMEZONE).format("MMM_DD_YYYY") : "";

export const toDate = (date: string) => new Date(dayjs(date).tz("Asia/Calcutta").toDate());

export const formatDateTamil = (date: string) =>
  new Intl.DateTimeFormat("ta", { dateStyle: "long" }).format(toDate(date));

export const toDayjs = (date: Date | string | undefined, format?: string) =>
  date ? (isDate(date) ? dayjs(date) : dayjs(date, format ?? SERVER_DATE_FORMAT)) : undefined;

export type DateRange = { label: string; value: [Dayjs, Dayjs] };
export const getFinancialYearRanges = (): DateRange[] => {
  const today = dayjs();
  const currentYear = today.year();
  const nextYear = currentYear + 1;
  const prevYear = currentYear - 1;
  return [
    {
      label: `FY ${currentYear} - ${nextYear % 2000}`,
      value: [dayjs(`${currentYear}-04-01`), dayjs(`${nextYear}-03-31`)],
    },
    {
      label: `FY ${prevYear} - ${currentYear % 2000}`,
      value: [dayjs(`${prevYear}-04-01`), dayjs(`${currentYear}-03-31`)],
    },
  ];
};

export function getPlaceholderDate() {
  return dayjs().format(DISPLAY_DATE_FORMAT);
}

export type FinancialPeriod = {
  financialYear: string;
  label: string;
  value: string;
  range: { startDate: Dayjs; endDate: Dayjs };
};

export function getFinancialPeriods(): FinancialPeriod[] {
  const today = dayjs();
  const options = getFinancialYearRanges().map((fy) => ({
    financialYear: fy.label,
    label: fy.label,
    value: fy.label,
    range: { startDate: fy.value[0], endDate: fy.value[1] },
  }));

  return options.concat(
    [3, 2, 1, 0].map((quarter) => {
      const quarterStart = today.startOf("Q").subtract(quarter, "Q");
      const year = quarterStart.month() >= 3 ? quarterStart.year() : quarterStart.year() - 1;
      const nextYear = `${year + 1}`.slice(2);
      const quarterNum = quarterStart.quarter() - 1 === 0 ? 4 : quarterStart.quarter() - 1;
      const financialYear = `FY ${year} - ${nextYear}`;
      const quarterEnd = quarterStart.endOf("Q");
      return {
        financialYear,
        label: `Q${quarterNum} / ${quarterStart.format("MMM")} - ${quarterEnd.format("MMM")} '${
          quarterEnd.year() % 2000
        }`,
        value: `${financialYear} Q${quarterNum}`,
        range: { startDate: quarterStart, endDate: quarterEnd },
      };
    })
  );
}
