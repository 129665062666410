import { useCallback } from "react";
import { Button, Divider, Image } from "antd";
import { FormikHelpers } from "formik";
import * as Yup from "yup";

import useError from "../../hooks/useError";
import { getApiUrl, getCSRFToken } from "../../utils/api";
import { FALLBACK_IMAGE } from "../../utils/image";
import Form from "../form/Form";
import FormTextField from "../form/FormTextField";

type FormData = { text: string };

export default function DeleteDocument({
  document,
  onSucess,
}: {
  document: NidhiDocumentFragment;
  onSucess: () => void;
}) {
  const { error, setError, resetError } = useError();

  const onDelete = useCallback(
    (values: FormData, formikActions: FormikHelpers<FormData>) => {
      const formData = new FormData();
      formData.append("documentKey", document.key);
      resetError();

      fetch(getApiUrl("/api/document/delete/"), {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: {
          Accept: "application/json",
          ...getCSRFToken(),
        },
        redirect: "error",
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            onSucess();
          } else {
            setError(response.message);
          }
        })
        .catch(() => {
          setError({ customError: "Internal server error" });
        })
        .finally(() => formikActions.setSubmitting(false));
    },
    [document.key, onSucess, setError, resetError]
  );

  return (
    <>
      <div className="w-full text-center">
        <Image
          loading="eager"
          src={document.url}
          fallback={FALLBACK_IMAGE}
          className="rounded-xl w-full"
          preview={false}
        />
      </div>

      <Form<FormData>
        initialValues={{ text: "" }}
        validationSchema={{
          text: Yup.string()
            .required('Type "delete" to delete the document.')
            .nullable(true)
            .equals(["delete"], "Type exact delete word."),
        }}
        onSubmit={onDelete}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormTextField
                autoFocus
                autoComplete="off"
                label={
                  <span className="text-gray-600 text-sm font-medium">
                    Enter &quot;delete&quot; in the text box below to delete the document
                  </span>
                }
                required
                name="text"
                placeholder="delete"
                column
              />

              <Divider />

              <Button
                shape="round"
                size="large"
                loading={isSubmitting}
                type="primary"
                danger
                htmlType="submit"
              >
                Delete
              </Button>

              {error && <div className="text-red-500 text-center my-2">{error.toString()}</div>}
            </>
          );
        }}
      </Form>
    </>
  );
}
