import dayjs from "dayjs";
import * as Yup from "yup";

import FormDateField from "../../form/FormDateField";
import {
  LOAN_AMOUNT_FORM_VALIDATIONS,
  LoanAmountForm,
  LoanAmountFormData,
} from "../../loans/forms/LoanForm";

export type LoanTopUpFormData = LoanAmountFormData & {
  topUpDate: string;
};

export function getLoanTopUpFormValidations(loan: CustomerLoanFragment): {
  [K in keyof LoanTopUpFormData]: Yup.AnySchema;
} {
  return {
    topUpDate: Yup.date()
      .nullable()
      .min(loan.contractStartDate, "")
      .required("Payment date is required"),
    ...LOAN_AMOUNT_FORM_VALIDATIONS,
  };
}

type FormProps = { edit?: boolean; loan: CustomerLoanFragment; values: LoanTopUpFormData };
export function LoanTopUpForm({ edit, loan, values }: FormProps) {
  const contractStartDate = dayjs(loan.contractStartDate);
  return (
    <>
      <FormDateField
        label="Top Up Date"
        name="topUpDate"
        placeholder="Top Up Date"
        required
        column
        disabledDate={(date) => date < contractStartDate || date > dayjs()}
      />

      <LoanAmountForm column values={values} edit={edit} />
    </>
  );
}
