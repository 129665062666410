import { message, Modal } from "antd";

import { LoanTopUpActionResult } from "../../../hooks/useLoanTopUpAction";
import { scrollToBottom } from "../../../utils/scroll";
import CloseIcon from "../../common/CloseIcon";
import LoanCard from "../../loans/LoanCard";
import AddLoanTopUp from "./AddLoanTopUp";
import DeleteLoanTopUp from "./DeleteLoanTopUp";
import EditLoanTopUp from "./EditLoanTopUp";

type Props = LoanTopUpActionResult;

export default function LoanTopUpFormModal(props: Props) {
  const { loan, action, loanTopUp, closeLoanTopUpForm, showForm } = props;

  function onClose(msg?: string) {
    if (msg) {
      message.success(msg);
    }
    closeLoanTopUpForm();
    scrollToBottom();
  }

  return showForm && action && loan ? (
    <Modal
      title={
        <>
          <h4 className="my-2 font-bold text-2xl text-center">
            {action.toLocaleUpperCase()} TOP UP
          </h4>

          <LoanCard loan={loan} />
        </>
      }
      centered
      closable
      maskClosable
      open
      closeIcon={<CloseIcon />}
      footer={false}
      onCancel={() => closeLoanTopUpForm()}
    >
      {loanTopUp && action === "edit" ? (
        <EditLoanTopUp
          loanTopUp={loanTopUp}
          loan={loan}
          onClose={(done) => onClose(done ? "Successfully edited the loan top up." : undefined)}
        />
      ) : loanTopUp && action === "delete" ? (
        <DeleteLoanTopUp
          loan={loan}
          loanTopUp={loanTopUp}
          onClose={(done) => onClose(done ? "Successfully deleted the loan top up." : undefined)}
        />
      ) : (
        <AddLoanTopUp
          loan={loan}
          onClose={(done) => onClose(done ? "Successfully added the loan top up." : undefined)}
        />
      )}
    </Modal>
  ) : null;
}
