/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql } from "@urql/core";
import { Button, Divider } from "antd";
import { FormikHelpers } from "formik";
import { useMutation } from "urql";
import * as Yup from "yup";

import useError from "../../../hooks/useError";
import { Loan } from "../../../hooks/useLoanAction";
import { trackEvent } from "../../../utils/analytics";
import { getLoanDataForAnalytics } from "../../../utils/analytics/data";
import Form from "../../form/Form";
import FormNumberField from "../../form/FormNumberField";
import FormTextArea from "../../form/FormTextArea";
import { LOAN_CONTRACT_FRAGMENT } from "../fragments";

const REVIVE_LOAN_MUTATION: ReviveLoanMutationDoc = gql`
  mutation ReviveLoan($loanId: Int!, $notes: String!) {
    reviveLoan(loanId: $loanId, loanNotes: $notes) {
      success
      failureMessage
      loan {
        ...Loan
      }
    }
  }
  ${LOAN_CONTRACT_FRAGMENT}
`;

type FormProps = {
  loan: Loan;
  onSuccess: () => void;
};

type FormData = {
  notes: string;
  psn: number | null;
};

export default function ReviveLoanForm({ loan, onSuccess }: FormProps) {
  const [, reviveLoan] = useMutation(REVIVE_LOAN_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return reviveLoan({ loanId: loan.id, notes: values.notes })
      .then((response) => {
        if (response.data?.reviveLoan?.success && response.data.reviveLoan.loan) {
          onSuccess();
          const loan = response.data.reviveLoan.loan;
          trackEvent({ action: "Revive Loan" }, getLoanDataForAnalytics(loan));
        } else {
          setError({
            customError: response.data?.reviveLoan?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to reopen loan, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{ psn: null, notes: loan.loanNotes || "" }}
        validationSchema={{
          psn: Yup.number()
            .required("Enter the PSN to reopen the loan.")
            .nullable(true)
            .equals([loan.pledgeSheetNumber], "Enter the PSN correctly to reopen the loan."),
          notes: Yup.string().required("Enter the reason for reopening the loan.").nullable(),
        }}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormNumberField
                label="Pledge Sheet Number"
                helper={
                  <span className="text-gray-600 text-sm font-medium">
                    Enter the &quot;PLEDGE SHEET NUMBER&quot; of the loan below to proceed with the
                    loan reopen.
                  </span>
                }
                name="psn"
                precision={0}
                placeholder={`${loan.pledgeSheetNumber}`}
                column
                required
              />

              <FormTextArea
                required
                helper="Reason for reopening this loan."
                label="Reason"
                name="notes"
                placeholder="Provide a reason for reopening this loan"
                column
              />

              <Divider />

              <Button
                shape="round"
                size="large"
                loading={isSubmitting}
                type="primary"
                danger
                htmlType="submit"
              >
                Submit
              </Button>
            </>
          );
        }}
      </Form>
    </div>
  );
}
