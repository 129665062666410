import { useCallback, useState } from "react";

const useDebounceFn = <T extends unknown>(callback?: (...args: T[]) => void, time = 100) => {
  const [timeoutObject, setTimeoutObject] = useState<NodeJS.Timeout | null>(null);
  return useCallback(
    (...args: T[]) => {
      const later = () => {
        setTimeoutObject(null);
        callback?.(...args);
      };
      const callNow = !timeoutObject;
      if (timeoutObject) clearTimeout(timeoutObject);
      setTimeoutObject(setTimeout(later, time));
      if (callNow) callback?.(...args);
    },
    [callback, time, timeoutObject]
  );
};

export default useDebounceFn;
