import { Radio } from "antd";

import { LoanMetricsResult, Timeline } from "../../hooks/api/loans/useLoanMetrics";
import useMobile from "../../hooks/useMobile";

type Props = Pick<LoanMetricsResult, "filters" | "updateFilters"> & { timelines?: Timeline[] };

const TIMELINES: Timeline[] = ["day", "week", "month", "quarter", "year"];

export default function TimelineSelector({ filters, updateFilters, timelines = TIMELINES }: Props) {
  const { isMobile } = useMobile();

  return (
    <div className="flex flex-col px-0.5 items-start max-w-sm">
      <div className="text-base font-medium pb-0.5 pl-0.5 text-gray-500">Timeline:</div>
      <Radio.Group
        name="timeline"
        defaultValue={filters.timeline}
        size={isMobile ? "small" : "large"}
        buttonStyle="solid"
        onChange={(e) => {
          updateFilters({ ...filters, timeline: e.target.value });
        }}
        options={timelines.map((timeline) => ({ value: timeline, label: timeline.toUpperCase() }))}
        optionType="button"
      />
    </div>
  );
}
