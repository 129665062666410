import { LoadingOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import clsx from "clsx";

import usePermissions from "../../hooks/api/usePermissions";
import type { PermissionType } from "../../types/enum";

type Props = {
  icon: React.ReactNode;
  perm?: PermissionType;
  onClick: () => void;
  loading?: boolean;
} & ButtonProps;

export default function ActionIconButton({
  icon,
  perm,
  loading,
  disabled,
  onClick,
  className,
  ...rest
}: Props) {
  const { hasPerm } = usePermissions();
  const unauthorized = perm ? !hasPerm(perm) : false;

  return (
    <Button
      type="primary"
      shape="circle"
      className={clsx("leading-none", className)}
      ghost
      disabled={loading || disabled || unauthorized}
      icon={loading ? <LoadingOutlined /> : icon}
      onClick={onClick}
      {...rest}
    />
  );
}
