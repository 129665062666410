import { useMemo, useState } from "react";
import { Card, Divider, Input } from "antd";
import { Key } from "antd/lib/table/interface";
import dayjs from "dayjs";
import { gql, useQuery } from "urql";

import { ACCOUNT_CATEGORIES_ORDERED } from "../../../constants/accounts";
import { BREAKPOINTS, SERVER_DATE_FORMAT } from "../../../constants/common";
import { AccountCategoryEnum } from "../../../gql/graphql";
import { TRANSACTION_LEG_FRAGMENT } from "../../../hooks/api/accounts/useAccountTransactionsSearch";
import useDateRangeFilters from "../../../hooks/useDateRangeFilters";
import { accountsSorter, getCategoryTitle } from "../../../utils/account";
import { formatCurrency, signBalance } from "../../../utils/currency";
import { formatDate } from "../../../utils/date";
import DateRangePickerFilter from "../../common/DateRangePickerFilter";
import FilterLabel from "../../common/FilterLabel";
import ResponsiveTable, { ResponsiveColumnType } from "../../common/ResponsiveTable";
import AccountLink from "../AccountLink";
import { ACCOUNT_TRANSACTION_COLUMNS } from "./AccountTransactions";

const ACCOUNT_BALANCES_QUERY: AccountBalancesQueryDoc = gql`
  query AccountBalances($startDate: Date!, $endDate: Date!) {
    accountBalances(startDate: $startDate, endDate: $endDate, hasTransactions: true) {
      account {
        id
        description
        category
        sign
      }
      openingBalance
      closingBalance
      transactions {
        ...TransactionLeg
      }
    }
  }
  ${TRANSACTION_LEG_FRAGMENT}
`;

const renderValue = (value: number) => (
  <div className="whitespace-nowrap">{formatCurrency(value)}</div>
);

const COLUMNS: ResponsiveColumnType<AccountBalance>[] = [
  {
    title: "Account",
    key: "id",
    dataIndex: "id",
    render: (value, account) => <AccountLink {...account} />,
    width: 300,
    cardable: { position: "top" },
  },
  {
    title: "Type / Subtype",
    dataIndex: "category",
    key: "category",
    filters: ACCOUNT_CATEGORIES_ORDERED.map((category) => ({
      value: category,
      text: getCategoryTitle(category),
    })),
    onFilter: (value, record) => record.category.includes(value as string),
    sorter: (a, b) => a.category.localeCompare(b.category),
    render: getCategoryTitle,
    cardable: { position: "top", valueClassName: "text-sm text-gray-500 font-medium" },
    defaultPrintable: false,
  },
  {
    title: "Opening Balance",
    key: "openingBalance",
    dataIndex: "openingBalance",
    render: renderValue,
    cardable: { position: "body" },
  },
  {
    title: "Closing Balance",
    key: "closingBalance",
    dataIndex: "closingBalance",
    render: renderValue,
    cardable: { position: "body" },
  },
  {
    title: "Net Change",
    key: "difference",
    dataIndex: "closingBalance",
    render: (_, account) => renderValue(account.closingBalance - account.openingBalance),
    cardable: { position: "body" },
  },
];

type AccountBalance = AccountBalancesQuery["accountBalances"][0]["account"] &
  Omit<AccountBalancesQuery["accountBalances"][0], "account"> & { category: AccountCategoryEnum };

const DEFAULT_FILTERS = {
  startDate: dayjs().subtract(1, "D").format(SERVER_DATE_FORMAT),
  endDate: dayjs().subtract(1, "D").format(SERVER_DATE_FORMAT),
};

export default function DayBook() {
  const dateRangeFilters = useDateRangeFilters({ defaults: DEFAULT_FILTERS });
  const { filters } = dateRangeFilters;
  const [searchText, setSearchText] = useState("");
  const [selectedAccountId, setSelectedAccountId] = useState<Key>();

  const variables = useMemo(() => {
    return { ...DEFAULT_FILTERS, ...filters };
  }, [filters]);
  const [{ data, fetching }] = useQuery({
    query: ACCOUNT_BALANCES_QUERY,
    variables,
    requestPolicy: "cache-and-network",
  });

  const { accounts } = useMemo(() => {
    if (data) {
      const accounts = data.accountBalances
        .filter((account) =>
          account.account.description.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((account) => ({
          ...account.account,
          ...account,
          openingBalance: signBalance(account.openingBalance, account.account.sign),
          closingBalance: signBalance(account.closingBalance, account.account.sign),
          account: undefined,
        }))
        .sort(accountsSorter);
      return { accounts };
    }
    {
      return { accounts: [] };
    }
  }, [data, searchText]);

  function onExpand(expanded: boolean, account: AccountBalance) {
    setSelectedAccountId(expanded ? account.id : undefined);
  }

  function expandedRowRender(account: AccountBalance) {
    return (
      <ResponsiveTable<TransactionLegFragment>
        rowKey="id"
        size="small"
        bordered
        columns={ACCOUNT_TRANSACTION_COLUMNS}
        dataSource={account.transactions}
        pagination={false}
        printSettings={false}
      />
    );
  }

  return (
    <Card
      headStyle={{ padding: 0 }}
      bodyStyle={{ padding: 0 }}
      title={
        <div className="flex w-full pt-3 pb-2 px-3 flex-wrap">
          <div className="flex w-full justify-center sm:justify-between items-center mb-1.5 flex-wrap">
            <div className="flex flex-col space-y-2 mr-1">
              <div className="text-3xl font-bold text-gray-600 whitespace-normal text-center">
                Day Book
              </div>
              <div className="text-lg font-medium text-center bg-yellow-50 text-gray-600 rounded-xl px-2 py-1">
                {formatDate(filters.startDate)} - {formatDate(filters.endDate)}
              </div>
            </div>

            <div className="flex flex-col px-1.5 items-start">
              <FilterLabel label="Accounts Search:" />
              <Input.Search
                value={searchText}
                size="large"
                className="w-full max-w-xs"
                loading={fetching}
                allowClear
                placeholder="Search for an account."
                onSearch={(value) => setSearchText(value || "")}
                onChange={(event) => setSearchText(event.target.value || "")}
              />
            </div>
          </div>
          <Divider className="my-1" />
          <div className="flex justify-center w-full">
            <DateRangePickerFilter allowClear={false} {...dateRangeFilters} />
          </div>
        </div>
      }
    >
      <ResponsiveTable<AccountBalance>
        rowKey="id"
        expandable={{
          expandedRowRender,
          onExpand,
          indentSize: 0,
          expandedRowKeys: selectedAccountId ? [selectedAccountId] : undefined,
        }}
        bordered
        // scroll={{ y: 650, x: BREAKPOINTS.md }}
        dataSource={accounts}
        columns={COLUMNS}
        loading={fetching}
        pagination={false}
        printSettings={{
          title: `DAY BOOK FOR PERIOD ${formatDate(filters.startDate)} - ${formatDate(
            filters.endDate
          )}`,
          hideExpandInPrint: true,
        }}
      />
    </Card>
  );
}
