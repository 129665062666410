import { useCallback, useState } from "react";
import { message } from "antd";
import { gql, useMutation } from "urql";

import { DAILY_LOANS_RECONCILIATION_FRAGMENT } from "../../../components/accounts/loans-reconciliation/gql";

const REFRESH_METRICS: RefreshMetricsMutationDoc = gql`
  mutation RefreshMetrics($startDate: Date!, $endDate: Date!) {
    refreshMetrics(startDate: $startDate, endDate: $endDate) {
      success
      error
      loanMetrics(metricDate_Gte: $startDate, metricDate_Lte: $endDate) {
        edges {
          node {
            ...DailyLoansRecociliation
          }
        }
      }
    }
  }
  ${DAILY_LOANS_RECONCILIATION_FRAGMENT}
`;

export default function useRefreshMetrics() {
  const [, refreshMetrics] = useMutation(REFRESH_METRICS);
  const [refreshing, setRefreshing] = useState(false);

  const refresh = useCallback(
    async ({ startDate, endDate }: RefreshMetricsMutationVariables) => {
      setRefreshing(true);
      await refreshMetrics({ startDate, endDate })
        .then((data) => {
          const error = data.error?.message ?? data.data?.refreshMetrics?.error;
          if (error) {
            console.error("Refresh metrics failed: ", error);
            if (error.includes("You do not have permission")) {
              message.error("Failed to refresh metrics -- Unauthorized");
            } else {
              message.error("Failed to refresh metrics.");
            }
          } else {
            message.success("Successfully refreshed the metrics.");
          }

          setRefreshing(false);
        })
        .catch((error) => {
          message.error("Failed to refresh metrics.");
          console.error("Failed to refresh metrics", error);
        })
        .finally(() => setRefreshing(false));
    },
    [refreshMetrics]
  );

  return { refresh, refreshing };
}
