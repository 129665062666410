import { useCallback, useMemo, useState } from "react";
import { gql, useQuery } from "urql";

import { LOAN_CONTRACT_FRAGMENT } from "../../../components/loans/fragments";
import { trackEvent } from "../../../utils/analytics";
import {
  arrayParam,
  booleanParam,
  dateParam,
  getQueryVariables,
  getURLParams,
  intParam,
  queryStringUpdate,
} from "../../../utils/query-params";

export const CUSTOMER_LOAN_FRAGMENT = gql`
  fragment CustomerLoan on LoanContract {
    ...Loan
    customer {
      id
      address
      careOfType
      careOfName
      user {
        id
        name
        phone
      }
    }
  }
  ${LOAN_CONTRACT_FRAGMENT}
`;

export const LOANS_SEARCH_QUERY: LoansSearchQueryDoc = gql`
  query LoansSearch(
    $offset: Int!
    $first: Int!
    $order: [LoanContractOrder!]
    $customerId: Int
    $loanStatus: GoldLoansLoanContractLoanStatusChoices
    $auctionable: Boolean
    $contractStartDateMin: Date
    $contractStartDateMax: Date
    $closingDateMin: Date
    $closingDateMax: Date
    $eventDates: String
    $itemType: GoldLoansLoanContractPledgeItemTypeChoices
    $loanAmountMin: Decimal
    $loanAmountMax: Decimal
    $partPaymentAmountMin: Decimal
    $partPaymentAmountMax: Decimal
    $interestRateMin: Decimal
    $interestRateMax: Decimal
    $itemWeightMin: Decimal
    $itemWeightMax: Decimal
  ) {
    loanContracts(
      offset: $offset
      first: $first
      order: $order
      customerId: $customerId
      loanStatus: $loanStatus
      auctionable: $auctionable
      contractStartDate_Gte: $contractStartDateMin
      contractStartDate_Lte: $contractStartDateMax
      closingDate_Gte: $closingDateMin
      closingDate_Lte: $closingDateMax
      eventDates: $eventDates
      pledgeItemType: $itemType
      loanAmount_Gte: $loanAmountMin
      loanAmount_Lte: $loanAmountMax
      partPaymentAmount_Gte: $partPaymentAmountMin
      partPaymentAmount_Lte: $partPaymentAmountMax
      interestRate_Gte: $interestRateMin
      interestRate_Lte: $interestRateMax
      pledgeItemWeight_Gte: $itemWeightMin
      pledgeItemWeight_Lte: $itemWeightMax
    ) {
      totalCount
      edges {
        node {
          ...CustomerLoan
        }
      }
    }
  }
  ${CUSTOMER_LOAN_FRAGMENT}
`;

const DEFAULT_LOANS_LIMIT = 10;
export type Variables = LoansSearchQueryVariables;

export type LoansSearchResult = {
  // Filter variables and callbacks.
  variables: Variables;
  updateVariables: (variables: Variables) => void;
  resetVariables: () => void;
  inlineMode: boolean;

  // Data
  loans?: CustomerLoanFragment[];
  total?: number;
  loading: boolean;
};

function flushToURL(variables: Variables) {
  queryStringUpdate(getURLParams(variables));
}

const DEFAULT_VARIABLES: Variables = {
  first: DEFAULT_LOANS_LIMIT,
  offset: 0,
};

export type LoansSearchProps = {
  filters?: Partial<Variables>;
  inlineMode?: boolean;
};

export default function useLoansSearch({
  filters: initialFilters,
  inlineMode,
}: LoansSearchProps): LoansSearchResult {
  function deseriableFilters(): Variables {
    const defaults = { ...DEFAULT_VARIABLES, ...(initialFilters || {}) };
    const _variables = getQueryVariables(inlineMode ? "" : window.location.search, defaults);
    return {
      ..._variables,
      offset: intParam(_variables.offset) || DEFAULT_VARIABLES.offset,
      first: intParam(_variables.first) || DEFAULT_VARIABLES.first,
      customerId: intParam(_variables.customerId),
      auctionable: booleanParam(`${_variables.auctionable}`),

      contractStartDateMin: dateParam(_variables.contractStartDateMin),
      contractStartDateMax: dateParam(_variables.contractStartDateMax),
      closingDateMin: dateParam(_variables.closingDateMin),
      closingDateMax: dateParam(_variables.closingDateMax),

      order: _variables.order ? arrayParam(_variables.order) : undefined,
    };
  }

  const [filters, setFilters] = useState<Variables>(deseriableFilters);

  const variables = useMemo(() => {
    // sanitize variables.
    const variables = { ...filters };
    if (!variables.auctionable) {
      delete variables["auctionable"];
    }

    return variables;
  }, [filters]);

  const [{ data, fetching }] = useQuery({ query: LOANS_SEARCH_QUERY, variables });

  const loans = useMemo(() => {
    if (!data || !data.loanContracts?.edges) {
      return [];
    }

    return data.loanContracts.edges
      .map((node) => node?.node)
      .filter((node) => !!node) as CustomerLoanFragment[];
  }, [data]);

  const updateVariables = useCallback(
    (variables: Variables) => {
      setFilters(variables);
      if (!inlineMode) {
        flushToURL(variables);
      }
      trackEvent({ action: "Loans Search", label: "Update" }, variables);
    },
    [inlineMode]
  );

  const resetVariables = useCallback(() => {
    setFilters({ offset: 0, first: DEFAULT_LOANS_LIMIT });
    flushToURL(DEFAULT_VARIABLES);
    trackEvent({ action: "Loans Search", label: "Reset" });
  }, []);

  return {
    inlineMode: !!inlineMode,
    variables,
    loans,
    total: data?.loanContracts?.totalCount,
    loading: fetching,

    updateVariables,
    resetVariables,
  };
}
