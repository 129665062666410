import { Button, Divider } from "antd";
import clsx from "clsx";
import { FormikHelpers } from "formik";
import * as Yup from "yup";

import type { Gender } from "../../gql/graphql";
import { Document } from "../../hooks/api/documents/useUploadDocument";
import { getCustomerDocumentName } from "../../utils/document";
import Form from "../form/Form";
import FormCustomerSearchField, { CustomerSearchResult } from "../form/FormCustomerSearchField";
import FormPhoneField from "../form/FormPhoneField";
import FormPhotoInput from "../form/FormPhotoInput";
import FormRadioField from "../form/FormRadioField";
import FormSelectField from "../form/FormSelectField";
import FormSwitchField from "../form/FormSwitchField";
import FormTextArea from "../form/FormTextArea";
import FormTextField from "../form/FormTextField";

const CARE_OF_OPTIONS = [
  { value: "S/O", label: "Son Of - S/O" },
  { value: "D/O", label: "Daughter Of - D/O" },
  { value: "W/O", label: "Wife Of - W/O" },
  { value: "H/O", label: "Husband Of - H/O" },
  { value: "C/O", label: "Care Of - C/O" },
];

const GENDER_OPTIONS = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];

export type FormData = {
  name: string;
  address: string;
  gender: Gender;

  phone?: string;
  careOfName?: string;
  careOfType?: string;
  occupation?: string;

  primaryCustomerId?: number;
  primaryCustomer?: CustomerSearchResult;

  photoDocId?: number;
  photoDoc?: Document;

  photoIdProofDocId?: number;
  photoIdProofDoc?: Document;
  isRepledgeCustomer: boolean;
};

type Props = {
  customerId?: number;
  initialValues: FormData;
  onSubmit: (values: FormData, formikActions: FormikHelpers<FormData>) => void;
  error?: string;
  edit?: boolean;
  onCancel?: () => void;
};

export function cleanFormValues(values: FormData) {
  const input = { ...values };

  delete input["photoDoc"];
  delete input["photoIdProofDoc"];
  delete input["primaryCustomer"];

  return input;
}

export default function CustomerForm({
  customerId,
  initialValues,
  onSubmit,
  error,
  edit,
  onCancel,
}: Props) {
  return (
    <div className="w-full max-w-screen-md pb-12 md:pb-24">
      <h4 className="mt-2 mb-5 font-bold text-2xl text-center">{edit ? "EDIT" : "ADD"} CUSTOMER</h4>

      <Form<FormData>
        initialValues={initialValues}
        validationSchema={{
          name: Yup.string()
            .nullable(true)
            .required("Name is required.")
            .min(5, "Name should be atleast 5 chars."),
          phone: Yup.string()
            .nullable()
            .optional()
            .min(10, "Phone number should have 10 digits.")
            .max(10, "Phone number should have 10 digits."),
          address: Yup.string()
            .nullable()
            .required("Address is required")
            .min(10, "Address should be atleast 10 chars."),
          gender: Yup.string()
            .nullable()
            .oneOf(
              GENDER_OPTIONS.map((option) => option.value),
              `Gender must be either ${GENDER_OPTIONS.map((option) => option.label).join(", ")}`
            )
            .required("Gender is required."),
        }}
        onSubmit={onSubmit}
        error={error}
      >
        {({ values, isSubmitting }) => {
          return (
            <>
              <FormTextField
                label="Name"
                name="name"
                placeholder="Full name of the customer"
                required
              />

              <FormRadioField
                optionType="button"
                label="Gender"
                name="gender"
                options={GENDER_OPTIONS}
                required
              />

              <FormPhoneField label="Phone" name="phone" />

              <FormSelectField
                label="Care Of"
                name="careOfType"
                placeholder="Select care taker type"
                options={CARE_OF_OPTIONS}
              />

              <FormTextField
                label="Care taker name"
                name="careOfName"
                placeholder="Care taker full name"
              />

              <FormTextArea
                label="Address"
                name="address"
                placeholder="Customer address"
                showCount
                maxLength={200}
                required
              />

              <FormTextField
                label="Occupation"
                name="occupation"
                placeholder="Enter customer occupation"
              />

              <FormSwitchField
                label="Repledge Customer"
                name="isRepledgeCustomer"
                disabled={!values.name}
              />

              {edit && (
                <FormCustomerSearchField
                  label="Primary Customer"
                  name="primaryCustomerId"
                  customerField="primaryCustomer"
                  placeholder="Type customer name or phone number."
                  disabledCustomerIds={customerId ? [customerId] : []}
                  primaryCustomersOnly
                />
              )}

              <FormPhotoInput
                label="Photo"
                name="photoDocId"
                documentField="photoDoc"
                documentType="CUSTOMER_PHOTO"
                customerId={customerId}
                assignToCustomer={!!customerId}
                filename={getCustomerDocumentName(values.name, "CUSTOMER_PHOTO")}
                disabled={!values.name}
              />

              <FormPhotoInput
                label="Photo ID Proof"
                name="photoIdProofDocId"
                documentField="photoIdProofDoc"
                documentType="CUSTOMER_ID_CARD"
                customerId={customerId}
                assignToCustomer={!!customerId}
                filename={getCustomerDocumentName(values.name, "CUSTOMER_ID_CARD")}
                disabled={!values.name}
              />

              <Divider />

              <div className="flex w-full space-x-2 sm:space-x-4">
                {onCancel && (
                  <Button
                    className="w-1/3"
                    shape="round"
                    size="large"
                    loading={isSubmitting}
                    type="default"
                    onClick={() => onCancel && onCancel()}
                  >
                    Cancel
                  </Button>
                )}

                <Button
                  className={clsx(onCancel ? "w-2/3" : "w-full")}
                  shape="round"
                  size="large"
                  loading={isSubmitting}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
}
