import { Select, SelectProps } from "antd";
import { getIn } from "formik";

import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

type ValueType = { label: string; value: string | number };
type Props = SelectProps<ValueType> & {
  name: string;
  options: ValueType[];
  parseValue?: (value: string) => string | string[] | number | number[];
  formatValue?: (value: string | string[] | number | number[]) => string | number;
};

const FormSelectField = (props: Props & WithFormFieldWrapperProps) => {
  const { formik, parseValue, formatValue, ...rest } = props;
  const { name } = rest;
  const fieldValue = getIn(formik.values, name);
  const value = fieldValue && formatValue ? formatValue(fieldValue) : fieldValue;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleChange(value: any) {
    const {
      name,
      formik: { setFieldValue },
    } = props;
    setFieldValue(name, value && parseValue ? parseValue(value) : value);
  }

  return (
    <Select<ValueType>
      defaultValue={value}
      onChange={handleChange}
      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
      {...rest}
    />
  );
};

export default withFormFieldWrapper<Props>(FormSelectField);
