import { Divider } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { FormikHelpers } from "formik";
import { gql, useMutation } from "urql";
import * as Yup from "yup";

import { ACCOUNT_CUT_OFF_DATE } from "../../../constants/accounts";
import { refetchTransactions } from "../../../hooks/api/accounts/useAccountTransactionsSearch";
import useError from "../../../hooks/useError";
import { trackEvent } from "../../../utils/analytics";
import { getAccountDataForAnalytics } from "../../../utils/analytics/data";
import { getPlaceholderDate, toServerDateNonNull } from "../../../utils/date";
import Form from "../../form/Form";
import FormAccountSearchField from "../../form/FormAccountSearchField";
import { FormButtons } from "../../form/FormButtons";
import FormDateField from "../../form/FormDateField";
import FormMoneyField from "../../form/FormMoneyField";
import FormTextArea from "../../form/FormTextArea";

const RECORD_EXPENSE_MUTATION: RecordExpenseMutationDoc = gql`
  mutation RecordExpense(
    $expenseAccountId: Int!
    $amount: Decimal!
    $date: Date!
    $description: String!
  ) {
    recordExpense(
      accountId: $expenseAccountId
      amount: $amount
      date: $date
      description: $description
    ) {
      success
      failureMessage
      transactions {
        id
        legs {
          id
          account {
            id
            currentBalance
          }
        }
      }
    }
  }
`;

type FormData = {
  expenseAccountId: number | null;
  account?: AccountDetailsFragment;

  amount: number | null;
  date: string | Dayjs;
  description: string;
};

type FormProps = {
  account?: AccountDetailsFragment;
  onClose: (done?: boolean, account?: AccountDetailsFragment) => void;
  column?: boolean;
};

const FORM_VALIDATIONS: {
  [K in keyof FormData]: Yup.AnySchema;
} = {
  expenseAccountId: Yup.number().nullable().required("Expense account id is required."),
  amount: Yup.number()
    .nullable()
    .required("Amount is required.")
    .test("non-zero", "Expense amount can't be ZERO!", (value) => {
      return value !== 0;
    }),
  description: Yup.string().nullable().required("Description is required."),
  date: Yup.date().nullable().required("Date of expense is required"),
};

export default function RecordExpense({ column, account, onClose }: FormProps) {
  const [, record] = useMutation(RECORD_EXPENSE_MUTATION);

  const { error, setError, resetError } = useError();
  const purchase = account?.subtype === "PROPERTY";

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    if (!values.amount || !values.expenseAccountId || !values.account) {
      formikActions.setSubmitting(false);
      return;
    }
    const accountId = values.expenseAccountId;
    const account = values.account;

    return record({
      expenseAccountId: accountId,
      amount: values.amount,
      date: toServerDateNonNull(values.date),
      description: values.description,
    })
      .then(async (response) => {
        if (response.data?.recordExpense?.success && response.data.recordExpense.transactions) {
          trackEvent({ action: "Record Expense" }, getAccountDataForAnalytics(account));

          refetchTransactions(accountId);
          onClose(true, account);
        } else {
          setError({
            gqlError: response.error,
            customError: response.data?.recordExpense?.failureMessage,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to record expense, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-md pb-6">
      <Form<FormData>
        initialValues={{
          account,
          expenseAccountId: account ? account.id : null,
          amount: null,
          description: "",
          date: dayjs().startOf("day"),
        }}
        validationSchema={FORM_VALIDATIONS}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting }) => {
          return (
            <>
              {!account && (
                <FormAccountSearchField
                  name="expenseAccountId"
                  required
                  column={column}
                  label="Expense Account"
                  placeholder="Select an expense account"
                  accountField="account"
                  accountsSearchProps={{ types: "EXPENSE" }}
                />
              )}

              <FormDateField
                label={`${purchase ? "Purchase" : "Expense"} Date`}
                column={column}
                name="date"
                placeholder={getPlaceholderDate()}
                required
                disabledDate={(date) => date <= ACCOUNT_CUT_OFF_DATE || date > dayjs()}
              />

              <FormMoneyField
                column={column}
                label="Amount"
                name="amount"
                placeholder="1,000"
                helper="Enter the expense amount, for reversal/corrections enter the amount in negative like -500."
                required
              />

              <FormTextArea
                column={column}
                required
                label="Description"
                name="description"
                placeholder="Description about the expense."
              />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
