import { Tag } from "antd";
import clsx from "clsx";

import type { GoldLoansLoanContractPledgeItemTypeChoices } from "../../gql/graphql";

export default function PledgeItemType({
  className,
  value,
}: {
  className?: string;
  value: GoldLoansLoanContractPledgeItemTypeChoices;
}) {
  return (
    <Tag
      className={clsx(className, "rounded-lg")}
      color={value === "GOLD" ? "gold" : value === "SILVER" ? "default" : "orange"}
    >
      {value}
    </Tag>
  );
}
