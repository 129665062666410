import React from "react";
import { Link } from "react-router-dom";
import { Tag, Typography } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";

import { CustomersSearchResult } from "../../../hooks/api/customers/useCustomersSearch";
import usePagination from "../../../hooks/usePagination";
import useTablet from "../../../hooks/useTablet";
import { formatCurrency, renderCurrencyZeroPaise } from "../../../utils/currency";
import { formatDate } from "../../../utils/date";
import { formatNumber } from "../../../utils/number";
import AnchorLink from "../../common/AnchorLink";
import ResponsiveTable, { ResponsiveColumnType } from "../../common/ResponsiveTable";
import { CustomerFiltersHeader } from "./CustomerFilters";

type Props = {
  customersSearch: CustomersSearchResult;
  title?: React.ReactNode;
};

export default function CustomersList({ customersSearch, title }: Props) {
  const { updateVariables, variables, loading, total, customers } = customersSearch;
  const { pagination, page } = usePagination({ variables, updateVariables, total });
  const { isTablet } = useTablet();

  function getColumns(): ResponsiveColumnType<CustomerFragment>[] {
    const columns: ResponsiveColumnType<CustomerFragment>[] = [
      {
        title: "Name",
        key: "name",
        dataIndex: "user",
        fixed: "left",
        render: (value, record) => (
          <div className="flex flex-col justify-start space-y-0.5">
            <Link to={`/customers/${record.id}`} href={`/customers/${record.id}`}>
              <AnchorLink>
                <span className="text-sm">{record.user.name}</span>
              </AnchorLink>
            </Link>
            <div className="">{record.user.phone}</div>
            {record.isRepledgeCustomer && (
              <Tag className="rounded-full w-min px-3 text-xs py-0.5" color="magenta-inverse">
                REPLEDGE
              </Tag>
            )}
          </div>
        ),
        cardable: { position: "top", containerClassName: "w-1/2" },
      },
      {
        title: "Address",
        key: "address",
        dataIndex: "address",
        ellipsis: true,
        render: (value, record) => (
          <div className="flex flex-col space-y-0.5">
            <div className={clsx(isTablet && "text-right")}>
              {record.careOfType} {record.careOfName}
            </div>
            <Typography.Paragraph
              ellipsis={{ tooltip: record.address }}
              className="text-right font-normal"
            >
              {record.address}
            </Typography.Paragraph>
          </div>
        ),
        cardable: { position: "top", containerClassName: "w-1/2" },
      },
      {
        title: "Customer Since",
        key: "dateJoined",
        dataIndex: "user",
        render: (value, record) => formatDate(record.user.dateJoined),
        cardable: { position: "body" },
      },
      {
        title: "Active Loans",
        key: "activeLoans",
        dataIndex: "loanMetric",
        render: (value, record) =>
          record.loanMetric?.activeLoans && record.loanMetric?.outstandingAmount
            ? `${renderCurrencyZeroPaise(record.loanMetric.outstandingAmount)} (${formatNumber(
                record.loanMetric.activeLoans
              )})`
            : "--",
        cardable: { position: "body" },
      },
      {
        title: "Closed Loans",
        key: "closedLoans",
        dataIndex: "loanMetric",
        render: (value, record) =>
          record.loanMetric?.closedLoans && record.loanMetric?.closedLoans
            ? `${renderCurrencyZeroPaise(record.loanMetric.repaidAmount)} (${formatNumber(
                record.loanMetric.closedLoans
              )})`
            : "--",
        cardable: { position: "body" },
      },
      {
        title: "Interest Paid",
        key: "interestPaid",
        dataIndex: "loanMetric",
        render: (value, record) =>
          !!record.loanMetric?.totalInterest &&
          parseFloat(`${record.loanMetric.totalInterest}`) > 0 &&
          formatCurrency(record.loanMetric.totalInterest),
        cardable: { position: "body" },
      },
      {
        title: "Avg. Days To Close",
        key: "avgDaysToClose",
        dataIndex: "loanMetric",
        render: (value, record) =>
          record.loanMetric?.averageDaysToClose &&
          dayjs.duration(record.loanMetric.averageDaysToClose, "days").humanize(),
        cardable: { position: "body" },
      },
    ];
    return columns;
  }

  return (
    <ResponsiveTable<CustomerFragment>
      rowKey="id"
      scroll={{ x: 750, y: 650, scrollToFirstRowOnChange: true }}
      loading={loading}
      bordered
      title={title ? () => title : undefined}
      columns={getColumns()}
      dataSource={customers}
      pagination={pagination}
      printSettings={{
        title: "LOANS SEARCH",
        orientation: "portrait",
        tableTitle: (
          <div className="flex flex-col items-center">
            <div className="text-2xl font-bold py-2 text-center capitalize">
              CUSTOMERS SEARCH{" "}
              <span className="text-base px-2 text-gray-600">
                Showing{" "}
                {total && total > customersSearch.variables.first
                  ? customersSearch.variables.first
                  : total}{" "}
                Customers - (Page {page.number + 1} / {page.total})
              </span>
            </div>
            <CustomerFiltersHeader customersSearch={customersSearch} />
          </div>
        ),
      }}
    />
  );
}
