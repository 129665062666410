export const TIMELINE_CHART_OPTIONS: Highcharts.Options = {
  legend: { enabled: true },
  credits: { enabled: false },
  title: { text: undefined },
  tooltip: {
    outside: true,
    useHTML: true,
    backgroundColor: "#FFF",
    style: {
      color: "#1F2A33",
    },
  },

  xAxis: {
    type: "category",
    labels: {
      style: {
        fontSize: "13px",
        fontFamily: "Verdana, sans-serif",
      },
    },
  },
};
