/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useHistory } from "react-router";
import { gql } from "@urql/core";
import { Button, Divider, message, Skeleton } from "antd";
import { FormikHelpers } from "formik";
import { useMutation } from "urql";

import type { ClosedLoanInput } from "../../../gql/graphql";
import { DOCUMENT_CONNECTION_FRAGMENT } from "../../../hooks/api/documents/useDocument";
import useLoan, { Loan } from "../../../hooks/api/loans/useLoan";
import useError from "../../../hooks/useError";
import { trackEvent } from "../../../utils/analytics";
import { getLoanDataForAnalytics } from "../../../utils/analytics/data";
import { sum } from "../../../utils/number";
import Centered from "../../common/Centered";
import EmptyPage from "../../common/EmptyPage";
import LoanStatus from "../../common/LoanStatus";
import CustomerCard from "../../customers/CustomerCard";
import Form from "../../form/Form";
import FormValue from "../../form/FormValue";
import { LOAN_CONTRACT_FRAGMENT } from "../fragments";
import {
  CLOSE_FORM_VALIDATIONS,
  CloseLoanForm,
  CloseLoanFormData,
  LOAN_FORM_VALIDATIONS,
  LoanForm,
  LoanFormData,
} from "./LoanForm";

const EDIT_LOAN_MUTATION: EditLoanMutationDoc = gql`
  mutation EditLoan(
    $loanId: Int!
    $pledgeSheetNumber: Int!
    $contractStartDate: String!
    $itemType: ItemType!
    $itemName: String!
    $itemWeight: Decimal!
    $estimatedValue: Decimal!
    $loanAmount: Decimal!
    $interestRate: Decimal!
    $firstMonthInterest: Decimal!
    $processingFees: Decimal!
    $notes: String
    $closedLoanData: ClosedLoanInput
    $itemPhotoDocId: Int
  ) {
    editLoan(
      loanId: $loanId
      pledgeSheetNumber: $pledgeSheetNumber
      contractStartDate: $contractStartDate
      itemType: $itemType
      itemName: $itemName
      itemWeight: $itemWeight
      estimatedValue: $estimatedValue
      loanAmount: $loanAmount
      interestRate: $interestRate
      firstMonthInterest: $firstMonthInterest
      processingFees: $processingFees
      closedLoanData: $closedLoanData
      loanNotes: $notes
      itemPhotoDocId: $itemPhotoDocId
    ) {
      success
      failureMessage
      loan {
        ...Loan
        documents {
          ...DocumentConnection
        }
      }
    }
  }
  ${DOCUMENT_CONNECTION_FRAGMENT}
  ${LOAN_CONTRACT_FRAGMENT}
`;

type FormData = LoanFormData &
  CloseLoanFormData & {
    closingPayment?: number | null;
  };

type Props = {
  loan: Loan;
};

function EditLoanForm({ loan }: Props) {
  const history = useHistory();
  const [, editLoan] = useMutation(EDIT_LOAN_MUTATION);

  const { error, setError, resetError } = useError();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    const closedLoanData: ClosedLoanInput | undefined =
      loan.loanStatus === "CLOSED"
        ? {
            closingDate: values.closingDate! as string,
            closingPayment: values.closingPayment!,
            finalInterestAmount: values.finalInterestAmount!,
          }
        : undefined;

    return editLoan({
      loanId: loan.id,
      pledgeSheetNumber: values.pledgeSheetNumber!,
      contractStartDate: values.contractStartDate!,
      itemType: values.itemType!,
      itemName: values.itemName!,
      itemWeight: values.itemWeight!,
      estimatedValue: values.estimatedValue!,
      loanAmount: values.loanAmount!,
      interestRate: values.interestRate! / 100,
      firstMonthInterest: values.firstMonthInterest ?? 0,
      processingFees: values.processingFees!,
      notes: values.notes,
      closedLoanData,
      itemPhotoDocId: values.itemPhotoDocId,
    })
      .then((response) => {
        if (response.data?.editLoan?.success && response.data?.editLoan?.loan) {
          const loan = response.data.editLoan.loan;
          message.success(`Successfully recorded the loan with PSN # ${loan.pledgeSheetNumber}`);
          trackEvent({ action: "Edit Loan" }, getLoanDataForAnalytics(loan));
          history.push(`/loans/${loan.id}`);
        } else {
          setError({
            customError: response.data?.editLoan?.failureMessage,
            gqlError: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ customError: "Failed to add customer, please try again!" });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  const closedLoanDataValidation = loan.loanStatus === "CLOSED" ? CLOSE_FORM_VALIDATIONS : {};

  const validationSchema = { ...LOAN_FORM_VALIDATIONS, ...closedLoanDataValidation };

  const itemPhotoDoc = loan.documents.find((doc) => doc.documentType === "LOAN_ITEM");

  return (
    <div className="w-full max-w-screen-md pb-12 md:pb-24">
      <h4 className="mt-2 mb-5 font-bold text-2xl text-center">EDIT LOAN</h4>

      <Form<FormData>
        initialValues={{
          pledgeSheetNumber: loan.pledgeSheetNumber,
          contractStartDate: loan.contractStartDate || "",
          itemType: loan.pledgeItemType || null,
          itemWeight: loan.pledgeItemWeight ? Number(loan.pledgeItemWeight) : null,
          itemName: loan.pledgeItemDesc || "",
          estimatedValue: loan.pledgeItemEstimate || null,
          interestRate: loan.interestRate ? loan.interestRate * 100 : null,
          loanAmount: loan.loanAmount || null,
          firstMonthInterest: loan.firstMonthInterest || null,
          processingFees: loan.processingFees || null,
          finalInterestAmount: sum(loan.closingInterest, loan.commission),
          closingDate: loan.closingDate || null,
          closingPayment: loan.closingPayment || null,
          notes: loan.loanNotes,
          itemPhotoDoc,
          itemPhotoDocId: itemPhotoDoc?.id,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        error={error}
      >
        {({ isSubmitting, values }) => {
          return (
            <>
              <FormValue
                label="Customer"
                required
                name="customer"
                value={<CustomerCard customer={loan.customer} border />}
              />

              <FormValue
                label="Loan Status"
                required
                name="loanStatus"
                value={
                  <div className="w-auto">
                    <LoanStatus
                      className="px-2 py-0.5 font-bold text-center w-56 text-lg"
                      status={loan.loanStatus!}
                    />
                  </div>
                }
              />

              <LoanForm
                customerId={loan.customer.id}
                customerName={loan.customer.user.name}
                repledgeCustomer={loan.customer.isRepledgeCustomer}
                loanId={loan.id}
                loanStatus={loan.loanStatus}
                hasTopUps={loan.hasTopUps}
                edit
                values={values}
              />

              {loan.loanStatus === "CLOSED" && <CloseLoanForm loan={loan} values={values} edit />}

              <Divider />

              <div className="flex w-full space-x-2 sm:space-x-4">
                <Button
                  className="w-1/3"
                  shape="round"
                  size="large"
                  loading={isSubmitting}
                  type="default"
                  onClick={() => history.push(`/loans/${loan.id}`)}
                >
                  Cancel
                </Button>

                <Button
                  className="w-2/3"
                  shape="round"
                  size="large"
                  loading={isSubmitting}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
}

export default function EditLoan() {
  const { loan, fetching } = useLoan();

  return fetching ? (
    <Centered>
      <Skeleton paragraph={{ rows: 10 }} className="p-2 w-full max-w-screen-md" />
    </Centered>
  ) : loan ? (
    <div className="w-full justify-center flex py-4 sm:pb-12 pb-24">
      <EditLoanForm loan={loan} />
    </div>
  ) : (
    <EmptyPage
      title="Unable to load the loan details!"
      back="/loans/search"
      backCta="Search Loans"
    />
  );
}
