import React from "react";
import { InputNumber, InputNumberProps } from "antd";
import { getIn } from "formik";

import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

type Props = InputNumberProps<number> & {
  inputStyle?: React.CSSProperties;
  children?: React.ReactElement;
};

const FormPhoneField = (props: Props & WithFormFieldWrapperProps) => {
  const handleChange = (value: number | null) => {
    const {
      name,
      formik: { setFieldValue },
    } = props;
    setFieldValue(name, value);
  };

  const { type, inputStyle, style, formik, children, ...rest } = props;
  const { name } = rest;
  const fieldValue = getIn(formik.values, name);
  return (
    <>
      <InputNumber
        placeholder="10 digit phone number"
        value={fieldValue}
        onChange={handleChange}
        style={{ width: "100%", ...inputStyle }}
        controls={false}
        precision={0}
        stringMode
        type="tel"
        inputMode="tel"
        {...rest}
      />
      {children}
    </>
  );
};

export default withFormFieldWrapper<Props>(FormPhoneField);
