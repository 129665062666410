import React from "react";

import { Variables } from "../../../hooks/api/accounts/useAccountTransactionsSearch";
import { FilterTag, getDatesText, getSelectedFilters } from "../../../utils/search";

const FILTERS: {
  [K in keyof Variables]: ((record: Variables) => React.ReactNode) | null;
} = {
  offset: null,
  first: null,

  transactionsStartDate: (record) => (
    <FilterTag>
      Date {getDatesText(record.transactionsStartDate, record.transactionsEndDate)}
    </FilterTag>
  ),
  transactionsEndDate: (record) =>
    !record.transactionsStartDate && (
      <FilterTag>
        Date {getDatesText(record.transactionsStartDate, record.transactionsEndDate)}
      </FilterTag>
    ),
  groupBy: (record) => <FilterTag>Group By: {record.groupBy}</FilterTag>,
  order: (record) => <FilterTag>Order By: {record.order}</FilterTag>,
  search: (record) => <FilterTag>Search: {record.search}</FilterTag>,
};

function _SelectedFilters({ filters }: { filters: Variables }) {
  const selectedFilters = getSelectedFilters(filters);

  return (
    <div className="flex flex-wrap">
      {selectedFilters.map((filter) => {
        const display = FILTERS[filter];

        return display ? (
          <div className="pb-1" key={filter}>
            {display(filters)}
          </div>
        ) : null;
      })}
    </div>
  );
}

export default React.memo(_SelectedFilters);
