import { useCallback, useState } from "react";

export type UseWebcamCaptureResult = {
  openWebcam: () => void;
  closeWebcam: () => void;
  open: boolean;
};

export default function useWebcamCapture(initialOpen?: boolean): UseWebcamCaptureResult {
  const [open, setOpen] = useState<boolean>(!!initialOpen);

  const openWebcam = useCallback(() => {
    setOpen(true);
  }, []);

  const closeWebcam = useCallback(() => {
    setOpen(false);
  }, []);

  return { openWebcam, closeWebcam, open };
}
