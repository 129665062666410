import { useMemo } from "react";
import { gql } from "@urql/core";
import { useQuery } from "urql";

import useQueryParams from "../../useQueryParams";

export const ACCOUNT_DETAILS_FRAGMENT: AccountDetailsFragmentDoc = gql`
  fragment AccountDetails on Account {
    id
    description
    type
    subtype
    currentBalance
  }
`;

const GET_ACCOUNT_QUERY: GetAccountDetailsQueryDoc = gql`
  query GetAccountDetails($id: Int!) {
    accounts(id: $id) {
      edges {
        node {
          code
          name
          verboseName
          ...AccountDetails
        }
      }
    }
  }
  ${ACCOUNT_DETAILS_FRAGMENT}
`;

export type Account = AccountDetailsFragment & {
  name: string;
  verboseName?: string;
  code: number;
};

export default function useAccount({ accountId }: { accountId?: number }): {
  fetching: boolean;
  account?: Account;
} {
  const urlId = useQueryParams("account_id");
  const id = useMemo(() => {
    return accountId ? accountId : urlId ? parseInt(urlId) : -1;
  }, [accountId, urlId]);

  const variables = useMemo(() => ({ id }), [id]);

  const [{ data, fetching }] = useQuery({
    query: GET_ACCOUNT_QUERY,
    variables,
    pause: !id,
    requestPolicy: "cache-and-network",
  });

  const account = data?.accounts?.edges?.[0]?.node;

  return { account, fetching };
}
