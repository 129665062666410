import React from "react";
import { Checkbox } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { CheckboxGroupProps } from "antd/lib/checkbox";
import { getIn } from "formik";

import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

type Props = CheckboxGroupProps & {
  inputStyle?: React.CSSProperties;
  allOptionValues?: Array<CheckboxValueType>;
};

const FormCheckboxGroupField = (props: Props & WithFormFieldWrapperProps) => {
  const handleChange = (values: CheckboxValueType[]) => {
    const {
      name,
      formik: { setFieldValue },
    } = props;
    setFieldValue(name, values);
  };

  const { children, inputStyle, formik, allOptionValues, ...rest } = props;
  const { name } = rest;
  const fieldValue = getIn(formik.values, name) as CheckboxValueType[];

  return (
    <div className="flex flex-col">
      {allOptionValues && allOptionValues.length > 1 && (
        <Checkbox
          className="mb-2 font-medium"
          checked={fieldValue.length === allOptionValues.length}
          onChange={(event) => handleChange(event.target.checked ? allOptionValues : [])}
        >
          SELECT ALL
        </Checkbox>
      )}
      <Checkbox.Group {...rest} value={fieldValue} onChange={handleChange} style={inputStyle}>
        {children}
      </Checkbox.Group>
    </div>
  );
};

export default withFormFieldWrapper<Props>(FormCheckboxGroupField);
