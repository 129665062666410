import type { Client } from "urql";
import { cacheExchange, createClient, dedupExchange, fetchExchange } from "urql";

const getUrl = () => {
  const server = process.env.REACT_APP_BACKEND_SERVER || "http://localhost:8000";

  // Get the db server url and strip "/" from the end
  const domain = server.replace(/\/$/, "");

  // Append "/graphql" if it isn't there
  const url = domain.endsWith("/graphql/") ? domain : `${domain}/graphql/`;

  return url;
};

const getClient = (): Client => {
  const url = getUrl();

  const client = createClient({
    url,
    exchanges: [dedupExchange, cacheExchange, fetchExchange],
    fetchOptions: { credentials: "include" },
  });

  return client;
};

export const graphqlClient = getClient();
