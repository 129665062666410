// import HC_exporting from 'highcharts/modules/exporting';
// import HC_ExportData from 'highcharts/modules/export-data';
// import HC_DrillDown from "highcharts/modules/drilldown";
import React, { useRef } from "react";
import { Divider } from "antd";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import * as Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

// HC_exporting(Highcharts);
// HC_ExportData(Highcharts);
// HC_DrillDown(Highcharts);

const TZ_OFFSET = new Date().getTimezoneOffset();

Highcharts.setOptions({
  time: {
    getTimezoneOffset: function (timestamp) {
      return TZ_OFFSET;
    },
  },
});

export const COLORS = ["#69c0ff", "#ff85c0"];

type Props = {
  options: Highcharts.Options;
  constructorType?: keyof typeof Highcharts;
  showDivider?: boolean;
};

export function Charts({ options, constructorType, showDivider }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  return !!options.series && !!options.series.length ? (
    <div className="w-full" ref={containerRef}>
      <ErrorBoundary message="Failed to load the chart!">
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={constructorType}
          options={options}
          oneToOne
        />
        {showDivider && <Divider />}
      </ErrorBoundary>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(Charts);
