import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const Svg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.88 76.44"
    width={props.width}
    height={props.height}
  >
    <title>Gold Loan</title>
    <path
      fill="#fac900"
      d="M27.3,41.46,34.43,16.4a2.08,2.08,0,0,1,2-1.52H66.8a2.09,2.09,0,0,1,2,1.68l7.31,25a2.08,2.08,0,0,1-1.41,2.57,2,2,0,0,1-.58.09H29.26a2.08,2.08,0,0,1-2.08-2.07,2.05,2.05,0,0,1,.12-.72Z"
    />
    <path
      fill="#fac900"
      d="M16.93,0,16.7.19a8.56,8.56,0,0,1,1.88,6.55,8.57,8.57,0,0,1-3.17,6l.18.23a8.5,8.5,0,0,1,6.56-1.87,8.56,8.56,0,0,1,6,3.16l.23-.18a8.58,8.58,0,0,1-1.88-6.56,8.62,8.62,0,0,1,3.18-6l-.19-.23A8.6,8.6,0,0,1,23,3.16a8.55,8.55,0,0,1-6-3.16Z"
    />
    <path
      fill="#fac900"
      d="M.12,73.66,7.24,48.59a2.1,2.1,0,0,1,2-1.52H39.62a2.08,2.08,0,0,1,2,1.68l7.31,25a2.07,2.07,0,0,1-1.41,2.57,2,2,0,0,1-.58.08H2.09a2.08,2.08,0,0,1-2-2.78Z"
    />
    <path
      fill="#fac900"
      d="M54.43,73.66l7.11-25.07a2.1,2.1,0,0,1,2-1.52H80.44a13.48,13.48,0,0,0,.87,2.79,14.68,14.68,0,0,0-.56,1.58,13,13,0,0,0,.69,9.35,14,14,0,0,0-.69,1.88,13.44,13.44,0,0,0-.56,3.69A14,14,0,0,0,84.6,76.44H56.39a2.08,2.08,0,0,1-2.08-2.07,1.69,1.69,0,0,1,.12-.71Z"
    />
    <path
      fill="#e6bd00"
      d="M27.25,41.52l7.13-25.06a2.09,2.09,0,0,1,2-1.53H66.75a2.09,2.09,0,0,1,2,1.68l.12.39H39.56a2.09,2.09,0,0,0-2,1.52L30.43,43.59h0a2,2,0,0,0-.12.7H29.23a2.08,2.08,0,0,1-2.08-2.07,1.63,1.63,0,0,1,.1-.71Z"
    />
    <path
      fill="#e3b600"
      d="M.12,73.66,7.24,48.59a2.09,2.09,0,0,1,2-1.51H39.62a2.08,2.08,0,0,1,2,1.67l.12.39H12.42a2.09,2.09,0,0,0-2,1.51L3.3,75.72h0a1.81,1.81,0,0,0-.12.7H2.08A2.08,2.08,0,0,1,0,74.36a2,2,0,0,1,.12-.7Z"
    />
    <path
      fill="#e6bd00"
      d="M.12,73.66,7.24,48.59a2.09,2.09,0,0,1,2-1.51H39.62a2.08,2.08,0,0,1,2,1.67l.12.39H12.42a2.09,2.09,0,0,0-2,1.51L3.3,75.72h0a1.81,1.81,0,0,0-.12.7H2.08A2.08,2.08,0,0,1,0,74.36a2,2,0,0,1,.12-.7Z"
    />
    <path
      fill="#e6bd00"
      d="M54.43,73.66l7.12-25.07a2.09,2.09,0,0,1,2-1.51H80.44A14.27,14.27,0,0,0,81,49.14H66.72a2.12,2.12,0,0,0-2,1.51L57.59,75.72h0a2,2,0,0,0-.12.7H56.39a2.08,2.08,0,0,1-2.08-2.07,1.62,1.62,0,0,1,.12-.7Z"
    />
    <path
      fill="#fac900"
      d="M6.65,21.42l-.23.18A8.57,8.57,0,0,1,8.3,28.15a8.57,8.57,0,0,1-3.17,6l.18.23a8.54,8.54,0,0,1,6.56-1.87,8.53,8.53,0,0,1,6,3.17l.23-.19A8.57,8.57,0,0,1,16.25,29a8.58,8.58,0,0,1,3.18-6l-.19-.23a8.57,8.57,0,0,1-6.55,1.88,8.61,8.61,0,0,1-6-3.17Z"
    />
    <path
      fillRule="evenodd"
      fill="#976e4f"
      d="M122.1,52.44a5.27,5.27,0,0,1,.78,2.69c0,5-7.68,9-17.13,9s-17.14-4-17.14-9a5.21,5.21,0,0,1,.79-2.69c2.18,3.65,8.67,6.3,16.35,6.3s14.17-2.65,16.35-6.3Zm.78,13.92c0,5-7.67,9-17.13,9s-17.14-4-17.14-9a5.19,5.19,0,0,1,.79-2.69c2.18,3.65,8.67,6.3,16.35,6.3,5.07,0,13.49-1.5,16.35-6.3a5.2,5.2,0,0,1,.78,2.69ZM111.93,29.81l-.2,2.23-8.4-.73a7.76,7.76,0,0,1,1.44,3l-2-.18a6.55,6.55,0,0,0-.91-2,4.06,4.06,0,0,0-1.75-1.56l.16-1.81,11.69,1Zm-6.18-3.49c7.77,0,14.08,2.48,14.08,5.52s-6.31,5.53-14.08,5.53-14.08-2.48-14.08-5.53,6.3-5.52,14.08-5.52Zm0-1.83c9.45,0,17.13,4,17.13,9s-7.68,9-17.13,9-17.14-4-17.14-9,7.68-9,17.14-9ZM122.16,41.9a5.23,5.23,0,0,1,.72,2.58c0,5-7.68,9-17.13,9s-17.14-4-17.14-9a5,5,0,0,1,.73-2.58c2.11,3.7,8.65,6.4,16.41,6.4s14.29-2.7,16.41-6.4Z"
    />
  </svg>
);

const Logo = (props: Partial<CustomIconComponentProps>) => <Icon component={Svg} {...props} />;

export default Logo;
