import { message, Modal } from "antd";

import { DocumentActionResult } from "../../hooks/api/documents/useDocumentAction";
import { Document } from "../../hooks/api/documents/useUploadDocument";
import useTablet from "../../hooks/useTablet";
import CloseIcon from "../common/CloseIcon";
import AddDocument from "./AddDocument";
import DeleteDocument from "./DeleteDocument";
import EditDocument from "./EditDocument";

type Props = DocumentActionResult & {
  onChange: (document: Document) => void;
  customer: CustomerDetailsFragment;
  loan?: LoanFragment;
};

export default function DocumentActionModal(props: Props) {
  const { action, customer, loan, showForm, closeForm, document } = props;
  const { isTablet } = useTablet();

  function onClose(document?: Document, msg?: string) {
    if (msg) {
      message.success(msg);
    }

    if (document) {
      props.onChange(document);
    }

    closeForm();
  }

  return showForm && action ? (
    <Modal
      onCancel={() => onClose()}
      open={showForm}
      closeIcon={<CloseIcon />}
      title={
        <div className="text-xl text-center text-red-400 font-bold">
          {action.toUpperCase()} {document ? document.documentType.replaceAll("_", " ") : "NEW"}{" "}
          DOCUMENT
        </div>
      }
      bodyStyle={{
        paddingTop: 16,
        paddingBottom: 24,
        paddingLeft: 8,
        paddingRight: 8,
      }}
      width="100%"
      footer={false}
      className={isTablet ? "max-w-md" : "max-w-lg"}
    >
      <div className="flex flex-col w-full items-center justify-center">
        {document && action === "edit" ? (
          <EditDocument
            document={document}
            onSuccess={(document) => onClose(document, "Successfully edited the document.")}
          />
        ) : document && action === "delete" ? (
          <DeleteDocument
            document={document}
            onSucess={() => onClose(document, "Successfully deleted the document.")}
          />
        ) : (
          <AddDocument
            customer={customer}
            loan={loan}
            onSucess={(document) => onClose(document, "Successfully added the document.")}
          />
        )}
      </div>
    </Modal>
  ) : null;
}
