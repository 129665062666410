import { PrinterFilled } from "@ant-design/icons";

import { Props as PrintProps, usePrint } from "../../../hooks/usePrint";
import ActionIconButton from "../../common/ActionIconButton";

type Props = PrintProps & { children: React.ReactNode };

export default function PrintInvoice({ children, ...props }: Props) {
  const { print, printing } = usePrint(props);

  return (
    <>
      <div className="fixed bottom-4 right-4">
        <ActionIconButton
          icon={<PrinterFilled />}
          loading={printing}
          onClick={() => print(children)}
        />
      </div>

      {children}
    </>
  );
}
