import { useHistory } from "react-router";
import { Skeleton, Typography } from "antd";

import useUser from "../../hooks/useUser";
import Centered from "../common/Centered";
import Dashboard from "../dashboard/Dashboard";
import LoginForm from "../login/LoginForm";

export default function Page() {
  const { user, loadingUser } = useUser();
  const history = useHistory();

  return loadingUser ? (
    <Centered>
      <Skeleton active paragraph={{ rows: 10 }} className="max-w-screen-sm p-3" />
    </Centered>
  ) : user ? (
    <Dashboard />
  ) : (
    <div className="w-full h-full flex flex-col justify-between items-center">
      <LoginForm onSuccess={() => history.push("/")} />
      <Typography.Link
        target="_blank"
        rel="noreferrer noopener"
        className="text-sm sm:text-base p-2 text-center"
        href="http://goldpricez.com/us/gram"
      >
        Gold Price provided by goldpricez.com
      </Typography.Link>
    </div>
  );
}
