import { useCallback, useState } from "react";
import { CombinedError } from "urql";

import { getErrorMessage } from "../utils/api";

export default function useError() {
  const [error, _setError] = useState<string>();

  const setError = useCallback(
    ({ gqlError, customError }: { gqlError?: CombinedError; customError?: string }) => {
      _setError(getErrorMessage(gqlError, customError));
    },
    [_setError]
  );

  const resetError = useCallback(() => {
    _setError(undefined);
  }, [_setError]);

  return { error, setError, resetError };
}
