import { useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { Skeleton, Typography } from "antd";
import dayjs from "dayjs";
import { gql, useQuery } from "urql";

import type { GoldLoansLoanContractPledgeItemTypeChoices } from "../../gql/graphql";
import { camel2title } from "../../utils/common";
import { formatCurrency, formatRate, renderCurrencyZeroPaise } from "../../utils/currency";
import { formatDate } from "../../utils/date";
import { formatNumber, sum, toNumber } from "../../utils/number";
import { getURLParams } from "../../utils/query-params";
import AnchorLink from "../common/AnchorLink";
import LabelValue from "../common/LabelValue";
import PledgeItemType from "../common/PledgeItemType";
import QuestionTooltip from "../common/QuestionTooltip";
import ResponsiveTable, { ResponsiveColumnType } from "../common/ResponsiveTable";

const LOANS_WIDGET_QUERY: LoansWidgetQueryDoc = gql`
  query LoansWidget(
    $status: GoldLoansLoanContractLoanStatusChoices!
    $order: [LoanContractOrder!]!
    $partPaymentAmountMin: Decimal
  ) {
    loanContracts(
      loanStatus: $status
      order: $order
      partPaymentAmount_Gte: $partPaymentAmountMin
      first: 5
    ) {
      edges {
        node {
          id
          contractStartDate
          loanAmount
          firstMonthInterest
          processingFees
          interestRate
          pledgeSheetNumber
          pledgeItemType
          pledgeItemWeight
          pledgeItemDesc
          closingDate
          closingInterest
          commission
          annualizedRateOfReturn
          pendingInterest
          partPaymentAmount
          partPaymentUpdatedAt
          customer {
            id
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`;

type Column =
  | "firstMonthInterest"
  | "processingFees"
  | "closedOn"
  | "bookedProfit"
  | "annualizedRateOfReturn"
  | "pendingInterest"
  | "partPaymentAmount";

export default function LoansWidget({
  variables,
  additionalColumns = [],
}: {
  variables: LoansWidgetQueryVariables;
  additionalColumns?: Column[];
}) {
  const [ref, inView] = useInView({ triggerOnce: true });

  const [{ data, fetching }] = useQuery({ query: LOANS_WIDGET_QUERY, variables, pause: !inView });
  const loans = (data?.loanContracts?.edges || [])
    .map((edge) => edge?.node)
    .filter((loan) => !!loan);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const loan = loans[0]!;
  type Loan = typeof loan;

  const columns = useMemo(() => {
    const cols: ResponsiveColumnType<Loan>[] = [
      {
        title: (
          <div className="flex flex-col">
            <div>PSN</div>
            <div>Customer</div>
          </div>
        ),
        key: "psn",
        dataIndex: "pledgeSheetNumber",
        width: 150,
        fixed: "left",
        align: "left",
        ellipsis: true,
        render: (value, record) => (
          <div className="flex flex-col space-y-2">
            <Link href={`/loans/${record.id}`} to={`/loans/${record.id}`}>
              PSN: <AnchorLink>{record.pledgeSheetNumber}</AnchorLink>
            </Link>
            <Link to={`/customers/${record.customer.id}`} href={`/customers/${record.customer.id}`}>
              <AnchorLink>
                <span className="text-sm font-normal">{record.customer.user.name}</span>
              </AnchorLink>
            </Link>
          </div>
        ),
        cardable: { position: "top", containerClassName: "w-1/3" },
      },
      {
        title: "Open Date",
        key: "openDate",
        dataIndex: "contractStartDate",
        width: 150,
        render: (value) => formatDate(value),
        cardable: { position: "body" },
      },
      {
        title: "Item",
        dataIndex: "pledgeItemType",
        key: "pledgeItemType",
        width: 200,
        render: (value: GoldLoansLoanContractPledgeItemTypeChoices, record) => (
          <div className="flex flex-col space-y-1">
            <div className="pb-0.5">
              <PledgeItemType value={value} className="px-2" />{" "}
              {record.pledgeItemWeight && `${formatNumber(record.pledgeItemWeight)} GRAMS`}
            </div>
            <Typography.Paragraph ellipsis={{ tooltip: record.pledgeItemDesc }} className="pl-1">
              {record.pledgeItemDesc}
            </Typography.Paragraph>
          </div>
        ),
        cardable: { position: "top", containerClassName: "w-1/3" },
      },
      {
        title: "Loan Amount",
        key: "loanAmount",
        dataIndex: "loanAmount",
        width: 150,
        render: (value) => formatCurrency(value),
        cardable: { position: "top", title: "Amount", containerClassName: "w-1/3 items-end" },
      },
      {
        title: "Interest Rate",
        key: "interestRate",
        dataIndex: "interestRate",
        width: 125,
        render: (value) => formatRate(value),
        cardable: { position: "body" },
      },
    ];

    for (let i = 0; i < additionalColumns.length; i++) {
      const column = additionalColumns[i];
      const data: Pick<ResponsiveColumnType<Loan>, "title" | "key" | "dataIndex" | "cardable"> = {
        title: camel2title(column),
        key: column,
        dataIndex: column as keyof Loan,
        cardable: { position: "body" },
      };
      switch (column) {
        case "firstMonthInterest":
          cols.push({ ...data, width: 150, render: (value) => formatCurrency(value) });
          break;

        case "processingFees":
          cols.push({ ...data, width: 150, render: (value) => formatCurrency(value) });
          break;

        case "closedOn":
          cols.push({
            ...data,
            dataIndex: "closingDate",
            width: 150,
            render: (value, record) => (
              <LabelValue
                label=""
                value={formatDate(value)}
                extras={dayjs(record.contractStartDate)
                  .startOf("day")
                  .from(dayjs(record.closingDate).add(1, "d").startOf("day"), true)}
              />
            ),
          });
          break;

        case "bookedProfit":
          cols.push({
            ...data,
            title: (
              <>
                <div className="flex items-center space-x-1">
                  <div>Booked Revenue</div>
                  <QuestionTooltip>
                    <span className="text-base font-medium">Booked Revenue</span> is the sum of
                    First Month Interest, Processing Fees, Closing Interest and Commission.
                  </QuestionTooltip>
                </div>
              </>
            ),
            width: 150,
            render: (value, record) =>
              formatCurrency(
                sum(
                  record.firstMonthInterest,
                  record.processingFees,
                  record.closingInterest,
                  record.commission
                )
              ),
          });
          break;

        case "annualizedRateOfReturn":
          cols.push({
            ...data,
            title: "Annual Return",
            width: 100,
            render: (value) => formatRate(value),
          });
          break;

        case "pendingInterest":
          cols.push({
            ...data,
            width: 100,
            render: (value, record) => formatCurrency(record.pendingInterest),
          });
          break;

        case "partPaymentAmount":
          cols.push({
            ...data,
            title: "Payments",
            width: 150,
            render: renderCurrencyZeroPaise,
          });
          break;
      }
    }

    return cols;
  }, [additionalColumns]);

  return (
    <div ref={ref}>
      <Skeleton
        className="p-2"
        paragraph={{ rows: 10 }}
        title={false}
        loading={fetching || !inView}
        active
      >
        <ResponsiveTable<Loan>
          printSettings={false}
          scroll={{ x: 500 }}
          columns={columns}
          dataSource={loans as Loan[]}
          pagination={false}
          rowKey="id"
          loading={fetching}
          size="small"
          footer={() => {
            const url = `/loans/search/?${getURLParams(variables)}`;
            return (
              <div className="flex justify-center">
                <Link href={url} to={url}>
                  <AnchorLink>
                    <span className="text-base font-normal">See More</span>
                  </AnchorLink>
                </Link>
              </div>
            );
          }}
        />
      </Skeleton>
    </div>
  );
}
