import { getIn } from "formik";

import { Document } from "../../hooks/api/documents/useUploadDocument";
import PhotoInput, { PhotoInputProps } from "../common/webcam/PhotoInput";
import { withFormFieldWrapper, WithFormFieldWrapperProps } from "./FormFieldWrapper";

type Props = Omit<PhotoInputProps, "onSelect"> & { documentField: string };

const FormAutoCompleteField = (props: Props & WithFormFieldWrapperProps) => {
  const {
    name,
    documentField,
    formik: { values, setFieldValue },
    ...rest
  } = props;

  const document = getIn(values, documentField);

  function onSelect(document: Document) {
    setFieldValue(documentField, document);
    setFieldValue(name, document.id);
  }

  return <PhotoInput useModalForWebcam {...rest} onSelect={onSelect} document={document} />;
};

export default withFormFieldWrapper<Props>(FormAutoCompleteField);
