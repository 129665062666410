import { NIDHI_LAYOUT_CONTAINER } from "../constants/common";

export function scrollToTop() {
  window.document
    .getElementById(NIDHI_LAYOUT_CONTAINER)
    ?.scroll({ top: 0, left: 0, behavior: "smooth" });
}

export function scrollToBottom() {
  const container = window.document.getElementById(NIDHI_LAYOUT_CONTAINER);

  if (!container) {
    return;
  }

  container.scroll({ top: container.scrollHeight, left: 0, behavior: "smooth" });
}
