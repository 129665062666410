import React from "react";
import { Skeleton } from "antd";

import Centered from "./Centered";

export default function Loading({
  loading,
  children,
}: {
  loading: boolean;
  children: React.ReactElement;
}) {
  return loading ? (
    <Centered>
      <Skeleton active round paragraph={{ rows: 10 }} loading className="max-w-screen-sm" />
    </Centered>
  ) : (
    children
  );
}
