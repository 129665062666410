import { ItemPurityType } from "../types/enum";
import { isNull } from "./common";
import { formatNumber, toNumber } from "./number";

export function renderCurrency(amount?: number | null) {
  return formatCurrency(amount, 2);
}

export function renderCurrencyZeroPaise(amount?: number | null) {
  return formatCurrency(amount, 0);
}

export function formatCurrency(amount?: number | null, precision?: number) {
  return amount !== null && amount !== undefined
    ? (amount * 1).toLocaleString("en-IN", {
        maximumFractionDigits: !isNull(precision) ? precision : 2,
        style: "currency",
        currency: "INR",
      })
    : "--";
}

export function formatRate(rate?: number) {
  return rate ? `${formatNumber(rate * 100, 2)}%` : "--";
}

export function formatMetalPurity(purity: ItemPurityType, purityRatio: number) {
  return `${purity.replace("K_", "")}K (${formatRate(purityRatio)})`;
}

export const formatMoneyHumanReadable = (amount: number | undefined, digits = 2) => {
  if (!amount) {
    return "--";
  }

  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e5, symbol: "L" },
    { value: 1e7, symbol: "C" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(amount) >= si[i].value) {
      break;
    }
  }
  const formattedAmount =
    (Math.abs(amount) / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  return `₹${amount < 0 ? `-${formattedAmount}` : formattedAmount}`;
};

export const signBalance = (amount: number, sign: number) => {
  const value = toNumber(amount);
  return value ? sign * value : value;
};
