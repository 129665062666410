export const NOT_AVAILABLE = "---";
export const NOT_APPLICABLE = "n/a";
export const NIL = "NIL";
export const DISPLAY_DATE_FORMAT = "DD/MM/YYYY";
export const SERVER_DATE_FORMAT = "YYYY-MM-DD";

export const BREAKPOINTS = {
  mobile: 575,
  lg: 1024,
  md: 768,
  sm: 640,
};

export const NIDHI_LAYOUT_CONTAINER = "nidhi-layout-container";

export const INVALID_ID = -1;

export const LANDSCAPE_PRINT_SETTINGS = `
  @page {
    size: landscape;
    filter: grayscale(100%);
  }
`;

export const PORTRAIT_PRINT_SETTINGS = `
  @page {
    size: portrait;
    filter: grayscale(100%);
  }
`;
