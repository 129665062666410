import { useCallback, useState } from "react";
import { EditFilled } from "@ant-design/icons";
import { Divider, message, Modal } from "antd";
import { FormikHelpers } from "formik";
import { gql, useClient, useMutation } from "urql";
import * as Yup from "yup";

import type { Metal } from "../../../gql/graphql";
import useError from "../../../hooks/useError";
import { formatCurrency } from "../../../utils/currency";
import ActionIconButton from "../../common/ActionIconButton";
import CloseIcon from "../../common/CloseIcon";
import Form from "../../form/Form";
import { FormButtons } from "../../form/FormButtons";
import FormMoneyField from "../../form/FormMoneyField";
import { DASHBOARD_QUERY } from "../Dashboard";

const UPDATE_RATE_MUTATION: UpdateRateMutationDoc = gql`
  mutation UpdateRate($metal: Metal!, $rate: Decimal!) {
    createRate(metal: $metal, rate: $rate) {
      success
      error
      rate {
        id
        metal
        rate
      }
    }
  }
`;

type FormData = {
  rate: number;
};

type Props = {
  metal: Metal;
  currentRate: number;
  onClose: (done?: boolean) => void;
};

function UpdateRateForm({ metal, currentRate, onClose }: Props) {
  const [, updateRate] = useMutation(UPDATE_RATE_MUTATION);
  const { error, setError, resetError } = useError();
  const client = useClient();

  function onSubmit(values: FormData, formikActions: FormikHelpers<FormData>) {
    // Reset the error.
    resetError();

    return updateRate({ metal, rate: values.rate })
      .then(async (response) => {
        const rate = response?.data?.createRate?.rate;
        if (rate && response.data?.createRate?.success) {
          await client.query(DASHBOARD_QUERY, {}, { requestPolicy: "network-only" }).toPromise();
          message.success(`Updated the ${rate.metal} rate to ${formatCurrency(rate.rate)}`);
          onClose(true);
        } else {
          message.error(`Failed to update ${metal} rate!`);
          setError({ customError: response.data?.createRate?.error, gqlError: response.error });
        }
      })
      .catch((error) => {
        message.error(`Failed to update ${metal} rate..`);
        setError({ customError: `Failed to update ${metal} rate..` });
      })
      .finally(() => formikActions.setSubmitting(false));
  }

  return (
    <div className="w-full max-w-screen-sm pb-6">
      <Form<FormData>
        initialValues={{ rate: currentRate }}
        validationSchema={{
          rate: Yup.number()
            .nullable()
            .required("Rate value is required.")
            .min(0, "Rate should be a positive number."),
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <FormMoneyField name="rate" label="Rate" column />

              <Divider />

              <FormButtons onCancel={onClose} isSubmitting={isSubmitting} />

              {error && <div className="text-red-500 text-center my-2">{error}</div>}
            </>
          );
        }}
      </Form>
    </div>
  );
}

export default function UpdateRate(props: Omit<Props, "onClose">) {
  const [show, setShow] = useState(false);

  const onClose = useCallback(() => {
    setShow(false);
  }, [setShow]);

  return (
    <>
      <Modal
        title={
          <h4 className="pb-3 font-bold text-2xl text-center border-b">
            UPDATE {props.metal} RATE
          </h4>
        }
        centered
        closable
        maskClosable
        open={show}
        closeIcon={<CloseIcon />}
        footer={false}
        onCancel={() => onClose()}
      >
        <UpdateRateForm {...props} onClose={onClose} />
      </Modal>

      <ActionIconButton
        size="small"
        type="text"
        className="text-green-500 mx-2"
        ghost={false}
        icon={<EditFilled />}
        loading={show}
        onClick={() => setShow(true)}
      />
    </>
  );
}
