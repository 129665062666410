import { Empty, message, Modal } from "antd";

import { Action, Loan } from "../../../hooks/useLoanAction";
import CloseIcon from "../../common/CloseIcon";
import LoanCard from "../LoanCard";
import CloseLoanForm from "./CloseLoan";
import DeleteLoanForm from "./DeleteLoan";
import ReviveLoanForm from "./ReopenLoan";

type Props = {
  loan: Loan;
  action: Action;
  onClose: (done?: boolean) => void;
};

export default function LoanFormModal({ loan, action, onClose }: Props) {
  function getLoanActionForm() {
    switch (action) {
      case "close":
        return (
          <CloseLoanForm
            loan={loan}
            onSuccess={() => {
              message.success(`PSN# ${loan.pledgeSheetNumber} is closed!`);
              onClose(true);
            }}
          />
        );
      case "delete":
        return (
          <DeleteLoanForm
            loan={loan}
            onSuccess={() => {
              message.success(`PSN# ${loan.pledgeSheetNumber} is deleted!`);
              onClose(true);
            }}
          />
        );
      case "reopen":
        return (
          <ReviveLoanForm
            loan={loan}
            onSuccess={() => {
              message.success(`PSN# ${loan.pledgeSheetNumber} is re-opened!`);
              onClose(true);
            }}
          />
        );
      default:
        return <Empty description={`Invalid loan action: ${action}`} />;
    }
  }
  return (
    <Modal
      title={
        <>
          <h4 className="my-2 font-bold text-2xl text-center">{action.toLocaleUpperCase()} LOAN</h4>
          <LoanCard loan={loan} />
        </>
      }
      centered
      closable
      maskClosable
      open
      closeIcon={<CloseIcon />}
      footer={false}
      onCancel={() => onClose()}
    >
      {getLoanActionForm()}
    </Modal>
  );
}
