import { useMemo, useState } from "react";
import { Card, Divider, Select } from "antd";
import dayjs from "dayjs";
import { gql, useQuery } from "urql";

import { BREAKPOINTS } from "../../../constants/common";
import { INVESTOR_INTEREST_CREDIT_RATE_PERCENTAGE } from "../../../constants/rate";
import { TRANSACTION_LEG_FRAGMENT } from "../../../hooks/api/accounts/useAccountTransactionsSearch";
import { formatCurrency, formatRate } from "../../../utils/currency";
import {
  FinancialPeriod,
  getFinancialPeriods,
  toDisplayDate,
  toServerDateNonNull,
} from "../../../utils/date";
import { sum } from "../../../utils/number";
import FilterLabel from "../../common/FilterLabel";
import ResponsiveTable, { ResponsiveColumnType } from "../../common/ResponsiveTable";
import AccountLink from "../AccountLink";
import { ACCOUNT_TRANSACTION_COLUMNS } from "../transactions/AccountTransactions";

const INVESTOR_INTERESTS_QUERY: InvestorInterestsQueryDoc = gql`
  query InvestorInterests($startDate: Date!, $endDate: Date!) {
    investorInterests(startDate: $startDate, endDate: $endDate) {
      creditedInterest
      accuredInterest
      accountTransactions {
        account {
          id
          description
          investorInterestRate
        }
        openingBalance
        closingBalance
        transactions {
          ...TransactionLeg
        }
      }
    }
  }
  ${TRANSACTION_LEG_FRAGMENT}
`;

type InvestorInterest = InvestorInterestsQuery["investorInterests"][0];

const COLUMNS: ResponsiveColumnType<InvestorInterest>[] = [
  {
    title: "Account",
    key: "account",
    dataIndex: "accountTransactions",
    render: (value, row) => <AccountLink {...row.accountTransactions.account} />,
    width: 300,
    cardable: { position: "top" },
  },
  {
    title: "Rate (%)",
    key: "rate",
    dataIndex: "accountTransactions",
    sorter: (a, b) =>
      (a.accountTransactions.account.investorInterestRate ||
        INVESTOR_INTEREST_CREDIT_RATE_PERCENTAGE) -
      (b.accountTransactions.account.investorInterestRate ||
        INVESTOR_INTEREST_CREDIT_RATE_PERCENTAGE),
    render: (value, row) =>
      formatRate(
        (row.accountTransactions.account.investorInterestRate ||
          INVESTOR_INTEREST_CREDIT_RATE_PERCENTAGE) / 100
      ),
    cardable: { position: "body" },
  },
  {
    title: "Opening Balance",
    key: "openingBalance",
    dataIndex: "accountTransactions",
    render: (value, row) => formatCurrency(row.accountTransactions.openingBalance),
    cardable: { position: "body" },
  },
  {
    title: "Closing Balance",
    key: "closingBalance",
    dataIndex: "accountTransactions",
    render: (value, row) => formatCurrency(row.accountTransactions.closingBalance),
    cardable: { position: "body" },
  },
  {
    title: "Accured Interest",
    dataIndex: "accuredInterest",
    key: "accuredInterest",
    render: (value) => formatCurrency(value),
    cardable: { position: "top" },
  },
  {
    title: "Credited Interest",
    dataIndex: "creditedInterest",
    key: "creditedInterest",
    render: (value) => formatCurrency(value),
    cardable: { position: "body" },
  },
];

function FinancialPeriodSelector({
  value,
  onSelection,
}: {
  value: FinancialPeriod;
  onSelection: (option: FinancialPeriod) => void;
}) {
  const financialPeriods = getFinancialPeriods();
  const options = useMemo(() => {
    const quartersByFinancialYear = financialPeriods.reduce((groups, value) => {
      if (!groups[value.financialYear]) {
        groups[value.financialYear] = [];
      }
      const options = groups[value.financialYear];
      options.push(value);
      return groups;
    }, {} as { [K: string]: FinancialPeriod[] });

    return Object.keys(quartersByFinancialYear)
      .sort((a, b) => -a.localeCompare(b))
      .map((financialYear) => ({
        label: financialYear,
        options: quartersByFinancialYear[financialYear].sort(
          (a, b) => -a.label.localeCompare(b.label)
        ),
      }));
  }, [financialPeriods]);

  return (
    <Select<FinancialPeriod>
      className="w-full"
      value={value}
      options={options}
      onChange={(_, option) => {
        if (option) {
          onSelection(option as FinancialPeriod);
        }
      }}
      size="large"
    />
  );
}

export default function InvestorInterests() {
  const financialPeriods = getFinancialPeriods();
  const defaultFinancialQuarter = financialPeriods[financialPeriods.length - 1];

  const [selectedPeriod, setSelectedPeriod] = useState<FinancialPeriod>(defaultFinancialQuarter);
  const variables = useMemo(() => {
    return {
      startDate: toServerDateNonNull(selectedPeriod.range.startDate),
      endDate: toServerDateNonNull(selectedPeriod.range.endDate),
    };
  }, [selectedPeriod]);

  const [{ data, fetching }] = useQuery({ query: INVESTOR_INTERESTS_QUERY, variables });
  const { totalInterest, interests } = useMemo(() => {
    if (data) {
      const interests = data.investorInterests;
      return {
        totalInterest: sum(...interests.map((i) => i.accuredInterest)),
        interests,
      };
    } else {
      return { totalInterest: 0, interests: [] };
    }
  }, [data]);

  function expandedRowRender(row: InvestorInterest) {
    return (
      <ResponsiveTable<TransactionLegFragment>
        printSettings={false}
        size="small"
        rowKey="id"
        bordered
        columns={ACCOUNT_TRANSACTION_COLUMNS}
        dataSource={row.accountTransactions.transactions}
        pagination={false}
      />
    );
  }

  return (
    <Card
      headStyle={{ padding: 0 }}
      bodyStyle={{ padding: 0 }}
      title={
        <div className="flex w-full flex-col justify-between items-start py-3 pr-3 px-1 bg-gray-50">
          <div className="flex flex-col w-full items-center pl-3 pb-2">
            <div className="text-3xl mb-2 lg:mb-0 font-bold text-gray-600 whitespace-normal pb-1">
              Interest
            </div>
            <div className="text-lg font-medium text-center bg-yellow-100 text-gray-600 rounded-xl px-3 py-1">
              {toDisplayDate(selectedPeriod.range.startDate)} -{" "}
              {toDisplayDate(selectedPeriod.range.endDate)}
            </div>
          </div>

          <Divider className="my-1" />

          <div className="flex w-full justify-between pl-2 pb-1">
            <div className="flex flex-col w-64">
              <div className="text-xl mb-2 lg:mb-0 font-bold text-gray-600 whitespace-normal pb-1">
                {selectedPeriod.range.endDate.isAfter(dayjs()) ? (
                  <>
                    Accured Interests<span className="text-red-500 font-bold pl-1 pr-1">*</span>
                    <span className="text-xs align-text-top text-gray-500 underline">
                      as-of today
                    </span>
                  </>
                ) : (
                  "Total Interests"
                )}
              </div>
              <div className="text-lg font-semibold bg-green-50 text-gray-600 rounded-xl px-2 py-1">
                {formatCurrency(totalInterest)}
              </div>
            </div>

            <div className="flex flex-col w-80 px-2 items-start justify-center flex-wrap">
              <FilterLabel label="Financial Year Period:" />
              <FinancialPeriodSelector value={selectedPeriod} onSelection={setSelectedPeriod} />
            </div>
          </div>
        </div>
      }
    >
      <ResponsiveTable<InvestorInterest>
        rowKey={(row) => row.accountTransactions.account.id}
        expandable={{
          expandedRowRender,
          indentSize: 0,
          rowExpandable: (record) => !!record.accountTransactions.transactions.length,
        }}
        bordered
        // scroll={{ y: 660, x: BREAKPOINTS.md }}
        dataSource={interests}
        columns={COLUMNS}
        loading={fetching}
        pagination={false}
        printSettings={{
          title: `INVESTOR INTEREST FOR PERIOD ${toDisplayDate(
            selectedPeriod.range.startDate
          )} - ${toDisplayDate(selectedPeriod.range.endDate)}`,
        }}
      />
    </Card>
  );
}
