import { Tag } from "antd";
import dayjs from "dayjs";
import * as Yup from "yup";

import { FieldPair } from "../types";
import { isNull } from "./common";
import { formatCurrency } from "./currency";
import { formatNumber } from "./number";

export function getDatesText(minDate?: string, maxDate?: string) {
  return minDate && maxDate
    ? maxDate === minDate
      ? `: ${dayjs(minDate).format("MMM DD, YY")}`
      : `Between ${dayjs(minDate).format("MMM DD, YY")} & ${dayjs(maxDate).format("MMM DD, YY")}`
    : minDate
    ? `From ${dayjs(minDate).format("MMM DD, YY")}`
    : maxDate
    ? `Upto ${dayjs(maxDate).format("MMM DD, YY")}`
    : "";
}

export function getAmountText(min?: number, max?: number) {
  return min && max
    ? `Between ${formatCurrency(min)} & ${formatCurrency(max)}`
    : min
    ? `From ${formatCurrency(min)}`
    : max
    ? `Upto ${formatCurrency(max)}`
    : "";
}

export function getNumberText(min?: number, max?: number) {
  return min && max
    ? `Between ${formatNumber(min)} & ${formatNumber(max)}`
    : min
    ? `From ${formatNumber(min)}`
    : max
    ? `Upto ${formatNumber(max)}`
    : "";
}

export function getSelectedFilters<T>(values: Partial<T>): (keyof T)[] {
  return Object.keys(values)
    .filter((field) => {
      const _field = field as keyof T;
      return !["first", "offset"].includes(_field as string) && !!values[_field];
    })
    .map((field) => field as keyof T);
}

export function getSelectedFiltersCount<T>(values: Partial<T>) {
  return getSelectedFilters(values).length;
}

export function changesCount<T>(one: Partial<T>, two: Partial<T>) {
  const fields = Array.from(new Set([...Object.keys(one), ...Object.keys(two)]));

  return fields.reduce((changes, field) => {
    const _field = field as keyof T;
    if (
      !["first", "offset"].includes(_field as string) &&
      (!!one[_field] || !!two[_field]) &&
      one[_field] !== two[_field]
    ) {
      changes += 1;
    }
    return changes;
  }, 0);
}

export function getFieldPairsValidationSchema<T>(fields: FieldPair<T>[]) {
  return {
    ...getMinMaxFieldsValidationSchema(
      fields.filter((field) => !field.isDate).map((field) => [field.minField, field.maxField])
    ),
    ...getDateRangeValidationSchema(
      fields.filter((field) => !!field.isDate).map((field) => [field.minField, field.maxField])
    ),
  };
}

export function getMinMaxFieldsValidationSchema<T>(fields: [keyof T, keyof T][]) {
  return fields.reduce((hash, value) => {
    hash[value[0]] = Yup.number()
      .nullable()
      .optional()
      .when([value[1] as string], (maxValue, schema) => {
        return !isNull(maxValue)
          ? schema.max(maxValue, "Min value can't be more than max value.")
          : schema.max(Number.MAX_VALUE);
      });
    return hash;
  }, {} as { [K in keyof T]: Yup.AnySchema });
}

export function getDateRangeValidationSchema<T>(fields: [keyof T, keyof T][]) {
  return fields.reduce((hash, value) => {
    hash[value[0]] = Yup.date()
      .nullable()
      .optional()
      .when([value[1] as string], (maxValue, schema) => {
        return !isNull(maxValue)
          ? schema.max(maxValue, "Start date can't be more than End date.")
          : schema.nullable();
      });
    return hash;
  }, {} as { [K in keyof T]: Yup.AnySchema });
}

export const FilterTag = ({ children }: { children: React.ReactNode }) => {
  return (
    <Tag className="rounded-xl px-3 py-1 text-xs" color="purple">
      {children}
    </Tag>
  );
};

export function getTextForFilterPair<T>(
  record: T,
  prefix: string,
  minField: keyof T,
  maxField: keyof T,
  callback: typeof getNumberText | typeof getAmountText | typeof getDatesText,
  ignoreMinMax?: boolean
): React.ReactNode {
  const minValue = record[minField] as undefined;
  const maxValue = record[maxField] as undefined;

  if (isNull(minValue) && isNull(maxValue)) {
    return null;
  }

  if (ignoreMinMax && !isNull(minValue)) {
    return null;
  }

  return (
    <FilterTag>
      {prefix} {callback(minValue, maxValue)}
    </FilterTag>
  );
}
