import { useMemo } from "react";
import { gql } from "@urql/core";
import { useQuery } from "urql";

import useQueryParams from "../../useQueryParams";

export const CUSTOMER_DETAILS_FRAGMENT: CustomerDetailsFragmentDoc = gql`
  fragment CustomerDetails on Customer {
    id
    gender
    occupation
    address
    careOfType
    careOfName
    isRepledgeCustomer
    user {
      id
      name
      phone
      dateJoined
    }
  }
`;

export const GET_CUSTOMER_QUERY: GetCustomerQueryDoc = gql`
  query GetCustomer($id: Int!) {
    customers(id: $id) {
      edges {
        node {
          ...CustomerDetails
        }
      }
    }
  }
  ${CUSTOMER_DETAILS_FRAGMENT}
`;

export default function useCustomer(): { customer?: CustomerDetailsFragment; fetching: boolean } {
  const id = useQueryParams("customer_id");

  const variables = useMemo(() => ({ id: id ? parseInt(id) : -1 }), [id]);

  const [{ data, fetching }] = useQuery({ query: GET_CUSTOMER_QUERY, variables, pause: !id });

  const customer = data?.customers?.edges?.[0]?.node;

  return { customer, fetching };
}
